import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef } from '@angular/material/dialog';
import {
  ADD_EMAIL,
  EMAIL_ALREADY_EXISTS,
  EMAIL_REGEX,
  VALID_EMAIL,
} from '../constants';
@Component({
  selector: 'app-run-report',
  templateUrl: './run-report.component.html',
  styleUrl: './run-report.component.css',
})
export class RunReportComponent {
  runReportForm: FormGroup;
  emails: string[] = [];
  emailError: string | null = null;
  constructor(
    public dialogRef: MatDialogRef<RunReportComponent>,
    private fb: FormBuilder,
  ) {
    this.runReportForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }
  get email() {
    return this.runReportForm.get('email');
  }
  onClose(): void {
    this.dialogRef.close();
  }
  addChip(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value && !this.emails.includes(value)) {
      if (this.isValidEmail(value)) {
        this.emails.push(value);
        this.emailError = null;
      } else {
        this.emailError = VALID_EMAIL;
      }
    } else if (this.emails.includes(value)) {
      this.emailError = EMAIL_ALREADY_EXISTS;
    }
    event.chipInput!.clear();
  }
  removeChip(email: string): void {
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }
  runReport() {
    this.runReportForm.markAllAsTouched();
    if (this.runReportForm.valid && this.emails.length > 0) {
      this.dialogRef.close();
    } else {
      this.emailError = ADD_EMAIL;
    }
  }
  isValidEmail(email: string): boolean {
    const emailPattern = EMAIL_REGEX;
    return emailPattern.test(email);
  }
}