import {
  Component,
  ElementRef,
  Renderer2,
  OnInit,
  OnDestroy,
  inject,
  ViewChild,
  HostListener,
} from '@angular/core';
import { NotificationService } from '../../notification/notification.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import {
  AuthenticateUserState,
  RemoveAuthenticateUser,
} from '../../../core/store/authenticate-user.state';
import { Store } from '@ngxs/store';
import { Observable, Subscription, filter, tap } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizationLoaderService } from '../../../localization-loader.service';
import { BaseAWSRequestService } from '../../service/base-services/base-aws-request.service';
import { exportoperation } from '../../models/exportoperation';
import { format, isToday } from 'date-fns';
import { DATEFORTMAT1, DATEFORTMAT2 } from '../../constants';
import { TopNavigationService } from '../../../core/services/top-navigation.service';
import { PermissionService } from '../../../core/services/permission.service';
@Component({
  selector: 'app-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrl: './top-navigation.component.css',
})
export class TopNavigationComponent implements OnInit, OnDestroy {
  @ViewChild('anchor', { static: false }) anchor!: ElementRef;
  isUserSettingPopUpOpen = false;
  isNotificationPopupOpen = false;
  hasPopupBeenOpened = false;
  currentUrl = '';
  loggedInName$: Observable<string | null | undefined> | undefined;
  primaryCompanyName$: Observable<string | null | undefined> | undefined;
  countries = [
    { code: 'en', icon: 'flag_us', name: 'English' },
    { code: 'es', icon: 'flag_spain', name: 'Spain' },
    { code: 'fr', icon: 'flag_spain', name: 'French' },
  ];
  selectedCode = 'en';
  selectedIcon = 'flag_us';
  selectedName = 'English';

  userSettingList = [
    { id: 1, name: 'My Profile', icon: 'open_in_new' },
    { id: 2, name: 'Logout', icon: 'open_in_new' },
  ];

  private readonly oidcSecurityService = inject(OidcSecurityService);
  private exportStatusSubscription: Subscription | undefined;
  syrcUserId: string | null = null; // To store the userId
  userIdSubscription: Subscription | null = null; // Subscription for user ID
  corviewUserId$: Observable<string | null>;

  private clickListener: () => void;
  corviewUserId = '';
  selectedPrimaryCompany = '';
  selectedLevel3Value = '';

  constructor(
    private permissionService: PermissionService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private notificationService: NotificationService,
    private topNavigationService: TopNavigationService,
    private store: Store,
    private router: Router,
    private translate: TranslateService,
    private localizationLoader: LocalizationLoaderService,
    private awsService: BaseAWSRequestService,
  ) {
    this.notificationService.notificationState.subscribe((state: boolean) => {
      this.isNotificationPopupOpen = state;
    });
    this.clickListener = this.renderer.listen(
      'document',
      'click',
      (event: MouseEvent) => {
        this.onDocumentClick(event);
      },
    );

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.currentUrl = this.router.url;
      });

    this.corviewUserId$ = this.store.select(
      AuthenticateUserState.getLoggedInCorViewUserId,
    );
  }

 async ngOnInit(): Promise<void> {
    // Retrieve the selected language from localStorage
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      this.selectedCode = savedLanguage;
    }
    this.unsubscribePreviousSubscription();
    this.translate.setDefaultLang(this.selectedCode);
    this.translate.use(this.selectedCode);

    // Set the corresponding icon and name based on the language code
    const selectedCountry = this.countries.find(
      (country) => country.code === this.selectedCode,
    );
    if (selectedCountry) {
      this.selectedIcon = selectedCountry.icon;
      this.selectedName = selectedCountry.name;
    }

    this.renderer.listen('document', 'click', (event: Event) => {
      this.onClickOutside(event);
    });

    // Fetch the logged-in user name from the store
    this.loggedInName$ = this.store.select(
      AuthenticateUserState.getLoggedInName,
    );
    this.primaryCompanyName$ = this.store.select(
      AuthenticateUserState.getSyrcPrimaryCompanyName,
    );

    this.userIdSubscription = this.store
      .select(AuthenticateUserState.getSyrcUserId)
      .subscribe({
        next: (userId) => {
          this.syrcUserId = userId;
          if (this.syrcUserId && this.syrcUserId.length > 1) {
            this.exportSubscribe();
          }
        },
        error: (err) => {
          console.error('Error getting user ID:', err);
        },
      });
  
  }


  @HostListener('document:click', ['$event'])
  onUserSettingPoupClick(event: MouseEvent): void {
    const clickedInside = this.anchor.nativeElement.contains(event.target);

    // Close the popup if clicked outside of the button or popup
    if (!clickedInside && this.isUserSettingPopUpOpen) {
      this.isUserSettingPopUpOpen = false;
    }
  }

  userSettingClicked() {
    if (!this.isUserSettingPopUpOpen) {
      this.isUserSettingPopUpOpen = true;
    } else {
      this.isUserSettingPopUpOpen = false;
    }
  }

  userSettingOptionClicked(dataItem: any) {
    if (dataItem.id == 2) {
      this.logout();
    }
  }

  changeLanguage(country: { code: string; icon: string; name: string }) {
    this.selectedCode = country.code;
    this.selectedIcon = country.icon;
    this.selectedName = country.name;
    // Change the language and save the preference to localStorage
    this.translate.use(this.selectedCode);
    localStorage.setItem('selectedLanguage', this.selectedCode);
    //this.localizationLoader.loadTranslations(this.selectedCode);
  }

  openNotificationPopup(event: MouseEvent) {
    if (!this.hasPopupBeenOpened) {
      event.stopPropagation();
      this.notificationService.setNotificationState(true);
      if (this.isNotificationPopupOpen) {
        this.hasPopupBeenOpened = true;
      }
    } else {
      this.notificationService.setNotificationState(false);
      this.hasPopupBeenOpened = false;
      this.notificationService.markAllAsRead();
    }
  }

  onDocumentClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isNotificationPopupOpen = false;
    } else {
      this.isNotificationPopupOpen = false;
    }
    // const clickedInside = this.anchor.nativeElement.contains(event.target);
    // // Close the popup if clicked outside of the button or popup
    // if (!clickedInside && this.isUserSettingPopUpOpen) {
    //   this.isUserSettingPopUpOpen = false;
    // }
  }

  closeNotification() {
    this.notificationService.setNotificationState(false);
  }

  onClickOutside(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isNotificationPopupOpen = false;

      if (this.hasPopupBeenOpened) {
        // Mark all notifications as read
        this.notificationService.markAllAsRead();
      }
    }
  }

  logout(): void {
    this.oidcSecurityService
      .logoffAndRevokeTokens()
      .pipe(
        tap(() => this.store.dispatch(new RemoveAuthenticateUser())),
        //tap(() => window.location.href = "https://account.corview.cloud/connect/endsession"),
      )
      .subscribe({
        error: (e) => console.error('Logoff error:', e),
        complete: () => console.log('Logged off successfully'),
      });
  }

  ngOnDestroy() {
    if (this.clickListener) {
      this.clickListener();
    }

    this.unsubscribePreviousSubscription();
  }

  async exportSubscribe(): Promise<void> {
    if (this.syrcUserId) {
      this.exportStatusSubscription = this.awsService
        .eexecuteExportStatusSubscription(this.syrcUserId)
        .subscribe({
          next: async (exportMessage: exportoperation) => {
            // <-- mark `next` handler as async
            try {
              const fileName = this.extractFileName(
                exportMessage.exportMessage,
              );
              this.addNewNotification(fileName);
              this.downloadFile(exportMessage.exportMessage, fileName);
            } catch (error) {
              console.error('Error getting pre-signed URL:', error);
            }
          },
          error: (err: any) => {
            console.error('Subscription error:', err);
          },
        });
    }
  }

  addNewNotification(fileName: string) {
    this.notificationService.addNotification({
      avatar: '',
      name: '',
      fileName: fileName,
      fileSize: '',
      timestamp: this.formatNotificationDate(new Date()),
      isNew: true,
    });
  }

  public formatNotificationDate(date: Date): string {
    if (isToday(date)) {
      return `Today at ${format(date, DATEFORTMAT2)}`;
    } else {
      return `${format(date, DATEFORTMAT1)} at ${format(date, DATEFORTMAT2)}`;
    }
  }

  downloadFile(fileUrl: string, fileName: string): void {
    fetch(fileUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to download file');
        }
        return response.blob();
      })
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  }

  extractFileName(url: string): string {
    const fileNameWithQuery = url.split('/').pop()!;
    const fileName = fileNameWithQuery.split('?')[0];
    return fileName;
  }

  closeUserSettings(): void {
    this.isUserSettingPopUpOpen = false;
  }

  private unsubscribePreviousSubscription(): void {
    if (this.exportStatusSubscription) {
      this.exportStatusSubscription.unsubscribe();
    }
  }
}
