<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="col-auto">
  <h5 class="mtx-text-primary" style="margin-left: 10px; margin-top: 6px">
    {{ 'SETUP_SEGMENT_ASSET.PAGE_TITLE' | translate }}
  </h5>
</div>
<div class="card mt-4">
  <div class="card-body">
    <div class="progress-container mb-4 bs-stepper">
      <div class="step-container">
        <span style="color: #737373"
          >{{ 'SETUP_SEGMENT_ASSET.STEP' | translate }}
          {{ currentStep }}/5</span
        >
        <div class="progress-bars">
          <div
            class="progress-bar"
            [ngClass]="{
              'active-bar': currentStep >= 1,
              'inactive-bar': currentStep < 1,
            }"
            role="progressbar"
          ></div>
          <div
            class="progress-bar"
            [ngClass]="{
              'active-bar': currentStep >= 2,
              'inactive-bar': currentStep < 2,
            }"
            role="progressbar"
          ></div>
          <div
            class="progress-bar"
            [ngClass]="{
              'active-bar': currentStep >= 3,
              'inactive-bar': currentStep < 3,
            }"
            role="progressbar"
          ></div>
          <div
            class="progress-bar"
            [ngClass]="{
              'active-bar': currentStep >= 4,
              'inactive-bar': currentStep < 4,
            }"
            role="progressbar"
          ></div>
          <div
          class="progress-bar"
          [ngClass]="{
            'active-bar': currentStep >= 5,
            'inactive-bar': currentStep < 5,
          }"
          role="progressbar"
        ></div>
        </div>
      </div>
    </div>
    <div class="header-container">
      <div class="line"></div>
      <div style="margin-top: -5px">
        <h5 style="font-size: 20px; font-weight: 500">{{ header }}</h5>
        <p style="font-size: 15px; color: #8e8e8e">{{ subHeader }}</p>
      </div>
    </div>

    <div class="card mtx-card-bg-primary mtx-card-info">
      <div class="card-body mtx-padding-bottom-0">
        <div class="row g-1">
          <div class="col-sm-12 col-md-9 d-flex">
            <label
              class="mtx-text-primary"
              style="
                margin-right: 5px;
                font-size: 20px;
                font-weight: 500;
                color: #5b5b5b;
              "
              >{{
                'SETUP_SEGMENT_ASSET.GETTING_STARTED_TITLE' | translate
              }}</label
            >
          </div>
        </div>
        <div style="margin-top: -5px">
          <div class="col">
            <label style="color: #8e8e8e">{{
              'SETUP_SEGMENT_ASSET.BULLET_POINT_1' | translate
            }}</label>
            <div
              style="display: flex; flex-direction: column; margin-left: 25px"
            >
              <label style="color: #8e8e8e">{{
                'SETUP_SEGMENT_ASSET.BULLET_POINT_2' | translate
              }}</label>
              <label style="color: #8e8e8e">{{
                'SETUP_SEGMENT_ASSET.BULLET_POINT_3' | translate
              }}</label>
              <label style="color: #8e8e8e">{{
                'SETUP_SEGMENT_ASSET.BULLET_POINT_4' | translate
              }}</label>
              <label style="color: #8e8e8e">{{
                'SETUP_SEGMENT_ASSET.BULLET_POINT_5' | translate
              }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
  class="mt-4"
  style="margin-left: 20px; margin-bottom: 80px"
  *ngIf="markBtnVisibility"
>
  <div class="upload-section">
    <h5>
      {{ 'SETUP_SEGMENT_ASSET.UPLOAD_SEGMENT_ASSET_TITLE' | translate }}
    </h5>

    <div class="segment-list" style="margin-left: 10px">
      <ul class="list-unstyled">
        <li
          *ngFor="let segment of listOfSegmentsAndAssets; let i = index"
          class="d-flex align-items-center mb-2 segment-item"
        >
          <div class="line-container">
            <div class="vertical-line"></div>
            <div class="dot"></div>
          </div>

          <div class="segment-details" style="margin-top: 40px; margin-left: -10px"></div>

          <div style="display: flex; margin-top: 42px">
            <span style="width: 170px;">{{ segment.segmentName }}</span>

            <div *ngIf="segment.files.length > 0" style="margin-left: 20px; display: flex;">
              <div *ngFor="let file of segment.files; let j = index" style="display: flex; align-items: center">
                
                <!-- Invalid File Section -->
                <span *ngIf="!file.isValid"
                  class="material-icons"
                  style="
                  margin-left: 5px;
                    color: white;
                    background-color: red;
                    border-radius: 50%;
                    padding: 2px;
                    font-size: 12px;
                  ">
                  fmd_bad
                </span>
                <span *ngIf="!file.isValid" style="color: red; margin-left: 5px;">
                  {{ 'SETUP_SEGMENT_ASSET.INVALID_FILE_UPLOAD' | translate }}
                </span>
                <span *ngIf="!file.isValid"
                  class="material-icons"
                  style="
                   margin-left: 5px;
                    color: red;
                    cursor: pointer;
                    padding: 2px;
                    font-size: 16px;
                  " (click)="downloadInvalidFile()">
                  save_alt
                </span>

                <!-- Valid File Section -->
                <span *ngIf="file.isValid"
                  class="material-icons"
                  style=" margin-left: 5px;
                    color: white;
                    background-color: #22c55e;
                    border-radius: 50%;
                    padding: 2px;
                    font-size: 12px;
                  ">
                  check
                </span>
                <span *ngIf="file.isValid" style="color: #22c55e; margin-left: 5px">
                  {{ 'SETUP_SEGMENT_ASSET.FILE_UPLOADED_SUCCESSFULLY' | translate }}
                </span>

                <!-- File Name and Remove File -->
                <span style="margin-left: 5px">
                  <a style="color: #3498db; margin-right: 5px">{{ file.name }}</a>
                  <!-- <span
                    class="material-icons close-icon"
                    (click)="removeFile(i, j)"
                    style="
                      cursor: pointer;
                      background-color: #b3b3b3;
                      color: white;
                      border-radius: 50%;
                      padding: 2px;
                      font-size: 14px;
                    ">
                    close
                  </span> -->
                </span>

              </div>
            </div>

            <div style="display: flex; align-items: center; margin-left: 15px">
              <span class="material-icons" style="color: #3498db">upload</span>

              <label
                for="fileUpload-{{ i }}"
                style="
                  color: #3498db;
                  text-decoration: underline;
                  cursor: pointer;
                ">
                {{ 'SETUP_SEGMENT_ASSET.UPLOAD_INFORMATION_FILES' | translate }}
              </label>
              <input 
                type="file"
                id="fileUpload-{{ i }}"
                (change)="onFileSelected($event, i, segment.segmentName, segment.assetTypeId)"
                style="display: none"
              />
            </div>
          </div>

          <div class="d-flex justify-content-end" style="margin-left: 20px" *ngIf="segment.files.length <= 0">
            <button
              (click)="downloadFileFormat(segment.segmentName)"
              class="btn mtx-btn-primary"
              style="
                width: 280px;
                height: 38px;
                margin-top: 20px;
                font-weight: 500;
              ">
              <span style="vertical-align: middle" class="material-icons">download</span>
              {{ 'SETUP_SEGMENT_ASSET.DOWNLOAD_BUTTON' | translate }}
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

  </div>

  <div class="card-footer">
    <div class="row"  style="justify-content: end;">
      <div class="col-md-6"></div>
      <div class="col-md-2">
        <button
          class="btn btn-secondary mtx-btn-secondary mtx-btn-custom"
          (click)="onCancel()"
          type="submit"
        >
          {{ 'SETUP_SEGMENT_ASSET.BTN_CANCEL' | translate }}
        </button>
      </div>
      <div class="col-md-2" *ngIf="!markBtnVisibility">
        <button
          class="btn btn-primary mtx-btn-custom mark-btn"
          type="button"
          (click)="nextBtnClick()"
        >
          {{ 'SETUP_SEGMENT_ASSET.BTN_NEXT' | translate }}
        </button>
      </div>
      <div class="col-md-2" *ngIf="markBtnVisibility">
        <button
          class="btn btn-primary mtx-btn-custom mark-btn"
          [disabled]="isDisabled"
          (click)="saveAndExitBtnClick()"
          type="button"
        >
          {{ 'SETUP_SEGMENT_ASSET.BTN_MARK' | translate }}
        </button>
      </div>
      <div class="col-md-2" *ngIf="markBtnVisibility">
        <button
          class="btn btn-primary mtx-btn-custom mark-btn"
          [disabled]="isDisabled"
          (click)="saveAndNextBtnClick()"
          type="button"
        >
          {{ 'SETUP_SEGMENT_ASSET.BTN_MARK_NXT' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
