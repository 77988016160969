import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrl: './export-modal.component.css',
})
export class ExportModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ExportModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}
}
