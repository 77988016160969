import { Injectable } from '@angular/core';
import {
  ADD_TO_FAV,
  ASSET_OVERVIEW,
  ASSET_SETUP,
  ASSETHISTORY,
  ASSETSETUP,
  ASSIGN_RMU,
  CHECK_IMAGE,
  COMPANY_OVERVIEW,
  DATA_PROCESSING,
  DELETE,
  DELETE_IMAGE,
  EDIT,
  EDIT_ASSET_ROUTE,
  EDIT_COMPANY_DETAILS,
  EDIT_IMAGE,
  EDIT_NOTE_IMAGE,
  EDIT_PRIMARY_COMPANY,
  EDIT_SURVEY,
  EDIT_SURVEY_ROUTE,
  EXCEPTION,
  EXCEPTIONS,
  FAV_IMAGE,
  FIELD_DATA_SUMMARY,
  GOTO_ASSET_HISTORY,
  GOTO_INSPECTION,
  GOTO_MAP,
  INSPECTION,
  INSPECTIONS,
  LOGINAS,
  LOGINAS_IMAGE,
  MAP,
  MOBILTEXMEMBERS,
  PENDING_IMAGE,
  PIN_IMAGE,
  PIN_ROW,
  REDIRECT_ASSET_HISTORY,
  REDIRECT_DATA_PROCESSING,
  REDIRECT_EDIT_SURVEY,
  REDIRECT_EXCEPTION,
  REDIRECT_IMAGE,
  REDIRECT_INSPECTION,
  REDIRECT_MAPS,
  REDIRECT_NONE,
  REPORT,
  RUN_REPORT,
  RUN_REPORT_IMAGE,
  SETUP_ASSET_TYPES,
  SETUP_HIERARCHY,
  SETUP_HIERARCHY_LEVELS,
  SETUP_IMPORT_INSPECTION,
  SETUP_SEGMENT_ASSET,
  SETUP_SEGMENTS_ASSETS,
  SURVEY_BACKLOG_IMAGE,
  SURVEYOVERVIEW,
  SURVEYROUTEMANAGEMENT,
  USERHISTORY,
  USERHISTORY_IMAGE,
  USEROVERVIEW,
  VIEW_IMAGE,
  VIEW_REPORT,
} from '../../constants';

@Injectable({
  providedIn: 'root',
})
export class RowActionsService {
  permission = false;
  menuOptions: {
    id: number;
    name: string;
    imgType: string;
    refLink: string;
    isEnable: boolean;
  }[] = [];
  assignRowActionElements(screenType: string, selectedRowData: any) {
    if (screenType === INSPECTION) {
      this.getInspectionRowItems();
      this.updateMenuOptionsBasedOnStatus(selectedRowData?.complianceStatus);
    } else if (screenType === EXCEPTION) {
      this.getExceptionRowItems();
    } else if (screenType == ASSETSETUP) {
      this.getAssetSetupRowItems();
    } else if (screenType == ASSETHISTORY) {
      this.getAssetHistoryItems();
    } else if (screenType == REPORT) {
      this.getReportItems();
    } else if (screenType == SURVEYOVERVIEW) {
      this.getSurveyOverviewItems();
    } else if (screenType == SURVEYROUTEMANAGEMENT) {
      this.getSurveyRouteItems();
    } else if (screenType == USEROVERVIEW) {
      this.getUserOverviewItems();
    } else if (screenType == COMPANY_OVERVIEW) {
      this.getCompanyItems();
      this.updateMenuOptionsBasedOnStep(selectedRowData?.setupStatus || 1);
    }
    else if (screenType == MOBILTEXMEMBERS) {
      this.getMobiltexMembersItems();
    }
    return this.menuOptions;
  }

  getInspectionRowItems() {
    this.menuOptions = [
      { id: 1, name: PIN_ROW, imgType: PIN_IMAGE, refLink: '', isEnable: true },
      {
        id: 2,
        name: 'Exceptions',
        imgType: REDIRECT_IMAGE,
        refLink: '/exception',
        isEnable: true,
      },
      {
        id: 3,
        name: 'Asset History',
        imgType: REDIRECT_IMAGE,
        refLink: '/asset-history',
        isEnable: true,
      },
      {
        id: 4,
        name: 'Asset Setup',
        imgType: REDIRECT_IMAGE,
        refLink: '/asset-setup',
        isEnable: true,
      },
      {
        id: 5,
        name: 'Field Data',
        imgType: REDIRECT_IMAGE,
        refLink: '/field-data',
        isEnable: false,
      },
      {
        id: 6,
        name: 'Data Processing',
        imgType: REDIRECT_IMAGE,
        refLink: '/data-processing',
        isEnable: false,
      },
      {
        id: 7,
        name: 'Pending Survey',
        imgType: PENDING_IMAGE,
        refLink: '/pending-survey',
        isEnable: false,
      },
    ];
  }
  getExceptionRowItems() {
    this.menuOptions = [
      { id: 1, name: PIN_ROW, imgType: PIN_IMAGE, refLink: '', isEnable: true },
      {
        id: 2,
        name: 'Map',
        imgType: REDIRECT_IMAGE,
        refLink: '/maps',
        isEnable: true,
      },
      ...(this.permission
        ? [
            {
              id: 3,
              name: 'Assign for Resolution',
              imgType: CHECK_IMAGE,
              refLink: '/resolution',
              isEnable: true,
            },
            {
              id: 4,
              name: 'Assign Remediation Date',
              imgType: REDIRECT_IMAGE,
              refLink: '/resolution',
              isEnable: true,
            },
          ]
          : []),
      {
        id: 5,
        name: 'Inspection',
        imgType: REDIRECT_IMAGE,
        refLink: '/compliance/inspection/:assetName',
        isEnable: true,
      },
      {
        id: 6,
        name: 'Asset History',
        imgType: REDIRECT_IMAGE,
        refLink: '/asset-history',
        isEnable: true,
      },
      {
        id: 7,
        name: 'Asset Setup',
        imgType: REDIRECT_IMAGE,
        refLink: '/asset-setup',
        isEnable: true,
      },
      {
        id: 8,
        name: 'Field Data',
        imgType: REDIRECT_IMAGE,
        refLink: '/field-data',
        isEnable: true,
      },
      {
        id: 9,
        name: 'Data Processing',
        imgType: REDIRECT_IMAGE,
        refLink: '/data-processing',
        isEnable: true,
      },
      ...(this.permission
        ? [
            {
              id: 10,
              name: 'Push to Survey Backlog',
              imgType: SURVEY_BACKLOG_IMAGE,
              refLink: '/survey-backlog',
              isEnable: true,
            },
          ]
          : []),
    ];
  }

  getAssetSetupRowItems() {
    this.menuOptions = [
      {
        id: 1,
        name: PIN_ROW,
        imgType: PIN_IMAGE,
        refLink: REDIRECT_NONE,
        isEnable: true,
      },
      ...(this.permission
        ? [
            {
              id: 2,
              name: EDIT,
              imgType: EDIT_IMAGE,
              refLink: EDIT_ASSET_ROUTE,
              isEnable: true,
            },
          ]
        : []),
        ...(this.permission
          ? [
      {
        id: 4,
        name: ASSIGN_RMU,
        imgType: CHECK_IMAGE,
        refLink: '',
        isEnable: true,
      } ]
      : []),
      {
        id: 5,
        name: GOTO_ASSET_HISTORY,
        imgType: REDIRECT_IMAGE,
        refLink: REDIRECT_ASSET_HISTORY,
        isEnable: true,
      },
      {
        id: 6,
        name: GOTO_MAP,
        imgType: REDIRECT_IMAGE,
        refLink: REDIRECT_MAPS,
        isEnable: true,
      },

      {
        id: 7,
        name: GOTO_INSPECTION,
        imgType: REDIRECT_IMAGE,
        refLink: REDIRECT_INSPECTION,
        isEnable: true,
      },
      ...(this.permission
        ? [
            {
              id: 8,
              name: DELETE,
              imgType: DELETE_IMAGE,
              refLink: REDIRECT_NONE,
              isEnable: true,
            },
          ]
        : []),
    ];
  }

  getAssetHistoryItems() {
    this.menuOptions = [
      ...(this.permission
        ? [
            {
              id: 2,
              name: 'Edit Asset',
              imgType: EDIT_IMAGE,
              refLink: EDIT_ASSET_ROUTE,
              isEnable: true,
            },
          ]
          : []),
      {
        id: 3,
        name: EXCEPTIONS,
        imgType: REDIRECT_IMAGE,
        refLink: REDIRECT_EXCEPTION,
        isEnable: true,
      },
      {
        id: 4,
        name: FIELD_DATA_SUMMARY,
        imgType: REDIRECT_IMAGE,
        refLink: REDIRECT_NONE,
        isEnable: false,
      },
      {
        id: 5,
        name: INSPECTIONS,
        imgType: REDIRECT_IMAGE,
        refLink: REDIRECT_INSPECTION,
        isEnable: true,
      },
      {
        id: 6,
        name: DATA_PROCESSING,
        imgType: REDIRECT_IMAGE,
        refLink: REDIRECT_DATA_PROCESSING,
        isEnable: false,
      },
      {
        id: 7,
        name: MAP,
        imgType: REDIRECT_IMAGE,
        refLink: REDIRECT_MAPS,
        isEnable: false,
      },

      {
        id: 8,
        name: ASSET_SETUP,
        imgType: REDIRECT_IMAGE,
        refLink: REDIRECT_NONE,
        isEnable: false,
      },
    ];
  }

  getReportItems() {
    this.menuOptions = [
      {
        id: 2,
        name: VIEW_REPORT,
        imgType: VIEW_IMAGE,
        refLink: REDIRECT_NONE,
        isEnable: true,
      },
      ...(this.permission
        ? [
            {
              id: 3,
              name: ADD_TO_FAV,
              imgType: FAV_IMAGE,
              refLink: REDIRECT_NONE,
              isEnable: true,
            },
            {
              id: 4,
              name: EDIT,
              imgType: EDIT_NOTE_IMAGE,
              refLink: REDIRECT_NONE,
              isEnable: true,
            },
            {
              id: 5,
              name: RUN_REPORT,
              imgType: RUN_REPORT_IMAGE,
              refLink: '',
              isEnable: true,
            },
          ]
          : []),
    ];
  }

  getSurveyOverviewItems() {
    this.menuOptions = [
      { id: 1, name: PIN_ROW, imgType: PIN_IMAGE, refLink: '', isEnable: true },
      ...(this.permission
        ? [
            {
              id: 2,
              name: EDIT_SURVEY,
              imgType: REDIRECT_IMAGE,
              refLink: REDIRECT_EDIT_SURVEY,
              isEnable: true,
            },
          ]
          : []),
      {
        id: 3,
        name: 'Field Data Review',
        imgType: REDIRECT_IMAGE,
        refLink: '/data-review',
        isEnable: true,
      },
      {
        id: 4,
        name: 'Data Processing',
        imgType: REDIRECT_IMAGE,
        refLink: '/data-processing',
        isEnable: true,
      },
    ];
  }

  getSurveyRouteItems() {
    this.menuOptions = [
      ...(this.permission
        ? [
            {
              id: 2,
              name: EDIT_SURVEY_ROUTE,
              imgType: EDIT_IMAGE,
              refLink: REDIRECT_NONE,
              isEnable: true,
            },
          ]
          : []),
    ];
  }

  getCompanyItems() {
    this.menuOptions = [
      ...(this.permission
        ? [
            {
              id: 2,
              name: EDIT_COMPANY_DETAILS,
              imgType: EDIT_IMAGE,
              refLink: EDIT_PRIMARY_COMPANY,
              isEnable: true,
            },
            {
              id: 3,
              name: SETUP_ASSET_TYPES,
              imgType: REDIRECT_IMAGE,
              refLink: '',
              isEnable: true,
            },
            {
              id: 4,
              name: SETUP_HIERARCHY,
              imgType: REDIRECT_IMAGE,
              refLink: SETUP_HIERARCHY_LEVELS,
              isEnable: false,
            },
            {
              id: 5,
              name: SETUP_SEGMENT_ASSET,
              imgType: REDIRECT_IMAGE,
              refLink: SETUP_SEGMENTS_ASSETS,
              isEnable: false,
            },
            {
              id: 6,
              name: 'Import Asset Inspection Data',
              imgType: REDIRECT_IMAGE,
              refLink: SETUP_IMPORT_INSPECTION,
              isEnable: false,
            },
          ]
          : []),
      {
        id: 7,
        name: ASSET_OVERVIEW,
        imgType: REDIRECT_IMAGE,
        refLink: '',
        isEnable: false,
      },
    ];
  }

  getUserOverviewItems() {
    this.menuOptions = [
      ...(this.permission
        ? [
            {
              id: 2,
              name: EDIT,
              imgType: EDIT_IMAGE,
              refLink: REDIRECT_NONE,
              isEnable: true,
            },
            {
              id: 3,
              name: DELETE,
              imgType: DELETE_IMAGE,
              refLink: REDIRECT_NONE,
              isEnable: true,
            },
          ]
          : []),
      {
        id: 4,
        name: USERHISTORY,
        imgType: USERHISTORY_IMAGE,
        refLink: REDIRECT_NONE,
        isEnable: true,
      },
      {
        id: 5,
        name: LOGINAS,
        imgType: LOGINAS_IMAGE,
        refLink: '',
        isEnable: true,
      },
    ];
  }

  getMobiltexMembersItems(){
    this.menuOptions = [
      ...(this.permission
        ? [
            {
              id: 2,
              name: "Edit Member",
              imgType: EDIT_IMAGE,
              refLink: REDIRECT_NONE,
              isEnable: true,
            },
          ]
        : []),
    ];
  }

  updateMenuOptionsBasedOnStep(step: number) {
    for (let i = 0; i < this.menuOptions.length; i++) {
      if (i < step + 1) {
        this.menuOptions[i].isEnable = true;
      } else {
        this.menuOptions[i].isEnable = false;
      }
    }
  }

  updateMenuOptionsBasedOnStatus(status: string) {
    for (let i = 0; i < this.menuOptions.length; i++) {
      if(i == 1){
        if(status != "Exception"){
          this.menuOptions[i].isEnable = false;
        }
      }
    }
  }
}
