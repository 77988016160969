import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.css',
})
export class SearchComponent {
  @Input() placeholder = '';
  @Output() searchInput = new EventEmitter<string>();
  searchText = '';
  showClearButton = false;

  onInput(event?: Event) {
    this.toggleClearButton();
    if (event) {
      const filter = event.target as HTMLInputElement;
      const changeWord = filter.value.trim().toLowerCase();
      this.searchInput.emit(changeWord);
    } else {
      this.searchInput.emit('');
    }
  }

  toggleClearButton(): void {
    this.showClearButton = this.searchText.length > 0;
  }

  clearInput(): void {
    this.searchText = '';
    this.onInput(); // Call onInput method to handle input clearing logic
  }
}
