import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ASSET_SETUP_DATA_STATE, ASSET_SETUP_DATA_STATE_TYPE_REMOVE, ASSET_SETUP_DATA_STATE_TYPE_SET, EMPTY_STRING } from '../../shared/constants';
export interface AssetDataStateModel {
  primaryCompany: string | null;
  assetId: string | null;
  level1: string | null;
  level2: string | null;
  level3: string | null;
  startDate: Date | null;
  endDate: Date | null;
}
export class SetAssetData {
  
  static readonly type = ASSET_SETUP_DATA_STATE_TYPE_SET;
  constructor(
    public primaryCompany: string,
    public assetId: string,
    public level1: string,
    public level2: string,
    public level3: string,
    public startDate: Date,
    public endDate: Date,
  ) {}
}
export class RemoveAssetData {
  static readonly type = ASSET_SETUP_DATA_STATE_TYPE_REMOVE;
}
@State<AssetDataStateModel>({
  name: ASSET_SETUP_DATA_STATE,
  defaults: {
    primaryCompany: null,
    assetId: null,
    level1: null,
    level2: null,
    level3: null,
    startDate: null,
    endDate: null,
  },
})
@Injectable()
export class AssetSetupDataState {
  @Selector()
  static getAssetPrimaryCompany(state: AssetDataStateModel) {
    return state.primaryCompany;
  }

  @Selector()
  static getAssetAssetId(state: AssetDataStateModel) {
    return state.assetId;
  }

  @Selector()
  static getAssetLevel1(state: AssetDataStateModel) {
    return state.level1;
  }

  @Selector()
  static getAssetLevel2(state: AssetDataStateModel) {
    return state.level2;
  }
  @Selector()
  static getAssetLevel3(state: AssetDataStateModel) {
    return state.level3;
  }
  @Selector()
  static getAssetStartDate(state: AssetDataStateModel) {
    return state.startDate;
  }
  @Selector()
  static getAssetEndDate(state: AssetDataStateModel) {
    return state.endDate;
  }

  //actions to do operation
  @Action(SetAssetData)
  SetAssetData(
    { patchState }: StateContext<AssetDataStateModel>,
    {
      primaryCompany,
      assetId,
      level1,
      level2,
      level3,
      startDate,
      endDate,
    }: SetAssetData,
  ): void {
    patchState({
      primaryCompany: primaryCompany,
      assetId: assetId,
      level1: level1,
      level2: level2,
      level3: level3,
      startDate: startDate,
      endDate: endDate,
    });
  }
  @Action(RemoveAssetData)
  RemoveAssetDataSetup({ setState }: StateContext<AssetDataStateModel>): void {
    setState({
      primaryCompany: EMPTY_STRING,
      assetId: EMPTY_STRING,
      level1: EMPTY_STRING,
      level2: EMPTY_STRING,
      level3: EMPTY_STRING,
      startDate: null,
      endDate: null,
    });
  }
}
