import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY_STRING, USER_PROFILE_DATA_STATE, USER_PROFILE_STATE_TYPE_REMOVE, USER_PROFILE_STATE_TYPE_SET } from "../../shared/constants";
import { Injectable } from "@angular/core";
import { PrimaryCompanyModel } from "../../../awsAppSync/API";

export interface UserProfileStateModel {
  primaryCompany: PrimaryCompanyModel | null | undefined;
  userId: string | null;
  userName: string | null;
  userEmail: string | null;
  isMTXUser: boolean | null;
  roles: (Roles | null)[] | null | undefined;
  syrcPrimaryCompanies: (PrimaryCompanyModel | null)[] | null | undefined;
  selectedPrimaryCompany: string | null;
  selectedLevel3Value: string | null;
  selectedLevel3Id: string | null;
}

export interface Roles {
  level1Id: string | null;
  level2Id: string | null;
  level3Id: string | null;
  level3Value: string | null;
  roleId: string | null;
  rolename: string | null;
  permissions: (Permissions | null)[] | null | undefined;
}

export interface Permissions {
  screenName: string | null;
  fullAccess: boolean | null;
  noAccess: boolean | null;
  readAccess: boolean | null;
}

// Actions
export class SetUserProfileData {
  static readonly type = USER_PROFILE_STATE_TYPE_SET;
  constructor(
    public primaryCompany: PrimaryCompanyModel,
    public userId: string,
    public userName: string,
    public userEmail: string,
    public isMTXUser: boolean,
    public roles: (Roles | null)[] | null | undefined,
    public syrcPrimaryCompanies: (PrimaryCompanyModel | null)[] | null | undefined,
    public selectedPrimaryCompany: string ,
    public selectedLevel3Value: string ,
    public selectedLevel3Id: string,
  ) {}
}

export class RemoveUserProfileData {
  static readonly type = USER_PROFILE_STATE_TYPE_REMOVE;
}

export class UpdateSelectedPrimaryCompany {
  static readonly type = '[UserProfile] Update Selected Primary Company';
  constructor(public selectedPrimaryCompany: string) {}
}

export class UpdateSelectedLevel3Value {
  static readonly type = '[UserProfile] Update Selected Level3Value';
  constructor(public selectedLevel3Value: string) {}
}

export class UpdateSelectedLevel3Id {
  static readonly type = '[UserProfile] Update Selected Level3Id';
  constructor(public selectedLevel3Id: string) {}
}

// State
@State<UserProfileStateModel>({
  name: USER_PROFILE_DATA_STATE,
  defaults: {
    primaryCompany: null,
    userId: null,
    userName: null,
    userEmail: null,
    isMTXUser: false,
    roles: null,
    syrcPrimaryCompanies: null,
    selectedPrimaryCompany: null,
    selectedLevel3Value: null,
    selectedLevel3Id: null,
  },
})
@Injectable()
export class UserProfileDataState {
  // Selectors
  @Selector()
  static getPrimaryCompany(state: UserProfileStateModel) {
    return state.primaryCompany;
  }

  @Selector()
  static getUserId(state: UserProfileStateModel) {
    return state.userId;
  }

  @Selector()
  static getUserName(state: UserProfileStateModel) {
    return state.userName;
  }

  @Selector()
  static getUserEmail(state: UserProfileStateModel) {
    return state.userEmail;
  }

  @Selector()
  static getIsMTXUser(state: UserProfileStateModel) {
    return state.isMTXUser;
  }

  @Selector()
  static getRoles(state: UserProfileStateModel) {
    return state.roles;
  }

  @Selector()
  static getSyrcPrimaryCompanies(state: UserProfileStateModel) {
    return state.syrcPrimaryCompanies;
  }

  @Selector()
  static getSelectedPrimaryCompany(state: UserProfileStateModel) {
    return state.selectedPrimaryCompany;
  }

  @Selector()
  static getSelectedLevel3Value(state: UserProfileStateModel) {
    return state.selectedLevel3Value;
  }

  @Selector()
  static getSelectedLevel3Id(state: UserProfileStateModel) {
    return state.selectedLevel3Id;
  }

  // Actions
  @Action(SetUserProfileData)
  SetAssetData(
    { patchState }: StateContext<UserProfileStateModel>,
    {
      primaryCompany,
      userId,
      userName,
      userEmail,
      isMTXUser,
      roles,
      syrcPrimaryCompanies,
      selectedPrimaryCompany,
      selectedLevel3Value,
      selectedLevel3Id,
    }: SetUserProfileData,
  ): void {
    patchState({
      primaryCompany: primaryCompany,
      userId: userId,
      userName: userName,
      userEmail: userEmail,
      isMTXUser: isMTXUser,
      roles: roles,
      syrcPrimaryCompanies: syrcPrimaryCompanies,
      selectedPrimaryCompany: selectedPrimaryCompany,
      selectedLevel3Value: selectedLevel3Value,
      selectedLevel3Id: selectedLevel3Id,
    });
  }

  @Action(RemoveUserProfileData)
  RemoveAssetDataSetup({ setState }: StateContext<UserProfileStateModel>): void {
    setState({
      primaryCompany: null,
      userId: EMPTY_STRING,
      userName: EMPTY_STRING,
      userEmail: EMPTY_STRING,
      isMTXUser: false,
      roles: null,
      syrcPrimaryCompanies: null,
      selectedPrimaryCompany: EMPTY_STRING,
      selectedLevel3Value: EMPTY_STRING,
      selectedLevel3Id: EMPTY_STRING,
    });
  }

  @Action(UpdateSelectedPrimaryCompany)
  updateSelectedPrimaryCompany(
    { patchState }: StateContext<UserProfileStateModel>,
    { selectedPrimaryCompany }: UpdateSelectedPrimaryCompany,
  ): void {
    patchState({
      selectedPrimaryCompany: selectedPrimaryCompany,
    });
  }

  @Action(UpdateSelectedLevel3Value)
  updateSelectedLevel3Value(
    { patchState }: StateContext<UserProfileStateModel>,
    { selectedLevel3Value }: UpdateSelectedLevel3Value,
  ): void {
    patchState({
      selectedLevel3Value: selectedLevel3Value,
    });
  }

  @Action(UpdateSelectedLevel3Id)
  updateSelectedLevel3Id(
    { patchState }: StateContext<UserProfileStateModel>,
    { selectedLevel3Id }: UpdateSelectedLevel3Id,
  ): void {
    patchState({
      selectedLevel3Id: selectedLevel3Id,
    });
  }
}
