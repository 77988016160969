<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="col-auto">
  <h5 class="mtx-text-primary" style="margin-left: 10px; margin-top: 6px">
    <ng-container *ngIf="!isEdit; else editTemplate">
      {{ 'CREATE_PRIMARY_PAGE.CREATE_PRIMARY_COMPANY_TITLE' | translate }}
    </ng-container>
    <ng-template #editTemplate>
      {{ 'CREATE_PRIMARY_PAGE.EDIT_PRIMARY_COMPANY_TITLE' | translate }}
    </ng-template>
  </h5>
</div>
<div class="company-form card">
  <form [formGroup]="companyForm" (ngSubmit)="onSaveAndaddAdmin()" novalidate>
    <div>
      <div class="card-body">
        <div class="row" style="margin-top: -10px;">
          <div class="col-md-2">
            <div class="progress-container mb-4 bs-stepper">
              <div class="step-container">
                <span style="color: #737373;">Step {{currentStep}}/5</span>
                <div class="progress-bars">
                  <div class="progress-bar" [ngClass]="{'active-bar': currentStep >= 1, 'inactive-bar': currentStep < 1}"
                    role="progressbar">
                  </div>
                  <div class="progress-bar" [ngClass]="{'active-bar': currentStep >= 2, 'inactive-bar': currentStep < 2}"
                    role="progressbar">
                  </div>
                  <div class="progress-bar" [ngClass]="{'active-bar': currentStep >= 3, 'inactive-bar': currentStep < 3}"
                    role="progressbar">
                  </div>
                  <div class="progress-bar" [ngClass]="{'active-bar': currentStep >= 4, 'inactive-bar': currentStep < 4}"
                    role="progressbar">
                  </div>
                  <div class="progress-bar" [ngClass]="{'active-bar': currentStep >= 5, 'inactive-bar': currentStep < 5}"
                    role="progressbar">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="header-container" style="margin-top: -10px;">
          <div class="line"></div>
          <div>
            <h5 style="font-size: 20px">
              {{ 'CREATE_PRIMARY_PAGE.FORM_HEADER' | translate }}
            </h5>
            <p style="font-size: 15px">
              <ng-container *ngIf="!isEdit; else editTemplate_SUBHEADER">
                {{
                  'CREATE_PRIMARY_PAGE.FORM_SUBHEADER' | translate
                }}</ng-container
              >
              <ng-template #editTemplate_SUBHEADER>
                {{ 'CREATE_PRIMARY_PAGE.FORM_SUBHEADER_EDIT' | translate }}
                {{ this.newPrimaryCompanyName }}
              </ng-template>
            </p>
          </div>
        </div>
        <div
          *ngIf="!isEdit"
          class="row mb-3 align-items-center"
          style="display: flex"
        >
          <div style="width: 48%">
            <div class="label-container" style="margin-bottom: 5px">
              <label for="newPrimaryCompanyName" style="color: #737373;"
                >{{ 'CREATE_PRIMARY_PAGE.CREATE_NEW_COMPANY' | translate }}
                <span class="asterisk">*</span></label
              >
              <div
                *ngIf="
                  companyForm.errors?.['atLeastOneFieldRequired'] &&
                  companyForm.touched
                "
                class="text-danger"
              >
                {{ 'CREATE_PRIMARY_PAGE.PRIMARY_COMPANY_REQUIRED' | translate }}
              </div>
            </div>
            <input
              type="text"
              id="newPrimaryCompanyName"
              maxlength="80"
              class="form-control"
              placeholder="{{
                'CREATE_PRIMARY_PAGE.CREATE_NEW_COMPANY_PLACEHOLDER' | translate
              }}"
              formControlName="newPrimaryCompanyName"
            />
          </div>

          <div class="text-center" style="width: 4%; margin-top: 20px">
            <span
              class="font-weight-bold"
              style="
                white-space: nowrap;
                color: #737373;
                font-size: large;
                font-weight: 400;
              "
              >OR</span
            >
          </div>

          <div style="width: 48%">
            <label for="existingPrimaryCompany" style="margin-bottom: 5px; color: #737373;">{{
              'CREATE_PRIMARY_PAGE.SELECT_EXISTING_PRIMARY_COMPANY' | translate
            }}</label>
            <select
              id="existingPrimaryCompany"
              class="form-select"
              formControlName="existingPrimaryCompany"
              (change)="existingPrimaryCompanyChange($event)"
              [ngClass]="{ 'placeholder-selected': !companyForm.controls['existingPrimaryCompany'].value }"
            >
              <option value="" class="placeholder-option">
                {{
                  'CREATE_PRIMARY_PAGE.SELECT_EXISTING_PRIMARY_COMPANY_PLACEHOLDER'
                    | translate
                }}
              </option>
              <option
                *ngFor="let companyOption of companyOptions"
                [value]="companyOption?.corViewCompanyId"
              >
                {{ companyOption?.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-12">
            <div class="label-container" style="margin-bottom: 5px">
              <label
                for="newCompanyLogo"
                style="margin-bottom: 5px; font-weight: 500; color: #737373;"
              >
                {{ 'CREATE_PRIMARY_PAGE.UPLOAD_LOGO' | translate }}
                <span *ngIf="!isEdit" class="asterisk">*</span>
              </label>
              <div
                *ngIf="
                  companyForm.get('newCompanyLogo')?.touched &&
                  companyForm.get('newCompanyLogo')?.errors?.['required']
                "
                class="text-danger"
              >
                {{ 'CREATE_PRIMARY_PAGE.COMPANY_LOGO_REQUIRED' | translate }}
              </div>
              <div
                style="margin-left: 10px"
                *ngIf="
                  companyForm.get('newCompanyLogo')?.errors?.[
                    'invalidImageFormat'
                  ]
                "
              >
                <span  class="text-danger">
                  Invalid image format. Only jpg, jpeg, or png allowed.
                </span>
              </div>
            </div>

            <div class="p-2 upload-area">
              <label class="custom-file-upload">
                <input
                  type="file"
                  id="newCompanyLogo"
                  (change)="onFileSelected($event)"
                  accept=".jpg, .jpeg, .png"
                  #fileInput
                />
                {{ 'CREATE_PRIMARY_PAGE.UPLOAD_LOGO' | translate }}
              </label>
              <span style="margin-left: 15px;">
                {{
                  uploadedFileName || 'Upload logo in jpg, jpeg, or png format'
                }}
              </span>
            </div>
          </div>
        </div>
        <div
          *ngIf="uploadedFileUrl"
          class="uploaded-file-info groupLayout-left"
          style="margin-bottom: 5px; position: relative"
        >
          <span class="image-icon" *ngIf="uploadedFileUrl">
            <img
              [src]="uploadedFileUrl"
              alt="Logo Preview"
              style="max-width: 120px; max-height: 100px"
            />
          </span>
          <span class="icon-group close-icon" (click)="removeFile()">
            <kendo-icon name="close"></kendo-icon>
          </span>
        </div>
        <div
          style="
            margin-bottom: 20px;
            margin-top: 10px;
            font-weight: 500;
            font-size: large;
          "
        >
          {{ 'CREATE_PRIMARY_PAGE.CORPORATE_OFFICE_DETAILS' | translate }}
        </div>
        <div class="row mb-3">
          <div class="col-md-6">
            <div class="label-container" style="margin-bottom: 5px">
              <label for="corporateOfficeNumber" style="color: #737373;"
                >{{
                  'CREATE_PRIMARY_PAGE.CORPORATE_OFFICE_NUMBER' | translate
                }}
                <span class="asterisk">*</span></label
              >
              <div
                *ngIf="
                  companyForm.get('corporateOfficeNumber')?.touched &&
                  companyForm.get('corporateOfficeNumber')?.errors?.['required']
                "
                class="text-danger"
              >
                {{
                  'CREATE_PRIMARY_PAGE.CORPORATE_OFFICE_NUMBER_REQUIRED'
                    | translate
                }}
              </div>
              <div
                *ngIf="
                  companyForm.get('corporateOfficeNumber')?.touched &&
                  companyForm.get('corporateOfficeNumber')?.errors?.['pattern']
                "
                class="text-danger"
              >
                {{
                  'CREATE_PRIMARY_PAGE.CORPORATE_OFFICE_NUMBER_NUMERIC'
                    | translate
                }}
              </div>
            </div>
            <input
              type="text"
              id="corporateOfficeNumber"
              maxlength="20"
              class="form-control"
              placeholder="{{
                'CREATE_PRIMARY_PAGE.CORPORATE_OFFICE_NUMBER_PLACEHOLDER'
                  | translate
              }}"
              formControlName="corporateOfficeNumber"
            />
          </div>
          <div class="col-md-6">
            <div class="label-container" style="margin-bottom: 5px">
              <label for="phoneNumber" style="margin-bottom: 5px; color: #737373;">{{
                'CREATE_PRIMARY_PAGE.PHONE_NUMBER' | translate
              }}</label>
              <div
                style="margin-left: 5px"
                *ngIf="
                  companyForm.get('phoneNumber')?.touched &&
                  companyForm.get('phoneNumber')?.errors?.['pattern']
                "
                class="text-danger"
              >
                {{
                  'CREATE_PRIMARY_PAGE.CORPORATE_OFFICE_NUMBER_NUMERIC'
                    | translate
                }}
              </div>
            </div>
            <input
              type="text"
              id="phoneNumber"
              maxlength="20"
              class="form-control"
              placeholder="{{
                'CREATE_PRIMARY_PAGE.PHONE_NUMBER_PLACEHOLDER' | translate
              }}"
              formControlName="phoneNumber"
            />
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-6">
            <label for="country" style="margin-bottom: 5px; color: #737373;">{{
              'CREATE_PRIMARY_PAGE.COUNTRY' | translate
            }}</label>
            <select
              id="country"
              class="form-select"
              formControlName="country"
              (change)="onCountryChange($event)"
              [ngClass]="{ 'placeholder-selected': !companyForm.controls['country'].value }"
            >
              <option value="">
                {{ 'CREATE_PRIMARY_PAGE.SELECT_COUNTRY' | translate }}
              </option>
              <option *ngFor="let country of countries" [value]="country.name">
                {{ country.name }}
              </option>
            </select>
          </div>
          <div class="col-md-6">
            <label for="stateProvince" style="margin-bottom: 5px; color: #737373;">{{
              'CREATE_PRIMARY_PAGE.STATE' | translate
            }}</label>
            <select
              id="state"
              class="form-select"
              formControlName="stateProvince"
              (change)="onStateChange($event)"
              [disabled]="!states.length"
              [ngClass]="{ 'placeholder-selected': !companyForm.controls['stateProvince'].value }"
            >
              <option value="">
                {{ 'CREATE_PRIMARY_PAGE.SELECT_STATE' | translate }}
              </option>
              <option *ngFor="let state of states" [value]="state.name">
                {{ state.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-6">
            <label for="city" style="margin-bottom: 5px; color: #737373;">{{
              'CREATE_PRIMARY_PAGE.CITY' | translate
            }}</label>
            <select
              id="city"
              class="form-select"
              formControlName="city"
              [disabled]="!cities.length"
              [ngClass]="{ 'placeholder-selected': !companyForm.controls['city'].value }"
            >
              <option value="">
                {{ 'CREATE_PRIMARY_PAGE.SELECT_CITY' | translate }}
              </option>
              <option *ngFor="let city of cities" [value]="city.name">
                {{ city.name }}
              </option>
            </select>
          </div>
          <div class="col-md-6">
            <div class="label-container" style="margin-bottom: 5px; color: #737373;">
              <label for="postalZipCode">{{
                'CREATE_PRIMARY_PAGE.ZIPCODE' | translate
              }}</label>
              <div
                *ngIf="
                  companyForm.get('postalZipCode')?.touched &&
                  companyForm.get('postalZipCode')?.invalid
                "
                class="text-danger"
                style="margin-left: 5px"
              >
                <ng-container
                  *ngIf="
                    companyForm.get('postalZipCode')?.errors?.['whitespace']
                  "
                >
                  Whitespace is not accepted
                </ng-container>
              </div>
            </div>
            <input
              type="text"
              id="postalZipCode"
              maxlength="20"
              class="form-control"
              placeholder="{{
                'CREATE_PRIMARY_PAGE.ZIPCODE_PLACEHOLDER' | translate
              }}"
              formControlName="postalZipCode"
            />
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-6">
            <div class="label-container" style="margin-bottom: 5px; color: #737373;">
              <label for="address"
                >{{ 'CREATE_PRIMARY_PAGE.ADDRESS' | translate }}
              </label>
              <div
                *ngIf="
                  companyForm.get('address')?.touched &&
                  companyForm.get('address')?.invalid
                "
                class="text-danger"
                style="margin-left: 5px"
              >
                <ng-container
                  *ngIf="companyForm.get('address')?.errors?.['whitespace']"
                >
                  Whitespace is not accepted.
                </ng-container>
              </div>
            </div>
            <input
              type="text"
              id="address"
              class="form-control"
              placeholder="{{
                'CREATE_PRIMARY_PAGE.ADDRESS_PLACEHOLDER' | translate
              }}"
              formControlName="address"
            />
          </div>
          <div class="col-md-6">
            <div class="label-container" style="margin-bottom: 5px; color: #737373;">
              <label for="timeZone"
                >{{ 'CREATE_PRIMARY_PAGE.TIMEZONE' | translate }}
                <span class="asterisk">*</span></label
              >
              <div
                *ngIf="
                  companyForm.get('timeZone')?.touched &&
                  companyForm.get('timeZone')?.invalid
                "
                class="text-danger"
              >
                {{ 'CREATE_PRIMARY_PAGE.TIMEZONE_REQUIRED' | translate }}
              </div>
            </div>
            <select
              id="timeZone"
              (change)="onTimeZoneChange($event)"
              class="form-select"
              formControlName="timeZone"
              [disabled]="isTimeZoneDisabled"
              [ngClass]="{ 'placeholder-selected': !companyForm.controls['timeZone'].value }"
            >
              <option value="">
                {{ 'CREATE_PRIMARY_PAGE.SELECT_TIMEZONE' | translate }}
              </option>
              <option *ngFor="let tz of allTimeZone" [value]="tz?.id">
                {{ tz?.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row" *ngIf="!isEdit">
          <div class="col-md-8"></div>
          <div class="col-md-2">
            <button
              type="button"
              class="btn btn-secondary mtx-btn-secondary mtx-btn-custom"
              (click)="onCancel()"
            >
              {{ 'CREATE_PRIMARY_PAGE.CANCEL' | translate }}
            </button>
          </div>
          <!-- <div class="col-md-2">
            <button
              type="submit"
              class="btn btn-secondary mtx-btn-secondary mtx-btn-custom"
            >
              {{ 'CREATE_PRIMARY_PAGE.SAVE_EXIT' | translate }}
            </button>
          </div> -->
          <div class="col-md-2">
            <button
              type="submit"
              class="btn btn-primary mtx-btn-custom save-next-btn"
              [disabled]="isDisabled"
            >
              {{ 'CREATE_PRIMARY_PAGE.SAVE_NEXT' | translate }}
            </button>
          </div>
        </div>
        <div class="row" *ngIf="isEdit">
          <div class="col-md-8"></div>
          <div class="col-md-2">
            <button
              type="button"
              class="btn btn-secondary mtx-btn-secondary mtx-btn-custom"
              (click)="onCancel()"
            >
              {{ 'CREATE_PRIMARY_PAGE.CANCEL' | translate }}
            </button>
          </div>
          <div class="col-md-2">
            <button
              type="submit"
              class="btn btn-secondary mtx-btn-secondary mtx-btn-custom"
            >
              {{ 'CREATE_PRIMARY_PAGE.SAVE_EXIT' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>