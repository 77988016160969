import { Injectable } from '@angular/core';
import {
  AssetFilterInput,
  AssetHistoryGraphInput,
  AssetHistoryGridConnection,
  AssetHistoryInput,
  AssetMetadataInput,
  GetAllAssetOptionQuery,
  GetAllAssetOptionQueryVariables,
  GetAssetDetailsByIdQuery,
  GetAssetDetailsByIdQueryVariables,
  GetAssetHistoryGraphDataQuery,
  GetAssetHistoryGraphDataQueryVariables,
  GetAssetHistoryQueryVariables,
} from '../../../awsAppSync/API';
import {
  getAllAssetOption,
  getAssetDetailsById,
  getAssetHistory,
  getAssetHistoryGraphData,
} from '../../../awsAppSync/graphql/queries';
import { BaseAWSRequestService } from '../../shared/service/base-services/base-aws-request.service';

@Injectable({
  providedIn: 'root',
})
export class AssetHistoryService {
  selectedRowData: any;
  graphData: any;
  measurementTypeArray: string[] = [];
  startDate: Date = new Date();
  endDate: Date = new Date();
  minDateForGridData : Date | null = new Date();
  maxDateForGridData : Date | null = new Date();
  assetHistorydynamicColumns: any[] = [];
  public gridData: any = [];
  public dynamicColumns: any[] = [];
  public data: { text: string; value: number }[] = [
    { text: 'Asset1', value: 1 },
    { text: 'assetMobiltex1234', value: 2 },
    { text: 'Asset 14', value: 3 },
    { text: 'Testing Asset New', value: 4 },
    { text: 'Testing Asset New', value: 5 },
    { text: 'Bonds 123', value: 6 },
    { text: 'Testing Asset New Bond', value: 7 },
    { text: 'Testing Asset New', value: 8 },
    { text: 'Testing Asset New_1', value: 9 },
    { text: 'Testing Asset New_1', value: 10 },
    { text: 'Testing Asset New_2', value: 11 },
    { text: 'Testing Asset New_2', value: 12 },
    { text: 'Testing Asset New_2', value: 13 },
    { text: 'Testing Asset New_3', value: 14 },
    { text: 'AssetImg', value: 15 },
    { text: 'Bonds2612', value: 16 },
    { text: 'Testing Asset New_2', value: 17 },
    { text: 'Testing Asset New_1', value: 18 },
    { text: '"Test Points | "', value: 19 },
    { text: 'aBCnew', value: 20 },
    { text: '01-Aug-05', value: 21 },
    { text: '01-Aug-05', value: 22 },
    { text: '"Test Points | "', value: 23 },
    { text: '"New Asset 06th Aug "', value: 24 },
    { text: 'testing_Asset', value: 25 },
    { text: '1833400+000.529-TS', value: 26 },
    { text: 'NewAsset12345', value: 27 },
    { text: 'Rectifier test 1', value: 28 },
    { text: 'bondsAsset', value: 29 },
    { text: 'BondAsset123', value: 30 },
    { text: 'PointaASSET', value: 31 },
    { text: 'Rect-1-MLV', value: 32 },
    { text: 'Asset Test', value: 33 },
    { text: 'Asset 12', value: 34 },
    { text: 'Asset 12', value: 35 },
    { text: 'Asset 12', value: 36 },
    { text: 'Asset 12', value: 37 },
    { text: 'Asset 12', value: 38 },
    { text: 'Asset 14', value: 39 },
    { text: 'Asset 14', value: 40 },
    { text: 'Rectifiers-Asset123', value: 41 },
    { text: 'CoreViewAsset', value: 42 },
    { text: '123-CoreView-Mob', value: 43 },
    { text: '123-CoreView-Mob', value: 44 },
    { text: 'Mobiltex_Asset', value: 45 },
    { text: 'Mobiltex_Asset123', value: 46 },
    { text: 'Mobiltex_Asset123', value: 47 },
    { text: 'Mobiltex_Asset_new22', value: 48 },
    { text: 'CoreViewAsset12345', value: 49 },
    { text: 'CoreViewAsset_Rect', value: 50 },
  ];

  constructor(private awsService: BaseAWSRequestService) {}

  getAssetName() {
    return this.data;
  }
  //Static data code

  getRandomDate(start: Date, end: Date): Date {
    const date = new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime()),
    );
    date.setHours(0, 0, 0, 0); // Set time to 00:00:00
    return date;
  }

  getRandomValue(min: number, max: number): string {
    return (-Math.floor(Math.random() * (max - min + 1)) + min).toString();
  }

  getRandomStatus(): string {
    const statuses = ['Compliant', 'Exception', 'Delinquent'];
    return statuses[Math.floor(Math.random() * statuses.length)];
  }

  getRandomComments(): string {
    const comments = [
      'No issues found',
      'Minor issues noted',
      'Major issues found',
      'All checks passed',
      'Requires attention',
    ];
    return comments[Math.floor(Math.random() * comments.length)];
  }

  generateAssetHitoryData() {
    const startDate = new Date(2022, 0, 1); // Start date: Jan 1, 2022
    const endDate = new Date(); // End date: Today

    this.gridData = Array.from({ length: 5 }, () => ({
      date: this.getRandomDate(startDate, endDate),
      criteria: '850mV IR',
      status: this.getRandomStatus(),
      comments: this.getRandomComments(),
      measurement: [
        {
          isCompliant: Math.random() >= 0.5,
          criteria: '850mV IR -Free',
          measurementName: 'Output Voltage AC',
          value: this.getRandomValue(840, 860),
        },
        {
          isCompliant: Math.random() >= 0.5,
          criteria: '850mV IR -Free',
          measurementName: 'Output Voltage DC',
          value: this.getRandomValue(840, 860),
        },
        {
          isCompliant: Math.random() >= 0.5,
          criteria: '850mV IR -Free',
          measurementName: 'P/S ON AC',
          value: this.getRandomValue(840, 860),
        },
        {
          isCompliant: Math.random() >= 0.5,
          criteria: '850mV IR -Free',
          measurementName: 'P/S ON DC',
          value: this.getRandomValue(840, 860),
        },
      ],
    }));

    return this.gridData;
  }

  generateDynamicColumns(data: any) {
    this.dynamicColumns = [];
    if (data) {
      // Find the maximum number of measurements to determine the columns
      const maxMeasurements = Math.max(
        ...data.map(
          (item: { measurement: string | any[] }) => item.measurement.length,
        ),
      );

      for (let i = 0; i < maxMeasurements; i++) {
        this.dynamicColumns.push({
          field: `measurement[${i}].value`,
          title: data[0].measurement[i]?.measurementName,
        });
      }
      return this.dynamicColumns;
    } else {
      return [];
    }
  }
  
  async getAssetDetailsById(
    input: AssetMetadataInput,
  ): Promise<GetAssetDetailsByIdQuery | null> {
    const query = getAssetDetailsById; // Ensure this is your GraphQL query string or document
    const variables: GetAssetDetailsByIdQueryVariables = { input };
    try {
      const result = await this.awsService.executeGetQuery(
        query,
        variables,
      );
      return result || null;
    } catch (error) {
      console.error('Error fetching asset', error);
      return null;
    }
  }

  async getAssetHistory(
    filter: AssetHistoryInput,
  ): Promise<AssetHistoryGridConnection | null> {
    const query = getAssetHistory;
    const variables: GetAssetHistoryQueryVariables = { filter };
    try {
      const result = await this.awsService.executeGetQuery<{
        getAssetHistory: AssetHistoryGridConnection;
      }>(query, variables);
      return result.getAssetHistory || null;
    } catch (error) {
      console.error('Error fetching asset history', error);
      return null;
    }
  }

  async getAssetHistoryGraph(
    input: AssetHistoryGraphInput,
  ): Promise<GetAssetHistoryGraphDataQuery | null> {
    const query = getAssetHistoryGraphData; // Ensure this is your GraphQL query string or document
    const variables: GetAssetHistoryGraphDataQueryVariables = { input };
    try {
      const result = await this.awsService.executeGetQuery(query, variables);
      return result || null;
    } catch (error) {
      console.error('Error fetching asset history graph', error);
      return null;
    }
  }

  async getAllAssetOption(
    input: AssetFilterInput,
  ): Promise<GetAllAssetOptionQuery | null> {
    const query = getAllAssetOption; // Ensure this is your GraphQL query string or document
    const variables: GetAllAssetOptionQueryVariables = { input };
    try {
      const result = await this.awsService.executeGetQuery(query, variables);
      return result || null;
    } catch (error) {
      console.error('Error fetching All asset option', error);
      return null;
    }
  }



}
