import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  EMPTY_STRING,
  TEMPLATE,
  TEMPLATE_STATE_TYPE_SET,
  TEMPLATE_STATE_TYPE_SET_REMOVE,
} from '../../shared/constants';

export interface TemplateModel {
  isFrom : string | null;
  templateName: string | null;
  reportType: string | null;
}

export class SetTemplate {
  static readonly type = TEMPLATE_STATE_TYPE_SET;
  constructor(
    public isFrom : string|null,
    public templateName: string | null,
    public reportType: string | null,
  ) {}
}

export class RemoveTemplate {
  static readonly type = TEMPLATE_STATE_TYPE_SET_REMOVE;
}

@State<TemplateModel>({
  name: TEMPLATE,
  defaults: {
    isFrom: null,
    templateName: null,
    reportType: null,
  },
})
@Injectable()
export class TemplateState {
  @Selector()
  static getTemplateName(state: TemplateModel) {
    return state.templateName;
  }

  @Selector()
  static getReportType(state: TemplateModel) {
    return state.reportType;
  }

  @Selector()
  static getIsFrom(state: TemplateModel){
    return state.isFrom;
  }

  @Action(SetTemplate)
  setTemplate(
    { patchState }: StateContext<TemplateModel>,
    { isFrom, templateName, reportType }: SetTemplate,
  ): void {
    patchState({ isFrom, templateName, reportType });
  }

  @Action(RemoveTemplate)
  removeTemplate({ setState }: StateContext<TemplateModel>): void {
    setState({ isFrom:EMPTY_STRING, templateName: EMPTY_STRING, reportType: EMPTY_STRING });
  }
}
