import {
  Component,
  HostListener,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import {
  SVGIcon,
  crosstabIcon,
  searchIcon,
  paperPlaneIcon,
  exclamationCircleIcon,
} from '@progress/kendo-svg-icons';
import { TemplateService } from '../service/template/template.service';
import {
  ADD_NEW_TEMPLATE,
  CREATE_TEMPLATE,
  DELETE_TEMPLATE,
  DUPLICATE_TEMPLATE_NAME,
  EMPTY_STRING,
  EMPTY_TEMPLATE_NAME,
  ERROR,
  FETCHING_TEMPLATES_ERROR,
  WARNING,
} from '../constants';
import {
  CreateTemplateInput,
  TemplateFilterInput,
  templateType,
} from '../../../awsAppSync/API';
import { PrepareTemplateFilter } from '../helper';
import { TemplateBase } from '../../core/models/template';
import { Store } from '@ngxs/store';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import { CustomToastrService } from '../ngx-toastr/custom-toastr.service';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrl: './template.component.css',
})
export class TemplateComponent implements OnChanges {
  public svgCart: SVGIcon = crosstabIcon;
  public svgSearch: SVGIcon = searchIcon;
  public svgAdd: SVGIcon = paperPlaneIcon;
  public svgDelete: SVGIcon = exclamationCircleIcon;
  public showAddButton = true;
  public showSvgAddButton = false; 
  public showDeleteDialog = false;
  public placeHolderText = EMPTY_STRING;
  public show = false;
  public searchTerm = '';
  public templateName = '';
  public templateType: templateType | undefined;
  public selectedTemplate!: {
    id: string;
    userId: string;
    templateName: string;
  };
  public templates: TemplateBase[] = [];
  public templateInputFilter!: TemplateFilterInput;
  @Input() templateTypeInput: templateType | undefined;
  loggedInCorViewUserId: string | null |undefined;
  loggedInSyrcUserId: string;

  constructor(private templateService: TemplateService, private store: Store,private toastr: CustomToastrService) {
    //Fetch the logged in user name from store
    this.loggedInCorViewUserId = this.store.selectSnapshot(AuthenticateUserState.getLoggedInCorViewUserId);
    this.loggedInSyrcUserId = this.store.selectSnapshot(AuthenticateUserState.getSyrcUserId) ?? "";
    //this.loggedInCorViewUserId = 'nupurl';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['templateTypeInput']) {
      this.templateType = this.templateTypeInput;
      this.templateInputFilter = PrepareTemplateFilter(this.templateTypeInput);
      this.getAllTemplatesByUser();
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.show && this.isClickInsidePopup(event)) {
      this.show = false;
    }
  }

  private isClickInsidePopup(_event: MouseEvent): boolean {
    const popupElement = document.querySelector('.template-container');
    return popupElement ? true : false;
  }

  public onToggle(event: MouseEvent): void {
    event.stopPropagation();
    this.templateName = '';
    this.showAddButton = true;
    this.show = !this.show;
    this.showSvgAddButton = false;
    this.placeHolderText = ADD_NEW_TEMPLATE;
  }

  //Get all templates by user
  async getAllTemplatesByUser(): Promise<void> {
    try {
      const templateConnection =
        await this.templateService.getAllTemplatesByUserMapped(
          this.templateInputFilter,
          this.loggedInSyrcUserId,
          this.templateType!,
        );
      if (templateConnection) {
        this.templates = templateConnection;
      }
    } catch (error) {
      console.error(FETCHING_TEMPLATES_ERROR, error);
    }
  }

  //Get all filtered templates based on searchTerm
  get filteredTemplates() {
    return this.templates.filter((template) =>
      template.templateName
        .toLowerCase()
        .includes(this.searchTerm.toLowerCase()),
    );
  }

  //Set the values for selected template
  public selectTemplate(template: TemplateBase) {
    this.templateService.setAddedTemplateId(template.id);
    this.templateService.setIsNewTemplate(false);
    this.templateService.triggerOpenTemplateSettings();
  }

  //Set the add button visibility
  public setAddButton() {
    this.showAddButton = false;
  }

  //Create the template
  async createTemplate() {
    try {
      const existingTemplateNames = this.templates
        .filter((template) => template.templateType === this.templateType)
        .map((template) => template.templateName.toLowerCase());
      if (!this.templateName || this.templateName.trim() === '') {
        this.toastr.showWarning(EMPTY_TEMPLATE_NAME, WARNING);
      } else if (
        existingTemplateNames.includes(this.templateName.toLowerCase().trim())
      ) {
        this.toastr.showError(DUPLICATE_TEMPLATE_NAME, ERROR);
      } else {
        const input: CreateTemplateInput = {
          userId: this.loggedInSyrcUserId,
          corViewUserID: this.loggedInCorViewUserId!,
          templateName: this.templateName,
          templateType: this.templateType!,
          isActive: true,
        };
        const createdTemplate =
          await this.templateService.createTemplate(input);
        if (createdTemplate) {
          this.getAllTemplatesByUser();
        } else {
          console.error(CREATE_TEMPLATE);
        }
      }
      this.templateName = '';
      this.showAddButton = true;
      this.show = false;
    } catch (error) {
      console.error(CREATE_TEMPLATE, error);
    }
  }

  //Open delete dialog based on template id
  public openDeleteDialog(id: string) {
    this.showDeleteDialog = true;
    const templateName =
      this.templates.find((x) => x.id == id)?.templateName || '';
    this.selectedTemplate = {
      id: id,
      userId: this.loggedInSyrcUserId,
      templateName: templateName,
    };
    this.templateService.setDeletedTemplateId(id);
  }

  //Delete the template based on userId and templateName
  async deleteTemplate() {
    const deletedTemplate = await this.templateService.deleteTemplate(
      this.selectedTemplate.userId,
      this.selectedTemplate.templateName,
      this.templateType!,
    );
    if (deletedTemplate) {
      this.showDeleteDialog = false;
      this.show = false;
      this.getAllTemplatesByUser();
      window.location.reload();
    } else {
      console.error(DELETE_TEMPLATE);
    }
  }

  //Set the visibility of delete dialog
  public setDeleteDialogVisibility() {
    this.showDeleteDialog = false;
  }

  toggleAddButton(){
    if(!this.showSvgAddButton){
      this.showSvgAddButton = true;
      this.placeHolderText = EMPTY_STRING;
    }
  }      
}
