<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="col-auto">
  <h5 class="mtx-text-primary" style="margin-left: 10px; margin-top: 6px">
    @if (!isEdit) {
      {{ 'HEIRARCHY_SETUP_LEVEL.TITLE' | translate }}
    } @else {
      {{
        'HEIRARCHY_SETUP_LEVEL.TITLE_EDIT' | translate
      }}
    }
  </h5>
</div>
<div class="card card-shadow mt-4 hierarchy-form">
  <div class="row" style="margin-left: 5px;">
    <div class="col-md-2">
      <div class="progress-container mb-4 bs-stepper">
        <div class="step-container">
          <span style="color: #737373;">Step {{currentStep}}/5</span>
          <div class="progress-bars">
            <div class="progress-bar" [ngClass]="{'active-bar': currentStep >= 1, 'inactive-bar': currentStep < 1}"
              role="progressbar">
            </div>
            <div class="progress-bar" [ngClass]="{'active-bar': currentStep >= 2, 'inactive-bar': currentStep < 2}"
              role="progressbar">
            </div>
            <div class="progress-bar" [ngClass]="{'active-bar': currentStep >= 3, 'inactive-bar': currentStep < 3}"
              role="progressbar">
            </div>
            <div class="progress-bar" [ngClass]="{'active-bar': currentStep >= 4, 'inactive-bar': currentStep < 4}"
              role="progressbar">
            </div>
            <div class="progress-bar" [ngClass]="{'active-bar': currentStep >= 5, 'inactive-bar': currentStep < 5}"
              role="progressbar">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body" style="margin-top: -15px;">
    <div class="card mtx-card-bg-primary mtx-card-info">
      <div class="mtx-padding-bottom-0" style="padding-left: 10px">
        <div class="row mt-2">
          <div class="col-sm-12 col-md-10 d-flex">
            <span
              class="mtx-font-20 mtx-font-weight-500"
              style="margin-right: 5px"
              >{{
                'HEIRARCHY_SETUP_LEVEL.FORM_HEADER_HEADING' | translate
              }}</span
            >
          </div>
          <div
            class="col-sm-12 col-md-2 pull-right mtx-text-end mtx-padding-right-0 mtx-font-15 justify-content-md-end"
          >
            <a class="mtx-theme-primary-color">{{
              'HEIRARCHY_SETUP_LEVEL.VIEW_DOCUMENTATION' | translate
            }}</a>
            <span
              class="material-icons mtx-theme-primary-color"
              style="vertical-align: middle"
              >open_in_new</span
            >
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <ul>
              <li class="mtx-sub-text-primary mtx-font-14">
                <span style="color: #8e8e8e; font-weight: 500">{{
                  'HEIRARCHY_SETUP_LEVEL.FORM_HEADER_POINT_1' | translate
                }}</span>
                {{
                  'HEIRARCHY_SETUP_LEVEL.FORM_HEADER_POINT_1_TEXT' | translate
                }}
                <span style="color: #8e8e8e; font-weight: 500">{{
                  'HEIRARCHY_SETUP_LEVEL.FORM_HEADER_POINT_1_TEXT_3' | translate
                }}</span>
              </li>
              <li class="mtx-sub-text-primary mtx-font-14">
                <span style="color: #8e8e8e; font-weight: 500">{{
                  'HEIRARCHY_SETUP_LEVEL.FORM_HEADER_POINT_2' | translate
                }}</span>
                {{
                  'HEIRARCHY_SETUP_LEVEL.FORM_HEADER_POINT_2_TEXT' | translate
                }}
              </li>
              <li class="mtx-sub-text-primary mtx-font-14">
                <span style="color: #8e8e8e; font-weight: 500">{{
                  'HEIRARCHY_SETUP_LEVEL.FORM_HEADER_POINT_3' | translate
                }}</span>
                {{
                  'HEIRARCHY_SETUP_LEVEL.FORM_HEADER_POINT_3_TEXT' | translate
                }}
              </li>
              <li class="mtx-sub-text-primary mtx-font-14">
                <span style="color: #8e8e8e; font-weight: 500">{{
                  'HEIRARCHY_SETUP_LEVEL.FORM_HEADER_POINT_4' | translate
                }}</span>
                {{
                  'HEIRARCHY_SETUP_LEVEL.FORM_HEADER_POINT_4_TEXT' | translate
                }}
              </li>
              <li class="mtx-sub-text-primary mtx-font-14">
                <span style="color: #8e8e8e; font-weight: 500">{{
                  'HEIRARCHY_SETUP_LEVEL.FORM_HEADER_POINT_5' | translate
                }}</span>
                {{
                  'HEIRARCHY_SETUP_LEVEL.FORM_HEADER_POINT_5_TEXT' | translate
                }}
              </li>
              <li class="mtx-sub-text-primary mtx-font-14">
                <span style="color: #8e8e8e; font-weight: 500">{{
                  'HEIRARCHY_SETUP_LEVEL.FORM_HEADER_POINT_6' | translate
                }}</span>
                {{
                  'HEIRARCHY_SETUP_LEVEL.FORM_HEADER_POINT_6_TEXT' | translate
                }}
              </li>
              <li class="mtx-sub-text-primary mtx-font-14">
                <span style="color: #8e8e8e; font-weight: 500">{{
                  'HEIRARCHY_SETUP_LEVEL.FORM_HEADER_POINT_7' | translate
                }}</span>
                {{
                  'HEIRARCHY_SETUP_LEVEL.FORM_HEADER_POINT_7_TEXT' | translate
                }}
              </li>
              <span class="mtx-sub-text-primary" style="margin-left: -18px">
                {{
                  'HEIRARCHY_SETUP_LEVEL.FORM_HEADER_POINT_8' | translate
                }}</span
              >
            </ul>
          </div>
        </div>
      </div>
    </div>
    <form [formGroup]="form" class="row g-4 mt-3">
      <div class="container">
        <div class="row align-items-start">
          <div class="col-12">
            <span class="mtx-font-weight-500" style="color: #5b5b5b">{{
              'HEIRARCHY_SETUP_LEVEL.NAME_YOUR_LEVELS' | translate
            }}</span>
          </div>
          <div class="col-md-2">
            <div class="label-container">
              <label for="inputPrimaryCompany" class="form-label">{{
                'HEIRARCHY_SETUP_LEVEL.PRIMARY_COMPANY' | translate
              }}</label>
            </div>
            <input
              maxlength="80"
              type="text"
              class="form-control input-text"
              id="primaryCompany"
              formControlName="primaryCompany"
              placeholder=""
            />
          </div>
          <div class="col-md-2">
            <div class="label-container">
              <label for="inputTemplateName" class="form-label">{{
                'HEIRARCHY_SETUP_LEVEL.LEVEL_1' | translate
              }}</label>
            </div>
            <input
              maxlength="80"
              type="text"
              class="form-control input-text"
              id="level1"
              formControlName="level1"
              placeholder="Enter Level 1 Name"
            />
            <div *ngIf="form.get('level1')?.errors?.['sameLevelValue']">
              <small class="text-danger">{{
                form.get('level1')?.errors?.['sameLevelValue']
              }}</small>
            </div>
          </div>
          <div class="col-md-2">
            <div class="label-container">
              <label for="inputLevel2" class="form-label">{{
                'HEIRARCHY_SETUP_LEVEL.LEVEL_2' | translate
              }}</label>
            </div>
            <input
              maxlength="80"
              type="text"
              class="form-control input-text"
              id="level2"
              formControlName="level2"
              placeholder="Enter Level 2 Name"
            />
            <div
              *ngIf="
                form.errors?.['level2Required'] &&
                (form.get('level1')?.touched || form.get('level2')?.touched)
              "
            >
              <small class="text-danger">{{
                'HEIRARCHY_SETUP_LEVEL.LEVEL_2_ERROR' | translate
              }}</small>
            </div>
            <div
              *ngIf="
                form.errors?.['level2RequiredForLevel3'] &&
                (form.get('level1')?.touched || form.get('level3')?.touched)
              "
            >
              <small class="text-danger">{{
                'HEIRARCHY_SETUP_LEVEL.LEVEL_2_ERROR' | translate
              }}</small>
            </div>
            <div *ngIf="form.get('level2')?.errors?.['sameLevelValue']">
              <small class="text-danger">{{
                form.get('level2')?.errors?.['sameLevelValue']
              }}</small>
            </div>
          </div>
          <div class="col-md-2">
            <div class="label-container">
              <label for="inputLevel3" class="form-label"
                >{{ 'HEIRARCHY_SETUP_LEVEL.LEVEL_3' | translate
                }}<span class="asterisk">*</span></label
              >
            </div>
            <input
              maxlength="80"
              type="text"
              class="form-control input-text"
              id="level3"
              formControlName="level3"
              placeholder="Enter Level 3 Name"
            />
            <div
              *ngIf="
                form.errors?.['level3isRequired'] && form.get('level3')?.touched
              "
            >
              <small class="text-danger">{{
                'HEIRARCHY_SETUP_LEVEL.LEVEL_3_ERROR_REQ' | translate
              }}</small>
            </div>
            <div
              *ngIf="
                form.errors?.['level3Required'] &&
                (form.get('level3')?.touched || form.get('level2')?.touched)
              "
            >
              <small class="text-danger"
                >{{ 'HEIRARCHY_SETUP_LEVEL.LEVEL_3_ERROR' | translate }}
              </small>
            </div>
            <div *ngIf="form.get('level3')?.errors?.['sameLevelValue']">
              <small class="text-danger">{{
                form.get('level3')?.errors?.['sameLevelValue']
              }}</small>
            </div>
          </div>
          <div class="col-md-2">
            <div class="label-container">
              <label for="inputLevel4" class="form-label"
                >{{ 'HEIRARCHY_SETUP_LEVEL.LEVEL_4' | translate }}
                <span class="asterisk">*</span></label
              >
            </div>
            <input
              maxlength="80"
              type="text"
              class="form-control"
              id="level4"
              formControlName="level4"
              [disabled]="true"
            />
          </div>
          <div class="col-md-2">
            <div class="label-container">
              <label
                for="inputTemplateName"
                class="form-label"
                style="color: white"
                >Save</label
              >
            </div>
            <button
              *ngIf="!isSaveClicked"
              class="btn mtx-btn-primary"
              style="width: 75px"
              type="submit"
              (click)="onLevelsSave()"
            >
              <span>{{ 'HEIRARCHY_SETUP_LEVEL.SAVE' | translate }}</span>
            </button>
            <div *ngIf="!isEdit">
              <button
              *ngIf="isSaveClicked"
              class="btn btn-edit"
              type="submit"
              (click)="onEdit()"
            >
              <span class="mtx-font-weight-500">{{
                'HEIRARCHY_SETUP_LEVEL.EDIT' | translate
              }}</span>
            </button>
            </div>
            
          </div>
        </div>
      </div>
    </form>
    <!-- Display the Hierarchy Tree -->
    <div *ngIf="hierarchyTree.length > 0 && !isEditClicked" class="mt-4">
      <span
        class="mtx-font-weight-500"
        style="color: #5b5b5b; padding-bottom: 20px"
        >{{ 'HEIRARCHY_SETUP_LEVEL.HIERARCHY_TREE_HEADING' | translate }}</span
      >
      <app-hierarchy-node
        *ngFor="let node of hierarchyTree"
        [node]="node"
      ></app-hierarchy-node>
    </div>
  </div>
  <div class="card-footer d-grid d-md-flex justify-content-md-end">
    <div style="margin-top: 10px">
      <button
        class="btn button btn-cancel"
        type="button"
        (click)="onCancel()"
        style="width: 170px;height: 40px;"
      >
        <span style="font-weight: 500">{{
          'HEIRARCHY_SETUP_LEVEL.CANCEL' | translate
        }}</span>
      </button>
      <button
        [disabled]="!isSaveClicked"
        class="btn button btn-cancel mtx-font-weight-500"
        style="width: 170px;height: 40px;"
        type="submit"
        (click)="onSaveAndExit()"
        [ngClass]="{ 'disable-btn': !isSaveClicked }"
      >
        <span style="font-weight: 500">{{
          'HEIRARCHY_SETUP_LEVEL.SAVE_EXIT' | translate
        }}</span>
      </button>
      <button
        *ngIf="!isEdit"
        [disabled]="!isSaveClicked"
        class="btn button mtx-btn-primary"
        style="width: 170px;height: 40px;"
        type="submit"
        (click)="onSaveAndNext()"
        [ngClass]="{ 'disable-btn-savenext': !isSaveClicked }"
      >
        <span style="font-weight: 500">{{
          'HEIRARCHY_SETUP_LEVEL.SAVE_NEXT' | translate
        }}</span>
      </button>
    </div>
  </div>
</div>
