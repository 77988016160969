<div>
  <div class="container">
    <div class="row">
      <div class="export-modal-content col-10">
        <div class="export-modal-featuredicon"></div>
        <div class="export-modal-textandsupportingtext">
          <span class="export-modal-text">
            <span>Export Settings</span>
          </span>
          <span class="export-modal-text02">
            <span>Export with your customizable format</span>
          </span>
        </div>
      </div>
      <div class="top-close-button col-2" (click)="close()"></div>
      <div class="export-modal-paddingbottom"></div>
    </div>
    <div class="export-modal-frame1000003690">
      <div class="export-modal-content1">
        <div class="export-modal-textandsupportingtext01">
          <span class="export-modal-text04"><span>File Format</span></span>
        </div>
        <div class="export-modal-checkboxgroup">
          <div class="button-container" (click)="selection('pdf')">
            <div class="button" [ngClass]="{'selected': selectedFormat === 'pdf'}">
              <div class="export-modal-featuredicon1">
                <div class="export-modal-layerstwo01">
                  <div class="export-modal-layerstwo011"></div>
                </div>
              </div>
              <div class="export-modal-textandsupportingtext02">
                <div class="export-modal-textandsubtext">
                  <div class="export-modal-text06">
                    <span>PDF</span>
                    <div class="indicator" [ngClass]="{'checkmark': selectedFormat === 'pdf'}"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="button-container" (click)="selection('csv')">
            <div class="button" [ngClass]="{'selected': selectedFormat === 'csv'}">
              <div class="export-modal-featuredicon1">
                <div class="export-modal-layerstwo012">
                  <div></div>
                </div>
              </div>
              <div class="export-modal-textandsupportingtext02">
                <div class="export-modal-textandsubtext">
                  <div class="export-modal-text06">
                    <span>CSV</span>
                    <div class="indicator" [ngClass]="{'checkmark': selectedFormat === 'csv'}"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="button-container" (click)="selection('json')" *ngIf="!isFromReportDetails">
            <div class="button" [ngClass]="{'selected': selectedFormat === 'json'}">
              <div class="export-modal-featuredicon3">
                <div class="export-modal-layerstwo013">
                  <div></div>
                </div>
              </div>
              <div class="export-modal-textandsupportingtext02">
                <div class="export-modal-textandsubtext">
                  <div class="export-modal-text06">
                    <span>JSON</span>
                    <div class="indicator" [ngClass]="{'checkmark': selectedFormat === 'json'}"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="button-container" (click)="selection('excel')" *ngIf="isFromReportDetails">
            <div class="button" [ngClass]="{'selected': selectedFormat === 'excel'}">
              <div class="export-modal-featuredicon3">
                <div class="export-modal-layerstwo013">
                  <div></div>
                </div>
              </div>
              <div class="export-modal-textandsupportingtext02">
                <div class="export-modal-textandsubtext">
                  <div class="export-modal-text06">
                    <span>EXCEL</span>
                    <div class="indicator" [ngClass]="{'checkmark': selectedFormat === 'excel'}"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="export-modal-content5" *ngIf="!isFromReportDetails">
        <div class="export-modal-frame1000003692">
          <div class="export-modal-textandsupportingtext05">
            <span class="export-modal-text12"><span>Records</span></span>
          </div>
          <div class="export-modal-frame1000003691">
            <div class="export-modal-frame1000003702">
              <div class="export-modal-checkbox1">
                <div>
                  <input type="radio" name="records" value="current" [(ngModel)]="selectedOption">
                </div>
                <div class="export-modal-textandsupportingtext06">
                  <span class="export-modal-text14 TextsmMedium"><span>Current Page</span></span>
                </div>
              </div>
              <div class="export-modal-checkbox1">
                <div>
                  <input type="radio" name="records" value="all" [(ngModel)]="selectedOption">
                </div>
                <div class="export-modal-textandsupportingtext07">
                  <span class="export-modal-text16 TextsmMedium"><span>All Pages</span></span>
                </div>
              </div>
              <div class="export-modal-checkbox2">
                <div>
                  <input type="radio" name="records" value="numberofrecords" [(ngModel)]="selectedOption">
                </div>
                <div class="export-modal-textandsupportingtext08">
                  <span class="export-modal-text18 TextsmMedium"><span>Number of Records</span></span>
                </div>
                <div class="export-modal-frame1">
                  <button class="export-modal-button" (click)="decrementExportCount()"
                    [disabled]="selectedOption !== 'numberofrecords'">-</button>
                  <span class="export-modal-text20">{{exportCount}}</span>
                  <button class="export-modal-button1" (click)="incrementExportCount()"
                    [disabled]="selectedOption !== 'numberofrecords'">+</button>
                </div>
              </div>
            </div>
            <div class="button-container">
              <label class="switch">
                <input type="checkbox" [disabled]="!isVisibleDisabled" [(ngModel)]="exportVisibleDataOnly">
                <span class="slider round" style="height: 28px;"></span>
              </label>
              <div class="export-modal-textandsupportingtext09">
                <span class="export-modal-text21"><span>Only Visible Columns</span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="export-modal-frame-modalactions">
      <div class="export-modal-content6">
        <div class="export-modal-frame1000003689">
          <button class="export-modal-button3" (click)="close()">
            <div class="export-modal-frame75">
              <span class="export-modal-text23"><span>Cancel</span></span>
            </div>
          </button>
          <button
            [ngClass]="{'export-modal-button4-selected': isFormatValid(), 'export-modal-button4-unselected': !isFormatValid()}"
            (click)="export()">
            <div
              [ngClass]="{'export-modal-text24-selected': isFormatValid(), 'export-modal-text24-unselected': !isFormatValid()}">
              <span
                [ngClass]="{'export-modal-text24-selected': isFormatValid(), 'export-modal-text24-unselected': !isFormatValid()}">
                <span class="button-text-alignment">Export File</span>
              </span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div style="position: absolute; top: 0; left: -10000px; width: 500px;">
  <kendo-pdf-export #pdfExport fileName="Export.pdf" paperSize="A1" class="temp-kendo-grid" [repeatHeaders]="true"
    [landscape]="true">
    <div>
      <app-pdf-export-component [filteredGridData]="communicate.data" [selectedColumns]="communicate._selectedColumns"
        [flag]="communicate.flag"></app-pdf-export-component>
    </div>
  </kendo-pdf-export>
</div>

<div style="position: absolute; top: 0; left: -10000px; width: 500px;">
  <kendo-excelexport #excelExport fileName="Export.xlsx" [data]="gridData">
   <kendo-excelexport-column *ngFor="let column of selectedColumnsForExcel"
      [field]="column.field"
      [title]="column.title"
      [width]="350">
    </kendo-excelexport-column>
  </kendo-excelexport>
</div>