<div class="filter-container" >
  <button
    kendoButton
    class="filterbutton"
    style="border-top-left-radius: 7px; border-bottom-left-radius: 7px;"
    #Level1Button
    *ngIf="isLevel1visible"
    [ngClass]="{ 'active-filter': IsLevel1FilterSelected }"
    data-id="1"
    (click)="onButtonClick(1, Level1Button)"
  >
    {{ level1Name }}
  </button>
  <button
    kendoButton
    class="filterbutton"
    #Level2Button
    *ngIf="isLevel2visible"
    [ngClass]="{ 'active-filter': IsLevel2FilterSelected }"
    data-id="2"
    [disabled]="!IsLevel2FilterEnabled"
    (click)="onButtonClick(2, Level2Button)"
  >
    {{ level2Name }}
  </button>
  <button
    kendoButton
    class="filterbutton"
    #Level3Button
    [ngClass]="{ 'active-filter': IsLevel3FilterSelected }"
    data-id="3"
    [disabled]="!IsLevel3FilterEnabled"
    (click)="onButtonClick(3, Level3Button)"
  >
    {{ level3Name }}
  </button>
  <button
    kendoButton
    class="filterbutton"
    #SegmentTypeButton
    [ngClass]="{ 'active-filter': IsSegmentTypeFilterSelected }"
    data-id="4"
    [disabled]="!IsSegmentTypeFilterEnabled"
    (click)="onButtonClick(4, SegmentTypeButton)"
  >
    Segment Type
  </button>
  <button
    kendoButton
    class="filterbutton"
    #SegmentButton
    [ngClass]="{ 'active-filter': IsSegmentFilterSelected }"
    data-id="5"
    [disabled]="!IsSegmentFilterEnabled"
    (click)="onButtonClick(5, SegmentButton)"
  >
    {{ 'HEIRARCHY_FILTER_BUTTONS.SEGMENTS' | translate }}
  </button>
  <button
    kendoButton
    class="filterbutton"
    #AssetTypeButton
    [ngClass]="{ 'active-filter': IsAssetTypeFilterSelected }"
    data-id="6"
    [disabled]="!IsAssetTypeFilterEnabled"
    (click)="onButtonClick(6, AssetTypeButton)"
  >
    {{ 'HEIRARCHY_FILTER_BUTTONS.ASSET_TYPE' | translate }}
  </button>
  <button
    kendoButton
    class="filterbutton"
    *ngIf="isFromMap"
    #TechnicianButton
    [ngClass]="{ 'active-filter': IsTechnicianFilterSelected }"
    data-id="8"
    (click)="onButtonClick(8, TechnicianButton)"
  >
    Technician
  </button>
  <button
    kendoButton
    [ngClass]="{
      enableclearfiltersbutton: isAnyFilterSelected(),
      disableclearfiltersbutton: !isAnyFilterSelected(),
    }"
    #clearfiltersbutton
    (click)="OnClearFilterButtonClick()"
  ></button>
  <button
    kendoButton
    (click)="onFilterSearchButtonClick()"
    class="searchfiltersbutton"
    style="border-top-right-radius: 7px; border-bottom-right-radius: 7px;"
    [ngClass]="{
      enablesearchfiltersbutton: isSearchBarEnabled,
      disablesearchfiltersbutton: !isSearchBarEnabled,
    }"
  ></button>
  <label class="label" *ngIf="isSurveyRouteFilterRequired">OR</label>
  <button *ngIf="isSurveyRouteFilterRequired"
    kendoButton
    class="filterbutton"
    style="border-top-left-radius: 7px; border-bottom-left-radius: 7px;"
    #SurveyRouteButton
    [ngClass]="{ 'active-filter': IsSurveyRouteFilterSelected }"
    data-id="7"
    (click)="onButtonClick(7, SurveyRouteButton)"
  >
    {{ 'HEIRARCHY_FILTER_BUTTONS.SURVEY_ROUTE' | translate }}
  </button>
  <button *ngIf="isSurveyRouteFilterRequired"
    kendoButton
    [ngClass]="{
      enableclearfiltersbutton: IsSurveyRouteSelected(),
      disableclearfiltersbutton: !IsSurveyRouteSelected(),
    }"
    #clearfiltersbutton
    (click)="OnSurveyRouteClearFilterButtonClick()"
  ></button>
  <button *ngIf="isSurveyRouteFilterRequired"
    kendoButton
    (click)="onSurveyRouteSearchButtonClick()"
    class="searchfiltersbutton"
    style="border-top-right-radius: 7px; border-bottom-right-radius: 7px;"
    [ngClass]="{
      enablesearchfiltersbutton: isSurveyRouteSearchBarEnabled,
      disablesearchfiltersbutton: !isSurveyRouteSearchBarEnabled,
    }"
  ></button>
  <button
    kendoButton
    style="border-top-left-radius: 7px; border-bottom-left-radius: 7px;border-top-right-radius: 7px; border-bottom-right-radius: 7px;"
    *ngIf="isTrayButtonVisible && isToggleFilterButtonVisible"
    class="closefiltersbutton"
    (click)="toggleFilterTray()"
  ></button>
</div>
