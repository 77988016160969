import { Injectable } from '@angular/core';
import { BaseAWSRequestService } from '../../shared/service/base-services/base-aws-request.service';
import { GetSyrcPrimaryCompaniesQueryVariables, GetUserProfileQueryVariables, PrimaryCompanyModelConnection, UserProfile } from '../../../awsAppSync/API';
import { getSyrcPrimaryCompanies, getUserProfile } from '../../../awsAppSync/graphql/queries';

@Injectable({
  providedIn: 'root'
})
export class TopNavigationService {

  constructor(private awsService: BaseAWSRequestService) { }

  async getUserProfileByUserId(
    userId: string,
  ): Promise<UserProfile | null> {
    try {
      const variables: GetUserProfileQueryVariables = { userId };
      const query = getUserProfile;
      const result = await this.awsService.executeGetQuery<{
        getUserProfile: UserProfile;
      }>(query, variables);
      return result.getUserProfile || null;
    } catch (error) {
      console.error('Error fetching user profile details', error);
      return null;
    }
  }

  async getSyrcPrimaryCompanies(): Promise<PrimaryCompanyModelConnection | null> {
    try {
      const variables: GetSyrcPrimaryCompaniesQueryVariables = { };
      const query = getSyrcPrimaryCompanies;
      const result = await this.awsService.executeGetQuery<{
        getSyrcPrimaryCompanies: PrimaryCompanyModelConnection;
      }>(query, variables);
      return result.getSyrcPrimaryCompanies || null;
    } catch (error) {
      console.error('Error fetching syrc primary companies', error);
      return null;
    }
  }
}
