import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CREATE_ASSET_IMPORT, CREATE_ASSET_NEW } from '../../shared/constants';

@Component({
  selector: 'app-add-asset-modal',
  templateUrl: './add-asset-modal.component.html',
  styleUrl: './add-asset-modal.component.css'
})
export class AddAssetModalComponent {
  selectedType = '';
  constructor(private router: Router, private dialogRef: MatDialogRef<AddAssetModalComponent>){}

  selection(value: string) {
    this.selectedType = value;
    if(this.selectedType === CREATE_ASSET_NEW) {
      this.router.navigate(['assets/asset-setup/create']);
    }
    if(this.selectedType === CREATE_ASSET_IMPORT) {
      this.router.navigate(['assets/asset-setup/import']);
    }
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }
}
