/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';
import { Amplify } from 'aws-amplify';
import { environment } from './environments/environment';

//Configure the amplify API graphQL settings
Amplify.configure({
  API: {
    GraphQL: {
      endpoint: environment.graphqlEndpoint,
      region: environment.graphqlRegion,
      defaultAuthMode: 'lambda',
      //apiKey: environment.graphqlApiKey
    },
  },
});

enableProdMode();
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));