import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { LEVEL3_LEVELNAME_SET, LEVEL3_LEVELNAME_SET_REMOVE, LEVEL3_LEVELNAME_STATE} from "../../shared/constants";

export interface Level3LevelName {
    Level3LevelName: string | null;
}
export class SetLevel3LevelName {
    static readonly type = LEVEL3_LEVELNAME_SET;
    constructor(
        public Level3LevelName: string,
    ) {}
}
export class RemoveLevel3LevelName {
    static readonly type = LEVEL3_LEVELNAME_SET_REMOVE;
}
@State<Level3LevelName>({
    name: LEVEL3_LEVELNAME_STATE,
    defaults: {
        Level3LevelName: null
    }
})

@Injectable()
export class Level3LevelNameState {
    @Selector()
    static getLevel3LevelNameId(state: Level3LevelName) {
        return state.Level3LevelName;
    }

    @Action(SetLevel3LevelName)
    SetLevel3LevelName(
        { patchState }: StateContext<Level3LevelName>,
        {
            Level3LevelName,
        }: SetLevel3LevelName,
    ): void {
        patchState({
            Level3LevelName : Level3LevelName
        });
    }
    @Action(RemoveLevel3LevelName)
    RemoveLevel3LevelName({
        setState,
    }: StateContext<Level3LevelName>): void {
        setState({
            Level3LevelName: null
        });
    }
}