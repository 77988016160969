import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { LEVEL1_LEVELNAME_SET, LEVEL1_LEVELNAME_SET_REMOVE, LEVEL1_LEVELNAME_STATE} from "../../shared/constants";

export interface Level1LevelName {
    Level1LevelName: string | null;
}
export class SetLevel1LevelName {
    static readonly type = LEVEL1_LEVELNAME_SET;
    constructor(
        public Level1LevelName: string,
    ) {}
}
export class RemoveLevel1LevelName {
    static readonly type = LEVEL1_LEVELNAME_SET_REMOVE;
}
@State<Level1LevelName>({
    name: LEVEL1_LEVELNAME_STATE,
    defaults: {
        Level1LevelName: null
    }
})

@Injectable()
export class Level1LevelNameState {
    @Selector()
    static getLevel1LevelNameId(state: Level1LevelName) {
        return state.Level1LevelName;
    }

    @Action(SetLevel1LevelName)
    SetLevel1LevelName(
        { patchState }: StateContext<Level1LevelName>,
        {
            Level1LevelName,
        }: SetLevel1LevelName,
    ): void {
        patchState({
            Level1LevelName : Level1LevelName
        });
    }
    @Action(RemoveLevel1LevelName)
    RemoveLevel1LevelName({
        setState,
    }: StateContext<Level1LevelName>): void {
        setState({
            Level1LevelName: null
        });
    }
}