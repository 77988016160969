import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalizationService } from '@progress/kendo-angular-l10n';

@Injectable({
  providedIn: 'root'
})
export class LocalizationLoaderService {

  constructor(private http: HttpClient, private localizationService: LocalizationService) { }

  loadTranslations(language: string): void {
    // Fetch the translation file based on the selected language
    this.http.get(`/assets/i18n/${language}.json`).subscribe((translations: any) => {
      // Register translations with the Kendo LocalizationService
      this.localizationService.register(translations, language);
      // Change the current language
      this.localizationService.notifyChanges();
    });
  }
  
}
