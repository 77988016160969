
<!-- Conditionally render the template -->
<div>
  <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</div>

<!-- Define the template -->
<ng-template #contentTemplate>
  <div class="filter-status">
    <div class="search-bar">
      <kendo-svgicon [icon]="svgSearch" style="margin-left: 5px; color: gray;"></kendo-svgicon>
      <input type="search" [(ngModel)]="searchValue" (ngModelChange)="searchFilter()" class="search-input"
        placeholder="Search" />
    </div>
    <ul>
      <ng-container *ngIf=""></ng-container>
      <li>
        <label>
          <kendo-checkbox [(ngModel)]="allSelected"
            (ngModelChange)="allSelected ? selectAll() : clearAll()"></kendo-checkbox>
          All
        </label>
      </li>
      <li *ngFor="let item of filteredItems">
        <label>
          <kendo-checkbox [(ngModel)]="item.selected" (ngModelChange)="updateAllSelected()"></kendo-checkbox>
          {{ item.text }}
        </label>
      </li>
    </ul>
  </div>
</ng-template>

