<nav aria-label="breadcrumb">
  <ol class="mb-2 breadcrumb mtx-font-14" style="margin-left: 12px;">
    <li
      *ngFor="let breadcrumb of breadcrumbs; let last = last"
      class="breadcrumb-item"
      [attr.data-id]="'breadcrumb-' + breadcrumb.label"
      [ngClass]="{ active: last }"
      [attr.aria-current]="last ? 'page' : null"
    >
      <a
        *ngIf="!last; else lastBreadcrumb"
        class="mtx-theme-primary-color breadcrumb-item-link"
        >{{ breadcrumb.label }}</a
      >
      <ng-template #lastBreadcrumb>{{ breadcrumb.label }}</ng-template>
    </li>
  </ol>
</nav>
<!-- In case in future if we have home in naviagation then place below code in anchor tag -->
<!-- [routerLink]="breadcrumb.url" -->