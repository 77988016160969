import { Injectable } from '@angular/core';
import { FULLACCESS, NOACCESS, READACCESS } from '../../shared/constants';
import { filter, Observable } from 'rxjs';
import {
  Roles,
  UserProfileDataState,
} from '../store/user-profile.state';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  roles$: Observable<(Roles | null)[] | null>;
  roles: (Roles | null)[] | null | undefined;
  permissionsFromStore: any[] = [];

  private permissions: Record<
    string,
    { fullAccess: boolean; readAccess: boolean; noAccess: boolean }
  > = {};

  constructor(private store: Store) {
    this.roles$ = this.store
      .select(UserProfileDataState.getRoles)
      .pipe(
        filter(
          (roles): roles is (Roles | null)[] | null => roles !== undefined,
        ),
      );
      this.roles$.subscribe((role) => {
        this.roles = role;
        if (this.roles) {
          this.permissionsFromStore = this.roles
            .filter(role => role !== null) 
            .map(role => role?.permissions || []) 
            .flat();
        }
      });
      this.transformData();
  }

  getdatefromlogin(data: any[]){
    this.permissions = data.reduce(
      (acc, curr) => {
        acc[curr.screenName] = {
          fullAccess: curr.fullAccess,
          readAccess: curr.readAccess,
          noAccess: curr.noAccess,
        };
        return acc;
      },
      {} as Record<
        string,
        { fullAccess: boolean; readAccess: boolean; noAccess: boolean }
      >,
    );
  }

  transformData() {
    this.permissions = this.permissionsFromStore.reduce(
      (acc, curr) => {
        acc[curr.screenName] = {
          fullAccess: curr.fullAccess,
          readAccess: curr.readAccess,
          noAccess: curr.noAccess,
        };
        return acc;
      },
      {} as Record<
        string,
        { fullAccess: boolean; readAccess: boolean; noAccess: boolean }
      >,
    );
  }

  hasAnyPermission(
    feature: string,
    requiredPermissions: ('fullAccess' | 'readAccess' | 'noAccess')[],
  ): boolean {
    const featurePermissions = this.permissions[feature];

    if (featurePermissions) {
      return requiredPermissions.some(
        (permission) => featurePermissions[permission],
      );
    }
    return false;
  }

  hasPermission(
    feature: string,
    requiredPermission: 'fullAccess' | 'readAccess' | 'noAccess',
  ): boolean {
    const featurePermissions = this.permissions[feature];

    if (featurePermissions) {
      if (requiredPermission === FULLACCESS) {
        return featurePermissions[FULLACCESS];
      } else if (requiredPermission === READACCESS) {
        return featurePermissions[READACCESS];
      } else {
        return featurePermissions[NOACCESS];
      }
    }
    return false;
  }
}
