<mat-dialog-content class="add-asset-dialog">
    <div class="dialog-header">
      <div class="asset-header-icon"></div>
      <div class="top-close-button col-2" (click)="close()"></div>
    </div>
    <h2 class="text-alignment">Export Map</h2>
    <span class="export-modal-text02">
      <span>Export current view of the map as an image</span>
    </span>
   
    <h3 class="sub-header-alignment">File Format</h3>
    <div class="button-container">
      <div class="button" (click)="selection('png')">
        <div class="asset-modal-featuredicon1">
          <div class="asset-modal-layerstwo01"></div>
        </div>
        <div class="asset-modal-textandsupportingtext02">
          <div class="asset-modal-textandsubtext">
            <div class="asset-modal-text06">
              <span>png</span>
            </div>
          </div>
        </div>
        <div class="indicator" [ngClass]="{'checkmark': selectedType === 'png'}"></div>
      </div>
      <div class="button" (click)="selection('jpg')">
        <div class="asset-modal-featuredicon1">
          <div class="asset-modal-layerstwo012"></div>
        </div>
        <div class="asset-modal-textandsupportingtext02">
          <div class="asset-modal-textandsubtext">
            <div class="asset-modal-text06">
              <span>jpg</span>
            </div>
          </div>
        </div>
        <div class="indicator" [ngClass]="{'checkmark': selectedType === 'jpg'}"></div>
      </div>
      <div class="button" (click)="selection('jpeg')">
        <div class="asset-modal-featuredicon1">
          <div class="asset-modal-layerstwo013"></div>
        </div>
        <div class="asset-modal-textandsupportingtext02">
          <div class="asset-modal-textandsubtext">
            <div class="asset-modal-text06">
              <span>jpeg</span>
            </div>
          </div>
        </div>
        <div class="indicator" [ngClass]="{'checkmark': selectedType === 'jpeg'}"></div>
      </div>
    </div>

    <div class="button-container">
      <label class="switch">
        <input type="checkbox" [(ngModel)]="includeLegendsMarkers">
        <span class="slider round"></span>
      </label>
      <div class="export-modal-textandsupportingtext09">
        <span class="export-modal-text21"><span>With Legends/Markers</span></span>
      </div>
    </div>
    <div class="action-buttons">
      <button class="action-button cancel" (click)="close()">Cancel</button>
      <button class="action-button export" [disabled]="!selectedType" (click)="export()">Export File</button>
    </div>
  </mat-dialog-content>
  