import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { SVGIcon, searchIcon } from '@progress/kendo-svg-icons';
import { filterButtonsEnum } from '../enums';
import { Observable, Subscription } from 'rxjs';
import {
  ASSET_TYPE_ID,
  EMPTY_STRING,
  LEVEL1_ID,
  LEVEL2_ID,
  LEVEL3_ID,
  SEGMENT_ID,
  SEGMENT_TYPE_ID,
  SURVEY_ROUTE_ID,
  TECHNICIAN_ID,
} from '../constants';
import { Store } from '@ngxs/store';
import {
  RemoveSelectedSegmentIds,
  SegmentState,
  SetSegmentModel,
} from '../../core/store/filter-segments';
import {
  RemoveSelectedSurveyRouteIds,
  SurveyRouteState,
  SetSurveyRouteModel,
} from '../../core/store/filter-serveyrotes';
import {
  AssetTypeState,
  RemoveSelectedAssetTypeIds,
  SetAssetTypeModel,
} from '../../core/store/filter-assettype';
import { HeirarchyFilterServiceService } from '../service/heirarchy-filter-service/heirarchy-filter-service.service';
import {
  Level1State,
  RemoveLevel1State,
  SetLevel1State,
} from '../../core/store/filter-level1.state';
import {
  Level2State,
  RemoveLevel2State,
  SetLevel2State,
} from '../../core/store/filter-level2.state';
import {
  Level3State,
  RemoveLevel3State,
  SetLevel3State,
} from '../../core/store/filter-level3.state';
import {
  RemoveSegmentTypeState,
  SegmentTypeState,
  SetSegmentTypeState,
} from '../../core/store/filter-segmenttype.state';
import { Level1LevelNameState } from '../../core/store/filter-level1Name.state';
import { Level2LevelNameState } from '../../core/store/filter-level2Name.state';
import { Level3LevelNameState } from '../../core/store/filter-level3Name.state';

@Component({
  selector: 'app-heirarchy-filter-popup',
  templateUrl: './heirarchy-filter-popup.component.html',
  styleUrl: './heirarchy-filter-popup.component.css',
})
export class HeirarchyFilterPopupComponent implements OnInit, OnDestroy {
  isFilterOpen = false;
  selectAllChecked = false;
  public svgSearch: SVGIcon = searchIcon;

  //used to store default filters
  public _filteredItems: {
    id: string;
    label: string;
    checked: boolean;
    filterId: number;
  }[] = [];

  //used for searching filter
  public _searchedFilteredItems = this._filteredItems;

  public _searchTerm = '';
  IsSearchResultNull = false;

  public selectedFilter: {
    filterId: filterButtonsEnum;
    anchor: ElementRef | null;
  } = { filterId: filterButtonsEnum.PrimarCompanyId, anchor: null };
  public show = false;
  private subscription: Subscription | undefined;
  storeSelectedLevel1$: Observable<string[]>;
  selectedLevel1: string[] = [];
  storeSelectedLevel2$: Observable<string[]>;
  selectedLevel2: string[] = [];
  storeSelectedLevel3$: Observable<string[]>;
  selectedLevel3: string[] = [];
  storeSegmentTypeSet$: Observable<string[]>;
  selectedSegmentType: string[] = [];
  storeSelectedSegments$: Observable<string[]>;
  selectedSegments: string[] = [];
  storeSelectedSurveyRoute$: Observable<string[]>;
  selectedSurveyRoute: string[] = [];
  storeSelectedAssetType$: Observable<string[]>;
  selectedAssetType: string[] = [];
  storeLevel1Name$: Observable<string | null>;
  storeLevel2Name$: Observable<string | null>;
  storeLevel3Name$: Observable<string | null>;
  level1Name = '';
  level2Name = '';
  level3Name = '';
  isLevel1visible = true;
  isLevel2visible = true;

  constructor(
    private eRef: ElementRef,
    private store: Store,
    private _heirarchyFilterService: HeirarchyFilterServiceService,
  ) {
    this.storeSelectedLevel1$ = this.store.select(
      Level1State.getSelectedLevel1Name,
    );
    this.storeSelectedLevel2$ = this.store.select(
      Level2State.getSelectedLevel2Name,
    );
    this.storeSelectedLevel3$ = this.store.select(
      Level3State.getSelectedLevel3Name,
    );
    this.storeSegmentTypeSet$ = this.store.select(
      SegmentTypeState.getSelectedSegmentType,
    );
    this.storeSelectedSegments$ = this.store.select(
      SegmentState.getSelectedSegmentIds,
    );
    this.storeSelectedSurveyRoute$ = this.store.select(
      SurveyRouteState.getSelectedSurveyRouteIds,
    );
    this.storeSelectedAssetType$ = this.store.select(
      AssetTypeState.getSelectedAssetTypeIds,
    );
    this.storeLevel1Name$ = this.store.select(Level1LevelNameState.getLevel1LevelNameId);
    this.storeLevel2Name$ = this.store.select(Level2LevelNameState.getLevel2LevelNameId);
    this.storeLevel3Name$ = this.store.select(Level3LevelNameState.getLevel3LevelNameId);
  }

  async ngOnInit() {
    this.isFilterOpen = false;
    this.subscription = this._heirarchyFilterService.selectedFilter.subscribe({
      next: (value) => {
        this._searchTerm = '';
        this.selectedFilter = value;
        this.generateList();
      },
    });
    this._heirarchyFilterService.isClearFilterClick.subscribe((value) => {
      this.onClearFilterPressed(value);
    });
    this._heirarchyFilterService.isSurveyRouteClearFilterClick.subscribe(
      (value) => {
        this.onSurveyRouteClearFilterPressed(value);
      },
    );
    this._heirarchyFilterService.isDropDownOpen.subscribe((value) => {
      this.isFilterOpen = value;
    });
    this.storeSelectedLevel1$.subscribe((level1name) => {
      this.selectedLevel1 = level1name;
    });
    this.storeSelectedLevel2$.subscribe((level2name) => {
      this.selectedLevel2 = level2name;
    });
    this.storeSelectedLevel3$.subscribe((level3name) => {
      this.selectedLevel3 = level3name;
    });
    this.storeSegmentTypeSet$.subscribe((segmentType) => {
      this.selectedSegmentType = segmentType;
    });

    this.storeSelectedSegments$.subscribe((segment) => {
      this.selectedSegments = segment;
    });
    this.storeSelectedSurveyRoute$.subscribe((Surveyroute) => {
      this.selectedSurveyRoute = Surveyroute;
    });
    this.storeSelectedAssetType$.subscribe((assettype) => {
      this.selectedAssetType = assettype;
    });
    this.storeLevel1Name$.subscribe((level1name) => {
      this.level1Name = level1name!;
      this.isLevel1visible = this.level1Name == EMPTY_STRING || this.level1Name == null? false : true;
    });

    this.storeLevel2Name$.subscribe((level2name) => {
      this.level2Name = level2name!;
      this.isLevel2visible = this.level2Name == EMPTY_STRING || this.level2Name == null? false : true;
    });

    this.storeLevel3Name$.subscribe((level3name) => {
      this.level3Name = level3name!;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    const currentEvent = event.target as HTMLElement;
    if (!this.eRef.nativeElement.contains(event.target)) {
      if (
        !currentEvent.classList.contains('filterbutton') &&
        !currentEvent.classList.contains('k-button-text')
      ) {
        this.isFilterOpen = false;
      }
    }
  }

  filterData(): void {
    if (this._searchTerm) {
      const searchItems = this._searchedFilteredItems.filter((item) =>
        item.label.toLowerCase().includes(this._searchTerm.toLowerCase()),
      );
      if (searchItems.length > 0) {
        this.IsSearchResultNull = false;
        this._searchedFilteredItems = this._searchedFilteredItems.filter(
          (item) =>
            item.label.toLowerCase().includes(this._searchTerm.toLowerCase()),
        );
      } else {
        this.IsSearchResultNull = true;
      }
    } else {
      this.IsSearchResultNull = false;
      this._searchedFilteredItems = this._filteredItems;
    }
  }

  onClearFilterPressed(currentValue: boolean) {
    if (currentValue) {
      this._searchTerm = '';
      this.store.dispatch(new RemoveLevel1State());
      this.store.dispatch(new RemoveLevel2State());
      this.store.dispatch(new RemoveLevel3State());
      this.store.dispatch(new RemoveSegmentTypeState());
      this.store.dispatch(new RemoveSelectedSegmentIds());
      this.store.dispatch(new RemoveSelectedAssetTypeIds());

      if(!this.isLevel1visible && this.isLevel2visible){
        this._heirarchyFilterService.setfilterAdded({
          filterId: LEVEL2_ID,
          filterAdded: false,
        });
      }
      else if(!this.isLevel2visible){
        this._heirarchyFilterService.setfilterAdded({
          filterId: LEVEL3_ID,
          filterAdded: false,
        });
      }
      else{
        this._heirarchyFilterService.setfilterAdded({
          filterId: LEVEL1_ID,
          filterAdded: false,
        });
      }
      this._heirarchyFilterService.setIsClearFilterClick(false);
      //this.generateList();
    }
  }

  onSurveyRouteClearFilterPressed(currentValue: boolean) {
    if (currentValue) {
      this._searchTerm = '';
      this.store.dispatch(new RemoveSelectedSurveyRouteIds());
      this._heirarchyFilterService.setfilterAdded({
        filterId: LEVEL1_ID,
        filterAdded: false,
      });
      this._heirarchyFilterService.setIsSurveyRouteClearFilterClick(false);
      this.generateList();
    }
  }

  async generateList() {
    this._filteredItems = [];
    this._searchedFilteredItems = [];
    const selectAll = {
      id : "0",
      label: 'Select All',
      checked: false,
      filterId : 0
    }
    this._filteredItems.push(selectAll);
    if (this.selectedFilter.filterId == LEVEL1_ID) {
      const level1List = this._heirarchyFilterService.level1List;
      level1List?.items?.forEach((element) => {
        if (level1List && level1List.items) {
          if (!this._filteredItems.find((x) => x.id == element?.id)) {
            const isStoreAdded =
              this.selectedLevel1.length > 0
                ? this.selectedLevel1.find((x) => x === element?.id)
                  ? true
                  : false
                : false;
            const newItem = {
              id: element!.id,
              label: element!.level1Value || '',
              checked: isStoreAdded,
              filterId: LEVEL1_ID,
            };
            this._filteredItems.push(newItem);
          }
        }
      });
      this.IsSearchResultNull = this._filteredItems.length > 0 ? false : true;
    } else if (this.selectedFilter.filterId == LEVEL2_ID) {
      const level2List = this._heirarchyFilterService.level2List;
        level2List?.items?.forEach((element) => {
          if ( !this.isLevel1visible ||
            (element?.level1ValueId != null &&
            this.selectedLevel1.includes(element.level1ValueId))
          ) {
            if (!this._filteredItems.find((x) => x.id == element?.id)) {
              const isStoreAdded =
                this.selectedLevel2.length > 0
                  ? this.selectedLevel2.find((x) => x === element?.id)
                    ? true
                    : false
                  : false;
              const newItem = {
                id: element!.id,
                label: element!.level2Value || '',
                checked: isStoreAdded,
                filterId: LEVEL2_ID,
              };
              this._filteredItems.push(newItem);
            }
          }
        });
      this.IsSearchResultNull = this._filteredItems.length > 0 ? false : true;
    } else if (this.selectedFilter.filterId == LEVEL3_ID) {
      if (this.selectedLevel2.length > 0) {
        const level3List = this._heirarchyFilterService.level3List;
        level3List!.items?.forEach((element) => {
          if (
            element?.level2ValueId != null &&
            this.selectedLevel2.includes(element.level2ValueId)
          ) {
            if (!this._filteredItems.find((x) => x.id == element?.id)) {
              const isStoreAdded =
                this.selectedLevel3.length > 0
                  ? this.selectedLevel3.find((x) => x === element?.id)
                    ? true
                    : false
                  : false;
              const newItem = {
                id: element!.id,
                label: element!.level3Value || '',
                checked: isStoreAdded,
                filterId: LEVEL3_ID,
              };
              this._filteredItems.push(newItem);
            }
          }
        });
      } else {
        const level3List = this._heirarchyFilterService.level3List;
        level3List!.items?.forEach((element) => {
          if (level3List && level3List.items) {
            if (!this._filteredItems.find((x) => x.id == element?.id)) {
              const isStoreAdded =
                this.selectedLevel3.length > 0
                  ? this.selectedLevel3.find((x) => x === element?.id)
                    ? true
                    : false
                  : false;
              const newItem = {
                id: element!.id,
                label: element!.level3Value || '',
                checked: isStoreAdded,
                filterId: LEVEL3_ID,
              };
              this._filteredItems.push(newItem);
            }
          }
        });
      }
      this.IsSearchResultNull = this._filteredItems.length > 0 ? false : true;
    } else if (this.selectedFilter.filterId == SEGMENT_TYPE_ID) {
      //const segmentTypeList = await this._heirarchyFilterService.getAllSegmentTypes();
      const segmentTypeList = this._heirarchyFilterService.segmentTypeList;
      segmentTypeList!.items?.forEach((element) => {
        if (segmentTypeList && segmentTypeList.items) {
          if (!this._filteredItems.find((x) => x.id == element?.id)) {
            const isStoreAdded =
              this.selectedSegmentType.length > 0
                ? this.selectedSegmentType.find(
                    (x) => x === element?.level4Type,
                  )
                  ? true
                  : false
                : false;
            const newItem = {
              id: element!.id!,
              label: element!.level4Type || '',
              checked: isStoreAdded,
              filterId: SEGMENT_TYPE_ID,
            };
            this._filteredItems.push(newItem);
          }
        }
      });
      this.IsSearchResultNull = this._filteredItems.length > 0 ? false : true;
    } else if (this.selectedFilter.filterId == SEGMENT_ID) {
      if (this.selectedSegmentType.length > 0) {
        //const segmentList = await this._heirarchyFilterService.getAllLevel4();
        const segmentList = this._heirarchyFilterService.segmentList;
        segmentList!.items?.forEach((element) => {
          if (
            element?.segmentType != null &&
            this.selectedSegmentType.includes(element.segmentType)
          ) {
            if (!this._filteredItems.find((x) => x.id == element?.id)) {
              const isStoreAdded =
                this.selectedSegments.length > 0
                  ? this.selectedSegments.find((x) => x === element?.id)
                    ? true
                    : false
                  : false;
              const newItem = {
                id: element!.id,
                label: element!.level4Value || '',
                checked: isStoreAdded,
                filterId: SEGMENT_ID,
              };
              this._filteredItems.push(newItem);
            }
          }
        });
      } else if (this.selectedLevel3.length > 0) {
        //const segmentList = await this._heirarchyFilterService.getAllLevel4();
        const segmentList = this._heirarchyFilterService.segmentList;
        segmentList!.items?.forEach((element) => {
          if (
            element?.level3ValueId != null &&
            this.selectedLevel3.includes(element.level3ValueId)
          ) {
            if (!this._filteredItems.find((x) => x.id == element?.id)) {
              const isStoreAdded =
                this.selectedSegments.length > 0
                  ? this.selectedSegments.find((x) => x === element?.id)
                    ? true
                    : false
                  : false;
              const newItem = {
                id: element!.id,
                label: element!.level4Value || '',
                checked: isStoreAdded,
                filterId: SEGMENT_ID,
              };
              this._filteredItems.push(newItem);
            }
          }
        });
      }
      this.IsSearchResultNull = this._filteredItems.length > 0 ? false : true;
    } else if (this.selectedFilter.filterId == ASSET_TYPE_ID) {
      //const assetTypeList = await this._heirarchyFilterService.getAllAssetTypes();
      const assetTypeList = this._heirarchyFilterService.assetTypeList;
      assetTypeList!.items?.forEach((element) => {
        if (assetTypeList && assetTypeList.items) {
          if (!this._filteredItems.find((x) => x.id == element?.id)) {
            const isStoreAdded =
              this.selectedAssetType.length > 0
                ? this.selectedAssetType.find((x) => x === element?.id)
                  ? true
                  : false
                : false;
            const newItem = {
              id: element!.id,
              label: element!.assetType || '',
              checked: isStoreAdded,
              filterId: ASSET_TYPE_ID,
            };
            this._filteredItems.push(newItem);
          }
        }
      });
      this.IsSearchResultNull = this._filteredItems.length > 0 ? false : true;
    } else if (this.selectedFilter.filterId == SURVEY_ROUTE_ID) {
      //const surveyRouteList = await this._heirarchyFilterService.getAllSurveyRoutes();
      const surveyRouteList = this._heirarchyFilterService.surveyRouteList;
      surveyRouteList!.items?.forEach((element) => {
        if (surveyRouteList && surveyRouteList.items) {
          if (!this._filteredItems.find((x) => x.id == element?.id)) {
            const isStoreAdded =
              this.selectedSurveyRoute.length > 0
                ? this.selectedSurveyRoute.find((x) => x === element?.id)
                  ? true
                  : false
                : false;
            const newItem = {
              id: element!.id,
              label: element!.routeName || '',
              checked: isStoreAdded,
              filterId: SURVEY_ROUTE_ID,
            };
            this._filteredItems.push(newItem);
          }
        }
      });
      this.IsSearchResultNull = this._filteredItems.length > 0 ? false : true;
    } else {
      const newItem = {
        id: '1',
        label: ' Jhon Doe',
        checked: false,
        filterId: TECHNICIAN_ID,
      };
      const newItem1 = {
        id: '2',
        label: ' Michael Jordan',
        checked: false,
        filterId: TECHNICIAN_ID,
      };
      this._filteredItems.push(newItem);
      this._filteredItems.push(newItem1);
    }
    const allChecked = this._filteredItems.slice(1).every(item => item.checked);
    if(allChecked){
      this._filteredItems[0].checked = true;
    }
    this._searchedFilteredItems = this._filteredItems;
  }

  selectAllFilterValues(isChecked: boolean): void {
    this._filteredItems.forEach((item) => {
      item.checked = !isChecked;
    });
  }

  onClose(): void {
    this._searchTerm = '';
    this.isFilterOpen = false;
  }

  OnResetListClicked() {
    this._filteredItems.forEach((element) => {
      if (element.checked) {
        element.checked = false;
      }
    });
  }

  async OnSaveListClicked() {
    const isSelectedfilterItem = this._filteredItems.some((x) => x.checked);
    if (isSelectedfilterItem) {
      const level1ListToAdd: string[] = [];
      const level2ListToAdd: string[] = [];
      const level3ListToAdd: string[] = [];
      const segmentTypeToAdd: string[] = [];
      const segmentsIdsToAdd: string[] = [];
      const assetTypeIdsToAdd: string[] = [];
      const surveyRouteIdsToAdd: string[] = [];
      this._filteredItems.forEach((element) => {
        if (element.checked) {
          this._heirarchyFilterService.setfilterAdded({
            filterId: element.filterId,
            filterAdded: true,
          });
          switch (element.filterId) {
            case LEVEL1_ID:
              if (!level1ListToAdd.includes(element.id)) {
                level1ListToAdd.push(element.id);
              }
              break;
            case LEVEL2_ID:
              if (!level2ListToAdd.includes(element.id)) {
                level2ListToAdd.push(element.id);
              }
              break;
            case LEVEL3_ID:
              if (!level3ListToAdd.includes(element.id)) {
                level3ListToAdd.push(element.id);
              }
              break;
            case SEGMENT_TYPE_ID:
              if (!segmentTypeToAdd.includes(element.label)) {
                segmentTypeToAdd.push(element.label);
              }
              break;
            case SEGMENT_ID:
              if (!segmentsIdsToAdd.includes(element.id)) {
                segmentsIdsToAdd.push(element.id);
              }
              break;
            case ASSET_TYPE_ID:
              if (!assetTypeIdsToAdd.includes(element.id)) {
                assetTypeIdsToAdd.push(element.id);
              }
              break;
            case SURVEY_ROUTE_ID:
              if (!surveyRouteIdsToAdd.includes(element.id)) {
                surveyRouteIdsToAdd.push(element.id);
              }
              break;
          }
        }
      });
      if (level1ListToAdd.length) {
        this.store.dispatch(new SetLevel1State(level1ListToAdd));
        this._heirarchyFilterService.setIsSurveyRouteFilterAdded(false);
      }
      if (level2ListToAdd.length) {
        this.store.dispatch(new SetLevel2State(level2ListToAdd));
        this._heirarchyFilterService.setIsSurveyRouteFilterAdded(false);
      }
      if (level3ListToAdd.length) {
        this.store.dispatch(new SetLevel3State(level3ListToAdd));
        this._heirarchyFilterService.setIsSurveyRouteFilterAdded(false);
      }
      if (segmentTypeToAdd.length) {
        this.store.dispatch(new SetSegmentTypeState(segmentTypeToAdd));
        this._heirarchyFilterService.setIsSurveyRouteFilterAdded(false);
      }
      if (segmentsIdsToAdd.length) {
        this.store.dispatch(new SetSegmentModel(segmentsIdsToAdd));
        this._heirarchyFilterService.setIsSurveyRouteFilterAdded(false);
      }
      if (assetTypeIdsToAdd.length) {
        this.store.dispatch(new SetAssetTypeModel(assetTypeIdsToAdd));
        this._heirarchyFilterService.setIsSurveyRouteFilterAdded(false);
      }
      if (surveyRouteIdsToAdd.length) {
        this.store.dispatch(new SetSurveyRouteModel(surveyRouteIdsToAdd));
        this._heirarchyFilterService.setIsSurveyRouteFilterAdded(true);
      }
    } else {
      this._filteredItems.forEach((element) => {
        switch (element.filterId) {
          case LEVEL1_ID:
            this.store.dispatch(new RemoveLevel1State());
            this.store.dispatch(new RemoveLevel2State());
            this.store.dispatch(new RemoveLevel3State());
            this.store.dispatch(new RemoveSegmentTypeState());
            this.store.dispatch(new RemoveSelectedSegmentIds());
            this.store.dispatch(new RemoveSelectedAssetTypeIds());
            break;
          case LEVEL2_ID:
            this.store.dispatch(new RemoveLevel2State());
            this.store.dispatch(new RemoveLevel3State());
            this.store.dispatch(new RemoveSegmentTypeState());
            this.store.dispatch(new RemoveSelectedSegmentIds());
            this.store.dispatch(new RemoveSelectedAssetTypeIds());
            break;
          case LEVEL3_ID:
            this.store.dispatch(new RemoveLevel3State());
            this.store.dispatch(new RemoveSegmentTypeState());
            this.store.dispatch(new RemoveSelectedSegmentIds());
            this.store.dispatch(new RemoveSelectedAssetTypeIds());
            break;
          case SEGMENT_TYPE_ID:
            this.store.dispatch(new RemoveSegmentTypeState());
            break;
          case SEGMENT_ID:
            this.store.dispatch(new RemoveSelectedSegmentIds());
            break;
          case SURVEY_ROUTE_ID:
            this.store.dispatch(new RemoveSelectedSurveyRouteIds());
            this._heirarchyFilterService.setIsSurveyRouteFilterAdded(false);
            break;
          case ASSET_TYPE_ID:
            this.store.dispatch(new RemoveSelectedAssetTypeIds());
            break;
        }
        this._heirarchyFilterService.setfilterAdded({
          filterId: element.filterId,
          filterAdded: false,
        });
      });
    }
    this.isFilterOpen = false;
  }

  onCheckboxChange(changedItem: any): void {
    // const allChecked = this._searchedFilteredItems.every(
    //   (item) => item.checked
    // );
    // this.selectAllChecked = allChecked;
    if(changedItem.id == "0"){
      if(changedItem.checked){
        this._searchedFilteredItems.forEach((item) => {
          item.checked = true;
      });
      }
      else{
        this._searchedFilteredItems.forEach((item) => {
          item.checked = false;
      });
      }
      
    }
   

    if (changedItem.filterId === SURVEY_ROUTE_ID && changedItem.checked) {
      this._searchedFilteredItems.forEach((item) => {
        if (item.filterId === SURVEY_ROUTE_ID && item !== changedItem) {
          item.checked = false;
        }
      });
    }
  }
}
