<mat-dialog-content class="add-route-dialog">
  <div class="dialog-header">
    <div class="route-header-icon"></div>
    <div class="top-close-button col-2" (click)="close()"></div>
  </div>

  <h2 class="text-alignment">Update Existing Survey Route or Create New</h2>
  <span class="export-modal-text02">
    <span>It looks like you want to manage your routes. Choose an option below:</span>
  </span>

  <div class="button-container" (click)="selection('1')" style="margin-top: 20px;">
    <div class="button" [ngClass]="{'selected': selectedType === '1'}">
      <div class="survey-modal-textandsupportingtext02 ">
        <div class="survey-modal-textandsubtext">
          <div class="survey-modal-text06">
            <span>{{options[0].label}}</span>
          </div>
        </div>
      </div>
      <div class="indicator" [ngClass]="{'checkmark': selectedType === '1'}"></div>
    </div>
  </div>

  <div class="button-container" (click)="selection('2')">
    <div class="button" [ngClass]="{'selected': selectedType === '2'}">
      <div class="survey-modal-textandsupportingtext02 ">
        <div class="survey-modal-textandsubtext">
          <div class="survey-modal-text06">
            <span>{{options[1].label}}</span>
          </div>
        </div>
      </div>
      <div class="indicator" [ngClass]="{'checkmark': selectedType === '2'}"></div>
    </div>
  </div>

  <div *ngIf="!data.isSurveyRouteFlag" class="button-container" (click)="selection('3')">
    <div class="button" [ngClass]="{'selected': selectedType === '3'}">
      <div class="survey-modal-textandsupportingtext02 ">
        <div class="survey-modal-textandsubtext">
          <div class="survey-modal-text06">
            <span>{{options[2].label}}</span>
          </div>
        </div>
      </div>
      <div class="indicator" [ngClass]="{'checkmark': selectedType === '3'}"></div>
    </div>
  </div>


  <!-- <div class="checkbox-group">
    <div *ngFor="let option of options" class="checkbox-row"
         [ngClass]="{'selected-row': option.selected}">
      <span class="checkbox-label">{{ option.label }}</span>
      <kendo-checkbox
        [(ngModel)]="option.selected"
        (change)="onCheckboxChange(option)"
        class="circle-checkbox">
      </kendo-checkbox>
    </div>
  </div> -->
<!-- 
  <div class="action-buttons">
    <button class="action-button cancel" (click)="close()">Back</button>
    <button class="action-button export" (click)="proceed()" [disabled]="!isAnyCheckboxSelected">Proceed</button>
  </div> -->
</mat-dialog-content>
