import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GoogleMaps } from '../../core/models/google-maps';
import { MapService } from '../../core/services/map.service';
import { TranslateService } from '@ngx-translate/core';
import {
  AssetHistoryGraphInput,
  AssetMetadataInput,
  FilterInput,
  HierarchyFilterInput,
  KendoGridFilters,
  SurveyRouteFilterInput,
} from '../../../awsAppSync/API';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import { Level3State } from '../../core/store/filter-level3.state';
import { SegmentTypeState } from '../../core/store/filter-segmenttype.state';
import { SegmentState } from '../../core/store/filter-segments';
import { SurveyRouteState } from '../../core/store/filter-serveyrotes';
import { AssetTypeState } from '../../core/store/filter-assettype';
import {
  ANODE,
  BOND,
  CASING,
  COMPLIANT_AH,
  CTS,
  DEFAULT,
  DELINQUENT_AH,
  EMPTY_STRING,
  ER_PROBE,
  EXCEPTION_AH,
  ISOLATIONJOINT,
  ND,
  RD,
  RECTIFIER,
  SHORT,
  ST,
  TEST_POINT,
  TH,
} from '../../shared/constants';
import { AssetSetupService } from '../../core/services/asset-setup.service';
import { HeirarchyFilterServiceService } from '../../shared/service/heirarchy-filter-service/heirarchy-filter-service.service';
import { Level1LevelNameState } from '../../core/store/filter-level1Name.state';
import { Level2LevelNameState } from '../../core/store/filter-level2Name.state';
import { Level3LevelNameState } from '../../core/store/filter-level3Name.state';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { AssetSetupDataState } from '../../core/store/asset.state';
import { AssetHistoryService } from '../../core/services/asset-history.service';
declare let google: GoogleMaps;
@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.css'],
})
export class MapsComponent implements OnInit, OnDestroy {
  dateRangeForm: FormGroup;
  startDate: Date | null = new Date();
  endDate: Date | null = new Date();
  assetHistoryStartDate: string | null = EMPTY_STRING;
  assetHistoryEndDate: string | null = EMPTY_STRING;
  assetMetadataInput: AssetMetadataInput = {
    primaryCompany: EMPTY_STRING,
    level1: EMPTY_STRING,
    level2: EMPTY_STRING,
    level3: EMPTY_STRING,
    assetId: EMPTY_STRING,
  };
  assetHistoryGraphInput: AssetHistoryGraphInput = {
    primaryCompany: EMPTY_STRING,
    level1: EMPTY_STRING,
    level2: EMPTY_STRING,
    level3: EMPTY_STRING,
    assetId: EMPTY_STRING,
    startDate: EMPTY_STRING,
    endDate: EMPTY_STRING,
    measurementTypes: [],
  };
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private mapService: MapService,
    private translate: TranslateService,
    private assetSetupService: AssetSetupService,
    private assetHistoryService: AssetHistoryService,
    private store: Store,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private hierarchyFilter: HeirarchyFilterServiceService,
  ) {
    const currentYear = new Date().getFullYear();
    this.startDate = new Date(currentYear, 0, 1); // January 1st
    this.endDate = new Date(currentYear, 11, 31); // December 31st
    this.dateRangeForm = this.fb.group(
      {
        startDate: [this.startDate],
        endDate: [this.endDate],
      },
      { validator: this.dateRangeValidator },
    );
    this.storePrimaryCompanyID$ = this.store.select(
      AuthenticateUserState.getSyrcPrimaryCompanyId,
    );
    this.level1$ = this.store.select(AssetSetupDataState.getAssetLevel1);
    this.level2$ = this.store.select(AssetSetupDataState.getAssetLevel2);
    this.level3$ = this.store.select(AssetSetupDataState.getAssetLevel3);
    // this.startDate$ = this.store.select(AssetSetupDataState.getAssetStartDate);
    // this.endDate$ = this.store.select(AssetSetupDataState.getAssetEndDate);
    this.storeSelectedLevel3$ = this.store.select(
      Level3State.getSelectedLevel3Name,
    );
    this.storeSegmentTypeSet$ = this.store.select(
      SegmentTypeState.getSelectedSegmentType,
    );
    this.storeSelectedSegments$ = this.store.select(
      SegmentState.getSelectedSegmentIds,
    );
    this.storeSelectedSurveyRoute$ = this.store.select(
      SurveyRouteState.getSelectedSurveyRouteIds,
    );
    this.storeSelectedAssetType$ = this.store.select(
      AssetTypeState.getSelectedAssetTypeIds,
    );
    this.storeLevel1Name$ = this.store.select(
      Level1LevelNameState.getLevel1LevelNameId,
    );
    this.storeLevel2Name$ = this.store.select(
      Level2LevelNameState.getLevel2LevelNameId,
    );
    this.storeLevel3Name$ = this.store.select(
      Level3LevelNameState.getLevel3LevelNameId,
    );
  }
  allAssetsData: any[] = [];
  assetData: any[] = [];
  searchPlaceholder = 'Search assets on map';
  isLoading = false;
  includeLegendsMarkers = true;
  filterModel: FilterInput = {};
  kendoGridFilters: KendoGridFilters = {};
  storePrimaryCompanyID$: Observable<string | null>;
  selectedprimaryComapnyId: string | null = '';
  storeSelectedLevel3$: Observable<string[]>;
  selectedLevel3: string[] = [];
  storeSegmentTypeSet$: Observable<string[]>;
  selectedSegmentType: string[] = [];
  storeSelectedSegments$: Observable<string[]>;
  selectedSegments: string[] = [];
  storeSelectedSurveyRoute$: Observable<string[]>;
  selectedSurveyRoute: string[] = [];
  storeSelectedAssetType$: Observable<string[]>;
  selectedAssetType: string[] = [];
  hierarchyFilterInput: HierarchyFilterInput[] = [];
  surveyRouteFilterInput: SurveyRouteFilterInput[] = [];
  currentPage = 0;
  pageSize = 1000;
  public searchText = EMPTY_STRING;
  filterTrayOpen = false;
  @ViewChild('map', { static: true }) mapElementRef!: ElementRef;
  center: { lat: number; lng: number } | undefined; // No default center
  map: any;
  showGraph = false;
  chartKey = false;
  storeLevel1Name$: Observable<string | null>;
  storeLevel2Name$: Observable<string | null>;
  storeLevel3Name$: Observable<string | null>;
  level1Name = '';
  level2Name = '';
  level3Name = '';
  level1$: Observable<string | null>;
  level2$: Observable<string | null>;
  level3$: Observable<string | null>;
  // startDate$: Observable<Date | null>;
  // endDate$: Observable<Date | null>;
  primaryCompany: string | null = EMPTY_STRING;
  level1: string | null = EMPTY_STRING;
  level2: string | null = EMPTY_STRING;
  level3: string | null = EMPTY_STRING;
  currentLevel1: string | null | undefined = EMPTY_STRING;
  currentLevel2: string | null | undefined = EMPTY_STRING;
  currentLevel3: string | null | undefined = EMPTY_STRING;
  measurementTypeArray: string[] = [];
  measurementTypeArrayWithSegment: string[] = [];
  measurementCriteriaInputs: any[] = [];

  query = '';
  filteredResults: any[] = [];
  selectedAsset: any;
  selectedAssetId = EMPTY_STRING;
  infoCardStyles: any = { display: 'none' }; // Initially hidden
  isFullScreen = false;

  infowindow = new google.maps.InfoWindow({
    pixelOffset: new google.maps.Size(500, -300),
  });

  markerMap = new Map();
  selectedAssetData: any;

  ngOnInit(): void {
    this.selectedAssetData = this.assetHistoryService.selectedRowData;
    this.storePrimaryCompanyID$.subscribe((id) => {
      this.selectedprimaryComapnyId = '';
      this.selectedprimaryComapnyId = id;
    });
    this.level1$.subscribe((level1) => {
      this.level1 = level1;
    });
    this.level2$.subscribe((level2) => {
      this.level2 = level2;
    });
    this.level3$.subscribe((level3) => {
      this.level3 = level3;
    });
    // this.startDate$.subscribe((startDate) => {
    //   this.startDate = startDate;
    // });
    // this.endDate$.subscribe((endDate) => {
    //   this.endDate = endDate;
    // });

    this.storeSelectedLevel3$.subscribe((level3name) => {
      this.selectedLevel3 = level3name;
      this.hierarchyFilterInput = [];
      if (this.selectedprimaryComapnyId && this.selectedLevel3.length > 0) {
        this.fetchLevel3Values(this.selectedprimaryComapnyId);
      }
    });

    this.storeSegmentTypeSet$.subscribe((segmentType) => {
      this.selectedSegmentType = [];
      this.selectedSegmentType = segmentType;
    });

    this.storeSelectedSegments$.subscribe((segment) => {
      this.selectedSegments = [];
      this.selectedSegments = segment;
    });

    this.storeSelectedSurveyRoute$.subscribe((Surveyroute) => {
      this.surveyRouteFilterInput = [];
      this.selectedSurveyRoute = Surveyroute;
      if (
        this.selectedSurveyRoute.length > 0 &&
        this.selectedprimaryComapnyId
      ) {
        this.fetchSurveyRouteValues(this.selectedprimaryComapnyId);
      }
    });

    this.storeSelectedAssetType$.subscribe((assettype) => {
      this.selectedAssetType = [];
      this.selectedAssetType = assettype;
    });

    this.storeLevel1Name$.subscribe((level1name) => {
      this.level1Name = level1name!;
    });
    this.storeLevel2Name$.subscribe((level2name) => {
      this.level2Name = level2name!;
    });
    this.storeLevel3Name$.subscribe((level3name) => {
      this.level3Name = level3name!;
    });

    this.dateRangeForm.get('startDate')?.valueChanges.subscribe((value) => {
      if (!this.dateRangeForm.errors?.['dateRangeInvalid']) {
        this.startDate = value;
        this.assetHistoryStartDate = this.formatToISO(this.startDate);
        this.creategraph();
        this.reinitializeChart();
      }
    });

    this.dateRangeForm.get('endDate')?.valueChanges.subscribe((value) => {
      if (!this.dateRangeForm.errors?.['dateRangeInvalid']) {
        this.endDate = value;
        this.assetHistoryEndDate = this.formatToISO(this.endDate);
        this.creategraph();
        this.reinitializeChart();
      }
    });

    this.assetHistoryStartDate = this.formatToISO(this.startDate);
    this.assetHistoryEndDate = this.formatToISO(this.endDate);
    this.loadAssetData();
  }

  formatToISO(date: Date | null): string | null {
    return date ? new Date(date).toISOString() : null;
  }

  dateRangeValidator(
    formGroup: AbstractControl,
  ): Record<string, boolean> | null {
    const startDate = formGroup.get('startDate')?.value;
    const endDate = formGroup.get('endDate')?.value;

    if (startDate && endDate && startDate > endDate) {
      return { dateRangeInvalid: true };
    }
    return null;
  }

  // ngAfterViewInit(): void {
  //   this.isLoading = false;
  //   this.center = this.mapService.getMapCenter();
  //   this.loadMap();

  //   if (this.mapElementRef.nativeElement) {
  //     this.mapService.initialize(this.mapElementRef.nativeElement, this.map);
  //   }
  // }

  loadAssetPlottingData() {
    this.mapService.assetData = this.assetData;
    this.allAssetsData = this.mapService.getData();
  }

  async loadAssetData(): Promise<void> {
    try {
      this.isLoading = true;
      await this.fetchLevel3Values(this.selectedprimaryComapnyId!);
      this.updateFilterModel();
      const response = await this.assetSetupService.getAssets(this.filterModel);
      if (response) {
        this.assetData = response.assets;
        this.loadAssetPlottingData();
      }
      this.center = this.mapService.getMapCenter();
      this.loadMap();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      console.log('Error: ', error);
    }
  }

  filterSearchButtonClicked(): void {
    this.loadAssetData();
    this.filterTrayOpen = true;
  }

  updateFilterModel() {
    this.filterModel = {
      primaryCompany: this.selectedprimaryComapnyId,
      level4: this.selectedSegments,
      assetTypes: this.selectedAssetType,
      segmentTypes: this.selectedSegmentType,
      surveyRouteFilterInputs: this.surveyRouteFilterInput,
      hierarchyFilterInputs: this.hierarchyFilterInput,
      pageSize: this.pageSize,
      pageNumber: this.currentPage,
      searchText: this.searchText,
      gridFilters: this.kendoGridFilters,
    };
  }

  async fetchLevel3Values(primaryCompanyId: string) {
    const allLevel3Values: HierarchyFilterInput[] = [];
    const level3Values =
      await this.hierarchyFilter.getAllLevel3ValueByPrimaryCompany(
        primaryCompanyId,
      );
    if (level3Values?.items) {
      level3Values.items.forEach((item) => {
        if (item) {
          if (this.selectedLevel3.includes(item.id))
            allLevel3Values.push({
              level1: item.level1ValueId || '',
              level2: item.level2ValueId || '',
              level3: item.id || '',
            });
        }
      });
    }
    this.hierarchyFilterInput = allLevel3Values;
  }

  async fetchSurveyRouteValues(primaryCompanyId: string) {
    const allSurveyRouteFilterValues: SurveyRouteFilterInput[] = [];
    const surveyRouteValues =
      await this.hierarchyFilter.getAllSurveyRouteBySubFilterInput({
        primaryCompanyId: primaryCompanyId,
      });
    if (surveyRouteValues?.items) {
      surveyRouteValues.items.forEach((item) => {
        if (item) {
          if (this.selectedSurveyRoute.includes(item.id)) {
            item.RouteAssociateAssetList?.forEach((level) => {
              level?.level3ValueIdList?.forEach((l3) => {
                allSurveyRouteFilterValues.push({
                  level1: level?.level1ValueId,
                  level2: level?.level2ValueId,
                  level3: l3,
                  assetId: level.assetId,
                });
              });
            });
          }
        }
      });
    }
    this.surveyRouteFilterInput = allSurveyRouteFilterValues;
  }

  async loadMap(): Promise<void> {
    const { Map } = await google.maps.importLibrary('maps');
    const mapEl = this.mapElementRef.nativeElement;

    if (this.center) {
      this.map = new Map(mapEl, {
        center: this.center,
        zoom: 12,
        mapId: '4504f8b37365c3d01',
        scaleControl: true,
        streetViewControl: false,
        zoomControl: true,
        overviewMapControl: true,
        mapTypeControl: true,
        fullscreenControl: true,
      });

      this.plotMarkers();

      google.maps.event.addListener(this.map, 'click', () => {
        this.closeInfoCard(); // Hide info card on map click
        this.closeInfoWindow();
      });

      this.addFullScreenListeners();
    }
  }

  openExportMapModal(): void {
    this.mapService.initialize(this.mapElementRef.nativeElement, this.map);
    this.mapService.openExportMapModal();
    this.collapseFilterTray();
  }

  ngOnDestroy(): void {
    if (this.map) {
      google.maps.event.clearInstanceListeners(this.map);
    }
    this.markerMap.forEach((marker) =>
      google.maps.event.clearInstanceListeners(marker),
    );
  }

  handleSearchInput(value: string): void {
    this.query = value;
    this.filteredResults = this.allAssetsData
      .filter((asset) =>
        asset.assetName.toLowerCase().includes(this.query.toLowerCase()),
      )
      .map((asset) => asset.assetName); // Map to array of asset names
  }

  onSelectAsset(assetName: string): void {
    this.query = assetName;
    this.filteredResults = [];
    const asset = this.allAssetsData.find((a) => a.assetName === assetName);
    if (asset) {
      this.focusOnAsset(asset);
    }
  }

  selectResult(assetName: string): void {
    this.query = assetName;
    this.filteredResults = [];
    const asset = this.allAssetsData.find((a) => a.assetName === assetName);
    if (asset) {
      this.map.setZoom(12);
      this.focusOnAsset(asset);
    }
  }

  private focusOnAsset(asset: any): void {
    this.selectedAsset = asset;
    this.infoCardStyles = {
      display: 'block',
      position: 'absolute',
      top: this.showHierarchicalFilter ? '103px' : '152px',
      right: '10px',
      width: '300px', // Adjust width if needed
      zIndex: 1000,
    };

    const latLng = new google.maps.LatLng(
      parseFloat(asset.latitude),
      parseFloat(asset.longitude),
    );
    this.map.setCenter(latLng);
    this.map.setZoom(15); // Adjust zoom level to focus on asset

    // Clear bounce animation for all markers
    this.markerMap.forEach((marker) => {
      marker.setAnimation(null);
    });

    // Get the selected marker
    const markerId = parseInt(asset.assetId);
    const marker = this.markerMap.get(markerId);

    if (marker) {
      marker.setAnimation(google.maps.Animation.BOUNCE);
      setTimeout(() => marker.setAnimation(null), 1500); // Stop bouncing after 1.5 seconds
    }
  }

  plotMarkers(): void {
    this.allAssetsData.forEach((data) => {
      const latLng = new google.maps.LatLng(
        parseFloat(data.latitude),
        parseFloat(data.longitude),
      );

      const assetType = data.assetType;
      const assetstatus = data.status;
      let fillColor = '#22C55E';
      let markertext = 'M';
      if (assetType == RECTIFIER) {
        markertext = 'R';
      } else if (assetType == TEST_POINT) {
        markertext = 'T';
      } else if (assetType == BOND) {
        markertext = 'B';
      } else if (assetType == ANODE) {
        markertext = 'A';
      } else if (assetType == CASING) {
        markertext = 'CS';
      } else if (assetType == CTS) {
        markertext = 'CP';
      } else if (assetType == ER_PROBE) {
        markertext = 'ER';
      } else if (assetType == ISOLATIONJOINT) {
        markertext = 'IJ';
      } else {
        const firstLetter = assetType.charAt(0).toUpperCase();
        markertext = firstLetter;
      }

      if (assetstatus == COMPLIANT_AH) {
        fillColor = '#22C55E';
      } else if (assetstatus == EXCEPTION_AH) {
        fillColor = '#E5AB09';
      } else if (assetstatus == DELINQUENT_AH) {
        fillColor = '#E77A3C';
      }

      const customMarker = {
        path: `M12 2C8.13 2 5 5.13 5 9c0 3.87 7 13 7 13s7-9.13 7-13c0-3.87-3.13-7-7-7z`,
        fillColor: fillColor,
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#fff',
        scale: 2,
        labelOrigin: new google.maps.Point(12, 10),
      };

      const marker = new google.maps.Marker({
        position: latLng,
        map: this.map,
        title: data.assetName,
        icon: customMarker,
        label: {
          text: markertext,
          color: 'white',
          fontSize: '16px',
          fontWeight: 'bold',
        },
      });

      marker.addListener('click', () => {
        this.showInfoCard(data);

        this.closeInfoWindow();
        if (this.isFullScreen) {
          this.infowindow.open({
            anchor: marker,
            map: this.map,
          });
          this.infowindow.setContent(this.generateInfoWindowContent());
        }
      });

      this.markerMap.set(data.id, marker);
    });
  }

  private showInfoCard(asset: any): void {
    this.selectedAsset = asset;
    this.selectedAssetId = this.selectedAsset.id;
    this.selectedAsset.station = '10+001.4';
    this.selectedAsset.technician = 'Tiger Woods';
    this.selectedAsset.inspectionDate = this.formatDate(
      this.selectedAsset.assetDelinquencyDate,
    );
    this.selectedAsset.assetDelinquencyDate = this.formatDate(
      this.selectedAsset.assetDelinquencyDate,
    );
    this.infoCardStyles = {
      display: 'block',
      position: 'absolute',
      top: this.showHierarchicalFilter ? '103px' : '10px',
      right: '10px',
      width: '300px', // Adjust width if needed
      zIndex: 900,
    };
    this.creategraph();
    if (this.showGraph) {
      this.reinitializeChart();
    }
  }

  reinitializeChart(): void {
    this.chartKey = false;
    setTimeout(() => (this.chartKey = true), 0);
  }

  creategraph() {
    this.isLoading = true;
    this.mapAssetHistoryMetaDataInput();
    this.getAssetDetailsById();
    this.showGraph = true;
    this.chartKey = true;
  }

  mapAssetHistoryMetaDataInput() {
    this.assetMetadataInput = {
      primaryCompany: this.selectedprimaryComapnyId!,
      level1: this.level1!,
      level2: this.level2!,
      level3: this.level3!,
      assetId: this.selectedAssetId!,
    };
  }

  mapAssetHistoryGraphInput() {
    this.assetHistoryGraphInput = {
      primaryCompany: this.selectedprimaryComapnyId!,
      level1: this.currentLevel1!,
      level2: this.currentLevel2!,
      level3: this.currentLevel3!,
      assetId: this.selectedAssetId!,
      startDate: this.assetHistoryStartDate!,
      endDate: this.assetHistoryEndDate!,
      measurementTypes: this.measurementTypeArray,
    };
  }

  async getAssetDetailsById() {
    this.isLoading = true;
    const response = await this.assetHistoryService.getAssetDetailsById(
      this.assetMetadataInput,
    );
    if (response && response.getAssetDetailsById?.measurementCriteria) {
      const measurementCriteriaArray =
        response.getAssetDetailsById?.measurementCriteria.map((item) => ({
          measurementSource: item?.measurementSource || EMPTY_STRING,
          measurementType: item?.measurementType || EMPTY_STRING,
          measurementOperator: item?.measurementOperator || EMPTY_STRING,
          criteria: item?.criteria || EMPTY_STRING,
          criteria1: EMPTY_STRING,
          criteria2: EMPTY_STRING,
          segment: item?.level4Value || EMPTY_STRING,
          segmentRelation: item?.level4Relation || EMPTY_STRING,
          compliance: item?.compliance || false,
          unit: item?.unit || EMPTY_STRING,
        })) || [];
      this.currentLevel1 = response.getAssetDetailsById.level1ValueId;
      this.currentLevel2 = response.getAssetDetailsById.level2ValueId;
      this.currentLevel3 = response.getAssetDetailsById.level3ValueId;
      this.measurementCriteriaInputs.push(...measurementCriteriaArray);
      this.measurementTypeArray =
        response.getAssetDetailsById?.measurementCriteria.map(
          (item) => item?.measurementType || EMPTY_STRING,
        );
      this.measurementTypeArrayWithSegment =
        response.getAssetDetailsById?.measurementCriteria.map((item) => {
          const measurementType = item?.measurementType || EMPTY_STRING;
          const level4Value = item?.level4Value || EMPTY_STRING;
          return `${measurementType} - ${level4Value}`.trim();
        });
    }
    this.assetHistoryService.measurementTypeArray =
      this.measurementTypeArrayWithSegment;
    this.mapAssetHistoryGraphInput();
    this.loadAssetGraphData();
  }

  async loadAssetGraphData() {
    const response = await this.assetHistoryService.getAssetHistoryGraph(
      this.assetHistoryGraphInput,
    );
    this.assetHistoryService.graphData =
      response?.getAssetHistoryGraphData?.items;
    if (response) {
      this.isLoading = false;
    }
  }

  addFullScreenListeners(): void {
    document.addEventListener('fullscreenchange', this.handleFullScreenChange);
    document.addEventListener(
      'webkitfullscreenchange',
      this.handleFullScreenChange,
    );
    document.addEventListener(
      'mozfullscreenchange',
      this.handleFullScreenChange,
    );
    document.addEventListener(
      'MSFullscreenChange',
      this.handleFullScreenChange,
    );
  }

  handleFullScreenChange = () => {
    this.isFullScreen = !!document.fullscreenElement;
    if (!this.isFullScreen) {
      this.closeInfoWindow();
    }
  };

  generateInfoWindowContent(): string {
    if (!this.selectedAsset) return '';

    const assetNameLabel = this.translate.instant('MAP_INFO_CARD.ASSET_NAME');
    const assetTypeLabel = this.translate.instant('MAP_INFO_CARD.ASSET_TYPE');
    const stationLabel = this.translate.instant('MAP_INFO_CARD.STATION');
    const techResponsibleLabel = this.translate.instant(
      'MAP_INFO_CARD.TECH_RESPONSIBLE',
    );
    const complianceStatusLabel = this.translate.instant(
      'MAP_INFO_CARD.COMPLIANCE_STATUS',
    );
    // const complianceMeasurementLabel = this.translate.instant(
    //   'MAP_INFO_CARD.COMPLIANCE_MEASUREMENT',
    // );
    const inspectionDateLabel = this.translate.instant(
      'MAP_INFO_CARD.INSPECTION_DATE',
    );
    const delinquencyDateLabel = this.translate.instant(
      'MAP_INFO_CARD.DELINQUENCY_DATE',
    );

    return `
      <div class="info-card" style="position: relative; width: 250px; padding-top: 0;">
        <div class="info-card-header" style="text-align: center;">
          <h5>${this.translate.instant('MAP_INFO_CARD.ASSET_INFO_CARD')}</h5>
        </div>
        <div class="info-card-content" style="margin-top: 10px;">
          <p><strong>${assetNameLabel}:</strong> ${this.selectedAsset.assetName}</p>
          <p><strong>${assetTypeLabel}:</strong> ${this.selectedAsset.assetType}</p>
          <p><strong>${stationLabel}:</strong> ${this.selectedAsset.station}</p>
          <p><strong>${techResponsibleLabel}:</strong> ${this.selectedAsset.technician}</p>
          <p><strong>${complianceStatusLabel}:</strong> ${this.selectedAsset.status}</p>
          <p><strong>${inspectionDateLabel}:</strong> ${this.selectedAsset.inspectionDate}</p>
          <p><strong>${delinquencyDateLabel}:</strong> ${this.selectedAsset.assetDelinquencyDate}</p>
        </div>
      </div>
    `;
  }

  closeInfoWindow() {
    if (this.infowindow) {
      this.infowindow.close();
    }
  }

  formatDate(date: Date): string {
    if (!date) return '';

    const day = date.getDate();
    const month = date.toLocaleString(DEFAULT, { month: SHORT });
    const year = date.getFullYear();

    const daySuffix = this.getDaySuffix(day);

    return `${day}${daySuffix} ${month}, ${year}`;
  }

  getDaySuffix(day: number): string {
    if (day > 3 && day < 21) return TH;
    switch (day % 10) {
      case 1:
        return ST;
      case 2:
        return ND;
      case 3:
        return RD;
      default:
        return TH;
    }
  }

  closeInfoCard(): void {
    this.selectedAsset = null;
    this.infoCardStyles = { display: 'none' };
  }

  public dropdownData: any[] = [
    { text: 'Inspection', value: 1 },
    { text: 'Asset History', value: 2 },
  ];

  dropdownVisible = false;

  toggleDropdown() {
    this.dropdownVisible = !this.dropdownVisible;
  }

  closePopup() {
    this.dropdownVisible = false;
  }

  openUrl(url: string) {
    this.router.navigate([url]);
    this.closePopup(); // Close the popup after navigation
  }

  // Ensure clicking outside the popup closes it
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (
      !target.closest('.info-card-header') &&
      !target.closest('.popup-menu')
    ) {
      this.closePopup();
    }
  }

  height = 800; // initial height
  showHierarchicalFilter = false;
  showFilterBtn = true;
  toggleFilterTray() {
    this.filterTrayOpen = true;
    this.showHierarchicalFilter = !this.showHierarchicalFilter;
    this.showFilterBtn = !this.showFilterBtn;
    if (this.showHierarchicalFilter) {
      this.height = 830;
    } else {
      this.height = 800;
    }

    if (this.selectedAsset) {
      this.infoCardStyles = {
        display: 'block',
        position: 'absolute',
        top: this.showHierarchicalFilter ? '103px' : '10px',
        right: '10px',
        width: '300px', // Adjust width if needed
        zIndex: 1000,
      };
    }
  }
  collapseFilterTray() {
    this.toggleFilterTray();
    this.filterTrayOpen = false;
  }
}
