<div class="container-fluid py-2">
  <div class="row g-2 justify-content-end">
    <div class="col-xs-6 col-sm-auto col-md-auto col-lg-auto">
      <button class="btn mtx-btn-primary" style="height: 38px;" (click)="openDialog()">
        <span style="vertical-align: middle" class="material-icons">download</span>
        <!-- {{ 'GENERIC_Buttons.EXPORT' | translate }} -->
        Export
      </button>
    </div>
  </div>
  <div class="mt-3">
    <kendo-grid
      [kendoGridBinding]="gridData"
      [pageable]="PAGINATION_ARRAY"
      [pageSize]="pageSize"
      class="grid-shadow"
      [resizable]="true"
    >
      <kendo-grid-column
        field="surveyName"
        title="Survey Name"
        [width]="150"
        [minResizableWidth]="80"
      ></kendo-grid-column>
      <kendo-grid-column
        field="surveyType"
        title="Survey Type"
        [width]="200"
        [minResizableWidth]="100"
      ></kendo-grid-column>
      <kendo-grid-column
        field="surveyPriority"
        title="Survey Priority"
        [width]="140"
        [minResizableWidth]="80"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span
            [ngClass]="getPriorityClass(dataItem.surveyPriority)"
            style="font-weight: 700"
          >
            <span class="dot"></span> {{ dataItem.surveyPriority }}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="surveyStatus"
        title="Survey Status"
        [width]="200"
        [width]="220"
        [minResizableWidth]="120"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span
            style="
              display: flex;
              align-items: center;
              color: #525252;
              font-weight: 700;
            "
          >
            <span
              style="
                width: 10px;
                height: 10px;
                background-color: #525252;
                border-radius: 50%;
                margin-right: 8px;
              "
            ></span>
            {{ dataItem.surveyStatus }}
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="dueDate"
        title="Due Date"
        [width]="150"
        [filter]="'date'"
        [minResizableWidth]="80"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.dueDate | customDate }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="dataCollectionType"
        title="Data Collection Type"
        [width]="160"
        [minResizableWidth]="90"
      ></kendo-grid-column>
      <kendo-grid-column
        field="technician"
        title="Technician"
        [width]="150"
        [minResizableWidth]="80"
      ></kendo-grid-column>
      <kendo-grid-column
        field="supervisor"
        title="Supervisor"
        [width]="150"
        [minResizableWidth]="70"
      ></kendo-grid-column>
      <kendo-grid-column
        field="dateOfCompletion"
        title="Date of Completion"
        [width]="150"
        [filter]="'date'"
        [minResizableWidth]="80"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.dateOfCompletion | customDate }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="surveyDescription"
        [width]="350"
        title="Survey Description"
        [minResizableWidth]="150"
      >
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
