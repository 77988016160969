import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { LEVEL3_STATE, LEVEL3_STATE_TYPE_SET, LEVEL3_STATE_TYPE_SET_REMOVE } from '../../shared/constants';
export interface Level3StateModel {
  selectedLevel3: string[];
}
export class SetLevel3State {
  static readonly type = LEVEL3_STATE_TYPE_SET;
  constructor(public selectedLevel3: string[]) {}
}
export class RemoveLevel3State {
  static readonly type = LEVEL3_STATE_TYPE_SET_REMOVE;
}
@State<Level3StateModel>({
  name: LEVEL3_STATE,
  defaults: {
    selectedLevel3: [],
  },
})
@Injectable()
export class Level3State {
  //selector has logic to get data

  @Selector()
  static getSelectedLevel3Name(state: Level3StateModel) {
    return state.selectedLevel3;
  }

  //actions to do operation
  @Action(SetLevel3State)
  SetLevel3State(
    { patchState }: StateContext<Level3StateModel>,
    { selectedLevel3 }: SetLevel3State,
  ): void {
    patchState({
      selectedLevel3: selectedLevel3,
    });
  }
  @Action(RemoveLevel3State)
  RemoveLevel3State({ setState }: StateContext<Level3StateModel>): void {
    setState({
      selectedLevel3: [],
    });
  }
}
