import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { AssetHistoryService } from '../../core/services/asset-history.service';
import { ExceptionService } from '../../feature/exception/service/exception.service';

@Component({
  selector: 'app-pdf-export-component',
  templateUrl: './pdf-export-component.component.html',
  styleUrl: './pdf-export-component.component.css',
})
export class PdfExportComponentComponent implements OnChanges {
  public dynamicColumns: any[] = [];
  @Input() filteredGridData: any[] = [];
  @Input() selectedColumns: string[] = [];
  @Input() flag = '';

  constructor(private assetHistoryService: AssetHistoryService, private _exceptionService: ExceptionService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.flag = changes['flag']?.currentValue;
    if (this.flag === 'asset-history') {
      if (changes['filteredGridData'] && this.filteredGridData) {
        this.dynamicColumns =
          this.assetHistoryService.assetHistorydynamicColumns;
      }
    }
    if (this.flag === 'exception') {
      if (changes['filteredGridData'] && this.filteredGridData) {
        this.dynamicColumns =
          this._exceptionService.exceptiondynamicColumns;
      }
    }
  }

  public IsColumnSelected(columnName: string): boolean {
    return this.selectedColumns.includes(columnName);
  }

  getNestedValue(dataItem: any, field: string): any {
    if (field in dataItem) {
      return dataItem[field];
    }
    return null;
  }
}
