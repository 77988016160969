import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class CreateReportServiceService {
  public gridData: any[] = [];
  constructor() {
    this.gridData = this.generatesimilarData(10);
  }

  getRandomDate(start: Date, end: Date): Date {
    const startTime = start.getTime();
    const endTime = end.getTime();
    const randomTime = startTime + Math.random() * (endTime - startTime);

    const randomDate = new Date(randomTime);

    // Set time to 00:00:00 to remove the time part
    randomDate.setHours(0, 0, 0, 0);

    return randomDate;
  }
  generatesimilarData(numRows: number): any[] {
    const dt = [];
    const startDate = new Date(2023, 0, 1); // January 1, 2023
    const endDate = new Date(2024, 11, 31); // December 31, 2024
    for (let i = 1; i <= numRows; i++) {
      const lastInspectionDate = this.getRandomDate(startDate, endDate);
      const data = {
        'Asset Type': 'Test Point ' + i,
        'Asset Name': 'TP-005+CB1' + i,
        'Station': '10+001.4',
        'Compliance Status': '850mv IR',
        'Compliance Criteria': '950mv IR',
        'Next Inspection Date': lastInspectionDate,
        'Last Compliance Read': lastInspectionDate,
        'Technician': 'Michael jordan',
      };
      dt.push(data);
    }
    return dt;
  }
}