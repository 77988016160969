import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { LEVEL2_STATE, LEVEL2_STATE_TYPE_SET, LEVEL2_STATE_TYPE_SET_REMOVE } from '../../shared/constants';
export interface Level2StateModel {
  selectedLevel2: string[];
}
export class SetLevel2State {
  static readonly type = LEVEL2_STATE_TYPE_SET;
  constructor(public selectedLevel2: string[]) {}
}
export class RemoveLevel2State {
  static readonly type = LEVEL2_STATE_TYPE_SET_REMOVE;
}
@State<Level2StateModel>({
  name: LEVEL2_STATE,
  defaults: {
    selectedLevel2: [],
  },
})
@Injectable()
export class Level2State {
  //selector has logic to get data

  @Selector()
  static getSelectedLevel2Name(state: Level2StateModel) {
    return state.selectedLevel2;
  }

  //actions to do operation
  @Action(SetLevel2State)
  SetLevel2State(
    { patchState }: StateContext<Level2StateModel>,
    { selectedLevel2 }: SetLevel2State,
  ): void {
    patchState({
      selectedLevel2: selectedLevel2,
    });
  }
  @Action(RemoveLevel2State)
  RemoveLevel2State({ setState }: StateContext<Level2StateModel>): void {
    setState({
      selectedLevel2: [],
    });
  }
}
