import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-no-access',
  templateUrl: './no-access.component.html',
  styleUrl: './no-access.component.css',
})
export class NoAccessComponent {
  constructor(
    private location: Location,
    private router: Router,
  ) {}

  goBack(): void {
    if (window.history.length > 1) {
      this.router.navigate(['/']);
      //this.location.back();
    } else {
      this.router.navigate(['/']);
    }
  }
}
