import { Injectable } from '@angular/core';
import {
  TemplateConnection,
  DeleteTemplateMutationVariables,
  CreateTemplateInput,
  Template,
  CreateTemplateMutationVariables,
  UpdateTemplateInput,
  UpdateTemplateMutationVariables,
  TemplateFilterInput,
  GetAllTemplateByUserQueryVariables,
  templateType,
} from '../../../../awsAppSync/API';
import {
  createTemplate,
  deleteTemplate,
  updateTemplate,
} from '../../../../awsAppSync/graphql/mutations';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import {
  CREATE_TEMPLATE_ERROR,
  FETCHING_TEMPLATES_ERROR,
  GLOBAL_TEMPLATE,
  GLOBAL_TEMPLATE_ITEM_ASSETSETUP,
  GLOBAL_TEMPLATE_ITEM_EXCEPTION,
  GLOBAL_TEMPLATE_ITEM_INSPECTION,
  GLOBAL_TEMPLATE_ITEM_SURVEY_OVERVIEW,
  GLOBAL_TEMPLATE_TYPE,
  UPDATE_TEMPLATE_ERROR,
} from '../../constants';
import { PrepareTemplateFilter } from '../../helper';
import { TemplateBase } from '../../../core/models/template';
import { getAllTemplateByUser } from '../../../../awsAppSync/graphql/queries';
import { BaseAWSRequestService } from '../base-services/base-aws-request.service';
import { parseDate } from '@progress/kendo-angular-intl';
import { Store } from '@ngxs/store';
import { AuthenticateUserState } from '../../../core/store/authenticate-user.state';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  public templates: TemplateBase[] = [];
  private templateList = new BehaviorSubject<TemplateBase[]>(this.templates);
  templates$ = this.templateList.asObservable();
  public templateItems: Record<string, { id: number; checked: boolean }[]> = {};
  private templateItemsList = new BehaviorSubject<
    Record<string, { id: number; checked: boolean }[]>
  >(this.templateItems);
  templatesItems$ = this.templateItemsList.asObservable();
  public createTemplateId = '';

  private userId = "" ;
  private userId$: Observable<string | null> = of(null);
  private subscriptions: Subscription = new Subscription();


  constructor(private awsService: BaseAWSRequestService, private store: Store) {
    this.initilizeUserObservable();
  }

  initilizeUserObservable(){
    this.userId$ = this.store.select(AuthenticateUserState.getSyrcUserId);
    this.subscriptions.add(
      this.userId$.subscribe((userId) => {
        this.userId = userId?? "";
      }),
    );
  }


  // //Get all templates by user
  // async getAllTemplatesByUser(
  //   filter: TemplateFilterInput,
  //   userId: string,
  // ): Promise<TemplateConnection | null> {
  //   try {
  //     const variables: GetAllTemplateByUserQueryVariables = { filter, userId };
  //     const response = (await this.awsService.client.graphql({
  //       query: getAllTemplateByUser,
  //       //authToken: this.authToken,
  //       variables: variables,
  //     })) as { data: GetAllTemplateByUserQuery };
  //     return response.data.getAllTemplateByUser!;
  //   } catch (error) {
  //     console.error(FETCHING_TEMPLATES_ERROR, error);
  //     return null;
  //   }
  // }

  private async getAllTemplatesByUser(
    filter: TemplateFilterInput,
    userId: string,
  ): Promise<TemplateConnection | null> {
    const query = getAllTemplateByUser; // Ensure this is your GraphQL query string or document
    const variables: GetAllTemplateByUserQueryVariables = { filter, userId };

    try {
      const result = await this.awsService.executeGetQuery<{
        getAllTemplateByUser: TemplateConnection;
      }>(query, variables);
      return result.getAllTemplateByUser || null;
    } catch (error) {
      console.error(FETCHING_TEMPLATES_ERROR, error);
      return null;
    }
  }

  //Get all templates by user mapped
  public getAllTemplatesByUserMapped(
    filter: TemplateFilterInput,
    userId: string,
    templateType: string,
  ): Promise<TemplateBase[]> {
    return new Promise<TemplateBase[]>((resolve, reject) => {
      this.getAllTemplatesByUser(filter, userId)
        .then((templateConnection) => {
          if (templateConnection?.items) {
            const mappedTemplates = templateConnection.items
              .sort(
                (a, b) => parseDate(a?.createdDate) - parseDate(b?.createdDate),
              )
              .map((template) => ({
                id: template!.id,
                templateName: template!.templateName,
                templateType: template!.templateType!,
                templateConfiguration: template!.templateConfiguration!,
                isGlobal: false,
              }));
            this.bindObservableObjects(mappedTemplates, templateType);
          } else {
            this.bindObservableObjects(null, templateType);
          }
          resolve(this.templates);
        })
        .catch((error) => {
          console.error(FETCHING_TEMPLATES_ERROR, error);
          reject(error);
        });
    });
  }

  //Bind the observable objects
  bindObservableObjects(
    mappedTemplates: TemplateBase[] | null,
    templateType: string,
  ) {
    if (mappedTemplates) {
      this.templates = [GLOBAL_TEMPLATE, ...mappedTemplates];
    } else {
      this.templates = [GLOBAL_TEMPLATE];
    }
    this.templateList.next(this.templates);
    this.prepareTemplateList(templateType);

    if (this.createTemplateId != '') {
      this.setAddedTemplateId(this.createTemplateId!);
      this.setIsNewTemplate(true);
      this.triggerOpenTemplateSettings();
      this.createTemplateId = '';
    }
  }

  //Bind template list
  private prepareTemplateList(templateTypeInput: string) {
    if (templateTypeInput == templateType.inspection) {
      this.templateItems = GLOBAL_TEMPLATE_ITEM_INSPECTION;
      this.templateItemsList.next(this.templateItems);
    } else if (templateTypeInput == templateType.exception) {
      this.templateItems = GLOBAL_TEMPLATE_ITEM_EXCEPTION;
      this.templateItemsList.next(this.templateItems);
    } else if (templateTypeInput == templateType.asset_setup) {
      this.templateItems = GLOBAL_TEMPLATE_ITEM_ASSETSETUP;
      this.templateItemsList.next(this.templateItems);
    } else if (templateTypeInput == templateType.survey_overview) {
      this.templateItems = GLOBAL_TEMPLATE_ITEM_SURVEY_OVERVIEW;
      this.templateItemsList.next(this.templateItems);
    }
    this.templates.forEach((template) => {
      if (
        template.templateConfiguration != '' &&
        template.templateType != GLOBAL_TEMPLATE_TYPE
      ) {
        const templateConfigurationJSON = JSON.parse(
          template.templateConfiguration,
        );
        this.addTemplateItem(template.id, templateConfigurationJSON);
      }
    });
  }

  //Add template item
  private addTemplateItem(
    templateId: string,
    items: { id: number; checked: boolean }[],
  ): void {
    this.templateItems[templateId] = items;
    this.templateItemsList.next(this.templateItems);
  }

  //Create template
  // async createTemplate(input: CreateTemplateInput): Promise<Template | null> {
  //   try {
  //     const variables: CreateTemplateMutationVariables = { input };
  //     const response = await this.awsService.client.graphql({
  //       query: createTemplate,
  //       variables: variables,
  //     });
  //     if (response) {
  //       const filter = PrepareTemplateFilter(input.templateType);
  //       this.createTemplateId = response!.data!.createTemplate!.id;
  //       this.getAllTemplatesByUserMapped(filter, USER_ID, input.templateType);
  //     }
  //     return response.data.createTemplate || null;
  //   } catch (error) {
  //     console.error(CREATE_TEMPLATE_ERROR, error);
  //     return null;
  //   }
  // }

  public async createTemplate(
    input: CreateTemplateInput,
  ): Promise<Template | null> {
    const mutation = createTemplate; // Ensure this is your GraphQL mutation string or document
    const variables: CreateTemplateMutationVariables = { input };
    try {
      const result = await this.awsService.executeMutation<{
        createTemplate: Template;
      }>(mutation, variables);

      if (result.createTemplate) {
        const filter = PrepareTemplateFilter(input.templateType);
        this.createTemplateId = result.createTemplate.id;
        this.getAllTemplatesByUserMapped(filter, this.userId, input.templateType);
      }
      return result.createTemplate || null;
    } catch (error) {
      console.error(CREATE_TEMPLATE_ERROR, error);
      return null;
    }
  }

  //Update template
  // async updateTemplate(
  //   input: UpdateTemplateInput,
  //   templateType: templateType,
  // ): Promise<Template | null> {
  //   try {
  //     const variables: UpdateTemplateMutationVariables = { input };
  //     const response = await this.awsService.client.graphql({
  //       query: updateTemplate,
  //       variables: variables,
  //     });
  //     if (response) {
  //       const filter = PrepareTemplateFilter(templateType);
  //       this.getAllTemplatesByUserMapped(filter, USER_ID, input.templateType);
  //     }
  //     return response.data.updateTemplate || null;
  //   } catch (error) {
  //     console.error(UPDATE_TEMPLATE_ERROR, error);
  //     return null;
  //   }
  // }

  public async updateTemplate(
    input: UpdateTemplateInput,
    templateType: templateType,
  ): Promise<Template | null> {
    const mutation = updateTemplate; // Ensure this is your GraphQL mutation string or document
    const variables: UpdateTemplateMutationVariables = { input };

    try {
      const result = await this.awsService.executeMutation<{
        updateTemplate: Template;
      }>(mutation, variables);

      if (result.updateTemplate) {
        const filter = PrepareTemplateFilter(templateType);
        this.getAllTemplatesByUserMapped(filter, this.userId, input.templateType);
      }

      return result.updateTemplate || null;
    } catch (error) {
      console.error(UPDATE_TEMPLATE_ERROR, error);
      return null;
    }
  }

  //Delete template
  // async deleteTemplate(
  //   userId: string,
  //   templateName: string,
  //   templateType: templateType,
  // ): Promise<string | null> {
  //   try {
  //     const variables: DeleteTemplateMutationVariables = {
  //       userId,
  //       templateName,
  //       templateType,
  //     };
  //     const response = await this.awsService.client.graphql({
  //       query: deleteTemplate,
  //       variables: variables,
  //     });
  //     if (response.data.deleteTemplate) {
  //       const filter = PrepareTemplateFilter(templateType);
  //       this.getAllTemplatesByUserMapped(filter, USER_ID, templateType);
  //     }
  //     return response.data.deleteTemplate || null;
  //   } catch (error) {
  //     console.error('Error deleting template:', error);
  //     return null;
  //   }
  // }

  public async deleteTemplate(
    userId: string,
    templateName: string,
    templateType: templateType,
  ): Promise<string | null> {
    const mutation = deleteTemplate; // Ensure this is your GraphQL mutation string or document
    const variables: DeleteTemplateMutationVariables = {
      userId,
      templateName,
      templateType,
    };

    try {
      const result = await this.awsService.executeMutation<{
        deleteTemplate: string;
      }>(mutation, variables);

      if (result.deleteTemplate) {
        const filter = PrepareTemplateFilter(templateType);
        this.getAllTemplatesByUserMapped(filter, this.userId, templateType);
      }

      return result.deleteTemplate || null;
    } catch (error) {
      console.error('Error deleting template:', error);
      return null;
    }
  }

  //For added new templateID
  private templateId = new BehaviorSubject<string>('');
  getAddedTemplateId = this.templateId.asObservable();
  setAddedTemplateId(templateId: string) {
    this.templateId.next(templateId);
  }

  //For deleted templateID
  private deletedTemplateId = new BehaviorSubject<string>('');
  getDeletedTemplateId = this.deletedTemplateId.asObservable();
  setDeletedTemplateId(deletedTemplateId: string) {
    this.deletedTemplateId.next(deletedTemplateId);
  }

  //To check if New template added
  private isNewTemplate = new BehaviorSubject<boolean>(false);
  getIsNewTemplate = this.isNewTemplate.asObservable();
  setIsNewTemplate(isNewTemplate: boolean) {
    this.isNewTemplate.next(isNewTemplate);
  }

  //To set template settings source
  private openTemplateSettingsSource = new Subject<void>();
  openTemplateSettings$ = this.openTemplateSettingsSource.asObservable();
  triggerOpenTemplateSettings() {
    this.openTemplateSettingsSource.next();
  }

  //To close popup
  private isTemplateDeletedSource = new Subject<boolean>();
  getIsTemplateDeleted$ = this.isTemplateDeletedSource.asObservable();
  setIsTemplateDeleted(value: boolean) {
    this.isTemplateDeletedSource.next(value);
  }
}
