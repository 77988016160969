<mat-dialog-content class="add-asset-dialog">
    <div class="dialog-header">
      <div class="asset-header-icon"></div>
      
      <div class="top-close-button col-2" (click)="close()"></div>
    </div>
    <h2 class="text-alignment">Add Asset</h2>
    <p>Add asset by selecting from the following options</p>
    <h3 class="sub-header-alignment">File Format</h3>
    <div class="button-container" (click)="selection('new')">
        <div class="button" [ngClass]="{'selected': selectedType === 'new'}">
          <div class="asset-modal-featuredicon1">
            <div class="asset-modal-layerstwo01">
              <div class="asset-modal-layerstwo011"></div>
            </div>
          </div>
          <div class="asset-modal-textandsupportingtext02 ">
            <div class="asset-modal-textandsubtext">
              <div class="asset-modal-text06">
                <span>Create New Single Asset</span>
              </div>
            </div>
          </div>
          <div class="indicator" [ngClass]="{'checkmark': selectedType === 'new'}"></div>
        </div>
      </div>
      <div class="button-container" (click)="selection('import')">
        <div class="button" [ngClass]="{'selected': selectedType === 'import'}">
          <div class="asset-modal-featuredicon1">
            <div class="asset-modal-layerstwo01">
              <div class="asset-modal-layerstwo012"></div>
            </div>
          </div>
          <div class="asset-modal-textandsupportingtext02 ">
            <div class="asset-modal-textandsubtext">
              <div class="asset-modal-text06">
                <span>Import Bulk Assets</span>
              </div>
            </div>
          </div>
          <div class="indicator" [ngClass]="{'checkmark': selectedType === 'import'}"></div>
        </div>
      </div>
  </mat-dialog-content>