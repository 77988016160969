/**
 * @param s string to capitalize
 * @returns capitalized string
 */
function capitalize(s) {
    return `${s[0].toUpperCase()}${s.slice(1)}`;
}

export { capitalize };

