<div style="display: flex">
  <button kendoButton #anchor (click)="onToggle($event)" class="top-right-btn">
    {{ 'GENERIC_Buttons.TEMPLATES' | translate }}
  </button>
</div>
<kendo-popup
  #popup
  [anchor]="anchor.element"
  (anchorViewportLeave)="show = false"
  *ngIf="show"
  class="kendo-popup templatepopup"
>
  <div class="template-container" (click)="$event.stopPropagation()">
    <div class="search-bar">
      <kendo-svgicon
        [icon]="svgSearch"
        style="margin-left: 5px; color: gray"
      ></kendo-svgicon>
      <input
        type="search"
        [(ngModel)]="searchTerm"
        class="search-input"
        placeholder="Search Template"
      />
    </div>
    <ul class="template-list">
      <li *ngFor="let template of filteredTemplates">
        <span class="template-text" (click)="selectTemplate(template!)">{{
          template!.templateName!
        }}</span>
        <button
          *ngIf="template.isGlobal"
          kendoButton
          class="global-icon-button"
          [disabled]="true"
        ></button>
        <button
          *ngIf="!template.isGlobal"
          (click)="openDeleteDialog(template.id)"
          class="delete-button"
        ></button>
      </li>
    </ul>

    <button
      kendoButton
      style="background-color: #0099cc;"
      *ngIf="showAddButton"
      (click)="setAddButton()"
      class="add-button"
    >
      + Template
    </button>

    <div *ngIf="!showAddButton" style="margin-top: 20px">
      <label style="color: gray" class="template-text"> Add Template</label>
      <div class="input-group">
        <button
          kendoButton
          class="input-group-button1"
          [disabled]="true"
        ></button>
        <input
          kendoTextBox
          [placeholder]="placeHolderText"
          [(ngModel)]="templateName"
          class="input-group-text"
          (click)="toggleAddButton()"
        />
        <button
          *ngIf="showSvgAddButton"
          kendoButton
          [svgIcon]="svgAdd"
          (click)="createTemplate()"
          class="input-group-button"
        ></button>
      </div>
    </div>
  </div>
</kendo-popup>
<kendo-dialog
  *ngIf="showDeleteDialog"
  (close)="setDeleteDialogVisibility()"
  [width]="450"
>
  <div style="margin: 10px">
    <div
      style="flex-direction: row; justify-content: space-between; display: flex"
    >
      <button
        kendoButton
        class="deleteDialog-button"
        [disabled]="true"
      ></button>
      <button
        kendoButton
        class="closeDialog-button"
        (click)="setDeleteDialogVisibility()"
      ></button>
    </div>
    <div style="margin-left: 10px">
      <h2
        class="mt-3"
        style="
          font-size: 20px;
          font-style: Regular;
          text-align: left;
          font-family: Roboto;
          font-weight: 800;
          color: #5b5b5b;
        "
      >
        Delete Template?
      </h2>
      <p class="template-text" style="color: #8e8e8e">
        This will permanently remove the template "<strong>{{
          selectedTemplate!.templateName
        }}</strong
        >" from your library. Are you sure you want to proceed?
      </p>
    </div>
    <kendo-dialog-actions
      class="mt-4"
      style="
        margin-bottom: 10px;
        border-color: transparent;
        align-content: space-between;
      "
    >
      <button
        kendoButton
        (click)="deleteTemplate()"
        style="
          font-size: 16px;
          font-family: Roboto;
          font-weight: 500;
          height: 40px;
          margin-left: 15px;
          background-color: #0099cc;
          color: white;
        "
      >
        Confirm
      </button>
      <button
        kendoButton
        (click)="setDeleteDialogVisibility()"
        class="cancelDialog-button"
      >
        Cancel
      </button>
    </kendo-dialog-actions>
  </div>
</kendo-dialog>
