<div mat-dialog-content class="dialog-content p-4">
  <div class="dialog-header">
    <!-- <div [ngClass]="getStatusClass()">
      </div> -->
    <div class="connection" *ngIf="data.icon === 'connection'">
      <kendo-icon name="non-recurrence" size="medium"></kendo-icon>
    </div>
    <div class="success" *ngIf="data.icon === 'success'">
      <kendo-icon name="success" size="medium"></kendo-icon>
    </div>
    <div class="failed" *ngIf="data.icon === 'failed'">
      <kendo-icon name="exception" size="medium"></kendo-icon>
    </div>
    <div class="help" *ngIf="data.icon === 'help'">
      <kendo-icon name="question" size="medium"></kendo-icon>
    </div>
    <div class="dialog-close">
      <kendo-icon (click)="onClose()" name="close" size="medium"></kendo-icon>
    </div>
  </div>
  <div class="dialog-body pt-2">
    <h2>{{ data.title }}</h2>
    <p style="color: #8E8E8E;">{{ data.message }}</p>
  </div>
  <div mat-dialog-actions class="dialog-footer mt-3 ">
    <button *ngIf="data.isCancelBtnRequired"   mat-dialog-close
      class="dialog-button-default" (click)="onCancel()"><strong>{{data.cancelButtonText}}</strong></button>
    <button mat-button class="dialog-button" (click)="onConfirm()">{{data.buttonText}}</button>
  </div>
</div>