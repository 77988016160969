import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
    ASSET_SETUP_STATE,
    ASSET_SETUP_STATE_TYPE_SET,
    ASSET_SETUP_STATE_TYPE_REMOVE,
    EMPTY_STRING,
} from '../../shared/constants';
export interface AssetSetupStateModel {
  isEditable: boolean | false,
  assetSetupHighlightFieldName: string | null;
  primaryCompany: string| null;
  company: string| null;
  region: string| null;
  segment: string| null;
  route: string| null;
  station: string| null;
  assetType: string| null;
  assetName: string| null;
  assetTypeTemplate: string| null;
  latitude: string| null;
  longitude: string| null;
  id: string| null;
  technician: string| null;
  comments: string| null;
  atmosphericCorrosionGrade: string| null;
  dateCreated: string| null;
  isMeasurementAvailable: boolean | false;
}
export class SetAsset {
  static readonly type = ASSET_SETUP_STATE_TYPE_SET;
  constructor(
    public isEditable: boolean,
    public assetSetupHighlightFieldName: string,
    public primaryCompany: string,
    public company: string,
    public region: string,
    public segment: string,
    public station: string,
    public assetType: string,
    public assetName: string,
    public assetTypeTemplate: string,
    public latitude: string,
    public longitude: string,
    public id: string,
    public dateCreated: string,
    public isMeasurementAvailable: false,
  ) {}
}
export class RemoveAsset {
  static readonly type = ASSET_SETUP_STATE_TYPE_REMOVE;
}
@State<AssetSetupStateModel>({
  name: ASSET_SETUP_STATE,
  defaults: {
    isEditable: false,
    assetSetupHighlightFieldName: null,
    primaryCompany: null,
    company: null,
    region: null,
    segment: null,
    route: null,
    station: null,
    assetType: null,
    assetName: null,
    assetTypeTemplate: null,
    latitude: null,
    longitude: null,
    id: null,
    technician: null,
    comments: null,
    atmosphericCorrosionGrade: null,
    dateCreated: null,
    isMeasurementAvailable: false,
  },
})
@Injectable()
export class AssetSetupState {
  //selector has logic to get data
  @Selector()
  static getAssetIsEditable(state: AssetSetupStateModel) {
    return state.isEditable;
  }

  @Selector()
  static getAssetSetupHighlightFieldName(state: AssetSetupStateModel) {
    return state.assetSetupHighlightFieldName;
  }

  @Selector()
  static getAssetSetupPrimaryCompany(state: AssetSetupStateModel) {
    return state.primaryCompany;
  }

  @Selector()
  static getAssetSetupCompany(state: AssetSetupStateModel) {
    return state.company;
  }
  @Selector()
  static getAssetSetupRegion(state: AssetSetupStateModel) {
    return state.region;
  }
  @Selector()
  static getAssetSetupSegment(state: AssetSetupStateModel) {
    return state.segment;
  }
  @Selector()
  static getAssetSetupStation(state: AssetSetupStateModel) {
    return state.station;
  }
  @Selector()
  static getAssetSetupLatitude(state: AssetSetupStateModel) {
    return state.latitude;
  }
  @Selector()
  static getAssetSetupLongitute(state: AssetSetupStateModel) {
    return state.longitude;
  }
  @Selector()
  static getAssetSetupAssetName(state: AssetSetupStateModel) {
    return state.assetName;
  }

  @Selector()
  static getAssetSetupAssetType(state: AssetSetupStateModel) {
    return state.assetType;
  }
  @Selector()
  static getAssetSetupAssetTypeTemplate(state: AssetSetupStateModel) {
    return state.assetTypeTemplate;
  }
  @Selector()
  static getAssetSetupId(state: AssetSetupStateModel) {
    return state.id;
  }
  @Selector()
  static getAssetDateCreated(state: AssetSetupStateModel) {
    return state.dateCreated;
  }
  @Selector()
  static getAssetTechnician(state: AssetSetupStateModel) {
    return state.technician;
  }
  @Selector()
  static getAssetComments(state: AssetSetupStateModel) {
    return state.comments;
  }
  @Selector()
  static getAssetAtmosphericCorrosionGrade(state: AssetSetupStateModel) {
    return state.atmosphericCorrosionGrade;
  }
  @Selector()
  static getAssetRoute(state: AssetSetupStateModel) {
    return state.route;
  }
  @Selector()
  static getAssetIsMeasurementAvailable(state: AssetSetupStateModel) {
    return state.isMeasurementAvailable;
  }
  
 
  //actions to do operation
  @Action(SetAsset)
  setAsset(
    { patchState }: StateContext<AssetSetupStateModel>,
    {
       isEditable,
        assetSetupHighlightFieldName,
        primaryCompany,
        company,
        region,
        segment,
        station,
        assetType,
        assetName,
        assetTypeTemplate,
        latitude,
        longitude,
        id,
        dateCreated,
        isMeasurementAvailable,
    }: SetAsset,
  ): void {
    patchState({
      isEditable: isEditable,
      assetSetupHighlightFieldName: assetSetupHighlightFieldName,
      primaryCompany: primaryCompany,
      company: company,
      region: region,
      segment: segment,
      station: station,
      assetType: assetType,
      assetName: assetName,
      assetTypeTemplate: assetTypeTemplate,
      latitude: latitude,
      longitude: longitude,
      id: id,
      dateCreated,
      isMeasurementAvailable,
    });
  }
  @Action(RemoveAsset)
  removeAssetSetup({
    setState,
  }: StateContext<AssetSetupStateModel>): void {
    setState({
      isEditable: false,
      assetSetupHighlightFieldName: EMPTY_STRING,
      primaryCompany: EMPTY_STRING,
      company: EMPTY_STRING,
      region: EMPTY_STRING,
      segment: EMPTY_STRING,
      route: EMPTY_STRING,
      station: EMPTY_STRING,
      assetType: EMPTY_STRING,
      assetName: EMPTY_STRING,
      assetTypeTemplate: EMPTY_STRING,
      latitude: EMPTY_STRING,
      longitude: EMPTY_STRING,
      id: EMPTY_STRING,
      technician: EMPTY_STRING,
      comments: EMPTY_STRING,
      atmosphericCorrosionGrade: EMPTY_STRING,
      dateCreated: EMPTY_STRING,
      isMeasurementAvailable: false,
    });
  }
}