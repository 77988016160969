import { Injectable } from '@angular/core';
import { ReportTemplateLibrary } from '../models/report-templates-library.module';
@Injectable({
  providedIn: 'root',
})
export class ReportTemplatesLibraryService {
  private staticTemplates: ReportTemplateLibrary[] = [
    {
      id:"1",
      templateName: 'Template 1',
      type: 'Audit',
      description:
        'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      lastUsedOn: '16th July, 2024',
      templateColumns: [
        'Asset Type',
        'Asset Name',
        'Station',
        'Next Inspection Date',
        'Compliance Criteria',
        'Compliance Status',
        'Last Compliance Read',
        'Technician',
      ],
    },
    {
      id:"2",
      templateName: 'Template 2',
      type: 'Performance',
      description: 'Click here to add a route description...',
      lastUsedOn: '15th July, 2024',
      templateColumns: [
        'Asset Type',
        'Asset Name',
        'Station',
        'Next Inspection Date',
        'Compliance Criteria',
      ],
    },
    {
      id:"3",
      templateName: 'Template 3',
      type: 'Audit',
      description:
        'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      lastUsedOn: '14th July, 2024',
      templateColumns: [
        'Asset Type',
        'Asset Name',
        'Station',
        'Next Inspection Date',
        'Compliance Criteria',
        'Compliance Status',
      ],
    },
    {
      id:"4",
      templateName: 'Template 4',
      type: 'Performance',
      description:
        'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.',
      lastUsedOn: '13th July, 2024',
      templateColumns: [
        'Asset Type',
        'Asset Name',
        'Station',
        'Next Inspection Date',
        'Compliance Criteria',
        'Compliance Status',
      ],
    },
    {
      id:"5",
      templateName: 'Template 5',
      type: 'Audit',
      description:
        'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.',
      lastUsedOn: '11th July, 2024',
      templateColumns: [
       'AssetType',
        'AssetName',
        'Station',
        'Compliance Criteria',
        'Compliance Status',
      ],
    },
    // Add more templates as needed
  ];
  // Sample data generation function
  generateRandomReportTemplates(): ReportTemplateLibrary[] {
    return this.staticTemplates;
  }
}
