<div class="notification-popup">
    <div class="notification-header">
      <span>Notifications</span>
      <!-- <a href="#" class="see-more">See More</a> -->
    </div>
    <div *ngFor="let notification of notifications" class="notification-item">
      <div class="notification-content">
        <div *ngIf="notification.isNew" class="new-indicator"></div>
        <span class="avatar"></span>
        
        <div class="notification-text">
          <p>
            <!-- <strong>{{ notification.name }}</strong> -->
             Your export of file <strong>{{ notification.fileName }}</strong>  is complete, please review.
          </p>
          <div class="file-info">
            <!-- <div class="file">
              <kendo-icon name="chart-pie" size="medium"></kendo-icon>
            </div>
            <span class="file-name">{{ notification.fileName }}</span>
            <span class="file-size">{{ notification.fileSize }}</span> -->
          </div>
          <div class="timestamp">{{ notification.timestamp }}</div>
        </div>
      </div>
    </div>
  </div>
  