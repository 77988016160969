import {
  ASSET_SETUP_DATA_STATE,
  ASSET_SETUP_STATE,
  ASSETTYPE_SETUP_STATE,
  AUTHENTICATE_USER_STATE,
  COMPANY_SETUP_STATE,
  LEVEL1_LEVELNAME_STATE,
  LEVEL1_STATE,
  LEVEL2_LEVELNAME_STATE,
  LEVEL2_STATE,
  LEVEL3_LEVELNAME_STATE,
  LEVEL3_STATE,
  PC_ADMIN__STATE,
  PRIMARY_COMPANY_SETUP_STATE,
  REGION_SETUP_STATE,
  SEGMENT_SETUP_STATE,
  SEGMENTTYPE_SETUP_STATE,
  SELECTED_MTX_Member_STATE,
  SELECTED_USER_STATE,
  SURVEY_OVERVIEW_DATA_STATE,
  SurveyROUTE_SETUP_STATE,
  TEMPLATE_SETUP_STATE,
  USER_PROFILE_DATA_STATE,
} from '../../shared/constants';
import { AssetSetupState } from './asset-setup.state';
import { AuthenticateUserState } from './authenticate-user.state';
import { LOCAL_STORAGE_ENGINE } from '@ngxs/storage-plugin';
import { StorageKey } from '@ngxs/storage-plugin/internals';
import { CompanyState } from './filter-company';
import { RegionState } from './filter-region';
import { SegmentState } from './filter-segments';
import { SurveyRouteState } from './filter-serveyrotes';
import { AssetTypeState } from './filter-assettype';
import { TemplateState } from './template.state';
import { Level1State } from './filter-level1.state';
import { Level2State } from './filter-level2.state';
import { Level3State } from './filter-level3.state';
import { SegmentTypeState } from './filter-segmenttype.state';
import { PrimaryCompanyState } from './primary-company';
import { SetupHierarchyState } from './setup-hierarchy.state';
import { SelectedUserState } from './selectedUser.state';
import { AssetSetupDataState } from './asset.state';
import { SurveyOverviewDataState } from './survey-overview.state';
import { SurveyRouteDataState } from './survey-route.state';
import { UserProfileDataState } from './user-profile.state';
import { SelectedMTX_MemberState } from './MTX_Member.state';
import { PCAdminDataState } from './user-admin.state.state';
import { Level1LevelNameState } from './filter-level1Name.state';
import { Level2LevelNameState } from './filter-level2Name.state';
import { Level3LevelNameState } from './filter-level3Name.state';

export const states = [
  AuthenticateUserState,
  AssetSetupState,
  CompanyState,
  RegionState,
  SegmentState,
  SurveyRouteState,
  AssetTypeState,
  TemplateState,
  Level1State,
  Level2State,
  Level3State,
  SegmentTypeState,
  PrimaryCompanyState,
  SetupHierarchyState,
  SelectedUserState,
  AssetSetupDataState,
  SurveyOverviewDataState,
  SurveyRouteDataState,
  UserProfileDataState,
  SelectedMTX_MemberState,
  PCAdminDataState,
  Level1LevelNameState,
  Level2LevelNameState,
  Level3LevelNameState
];

export const statesToStoreInLocalStorage: StorageKey[] = [
  AuthenticateUserState,
  AssetSetupState,
  CompanyState,
  RegionState,
  SegmentState,
  SurveyRouteState,
  AssetTypeState,
  TemplateState,
  Level1State,
  Level2State,
  Level3State,
  SegmentTypeState,
  PrimaryCompanyState,
  SetupHierarchyState,
  SelectedUserState,
  AssetSetupDataState,
  SurveyOverviewDataState,
  SurveyRouteDataState,
  UserProfileDataState,
  SelectedMTX_MemberState,
  PCAdminDataState,
  Level1LevelNameState,
  Level2LevelNameState,
  Level3LevelNameState
];

export enum enStateName {
  AuthenticateUserState = AUTHENTICATE_USER_STATE,
  AssetSetupState = ASSET_SETUP_STATE,
  CompanyState = COMPANY_SETUP_STATE,
  RegionState = REGION_SETUP_STATE,
  SegmentState = SEGMENT_SETUP_STATE,
  SurveyRouteState = SurveyROUTE_SETUP_STATE,
  AssetTypeState = ASSETTYPE_SETUP_STATE,
  TemplateState = TEMPLATE_SETUP_STATE,
  Level1State = LEVEL1_STATE,
  Level2State = LEVEL2_STATE,
  Level3State = LEVEL3_STATE,
  SegmentTypeState = SEGMENTTYPE_SETUP_STATE,
  PrimaryCompanyState = PRIMARY_COMPANY_SETUP_STATE,
  SetupHierarchyState = 'setupHierarchy',
  SelectedUserState = SELECTED_USER_STATE,
  AssetSetupDataState = ASSET_SETUP_DATA_STATE,
  SurveyOverviewDataState = SURVEY_OVERVIEW_DATA_STATE,
  SurveyRouteDataState = 'surveyRouteDataSetup',
  UserProfileDataState = USER_PROFILE_DATA_STATE,
  SelectedMTX_MemberState = SELECTED_MTX_Member_STATE,
  PCAdminDataState = PC_ADMIN__STATE,
  Level1LevelNameState = LEVEL1_LEVELNAME_STATE,
  Level2LevelNameState = LEVEL2_LEVELNAME_STATE,
  Level3LevelNameState = LEVEL3_LEVELNAME_STATE,
}

export const storageSettingForStore: StorageKey[] = [
  {
    key: enStateName.AuthenticateUserState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: enStateName.SetupHierarchyState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: enStateName.AssetSetupState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: enStateName.CompanyState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: enStateName.RegionState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: enStateName.SegmentState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: enStateName.SurveyRouteState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: enStateName.AssetTypeState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: enStateName.TemplateState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: enStateName.Level1State,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: enStateName.Level2State,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: enStateName.Level3State,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: enStateName.SegmentTypeState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: enStateName.PrimaryCompanyState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: enStateName.SelectedUserState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: enStateName.AssetSetupDataState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: enStateName.SurveyOverviewDataState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: enStateName.SurveyRouteDataState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: enStateName.UserProfileDataState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: enStateName.SelectedMTX_MemberState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: enStateName.PCAdminDataState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: enStateName.Level1LevelNameState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: enStateName.Level2LevelNameState,
    engine: LOCAL_STORAGE_ENGINE,
  },
  {
    key: enStateName.Level3LevelNameState,
    engine: LOCAL_STORAGE_ENGINE,
  },
];
