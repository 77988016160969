import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const password = control.value;
    if (!password) {
      return null; // return if no password
    }

    // Regular expressions for validations
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const isValidLength = password.length >= 8 && password.length <= 15;
    const noMoreThanTwoRepeatingChars = !/(.)\1\1/.test(password); // No more than 2 repeating chars

    const passwordValid =
      hasUpperCase &&
      hasLowerCase &&
      hasSpecialChar &&
      isValidLength &&
      noMoreThanTwoRepeatingChars;

    return !passwordValid
      ? {
          passwordStrength: {
            hasUpperCase,
            hasLowerCase,
            hasSpecialChar,
            isValidLength,
            noMoreThanTwoRepeatingChars,
          },
        }
      : null;
  };
}