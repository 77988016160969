<div class="pagination-container">
  <div class="pagination-buttons">
    <!-- Previous Page Link with Custom SVG Icon -->
    <button 
      class="pagination-button prev-next"
      (click)="goToPreviousPage()" 
      [disabled]="currentPage === 1"> <!-- Disable button when on page 1 -->
      <!-- Custom Left Arrowhead SVG -->
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="none" d="M0 0h24v24H0z"/>
        <path d="M15 18l-6-6 6-6"/>
      </svg>
    </button>
  
    <!-- Dynamic Page Number Links -->
    <ng-container *ngFor="let page of displayedPages">
      <button  *ngIf="page == 1" 
        class="pagination-button" 
        (click)="goToPage(page)" 
        [class.active]="currentPage === page">
        {{ page }}
      </button>
    </ng-container>

    <span class="dot-span"  *ngIf="currentPage > 6">
      ...
    </span>
    <ng-container *ngFor="let page of displayedPages">
     <button   *ngIf="page > 1"
        class="pagination-button" 
        (click)="goToPage(page)" 
        [class.active]="currentPage === page">
        {{ page }}
      </button>
    </ng-container>

    <!-- Next Page Link with Custom SVG Icon -->
  <span class="dot-span"  *ngIf="nextKey">
    ...
 </span>

    <button 
      class="pagination-button prev-next"
      (click)="goToNextPage()" 
      [disabled]="!nextKey">
      <!-- Custom Right Arrowhead SVG -->
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="none" d="M0 0h24v24H0z"/>
        <path d="M9 18l6-6-6-6"/>
      </svg>
    </button>
  </div>

  <!-- Page Size Selector Dropdown -->
  <div class="page-size-container">
    <label for="page-size">Items per page:</label>
    <select (change)="onPageSizeChange($event)" [value]="pageSize" class="pagination-select">
      <option value="10">10</option>
      <option value="20">20</option>
      <option value="50">50</option>
    </select>
  </div>
</div>
