import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
  CANCEL_TEMPLATE_HEADING,
  CANCEL_TEMPLATE_MSG,
  CREATE_SURVEY,
  EMPTY_STRING,
  ERROR,
  FAILED,
  NO,
  REDIRECT_ASSET_SELECTION,
  REDIRECT_SURVEY_OVERVIEW,
} from '../../shared/constants';
import { SurveyFields } from '../../core/models/create-survey.module';
import { CreateSurveyService } from '../../core/services/create-survey.service';
import { PopupDialogService } from '../../shared/popup-dialog/popup-dialog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SurveyOverviewService } from '../../core/services/survey-overview.service';
import { Store } from '@ngxs/store';
import {
  RemoveSurveyOverviewData,
  SetSurveyOverviewData,
  SurveyOverviewDataState,
} from '../../core/store/survey-overview.state';
import { Observable } from 'rxjs';
import { SurveyAssociatedAsset, SurveyInput, UserSYRCOptionInput } from '../../../awsAppSync/API';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import { CustomToastrService } from '../../shared/ngx-toastr/custom-toastr.service';

@Component({
  selector: 'app-create-survey',
  templateUrl: './create-survey.component.html',
  styleUrl: './create-survey.component.css',
})
export class CreateSurveyComponent implements OnInit {
  isLoading = false;
  surveyForm: FormGroup;
  currentStep = 1;
  reportSubheading = CREATE_SURVEY;
  surveyTypes: SurveyFields[] = [];
  surveyPriority: SurveyFields[] = [];
  surveyCollectionType: SurveyFields[] = [];
  surveyTroubleshooting: SurveyFields[] = [];
  assignTo: SurveyFields[] = [];
  storageKey = '';
  surveyNameVal: string | null = '';
  surveyTypeVal: string | null = '';
  surveyPriorityVal: string | null = '';
  surveyCollectionTypeVal: string | null = '';
  dueDateVal: Date | null = new Date();
  assignedToVal: string | null = '';
  troubleshootingVal: string | null = '';
  surveyDescriptionVal: string | null = '';
  surveyId$: Observable<string | null>;
  surveyPK$: Observable<string | null>;
  surveySK$: Observable<string | null>;
  surveyIsEdit$: Observable<boolean>;
  surveyName$: Observable<string | null>;
  surveyType$: Observable<string | null>;
  surveyPriority$: Observable<string | null>;
  dataCollectionType$: Observable<string | null>;
  dueDate$: Observable<Date | null>;
  assignedTo$: Observable<string | null>;
  troubleshooting$: Observable<string | null>;
  surveyDescription$: Observable<string | null>;
  isEdit = false;
  storeSurveyId: string | null = EMPTY_STRING;
  storeSurveyPk: string | null = EMPTY_STRING;
  storeSurveySk: string | null = EMPTY_STRING;
  assetAssociatedArray: (SurveyAssociatedAsset | null)[] | undefined;
  backFlag = false;
  storePrimaryCompanyID$: Observable<string | null>;
  primaryCompanyId:string | null = null;
  storeCoreViewPrimaryCompanyID$: Observable<string | null>;
  coreViewPrimaryCompanyId:string | null = null;

  constructor(
    private createSurveyService: CreateSurveyService,
    private fb: FormBuilder,
    private popupDialogService: PopupDialogService,
    private router: Router,
    private surveyOverviewService: SurveyOverviewService,
    private route: ActivatedRoute,
    private toastr: CustomToastrService,
    private store: Store,
  ) {
    const currentDate = new Date();

    this.surveyForm = this.fb.group({
      surveyName: ['', [Validators.required, Validators.maxLength(80)]],
      surveyType: ['', Validators.required],
      surveyPriority: ['', Validators.required],
      surveyCollectionType: ['', Validators.required],
      dueDate: ['', Validators.required],
      assignedTo: ['', Validators.required],
      // troubleshooting: ['', Validators.required],
      surveyDescription: [''],
      dateCreated: [currentDate],
    });
    this.surveyId$ = this.store.select(SurveyOverviewDataState.getSurveyID);
    this.surveyPK$ = this.store.select(SurveyOverviewDataState.getSurveyPK);
    this.surveySK$ = this.store.select(SurveyOverviewDataState.getSurveySK);
    this.surveyIsEdit$ = this.store.select(SurveyOverviewDataState.getIsEdit);
    this.surveyName$ = this.store.select(SurveyOverviewDataState.getSurveyName);
    this.surveyType$ = this.store.select(SurveyOverviewDataState.getSurveyType);
    this.surveyPriority$ = this.store.select(
      SurveyOverviewDataState.getSurveyPriority,
    );
    this.dataCollectionType$ = this.store.select(
      SurveyOverviewDataState.getDataCollectionType,
    );
    this.dueDate$ = this.store.select(SurveyOverviewDataState.getDueDate);
    this.assignedTo$ = this.store.select(SurveyOverviewDataState.getAssignedTo);
    this.troubleshooting$ = this.store.select(
      SurveyOverviewDataState.getTroubleshooting,
    );
    this.surveyDescription$ = this.store.select(
      SurveyOverviewDataState.getSurveyDescription,
    );
    this.storePrimaryCompanyID$= this.store.select(AuthenticateUserState.getSyrcPrimaryCompanyId);
    this.storeCoreViewPrimaryCompanyID$= this.store.select(AuthenticateUserState.getCoreViewPrimaryCompanyId);
  }
  checkPreFilledData() {
    //this.createSurveyService.clearLocalStorageKey(this.storageKey);

    this.surveyName$.subscribe((surveyName: string | null) => {
      this.surveyNameVal = surveyName;
    });

    this.surveyType$.subscribe((surveyType: string | null) => {
      this.surveyTypeVal = surveyType;
    });

    this.surveyPriority$.subscribe((surveyPriority: string | null) => {
      this.surveyPriorityVal = surveyPriority;
    });

    this.dataCollectionType$.subscribe(
      (surveyCollectionType: string | null) => {
        this.surveyCollectionTypeVal = surveyCollectionType;
      },
    );

    this.dueDate$.subscribe((dueDateVal: Date | null) => {
      this.dueDateVal = new Date(dueDateVal ?? '');
    });

    this.assignedTo$.subscribe((assignedTo: string | null) => {
      this.assignedToVal = assignedTo;
    });

    this.troubleshooting$.subscribe((troubleshooting: string | null) => {
      this.troubleshootingVal = troubleshooting;
    });
    this.surveyDescription$.subscribe((surveyDescription: string | null) => {
      this.surveyDescriptionVal = surveyDescription;
    });
  }
  async ngOnInit() {
    this.isLoading = true;

    this.surveyTypes = this.createSurveyService.getSurveyType();
    this.surveyPriority = this.createSurveyService.getSurveyPriority();
    this.surveyCollectionType =
      this.createSurveyService.getSurveyCollectionType();
    this.surveyTroubleshooting =
      this.createSurveyService.getsurveyTroubleshooting();
    

    this.route.queryParams.subscribe((params) => {
      this.backFlag = params['isbackflag'];
      if (this.backFlag) {
        this.checkPreFilledData();
      }
      // Handle the flag value here
    });

    this.surveyIsEdit$.subscribe((isEdit) => {
      this.isEdit = isEdit;
    });
    if (this.isEdit) {
      this.surveyId$.subscribe((id) => {
        this.storeSurveyId = id;
      });
      this.surveyPK$.subscribe((pk) => {
        this.storeSurveyPk = pk;
      });
      this.surveySK$.subscribe((sk) => {
        this.storeSurveySk = sk;
      });

      await this.LoadSurveyData();
      this.isLoading = false;
    }else{
      this.isLoading = false;
    }
    this.storePrimaryCompanyID$.subscribe(id => {
      this.primaryCompanyId = id;
    });

    this.storeCoreViewPrimaryCompanyID$.subscribe(id => {
      this.coreViewPrimaryCompanyId = id;
    });

    await this.bindSYRCUserList();
  }

  async bindSYRCUserList() {
    try {
      const usersSyrcOptionInput: UserSYRCOptionInput = {
        corViewCompanyId: this.coreViewPrimaryCompanyId ?? '',
        syrcPrimaryCompanyId: this.primaryCompanyId ?? '',
      };
      const syrcUserList = await this.createSurveyService.getAllSYRCUsers(usersSyrcOptionInput);
      if (syrcUserList) {
        this.assignTo =
          syrcUserList?.items?.map((item) => ({
            id: item?.id ?? '',
            value: `${item?.firstName} ${item?.lastName}`,
          })) || [];
      }
    } finally {
      //this.isLoading = false;
    }
  }

  async LoadSurveyData() {
    const input: SurveyInput = {
      pk: this.storeSurveyPk ?? '',
      sk: this.storeSurveySk ?? '',
      id: this.storeSurveyId ?? '',
    };
    const _survey = await this.createSurveyService.getSurveyById(input);
    this.assetAssociatedArray = _survey?.surveyAssociatedAssets || [];
    const assignedToEntry = this.assignTo.find(
      (item) => item.id === _survey?.assignedTo,
    );
    this.surveyForm.patchValue({
      surveyName: _survey?.surveyName,
      surveyType: _survey?.surveyType,
      surveyPriority: _survey?.surveyPriority,
      surveyCollectionType: _survey?.dataCollectionType,
      dueDate: _survey?.dueDate ? new Date(_survey.dueDate) : null,
      assignedTo: assignedToEntry?.id,
      troubleshooting: _survey?.troubleShooting,
      surveyDescription: _survey?.surveyDescription,
    });
  }
  async onSaveAndContinue() {
    
    if(this.surveyForm.get('surveyName')?.value.trim().length === 0){
      this.toastr.showError(
        "Survey name can not be empty.",
        ERROR,
      );
      return;
    }

    const survey = await this.surveyOverviewService.getSurveyName(
      this.primaryCompanyId ?? '',
      this.surveyForm.get('surveyName')?.value.trim() ?? '',
    );
    if (survey?.surveyName === this.surveyForm.get('surveyName')?.value.trim() && !this.isEdit) {
      this.surveyForm.get('surveyName')?.setErrors({ nameExists: true });
    } else {
      this.surveyForm.get('surveyName')?.setErrors(null);
      this.surveyForm.markAllAsTouched();
      if (this.surveyForm.valid) {
        const formData = this.surveyForm.value;

        const selectedAssignedToId = formData.assignedTo;
        const selectedAssignedTo = this.assignTo.find(
          (option) => option.id === selectedAssignedToId,
        );
        const selectedAssignedToName = selectedAssignedTo
          ? selectedAssignedTo.value
          : null;
        const surveyRecords = {
          surveyId: this.storeSurveyId,
          surveyPk: this.storeSurveyPk,
          surveySk: this.storeSurveySk,
          surveyName: formData.surveyName,
          surveyType: formData.surveyType,
          surveyAssociatedAssets: this.assetAssociatedArray,
          surveyPriority: formData.surveyPriority,
          surveyCollectionType: formData.surveyCollectionType,
          dueDate: formData.dueDate,
          assignedTo: formData.assignedTo,
          assignedName: selectedAssignedToName,
          troubleshooting: formData.troubleshooting ?? false,
          surveyDescription: formData.surveyDescription,
          isEdit: this.isEdit,
        };

        this.disptachDataToStore(surveyRecords);
        this.goToStep(2);
      }
    }
  }

  goToStep(step: number) {
    if (step === 2) {
      // this.currentStep = step;
    }
    //this.surveyOverviewService.setFormData(this.surveyForm.value);
    this.router.navigate([REDIRECT_ASSET_SELECTION]);
  }
  cancel() {
    this.popupDialogService.openDialog(
      CANCEL_TEMPLATE_HEADING,
      CANCEL_TEMPLATE_MSG,
      FAILED,
      CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
      () => {
        this.router.navigate([REDIRECT_SURVEY_OVERVIEW]);
        this.store.dispatch(new RemoveSurveyOverviewData());
      },
      true,
      NO,
      true,
    );
  }

  disptachDataToStore(surveyRecords: any) {
    this.store.dispatch(
      new SetSurveyOverviewData(
        surveyRecords.surveyId,
        surveyRecords.surveyPk,
        surveyRecords.surveySk,
        surveyRecords.surveyName,
        surveyRecords.surveyType,
        surveyRecords.surveyAssociatedAssets,
        surveyRecords.surveyPriority,
        surveyRecords.surveyCollectionType,
        surveyRecords.dueDate,
        surveyRecords.assignedTo,
        surveyRecords.assignedName,
        surveyRecords.troubleshooting,
        surveyRecords.surveyDescription,
        surveyRecords.isEdit,
      ),
    );
  }
}
