<div style="display: flex;">
  <button kendoButton #columnadjustmentbutton class="columnadjustmentbutton"
    (click)="ColumnAdjustmentButtonClicked($event)"></button>
</div>
<kendo-popup [anchor]="columnadjustmentbutton.element" (anchorViewportLeave)="showColumnAdjustmentPopup = false"
  *ngIf="showColumnAdjustmentPopup" class="kendo-popup columnadjustmentpopup">
  <div class="template-container" (click)="$event.stopPropagation()">
    <div>
      <!-- Dropdown to select the template -->
      <kendo-dropdownlist id="colManagementTemplates" [data]="templates" [textField]="'templateName'" [valueField]="'id'" [(ngModel)]="selectedTemplateId"
        class="template-combobox" (selectionChange)="updateItemsForSelectedTemplate($event.id)">
      </kendo-dropdownlist>

      <!-- Search box to filter items -->
      <div class="search-bar-Popup">
        <kendo-svgicon [icon]="svgSearch" style="margin-left: 5px; color: gray;"></kendo-svgicon>
        <input type="search" [(ngModel)]="searchText" class="search-input" (input)="updateFilteredItems()"
          placeholder="Search Column" />
      </div>

      <!-- Checkbox list for items -->
      <div cdkDropList (cdkDropListDropped)="drop($event)" class="column-list-container">
        <div *ngFor="let item of filteredItems" cdkDrag class="column-list" cdkDragBoundary=".column-list-container">
          <kendo-checkbox #checkedState [checkedState]="item.checked" [(ngModel)]="item.checked" [disabled]="item.isPermanentColumn"></kendo-checkbox>
          <div  class="column-list-item" >{{ item.text }}</div>
        </div>
      </div>

      <!-- Buttons to reset and save the configuration -->
      <div class="action-buttons-div">
        <button kendoButton (click)="reset()" class="reset-button">Reset</button>
        <button kendoButton (click)="save()" class="save-button">Save</button>
      </div>
    </div>
  </div>
</kendo-popup>