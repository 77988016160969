import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private http: HttpClient) { }

  async uploadToS3(presignedUrl: string, file: File, contentType: string): Promise<boolean> {
    try {
      const headers = new HttpHeaders({
        'Content-Type' : contentType
      });

      const _result = await this.http.put(presignedUrl, file, { headers }).toPromise();
      return true;
    } catch {
      return false;
    }
  }
}
