import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDate',
})
export class CustomDatePipe implements PipeTransform {
  transform(value: Date): unknown {
    if (!value) return '';
    const date = new Date(value);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' }); // Use 'short' for three-letter month
    const year = date.getFullYear();

    const daySuffix = this.getDaySuffix(day);

    return `${day}${daySuffix} ${month}, ${year}`;
  }
  private getDaySuffix(day: number): string {
    if (day > 3 && day < 21) return 'th'; // 'th' for 11th to 13th
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }
}
