import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-grid-pagination',
  templateUrl: './grid-pagination.component.html',
  styleUrl: './grid-pagination.component.css',
})
export class GridPaginationComponent {
  @Input() currentPage = 0;
  @Input() pageSize = 0;
  @Input() totalItems = 0;

  @Output() pageChange = new EventEmitter<number>();
  @Output() dropdownValueChange = new EventEmitter<number>();

  pageSizes = [
    { value: 50, text: '50 per page' },
    { value: 100, text: '100 per page' },
    { value: 500, text: '500 per page' },
  ];

  get totalPages(): number {
    return Math.ceil(this.totalItems / this.pageSize);
  }

  get startItem(): number {
    return (this.currentPage - 1) * this.pageSize + 1;
  }

  get endItem(): number {
    return Math.min(this.currentPage * this.pageSize, this.totalItems);
  }

  goToFirstPage() {
    this.pageChange.emit(1);
  }

  goToPreviousPage() {
    this.pageChange.emit(this.currentPage - 1);
  }

  goToNextPage() {
    this.pageChange.emit(this.currentPage + 1);
  }

  goToLastPage() {
    this.pageChange.emit(this.totalPages);
  }
  public onPageSizeChange(event: any): void {
    this.pageSize = event.value;
    this.dropdownValueChange.emit(event.value);
  }
}
