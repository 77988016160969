import { Injectable } from '@angular/core';
import { BaseAWSRequestService } from '../../shared/service/base-services/base-aws-request.service';
import { CreateAllLevelNamesMutationVariables, CreateAllLevelValuesForPrimaryCompanyMutationVariables, DeleteLevelValueForPrimaryCompanyMutationVariables, GetAllLevelNameByPrimaryCompanyQueryVariables, Level1ValueOutput, LevelNameConnection, LevelNameCreateInput, LevelNameResponse, LevelValueCreateInput, LevelValueDeleteInput, LevelValueOutput, LevelValueResponse, LevelValueUpdateInput, UpdateAllLevelValuesForPrimaryCompanyMutationVariables } from '../../../awsAppSync/API';
import { createAllLevelNames, createAllLevelValuesForPrimaryCompany, deleteLevelValueForPrimaryCompany, updateAllLevelValuesForPrimaryCompany } from '../../../awsAppSync/graphql/mutations';
import { CREATE_PRIMARY_COMPANY_LEVEL_ERROR, CREATE_PRIMARY_COMPANY_LEVEL_TREE_ERROR, EMPTY_STRING, GENERIC_FAILED_ERROR, UPDATE_PRIMARY_COMPANY_LEVEL_TREE_ERROR } from '../../shared/constants';
import { HierarchyNode } from '../models/heirarchy-tree';
import { getAllLevelNameByPrimaryCompany, getLevelValueByPrimaryCompanyId } from '../../../awsAppSync/graphql/queries';

@Injectable({
  providedIn: 'root'
})
export class SetupHierarchyService {

  levels: string[] = [];
  public primaryCompanyid : string | null = EMPTY_STRING;
  public level1Values : (Level1ValueOutput | null)[] | null | undefined;
  public isEdit = false;
  nodeData: HierarchyNode | null = null;
  constructor(private awsService: BaseAWSRequestService) { }

  async createAllLevelNames(
    input: LevelNameCreateInput,
  ): Promise<LevelNameResponse | null> {
    const mutation = createAllLevelNames;
    const variables: CreateAllLevelNamesMutationVariables = { input };
    try {
      const result = await this.awsService.executeMutation<{
        createAllLevelNames: LevelNameResponse;
      }>(mutation, variables);

      return result.createAllLevelNames || null;
    } catch (error) {
      console.error(CREATE_PRIMARY_COMPANY_LEVEL_ERROR, error);
      return null;
    }
  }

  async createAllLevelValuesForPrimaryCompany(
    input: LevelValueCreateInput,
  ): Promise<LevelValueResponse | null> {
    const mutation = createAllLevelValuesForPrimaryCompany;
    const variables: CreateAllLevelValuesForPrimaryCompanyMutationVariables = { input };
    try {
      const result = await this.awsService.executeMutation<{
        createAllLevelValuesForPrimaryCompany: LevelValueResponse;
      }>(mutation, variables);

      return result.createAllLevelValuesForPrimaryCompany || null;
    } catch (error) {
      console.error(CREATE_PRIMARY_COMPANY_LEVEL_TREE_ERROR, error);
      return null;
    }
  }

  async updateAllLevelValuesForPrimaryCompany(
    input: LevelValueUpdateInput,
  ): Promise<LevelValueResponse | null> {
    const mutation = updateAllLevelValuesForPrimaryCompany;
    const variables: UpdateAllLevelValuesForPrimaryCompanyMutationVariables = { input };
    try {
      const result = await this.awsService.executeMutation<{
        updateAllLevelValuesForPrimaryCompany: LevelValueResponse;
      }>(mutation, variables);

      return result.updateAllLevelValuesForPrimaryCompany || null;
    } catch (error) {
      console.error(UPDATE_PRIMARY_COMPANY_LEVEL_TREE_ERROR, error);
      return null;
    }
  }

  async deleteLevelValueForPrimaryCompany(input: LevelValueDeleteInput): Promise<LevelValueResponse | null> 
  {
    const query = deleteLevelValueForPrimaryCompany;
    const variables: DeleteLevelValueForPrimaryCompanyMutationVariables = { input };
    try {
      const result = await this.awsService.executeMutation<{
        deleteLevelValueForPrimaryCompany: LevelValueResponse;
      }>(query, variables);
      return result.deleteLevelValueForPrimaryCompany || null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  async getAllLevelNameByPrimaryCompany(primaryCompanyId: string): Promise<LevelNameConnection | null> 
  {
    const query = getAllLevelNameByPrimaryCompany;
    const variables: GetAllLevelNameByPrimaryCompanyQueryVariables = { primaryCompanyId };
    try {
      const result = await this.awsService.executeGetQuery<{
        getAllLevelNameByPrimaryCompany: LevelNameConnection;
      }>(query, variables);
      return result.getAllLevelNameByPrimaryCompany || null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  async getLevelValuesByPrimaryCompanyId(primaryCompanyId: string): Promise<LevelValueOutput | null> {
    const query = getLevelValueByPrimaryCompanyId;
    const variables = { primaryCompanyId };

    try {
      const result = await this.awsService.executeGetQuery<{
        getLevelValueByPrimaryCompanyId: LevelValueOutput;
      }>(query, variables);
      

      return result.getLevelValueByPrimaryCompanyId || null;
    } catch (error) {
      console.error('Error fetching level values:', error);
      return null;
    }
  }

}
