<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>

<div class="node" style="padding-top: 12px">
  <span
    style="margin-top: 5px; display: flex"
    (click)="toggleExpand()"
    class="expand-icon"
  >
    <div>
      <mat-icon *ngIf="isExpanded && !isPrimaryCompanyNode()">remove_circle_outline</mat-icon>
      <mat-icon *ngIf="!isExpanded && !isPrimaryCompanyNode()">add_circle_outline</mat-icon>
    </div>
    <ng-container *ngIf="node.name.includes(':'); else noColon">
      <span
        id="nodeName"
        (click)="toggleEdit(node.id)"
        style="padding-left: 5px"
      >
        <span>{{ node.name.split(':')[0] }}</span
        >:
        <span class="mtx-font-weight-500">{{ node.name.split(':')[1] }}</span>
      </span>
    </ng-container>

    <ng-template #noColon>
      <span
        style="padding-left: 5px"
        id="nodeName"
        (click)="toggleEdit(node.id)"
        [ngClass]="{ 'node-name': !isPrimaryCompanyNode() && showInput }"
        >{{ node.name }}</span
      >
    </ng-template>
    <span
      style="margin-top: -5px"
      *ngIf="!isPrimaryCompanyNode() && node.isNodeEdited"
      class="action-icons"
    >
      <mat-icon class="action-btn" (click)="onNodeEdit(node)"
        >edit_outline</mat-icon
      >
      <span style="cursor: pointer" (click)="onNodeEdit(node)">Edit</span>
      <span *ngIf="!node.isAssetConfigured" style="padding-left: 15px; padding-right: 10px; font-size: 20px"
        >|</span
      >
      <mat-icon *ngIf="!node.isAssetConfigured" class="action-btn" (click)="onNodeDelete(node, $event)"
        >delete_outline</mat-icon
      >
      <span style="cursor: pointer" *ngIf="!node.isAssetConfigured" (click)="onNodeDelete(node, $event)">Delete</span>
    </span>
  </span>

  <div *ngIf="showInput">
    <div
      *ngIf="isEditing && !isPrimaryCompanyNode()"
      class="card col-md-3"
      [ngClass] ="{ 'input-container' : !isLastNode() && isExpanded}"
      style="width: 18rem; margin-left: 28px; margin-bottom: 10px"
    >
      <div class="card-body">
        <span class="card-title mtx-font-14 mtx-font-weight-500">{{
          node.name
        }}</span>
        <div class="input-group">
          <input
            kendoTextBox
            class="form-control input-group-text"
            [(ngModel)]="newName"
          />
          <button
            kendoButton
            [svgIcon]="svgAdd"
            (click)="save()"
            class="input-group-button"
          ></button>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="editNode"
    class="card col-md-3"
    [ngClass] ="{ 'input-container' : !isLastNode() && isExpanded}"
    style="width: 18rem; margin-left: 28px; margin-bottom: 10px"
  >
    <div class="card-body">
      <span class="card-title mtx-font-14 mtx-font-weight-500">{{
        node.name
      }}</span>
      <div class="input-group">
        <input
          kendoTextBox
          class="form-control input-group-text"
          [(ngModel)]="newName"
        />
        <button
          kendoButton
          [svgIcon]="svgAdd"
          (click)="updateNode(node.id)"
          class="input-group-button"
        ></button>
      </div>
    </div>
  </div>
  
  <ul
    *ngIf="isExpanded && node.children && node.children.length > 0"
    style="padding-top: 0px; padding-left: 50px"
  >
    <li *ngFor="let child of node.children">
      <app-hierarchy-node
        class="node-child"
        [node]="child"
      ></app-hierarchy-node>
    </li>
  </ul>
</div>
