import { AbstractControl, ValidatorFn } from '@angular/forms';

export function atLeastOneFieldRequiredValidator(
  fields: string[],
): ValidatorFn {
  return (formGroup: AbstractControl): Record<string, any> | null => {
    const isAnyFieldFilled = fields.some((field) => {
      const value = formGroup.get(field)?.value;
      return value && value.trim() !== ''; // Check if the field is filled
    });

    if (!isAnyFieldFilled) {
      return { atLeastOneFieldRequired: true }; // Return an error object
    }

    return null; // Return null if no error
  };
}
