import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { SurveyROUTE_SETUP_STATE, SurveyROUTE_SETUP_STATE_TYPE_SET, SurveyROUTE_SETUP_STATE_TYPE_SET_REMOVE } from "../../shared/constants";

export interface SurveyRouteModel {
    selectedSurveyRouteIds: string[];
}
export class SetSurveyRouteModel {
    static readonly type = SurveyROUTE_SETUP_STATE_TYPE_SET;
    constructor(
      public selectedSurveyRouteIds: string[],
    ) {}
}
export class RemoveSelectedSurveyRouteIds {
    static readonly type = SurveyROUTE_SETUP_STATE_TYPE_SET_REMOVE;
}
@State<SurveyRouteModel>({
    name: SurveyROUTE_SETUP_STATE,
    defaults: {
        selectedSurveyRouteIds: []
    }
})

@Injectable()
export class SurveyRouteState {
    @Selector()
    static getSelectedSurveyRouteIds(state: SurveyRouteModel) {
        return state.selectedSurveyRouteIds;
    }

    @Action(SetSurveyRouteModel)
    SetSurveyRouteModel(
        { patchState }: StateContext<SurveyRouteModel>,
        {
            selectedSurveyRouteIds,
        }: SetSurveyRouteModel,
    ): void {
        patchState({
            selectedSurveyRouteIds : selectedSurveyRouteIds
        });
    }
    @Action(RemoveSelectedSurveyRouteIds)
    RemoveSelectedSurveyRouteIds({
        setState,
    }: StateContext<SurveyRouteModel>): void {
        setState({
            selectedSurveyRouteIds:[]
        });
    }
}