import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { LEVEL2_LEVELNAME_SET, LEVEL2_LEVELNAME_SET_REMOVE, LEVEL2_LEVELNAME_STATE} from "../../shared/constants";

export interface Level2LevelName {
    Level2LevelName: string | null;
}
export class SetLevel2LevelName {
    static readonly type = LEVEL2_LEVELNAME_SET;
    constructor(
        public Level2LevelName: string,
    ) {}
}
export class RemoveLevel2LevelName {
    static readonly type = LEVEL2_LEVELNAME_SET_REMOVE;
}
@State<Level2LevelName>({
    name: LEVEL2_LEVELNAME_STATE,
    defaults: {
        Level2LevelName: null
    }
})

@Injectable()
export class Level2LevelNameState {
    @Selector()
    static getLevel2LevelNameId(state: Level2LevelName) {
        return state.Level2LevelName;
    }

    @Action(SetLevel2LevelName)
    SetLevel2LevelName(
        { patchState }: StateContext<Level2LevelName>,
        {
            Level2LevelName,
        }: SetLevel2LevelName,
    ): void {
        patchState({
            Level2LevelName : Level2LevelName
        });
    }
    @Action(RemoveLevel2LevelName)
    RemoveLevel2LevelName({
        setState,
    }: StateContext<Level2LevelName>): void {
        setState({
            Level2LevelName: null
        });
    }
}