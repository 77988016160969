<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>

<div class="container-fluid py-2" style="margin-top: -28px">
  <div class="container-fluid">
    <div class="row align-items-center justify-content-between">
      <div class="col-auto">
        <h5
          class="mtx-text-primary"
          style="margin-left: -12px; margin-top: 22px"
        >
          {{ 'TOP_NAV.MAP' | translate }}
        </h5>
      </div>
      <div class="col-auto ml-auto">
        <div
          class="d-flex flex-wrap align-items-center gap-2 justify-content-end"
        >
          <div style="width: 330px">
            <kendo-autocomplete
              [data]="filteredResults"
              textField="assetName"
              (filterChange)="handleSearchInput($event)"
              (valueChange)="onSelectAsset($event)"
              [placeholder]="searchPlaceholder"
              [filterable]="true"
              class="autocomplete"
            >
            </kendo-autocomplete>
          </div>
          <div *ngIf="showFilterBtn">
            <button
              (click)="toggleFilterTray()"
              class="btn mtx-btn-secondary mtx-font-weight-500"
            >
              <span style="vertical-align: bottom" class="material-icons"
                >filter_alt</span
              >
              {{ 'GENERIC_Buttons.FILTERS' | translate }}
              <span style="vertical-align: bottom" class="material-icons"
                >arrow_drop_down</span
              >
            </button>
          </div>
          <div>
            <button
              class="btn mtx-btn-primary"
              style="height: 38px"
              (click)="openExportMapModal()"
            >
              <span style="vertical-align: middle" class="material-icons"
                >download</span
              >
              {{ 'GENERIC_Buttons.EXPORT' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="row mt-3 justify-content-end mb-3"
    *ngIf="showHierarchicalFilter"
    style="float: right"
  >
    <app-heirarchy-filter-buttons
      (filterSearchButtonClicked)="filterSearchButtonClicked()"
      (isTrayCollapsed)="collapseFilterTray()"
      style="z-index: 9"
      [isSurveyRouteFilterRequired]="true"
    >
    </app-heirarchy-filter-buttons>
    <app-heirarchy-filter-popup></app-heirarchy-filter-popup>
  </div>

  <div class="mt-3">
    <div class="map-container">
      <!-- Map Wrapper -->
      <div class="map" #map></div>
      <!-- Info Card Container -->
      <div *ngIf="selectedAsset" [ngStyle]="infoCardStyles" class="info-card">
        <button (click)="closeInfoCard()" class="close-btn"></button>

        <div class="info-card-header">
          <div class="ellipsis">
            <kendo-icon
              class="k-icon k-icon-inline text-muted me-2 elipse"
              name="more-vertical"
              (click)="toggleDropdown()"
            ></kendo-icon>
          </div>
          <h5>Asset Info Card</h5>
        </div>
        <!-- Popup Content -->
        <div
          class="popup-menu"
          [ngStyle]="{ display: dropdownVisible ? 'block' : 'none' }"
        >
          <ul>
            <li (click)="openUrl('/compliance/inspection')">
              <img
                src="assets/images/redirect.png"
                alt="Inspections"
                class="menu-icon"
              />
              Inspections
            </li>
            <li (click)="openUrl('/assets/asset-history')">
              <img
                src="assets/images/redirect.png"
                alt="Asset History"
                class="menu-icon"
              />
              Asset History
            </li>
            <!-- Add more options as needed -->
          </ul>
        </div>
        <div class="info-card-content">
          <p><strong>Asset Name:</strong> {{ selectedAsset.assetName }}</p>
          <p><strong>Asset Type:</strong> {{ selectedAsset.assetType }}</p>
          <p><strong>Station:</strong> {{ selectedAsset.station }}</p>
          <p>
            <strong>Tech Responsible:</strong> {{ selectedAsset.technician }}
          </p>
          <p>
            <strong>Compliance Status:</strong>
            {{ selectedAsset.status }}
          </p>
          <p>
            <strong>Inspection Target Date:</strong>
            {{ selectedAsset.inspectionDate }}
          </p>
          <p>
            <strong>Date of Delinquency:</strong>
            {{ selectedAsset.assetDelinquencyDate }}
          </p>
        </div>
      </div>

      <!-- Inline Legends Row -->
      <div
        class="legend-container"
        [ngClass]="{ 'legends-position-filter-opened': filterTrayOpen }"
      >
        <div class="legend-item">
          <span class="legends-text">Legends: </span>
        </div>
        <div class="legend-item">
          <img
            class="legend-icon"
            src="assets/images/Compliant.svg"
            alt="location"
          />
          <span class="legend-text">Compliant</span>
        </div>
        <div class="legend-item">
          <img
            class="legend-icon"
            src="assets/images/Exception.svg"
            alt="location"
          />
          <span class="legend-text">Exception</span>
        </div>
        <div class="legend-item">
          <img
            class="legend-icon"
            src="assets/images/Delinquent.svg"
            alt="location"
          />
          <span class="legend-text">Delinquent</span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showGraph" class="mt-3" >
    <form [formGroup]="dateRangeForm">
      <div class="row" [ngClass]="{ 'postion-date': filterTrayOpen }" style="justify-content: end; margin-right: 11px">
        <div class="col-md-2">
          <div class="mb-3">
            <div class="label-container">
              <label for="startDate" class="">{{
                'ASSET_HISTORY_PAGE.StartDate' | translate
              }}</label>
            </div>
            <kendo-datepicker
              formControlName="startDate"
              placeholder="Select Start Date"
              format="dd-MMM-yyyy"
              [(ngModel)]="startDate"
            ></kendo-datepicker>
            <div *ngIf="dateRangeForm.errors?.['dateRangeInvalid']">
              <span class="small text-danger">{{
                'ASSET_HISTORY_PAGE.START_DATE_ERROR' | translate
              }}</span>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <div class="mb-3">
            <div class="label-container">
              <label for="endDate" class="">{{
                'ASSET_HISTORY_PAGE.EndDate' | translate
              }}</label>
            </div>
            <kendo-datepicker
              formControlName="endDate"
              placeholder="Select End Date"
              format="dd-MMM-yyyy"
              [(ngModel)]="endDate"
            ></kendo-datepicker>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="showGraph" class="mt-3">
    <div class="card-body-graph">
      <app-asset-history-chart *ngIf="chartKey"></app-asset-history-chart>
    </div>
  </div>
</div>
