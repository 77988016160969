import { Injectable, inject } from '@angular/core';
import { generateClient } from 'aws-amplify/api';
import { V6Client } from '@aws-amplify/api-graphql';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { AuthenticateUserState } from '../../../core/store/authenticate-user.state';
import { UserProfileDataState } from '../../../core/store/user-profile.state';
import { Store } from '@ngxs/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { exportoperation } from '../../models/exportoperation';

@Injectable({
  providedIn: 'root',
})
export class BaseAWSRequestService {
  private client: V6Client;
  private authToken: string | null = null;
  private idToken: string | null = null;
  private userId: string | null = null;
  private isAuthenticated = false;
  private selectedLevel3Id: string | null = null;

  // Initialize observables with null values
  // Initialize observables properly using rxjs 'of' function
  private authToken$: Observable<string | null> = of(null);
  private idToken$: Observable<string | null> = of(null);
  private isAuthenticated$: Observable<boolean> = of(false);
  private userId$: Observable<string | null> = of(null);
  private level3Id$: Observable<string | null> = of(null);
  private exportMessageSubject: Subject<exportoperation> = new Subject<exportoperation>();

  private subscriptions: Subscription = new Subscription();

  private readonly oidcSecurityService = inject(OidcSecurityService);

  constructor(private awsService: BaseAWSRequestService,private store: Store) {
    this.client = generateClient();
    this.initializeObservables();
  }

  private initializeObservables() {
    this.isAuthenticated$ = this.store.select(
      AuthenticateUserState.getIsAuthenticated,
    );
    this.idToken$ = this.store.select(AuthenticateUserState.getIdToken);
    this.authToken$ = this.store.select(AuthenticateUserState.getAccessToken);
    this.userId$ = this.store.select(AuthenticateUserState.getSyrcUserId);
    this.level3Id$ = this.store.select(UserProfileDataState.getSelectedLevel3Id);

    this.subscriptions.add(
      this.isAuthenticated$.subscribe((authenticated) => {
        this.isAuthenticated = authenticated;
      }),
    );

    this.subscriptions.add(
      this.idToken$.subscribe((token) => {
        this.idToken = token;
      }),
    );

    this.subscriptions.add(
      this.authToken$.subscribe((token) => {
        this.authToken = token;
      }),
    );

    this.subscriptions.add(
      this.userId$.subscribe((userId) => {
        this.userId = userId;
      }),
    );

    this.subscriptions.add(
      this.level3Id$.subscribe((level3Id) => {
        this.selectedLevel3Id = level3Id;
      }),
    );
  }

  // Handle 401 errors and trigger re-authentication
  private handleUnauthorizedError(error: any) {
    if (error && error?.errors?.length > 0 &&  error?.errors[0]?.message === "Unauthorized") {
      console.warn('401 Unauthorized: Redirecting to login');
      //this.oidcSecurityService.authorize();
    }
  }

  // Shared method for additional headers
  private async getAdditionalHeaders(): Promise<Record<string, string>> {
    return {
      'X-Syrc-User-Id': this.userId ?? '',
      'X-Level3-Value-Id': this.selectedLevel3Id ?? '',
    };
  }


  // Reusable method for GraphQL queries to get the data
  async executeGetQuery<T>(query: string, variables: any = {}): Promise<T> {
    if(this.idToken){
      try {
        const additionalHeaders = await this.getAdditionalHeaders();
        const response = (await this.client.graphql({
          query: query,
          variables: variables,
          authToken: this.idToken,
        }, additionalHeaders)) as { data: T };
        return response.data;
      } catch (error) {
        console.error('Error executing GraphQL query', error);
        this.handleUnauthorizedError(error);
        throw error;
      }
    }else{
      return Promise.reject(null);
    }
    
  }

  // Reusable method for GraphQL mutations (create, update, delete)
  async executeMutation<T>(
    mutation: string,
    variables: any = {}
  ): Promise<any> {
    try {
        const additionalHeaders = await this.getAdditionalHeaders();
        const response = (await this.client.graphql({
        query: mutation,
        variables: variables,
        authToken: this.idToken ?? '',
      }, additionalHeaders)) as { data: T };
      return response.data;
    } catch (error) {
      console.error('Error executing GraphQL mutation', error);
      this.handleUnauthorizedError(error);
      throw error;
    }
  }


  eexecuteExportStatusSubscription(userId: string): Observable<exportoperation> {
    const subscription = `subscription OnUpdateDataExportStatus($userId: String!) {
      onUpdateDataExportStatus(userId: $userId) {
        userId
        status
        exportMessage
      }
    }`;

    // Subscribe to the GraphQL subscription and emit exportMessage updates
    const subscriptionObservable = this.client.graphql({
      query: subscription,
      variables: { userId: userId },
      authToken: this.idToken ?? '',
    }) as any;

    subscriptionObservable.subscribe({
      next: (response: any) => {
        if (response.data.onUpdateDataExportStatus.status === 'success') {
          const exportMessage = response.data.onUpdateDataExportStatus.exportMessage;
          const status = response.data.onUpdateDataExportStatus.status;
          // Emit the exportMessage and status as an object
          this.exportMessageSubject.next({ exportMessage, status });
      } else {
          
          console.error('Export failed:', response.data.onUpdateDataExportStatus.errorMessage || 'Unknown error');
      }
      },
      error: (error: any) => {
        console.error('Error with subscription', error);
      },
    });

    // Return an observable to allow components to subscribe to export messages
    return this.exportMessageSubject.asObservable();
  }


}
