/* eslint-disable */
import {
  ASSET_NAME_TEXT,
  ASSET_TYPE_TEMPLATE_TEXT,
  ASSET_TYPE_TEXT,
  AUDIT,
  AUDIT_PERFORMANCE,
  COMMENTS,
  COMPLAINCE_STATUS_SIMPLE,
  COMPLAINCE_STATUS_TEXT,
  COMPLIANCE_CRITERIA_TEXT,
  DAYS_UNTIL_DELINQUENT_TEXT,
  GPS_COORDINATE_TEXT,
  INSPECTION_DATE,
  LAST_COMPLAINCE_READ_TEXT,
  LAST_INSPECTION_DATE_TEXT,
  LAST_MEASUREMENT_TEXT,
  LATITUDE,
  LONGITUDE,
  MEASUREMENT_FREQUENCY_TEXT,
  MILEPOST_TEXT,
  NEXT_DELINQUENCY_DATE_TEXT,
  PERFORMANCE,
  PRIMARY_COMPANY_TEXT,
  REGION_TEXT,
  ROOT_CAUSE_TEXT,
  ROUTE,
  SEGMENT_TEXT,
  SPECIALIST_TEXT,
  STATION,
  TECHNICIAN,
  WORKFLOW_STATUS_TEXT,
} from './constants';

export enum filterButtonsEnum {
  PrimarCompanyId = 1,
  CompanyId = 2,
  RegionId = 3,
  SegmentId = 4,
  SurveyRouteId = 5,
  AssetTypeId = 6,
  TechnicianId = 7,
}

//Make sure the report types are comma separated
// TO DO: Harmeet - This will get updated once customer provides the information
export enum reportColumns {
  AssetType = AUDIT_PERFORMANCE,
  AssetName = AUDIT_PERFORMANCE,
  InspectionDate = AUDIT,
  Status = PERFORMANCE,
  PrimaryCompany = AUDIT_PERFORMANCE,
  Region = AUDIT_PERFORMANCE,
  Segment = AUDIT_PERFORMANCE,
  Station = AUDIT_PERFORMANCE,
  Route = AUDIT_PERFORMANCE,
  Latitude = AUDIT_PERFORMANCE,
  Longitude = AUDIT_PERFORMANCE,
  GPSCoordinate = AUDIT_PERFORMANCE,
  NextInspectionDate = AUDIT_PERFORMANCE,
  ComplianceCriteria = AUDIT_PERFORMANCE,
  ComplianceStatus = AUDIT_PERFORMANCE,
  LastComplianceRead = AUDIT_PERFORMANCE,
  Technician = AUDIT_PERFORMANCE,
  LastMeasurement = AUDIT_PERFORMANCE,
  Milepost = AUDIT_PERFORMANCE,
  DaysUntilDeliquency = AUDIT_PERFORMANCE,
  LastInspectionDate = AUDIT_PERFORMANCE,
  WorkflowStatus = AUDIT_PERFORMANCE,
  Comments = AUDIT_PERFORMANCE,
  Specialist = AUDIT_PERFORMANCE,
  RootCause = AUDIT_PERFORMANCE,
  MeasurementFrequency = AUDIT_PERFORMANCE,
  AssetTypeTemplate = AUDIT_PERFORMANCE,
}

export enum reportColumnsForDynamicColumns {
  'Asset Type' = AUDIT_PERFORMANCE,
  'Asset Name' = AUDIT_PERFORMANCE,
  'Status' = PERFORMANCE,
  'Station' = AUDIT_PERFORMANCE,
  'Next Inspection Date' = AUDIT_PERFORMANCE,
  'Compliance Criteria' = AUDIT_PERFORMANCE,
  'Compliance Status' = AUDIT_PERFORMANCE,
  'Last Compliance Read' = AUDIT_PERFORMANCE,
  'Technician' = AUDIT_PERFORMANCE,
}

export const columnNames: { [key in keyof typeof reportColumns]: string } = {
  AssetType: ASSET_TYPE_TEXT,
  AssetName: ASSET_NAME_TEXT,
  InspectionDate: INSPECTION_DATE,
  Status: COMPLAINCE_STATUS_SIMPLE,
  PrimaryCompany: PRIMARY_COMPANY_TEXT,
  Region: REGION_TEXT,
  Segment: SEGMENT_TEXT,
  Station: STATION,
  Route: ROUTE,
  Latitude: LATITUDE,
  Longitude: LONGITUDE,
  GPSCoordinate: GPS_COORDINATE_TEXT,
  NextInspectionDate: NEXT_DELINQUENCY_DATE_TEXT,
  ComplianceCriteria: COMPLIANCE_CRITERIA_TEXT,
  ComplianceStatus: COMPLAINCE_STATUS_TEXT,
  LastComplianceRead: LAST_COMPLAINCE_READ_TEXT,
  Technician: TECHNICIAN,
  LastMeasurement: LAST_MEASUREMENT_TEXT,
  Milepost: MILEPOST_TEXT,
  DaysUntilDeliquency: DAYS_UNTIL_DELINQUENT_TEXT,
  LastInspectionDate: LAST_INSPECTION_DATE_TEXT,
  WorkflowStatus: WORKFLOW_STATUS_TEXT,
  Comments: COMMENTS,
  Specialist: SPECIALIST_TEXT,
  RootCause: ROOT_CAUSE_TEXT,
  MeasurementFrequency: MEASUREMENT_FREQUENCY_TEXT,
  AssetTypeTemplate: ASSET_TYPE_TEMPLATE_TEXT,
};

export const RectifierColumnsDataType: { [key: string]: string } = { //Add columns other than level names
  'Mapping Asset ID': 'Number',
  'Asset Name': 'String',
  'Primary Seg? (Y/N)': 'String',
  'Longitude': 'Number',
  'Latitude': 'Number',
  'Milestone': 'Number',
  'Creation Date': 'Date',
  'Effective Date': 'Date',
  'Comments': 'String',
  'Location Description': 'String',
  'Rectifier Manufacturer': 'String',
  'Rectifier Date of Commissioning (effective date for the rectifier)':
    'String',
  'Rectifier Type ': 'String',
  'Rectifier Max Output Voltage': 'String',
  'Rectifier Max Output Amperage': 'String',
  'Rectifier Shunt values (could be multiple)': 'String',
  'Rectifier Meter Number': 'String',
  'Groundbed Type (Conventional vs. MMO)': 'String',
  'Backfill mass': 'String',
  'Backfill diameter if deepwell': 'String',
  'Maintenance details (rehydration, known rectifier off periods)': 'String',
  'Groundbed Orientation (deep vs. shallow)': 'String',
  'Anode Type (from which material diameter, length, mass can be determined)':
    'String',
  'Number of anodes': 'String',
  'Groundbed Date of Commissioning (effective date for the groundbed)':
    'String',
};

export const BondColumnsDataType: { [key: string]: string } = {
  'Mapping Asset ID': 'Number',
  'Asset Name': 'String',
  'Primary Seg? (Y/N)': 'String',
  'Longitude': 'Number',
  'Latitude': 'Number',
  'Milestone': 'Number',
  'Creation Date': 'Date',
  'Effective Date': 'Date',
  'Comments': 'String',
  'Location Description': 'String',
  'Bond shunt values': 'String',
};

export const TestPointsColumnsDataType: { [key: string]: string } = {
  'Mapping Asset ID': 'Number',
  'Asset Name': 'String',
  'Primary Seg? (Y/N)': 'String',
  'Longitude': 'Number',
  'Latitude': 'Number',
  'Milestone': 'Number',
  'Creation Date': 'Date',
  'Effective Date': 'Date',
  'Comments': 'String',
  'Location Description': 'String',
  'Ref. Electrode Manufacturer': 'String',
  'Ref. Electrode Type': 'String',
  'Ref. Electrode Chemistry': 'String',
  'Ref. Electrode Installation Date': 'String',
  'AC Coupon Size (m^2)': 'String',
  'DC Coupon Size (m^2)': 'String'
};

export const DefaultColumnsDataType: { [key: string]: string } = {
  'Mapping Asset ID': 'Number',
  'Asset Name': 'String',
  'Primary Seg? (Y/N)': 'String',
  'Longitude': 'Number',
  'Latitude': 'Number',
  'Milestone': 'Number',
  'Creation Date': 'Date',
  'Effective Date': 'Date',
  'Comments': 'String',
  'Location Description': 'String',
};

 export const ColumnsForFileFormat: { [key: string]: string } = {
  'Mapping Asset ID': 'Number',
  'Asset Name': 'String',
  'Primary Seg? (Y/N)': 'String',
  'Longitude': 'Number',
  'Latitude': 'Number',
  'Milestone': 'Number',
  'Creation Date': 'Date',
  'Effective Date': 'Date',
  'Comments': 'String',
  'Location Description': 'String',
};