/**-----------------------------------------------------------------------------------------
* Copyright © 2024 Progress Software Corporation. All rights reserved.
* Licensed under commercial license. See LICENSE.md in the project root for more information
*-------------------------------------------------------------------------------------------*/
import * as i0 from '@angular/core';
import { Directive, Input, forwardRef, Component, ChangeDetectionStrategy, ViewChild, HostBinding, Optional, ElementRef, EventEmitter, ContentChildren, Output, HostListener, NgModule } from '@angular/core';
import * as i1 from '@progress/kendo-angular-l10n';
import { ComponentMessages, LocalizationService, L10N_PREFIX } from '@progress/kendo-angular-l10n';
import { Subject, Subscription } from 'rxjs';
import { caretAltLeftIcon, caretAltToLeftIcon, caretAltRightIcon, caretAltToRightIcon } from '@progress/kendo-svg-icons';
import * as i3 from '@progress/kendo-angular-buttons';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import * as i3$1 from '@progress/kendo-angular-dropdowns';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import * as i5 from '@progress/kendo-angular-common';
import { Keys, anyChanged, ResizeSensorComponent, EventsModule, ResizeSensorModule } from '@progress/kendo-angular-common';
import * as i3$2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i4 from '@progress/kendo-angular-inputs';
import { NumericTextBoxComponent, NumericTextBoxModule } from '@progress/kendo-angular-inputs';
import { validatePackage } from '@progress/kendo-licensing';

/**
 * @hidden
 */
const _c0 = ["dropdownlist"];
const _c1 = ["select"];
const _c2 = ["numbers"];
const _c3 = a0 => ({
  "k-selected": a0
});
function PagerNumericButtonsComponent_option_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 9);
    i0.ɵɵtext(1, "... ");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("selected", false);
    i0.ɵɵattribute("aria-label", ctx_r1.pageLabel(ctx_r1.start - 1));
  }
}
function PagerNumericButtonsComponent_option_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 10);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const num_r3 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("value", num_r3.toString())("selected", num_r3 === ctx_r1.currentPage)("ngClass", i0.ɵɵpureFunction1(6, _c3, ctx_r1.currentPage === num_r3));
    i0.ɵɵattribute("aria-label", ctx_r1.pageLabel(num_r3))("aria-current", ctx_r1.currentPage === num_r3 ? "page" : undefined);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", num_r3, " ");
  }
}
function PagerNumericButtonsComponent_option_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 11);
    i0.ɵɵtext(1, "... ");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("selected", false);
    i0.ɵɵattribute("aria-label", ctx_r1.pageLabel(ctx_r1.end + 1));
  }
}
function PagerNumericButtonsComponent_button_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 12);
    i0.ɵɵlistener("click", function PagerNumericButtonsComponent_button_7_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.changePage(ctx_r1.start - 2));
    });
    i0.ɵɵtext(1, "...");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("size", ctx_r1.size);
    i0.ɵɵattribute("aria-label", ctx_r1.pageLabel(ctx_r1.start - 1))("title", ctx_r1.pageLabel(ctx_r1.start - 1));
  }
}
function PagerNumericButtonsComponent_button_8_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 13);
    i0.ɵɵlistener("click", function PagerNumericButtonsComponent_button_8_Template_button_click_0_listener() {
      const num_r6 = i0.ɵɵrestoreView(_r5).$implicit;
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.changePage(num_r6 - 1));
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const num_r6 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("size", ctx_r1.size)("selected", ctx_r1.currentPage === num_r6);
    i0.ɵɵattribute("aria-label", ctx_r1.pageLabel(num_r6))("title", ctx_r1.pageLabel(num_r6))("aria-current", ctx_r1.currentPage === num_r6 ? "page" : undefined);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", num_r6, " ");
  }
}
function PagerNumericButtonsComponent_button_9_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 12);
    i0.ɵɵlistener("click", function PagerNumericButtonsComponent_button_9_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r7);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.changePage(ctx_r1.end));
    });
    i0.ɵɵtext(1, "...");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("size", ctx_r1.size);
    i0.ɵɵattribute("aria-label", ctx_r1.pageLabel(ctx_r1.end + 1))("title", ctx_r1.pageLabel(ctx_r1.end + 1));
  }
}
const _c4 = () => ({
  "k-pager-input": true,
  "k-label": true
});
const _c5 = (a0, a1) => ({
  keydown: a0,
  focusout: a1
});
function PagerComponent_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 3);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.template.first == null ? null : ctx_r0.template.first.templateRef)("ngTemplateOutletContext", ctx_r0.templateContext);
  }
}
function PagerComponent_ng_container_2_kendo_datapager_prev_buttons_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "kendo-datapager-prev-buttons", 8);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("size", ctx_r0.size);
  }
}
function PagerComponent_ng_container_2_kendo_datapager_numeric_buttons_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "kendo-datapager-numeric-buttons", 9);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("size", ctx_r0.size)("buttonCount", ctx_r0.buttonCount);
  }
}
function PagerComponent_ng_container_2_kendo_datapager_input_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "kendo-datapager-input", 8);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("size", ctx_r0.size);
  }
}
function PagerComponent_ng_container_2_kendo_datapager_next_buttons_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "kendo-datapager-next-buttons", 8);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("size", ctx_r0.size);
  }
}
function PagerComponent_ng_container_2_kendo_datapager_page_sizes_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "kendo-datapager-page-sizes", 10);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("size", ctx_r0.size)("pageSizes", ctx_r0.pageSizeValues);
  }
}
function PagerComponent_ng_container_2_kendo_datapager_info_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "kendo-datapager-info");
  }
}
function PagerComponent_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "div", 4);
    i0.ɵɵtemplate(2, PagerComponent_ng_container_2_kendo_datapager_prev_buttons_2_Template, 1, 1, "kendo-datapager-prev-buttons", 5)(3, PagerComponent_ng_container_2_kendo_datapager_numeric_buttons_3_Template, 1, 2, "kendo-datapager-numeric-buttons", 6)(4, PagerComponent_ng_container_2_kendo_datapager_input_4_Template, 1, 1, "kendo-datapager-input", 5)(5, PagerComponent_ng_container_2_kendo_datapager_next_buttons_5_Template, 1, 1, "kendo-datapager-next-buttons", 5);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(6, PagerComponent_ng_container_2_kendo_datapager_page_sizes_6_Template, 1, 2, "kendo-datapager-page-sizes", 7)(7, PagerComponent_ng_container_2_kendo_datapager_info_7_Template, 1, 0, "kendo-datapager-info", 2);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r0.previousNext);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.type === "numeric" && ctx_r0.buttonCount > 0);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.type === "input");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.previousNext);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.pageSizeValues);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.info);
  }
}
class PreventableEvent {
  constructor() {
    this.prevented = false;
  }
  /**
   * Prevents the default action for a specified event.
   * In this way, the source component suppresses the built-in behavior that follows the event.
   */
  preventDefault() {
    this.prevented = true;
  }
  /**
   * If the event is prevented by any of its subscribers, returns `true`.
   *
   * @returns `true` if the default action was prevented. Otherwise, returns `false`.
   */
  isDefaultPrevented() {
    return this.prevented;
  }
}

/**
 * Arguments for the `pageSizeChange` event. The `pageSizeChange` event fires when the page size
 * is changed from the UI. If you cancel the event, the change is prevented.
 */
class PageSizeChangeEvent extends PreventableEvent {
  /**
   * Constructs the event arguments for the `pageSizeChange` event.
   * @param newPageSize - The newly selected page size.
   * @hidden
   */
  constructor(newPageSize) {
    super();
    this.newPageSize = newPageSize;
  }
}

/**
 * @hidden
 */
class Messages extends ComponentMessages {}
Messages.ɵfac = /* @__PURE__ */(() => {
  let ɵMessages_BaseFactory;
  return function Messages_Factory(t) {
    return (ɵMessages_BaseFactory || (ɵMessages_BaseFactory = i0.ɵɵgetInheritedFactory(Messages)))(t || Messages);
  };
})();
Messages.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: Messages,
  selectors: [["kendoPagerMessages"]],
  inputs: {
    firstPage: "firstPage",
    lastPage: "lastPage",
    previousPage: "previousPage",
    nextPage: "nextPage",
    page: "page",
    pageNumberInputTitle: "pageNumberInputTitle",
    itemsPerPage: "itemsPerPage",
    of: "of",
    items: "items",
    selectPage: "selectPage",
    inputLabel: "inputLabel"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Messages, [{
    type: Directive,
    args: [{
      // eslint-disable-next-line @angular-eslint/directive-selector
      selector: 'kendoPagerMessages'
    }]
  }], null, {
    firstPage: [{
      type: Input
    }],
    lastPage: [{
      type: Input
    }],
    previousPage: [{
      type: Input
    }],
    nextPage: [{
      type: Input
    }],
    page: [{
      type: Input
    }],
    pageNumberInputTitle: [{
      type: Input
    }],
    itemsPerPage: [{
      type: Input
    }],
    of: [{
      type: Input
    }],
    items: [{
      type: Input
    }],
    selectPage: [{
      type: Input
    }],
    inputLabel: [{
      type: Input
    }]
  });
})();

/**
 * Custom component messages override default component messages
 * ([see example]({% slug pager_globalization %}#toc-messages)).
 */
class CustomMessagesComponent extends Messages {
  constructor(service) {
    super();
    this.service = service;
  }
  get override() {
    return true;
  }
}
CustomMessagesComponent.ɵfac = function CustomMessagesComponent_Factory(t) {
  return new (t || CustomMessagesComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService));
};
CustomMessagesComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CustomMessagesComponent,
  selectors: [["kendo-datapager-messages"]],
  features: [i0.ɵɵProvidersFeature([{
    provide: Messages,
    useExisting: forwardRef(() => CustomMessagesComponent)
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function CustomMessagesComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CustomMessagesComponent, [{
    type: Component,
    args: [{
      providers: [{
        provide: Messages,
        useExisting: forwardRef(() => CustomMessagesComponent)
      }],
      selector: 'kendo-datapager-messages',
      template: ``
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }];
  }, null);
})();

/**
 * @hidden
 */
class LocalizedMessagesDirective extends Messages {
  constructor(service) {
    super();
    this.service = service;
  }
}
LocalizedMessagesDirective.ɵfac = function LocalizedMessagesDirective_Factory(t) {
  return new (t || LocalizedMessagesDirective)(i0.ɵɵdirectiveInject(i1.LocalizationService));
};
LocalizedMessagesDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: LocalizedMessagesDirective,
  selectors: [["", "kendoDataPagerLocalizedMessages", ""]],
  features: [i0.ɵɵProvidersFeature([{
    provide: Messages,
    useExisting: forwardRef(() => LocalizedMessagesDirective)
  }]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalizedMessagesDirective, [{
    type: Directive,
    args: [{
      providers: [{
        provide: Messages,
        useExisting: forwardRef(() => LocalizedMessagesDirective)
      }],
      selector: '[kendoDataPagerLocalizedMessages]'
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }];
  }, null);
})();

/**
 * @hidden
 */
class PagerContextService {
  constructor() {
    this.changes = new Subject();
    this.pageChange = new Subject();
    this.pageSizeChange = new Subject();
  }
  get currentPage() {
    return this.skip / this.pageSize;
  }
  notifyChanges(changes) {
    this.total = changes.total;
    this.pageSize = changes.pageSize;
    this.skip = changes.skip;
    this.changes.next(changes);
  }
  changePage(page) {
    this.pageChange.next({
      skip: page * this.pageSize,
      take: this.pageSize
    });
  }
  changePageSize(event) {
    this.pageSizeChange.next(event);
  }
  nextPage() {
    const nextPage = this.currentPage + 1;
    if (nextPage * this.pageSize <= this.total) {
      this.changePage(nextPage);
    }
  }
  prevPage() {
    const prevPage = this.currentPage - 1;
    if (prevPage * this.pageSize >= 0) {
      this.changePage(prevPage);
    }
  }
}

/**
 * @hidden
 */
class PagerElementComponent {
  constructor(localization, pagerContext, cd) {
    this.localization = localization;
    this.pagerContext = pagerContext;
    this.cd = cd;
    this.total = this.pagerContext.total;
    this.skip = this.pagerContext.skip;
    this.pageSize = this.pagerContext.pageSize;
    this.caretAltLeftIcon = caretAltLeftIcon;
    this.caretAltToLeftIcon = caretAltToLeftIcon;
    this.caretAltRightIcon = caretAltRightIcon;
    this.caretAltToRightIcon = caretAltToRightIcon;
  }
  /**
   * @hidden
   *
   * @readonly
   * @type {number}
   * @memberOf PagerElementComponent
   */
  get currentPage() {
    return Math.floor((this.skip || 0) / this.pageSize) + 1;
  }
  /**
   * @hidden
   *
   * @readonly
   * @type {number}
   * @memberOf PagerElementComponent
   */
  get totalPages() {
    return Math.ceil((this.total || 0) / this.pageSize);
  }
  /**
   * @hidden
   *
   * @param {string} key
   * @returns {string}
   *
   * @memberOf PagerElementComponent
   */
  textFor(key) {
    return this.localization.get(key);
  }
  /**
   * @hidden
   *
   * @param {number} page
   *
   * @memberOf PagerElementComponent
   */
  changePage(page) {
    this.pagerContext.changePage(page);
    return false;
  }
  /**
   * @hidden
   *
   * @memberOf PagerElementComponent
   */
  ngOnInit() {
    this.subscriptions = this.pagerContext.changes.subscribe(this.onChanges.bind(this));
    this.subscriptions.add(this.localization.changes.subscribe(() => this.cd.markForCheck()));
  }
  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
  get prevArrowIcons() {
    return !this.localization.rtl ? ['caret-alt-to-left', 'caret-alt-left'] : ['caret-alt-to-right', 'caret-alt-right'];
  }
  get prevArrowSVGIcons() {
    return !this.localization.rtl ? [this.caretAltToLeftIcon, this.caretAltLeftIcon] : [this.caretAltToRightIcon, this.caretAltRightIcon];
  }
  get nextArrowIcons() {
    return !this.localization.rtl ? ['caret-alt-right', 'caret-alt-to-right'] : ['caret-alt-left', 'caret-alt-to-left'];
  }
  get nextArrowSVGIcons() {
    return !this.localization.rtl ? [this.caretAltRightIcon, this.caretAltToRightIcon] : [this.caretAltLeftIcon, this.caretAltToLeftIcon];
  }
}
PagerElementComponent.ɵfac = function PagerElementComponent_Factory(t) {
  return new (t || PagerElementComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(PagerContextService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
PagerElementComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PagerElementComponent,
  selectors: [["kendo-pager-element"]],
  decls: 0,
  vars: 0,
  template: function PagerElementComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerElementComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-pager-element',
      template: ``
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }, {
      type: PagerContextService
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();

/**
 * @hidden
 */
const DEFAULT_PAGE_SIZE_VALUES = [5, 10, 20];
/**
 * @hidden
 */
const focusableDirectiveSelector = '[kendoPagerFocusable]';
/**
 * @hidden
 */
const getAllFocusableChildren = parent => {
  return parent.querySelectorAll(focusableDirectiveSelector);
};
/**
 * @hidden
 */
const focusableSelector = ['a[href]:not([disabled])', 'area[href]:not([disabled])', 'input:not([disabled])', 'select:not([disabled])', 'textarea:not([disabled])', 'button', 'iframe:not([disabled])', 'object:not([disabled])', 'embed:not([disabled])', '*[tabindex]:not([disabled])', '*[contenteditable]:not([disabled]):not([contenteditable="false"])'].join(',');
/**
 * @hidden
 */
const DEFAULT_SIZE = 'medium';
const SIZES = {
  small: 'sm',
  medium: 'md',
  large: 'lg'
};
/**
 * @hidden
 *
 * Returns the styling classes to be added and removed
 */
const getStylingClasses = (componentType, stylingOption, previousValue, newValue) => {
  switch (stylingOption) {
    case 'size':
      return {
        toRemove: `k-${componentType}-${SIZES[previousValue]}`,
        toAdd: newValue !== 'none' ? `k-${componentType}-${SIZES[newValue]}` : ''
      };
    default:
      break;
  }
};

/**
 * @hidden
 */
class PagerNavigationService {
  constructor() {
    this.isNavigable = false;
    this.innerNavigationChange = new Subject();
  }
  toggleInnerNavigation(value) {
    this.innerNavigationChange.next(value);
  }
  keepFocusWithinComponent(wrapper, target, event) {
    const [firstFocusable, lastFocusable] = this.getFirstAndLastFocusable(wrapper);
    const tabAfterLastFocusable = !event.shiftKey && target === lastFocusable;
    const shiftTabAfterFirstFocusable = event.shiftKey && target === firstFocusable;
    if (tabAfterLastFocusable) {
      event.preventDefault();
      firstFocusable.focus();
    }
    if (shiftTabAfterFirstFocusable) {
      event.preventDefault();
      lastFocusable.focus();
    }
  }
  getFirstAndLastFocusable(wrapper) {
    const all = getAllFocusableChildren(wrapper);
    const firstFocusable = all.length > 0 ? all[0] : parent;
    const lastFocusable = all.length > 0 ? all[all.length - 1] : parent;
    return [firstFocusable, lastFocusable];
  }
}

/**
 * Place the directive on custom focusable elements in the [`kendoDataPagerTemplate`]({% slug api_pager_pagertemplatedirective %}) to include them in the built-in Pager keyboard navigation.
 */
class PagerFocusableDirective {
  constructor(navigationService, element, renderer) {
    this.navigationService = navigationService;
    this.element = element;
    this.renderer = renderer;
    this.subscriptions = new Subscription();
  }
  ngOnInit() {
    this.subscriptions.add(this.navigationService.innerNavigationChange.subscribe(this.innerNavigationChange.bind(this)));
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  get nativeElement() {
    return this.element.nativeElement;
  }
  innerNavigationChange(value) {
    if (!this.navigationService.isNavigable) {
      return;
    }
    const index = value ? '0' : '-1';
    if (this.nativeElement.matches(focusableSelector)) {
      this.renderer.setAttribute(this.nativeElement, 'tabindex', index);
    }
    const focusableElements = this.nativeElement.querySelectorAll(focusableSelector);
    focusableElements.forEach(el => {
      this.renderer.setAttribute(el, 'tabindex', index);
    });
  }
}
PagerFocusableDirective.ɵfac = function PagerFocusableDirective_Factory(t) {
  return new (t || PagerFocusableDirective)(i0.ɵɵdirectiveInject(PagerNavigationService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
};
PagerFocusableDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: PagerFocusableDirective,
  selectors: [["", "kendoPagerFocusable", ""]],
  exportAs: ["kendoPagerFocusable"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerFocusableDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoPagerFocusable]',
      exportAs: 'kendoPagerFocusable'
    }]
  }], function () {
    return [{
      type: PagerNavigationService
    }, {
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }];
  }, null);
})();

// eslint-disable no-access-missing-member
/**
 * Displays buttons for navigating to the first and to the previous page ([see example]({% slug pager_settings %})).
 */
class PagerPrevButtonsComponent extends PagerElementComponent {
  constructor(localization, pagerContext, cd) {
    super(localization, pagerContext, cd);
    /**
     * Specifies the padding of the buttons.
     *
     * The possible values are:
     * * `small`
     * * `medium` (default)
     * * `large`
     * * `none`
     */
    this.size = DEFAULT_SIZE;
  }
  /**
   * @hidden
   *
   * @readonly
   * @type {boolean}
   * @memberOf PagerPrevButtonsComponent
   */
  get disabled() {
    return this.currentPage === 1 || !this.total;
  }
  onChanges({
    total,
    skip,
    pageSize
  }) {
    this.total = total;
    this.skip = skip;
    this.pageSize = pageSize;
    this.cd.markForCheck();
  }
}
PagerPrevButtonsComponent.ɵfac = function PagerPrevButtonsComponent_Factory(t) {
  return new (t || PagerPrevButtonsComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(PagerContextService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
PagerPrevButtonsComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PagerPrevButtonsComponent,
  selectors: [["kendo-datapager-prev-buttons"]],
  inputs: {
    size: "size"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 12,
  consts: [["type", "button", "kendoButton", "", "kendoPagerFocusable", "", "fillMode", "flat", "rounded", "none", 1, "k-pager-nav", "k-pager-first", 3, "click", "disabled", "icon", "svgIcon", "size", "title"], ["type", "button", "kendoButton", "", "kendoPagerFocusable", "", "fillMode", "flat", "rounded", "none", 1, "k-pager-nav", 3, "click", "disabled", "icon", "svgIcon", "size", "title"]],
  template: function PagerPrevButtonsComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "button", 0);
      i0.ɵɵlistener("click", function PagerPrevButtonsComponent_Template_button_click_0_listener() {
        return ctx.currentPage !== 1 ? ctx.changePage(0) : false;
      });
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(1, "button", 1);
      i0.ɵɵlistener("click", function PagerPrevButtonsComponent_Template_button_click_1_listener() {
        return ctx.currentPage !== 1 ? ctx.changePage(ctx.currentPage - 2) : false;
      });
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("disabled", ctx.disabled)("icon", ctx.prevArrowIcons[0])("svgIcon", ctx.prevArrowSVGIcons[0])("size", ctx.size)("title", ctx.textFor("firstPage"));
      i0.ɵɵattribute("aria-label", ctx.textFor("firstPage"));
      i0.ɵɵadvance();
      i0.ɵɵproperty("disabled", ctx.disabled)("icon", ctx.prevArrowIcons[1])("svgIcon", ctx.prevArrowSVGIcons[1])("size", ctx.size)("title", ctx.textFor("previousPage"));
      i0.ɵɵattribute("aria-label", ctx.textFor("previousPage"));
    }
  },
  dependencies: [i3.ButtonComponent, PagerFocusableDirective],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerPrevButtonsComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      selector: 'kendo-datapager-prev-buttons',
      template: `
        <button
            type="button" kendoButton kendoPagerFocusable
            class="k-pager-nav k-pager-first"
            [disabled]="disabled"
            [icon]="prevArrowIcons[0]"
            [svgIcon]="prevArrowSVGIcons[0]"
            fillMode="flat"
            rounded="none"
            [size]="size"
            [title]="textFor('firstPage')"
            [attr.aria-label]="textFor('firstPage')"
            (click)="currentPage !== 1 ? changePage(0) : false">
        </button>
        <button
            type="button" kendoButton kendoPagerFocusable
            class="k-pager-nav"
            [disabled]="disabled"
            [icon]="prevArrowIcons[1]"
            [svgIcon]="prevArrowSVGIcons[1]"
            fillMode="flat"
            rounded="none"
            [size]="size"
            [title]="textFor('previousPage')"
            [attr.aria-label]="textFor('previousPage')"
            (click)="currentPage !== 1 ? changePage(currentPage-2) : false">
        </button>
    `
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }, {
      type: PagerContextService
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    size: [{
      type: Input
    }]
  });
})();

/**
 * Displays a drop-down list for the page size selection ([see example]({% slug pager_settings %})).
 */
class PagerPageSizesComponent extends PagerElementComponent {
  constructor(localization, cd, pagerContext, element, ngZone) {
    super(localization, pagerContext, cd);
    this.pagerContext = pagerContext;
    this.element = element;
    this.ngZone = ngZone;
    /**
     * Specifies the padding of the dropdown.
     *
     * The possible values are:
     * * `small`
     * * `medium` (default)
     * * `large`
     * * `none`
     */
    this.size = DEFAULT_SIZE;
    this._pageSizes = [];
  }
  /**
   * The page sizes collection. Can contain numbers and [PageSizeItem]({% slug api_pager_pagesizeitem %}) objects.
   *
   * @example
   *
   * ```ts-preview
   * _@Component({
   *    selector: 'my-app',
   *    template: `
   *        <div *ngFor="let item of pagedData" style="border: 1px solid black; padding: 10px;">
   *            <span>{{item.ProductID}}. </span>
   *            <span>{{item.ProductName}}</span>
   *        </div>
   *        <kendo-datapager
   *             [skip]="skip"
   *             [pageSize]="pageSize"
   *             [total]="total"
   *             (pageChange)="onPageChange($event)">
   *           <ng-template kendoDataPagerTemplate>
   *               <kendo-datapager-page-sizes [pageSizes]="pagesizes"></kendo-datapager-page-sizes>
   *           </ng-template>
   *        </kendo-datapager>
   *    `
   * })
   * class AppComponent {
   *    public data: any[] = products;
   *    public pagedData = [];
   *    public skip = 0;
   *    public pageSize = 2;
   *    public total = products.length;
   *    public pagesizes = [{text: 'One', value: 1}, {text: 'Two', value: 2}, {text: 'All', value : 'all'}];
   *
   *    public ngOnInit() {
   *        this.pageData();
   *    }
   *
   *    public onPageChange(e) {
   *        this.skip = e.skip;
   *        this.pageSize = e.take;
   *        this.pageData();
   *    }
   *
   *    private pageData() {
   *        this.pagedData = this.data.slice(this.skip, this.skip + this.pageSize);
   *    }
   * }
   *
   * const products = [{
   *   'ProductID' : 1,
   *   'ProductName' : "Chai",
   *   'SupplierID' : 1,
   *   'CategoryID' : 1,
   *   'QuantityPerUnit' : "10 boxes x 20 bags",
   *   'UnitPrice' : 18.0000,
   *   'UnitsInStock' : 39,
   *   'UnitsOnOrder' : 0,
   *   'ReorderLevel' : 10,
   *   'Discontinued' : false
   *
   * }, {
   *   'ProductID' : 2,
   *   'ProductName' : "Chang",
   *   'SupplierID' : 1,
   *   'CategoryID' : 1,
   *   'QuantityPerUnit' : "24 - 12 oz bottles",
   *   'UnitPrice' : 19.0000,
   *   'UnitsInStock' : 17,
   *   'UnitsOnOrder' : 40,
   *   'ReorderLevel' : 25,
   *   'Discontinued' : false
   * }, {
   *   'ProductID' : 3,
   *   'ProductName' : "Aniseed Syrup",
   *   'SupplierID' : 1,
   *   'CategoryID' : 2,
   *   'QuantityPerUnit' : "12 - 550 ml bottles",
   *   'UnitPrice' : 10.0000,
   *   'UnitsInStock' : 13,
   *   'UnitsOnOrder' : 70,
   *   'ReorderLevel' : 25,
   *   'Discontinued' : false
   * }, {
   *   'ProductID' : 4,
   *   'ProductName' : "Chef Anton\'s Cajun Seasoning",
   *   'SupplierID' : 2,
   *  'CategoryID' : 2,
   *   'QuantityPerUnit' : "48 - 6 oz jars",
   *   'UnitPrice' : 22.0000,
   *   'UnitsInStock' : 53,
   *   'UnitsOnOrder' : 0,
   *   'ReorderLevel' : 0,
   *   'Discontinued' : false
   * }, {
   *   'ProductID' : 5,
   *   'ProductName' : "Chef Anton\'s Gumbo Mix",
   *   'SupplierID' : 2,
   *   'CategoryID' : 2,
   *   'QuantityPerUnit' : "36 boxes",
   *   'UnitPrice' : 21.3500,
   *   'UnitsInStock' : 0,
   *   'UnitsOnOrder' : 0,
   *   'ReorderLevel' : 0,
   *   'Discontinued' : true
   * }, {
   *   'ProductID' : 6,
   *   'ProductName' : "Grandma\'s Boysenberry Spread",
   *   'SupplierID' : 3,
   *   'CategoryID' : 2,
   *   'QuantityPerUnit' : "12 - 8 oz jars",
   *   'UnitPrice' : 25.0000,
   *   'UnitsInStock' : 120,
   *   'UnitsOnOrder' : 0,
   *   'ReorderLevel' : 25,
   *   'Discontinued' : false
   * }];
   * ```
   *
   */
  set pageSizes(pageSizes) {
    let normalizedItems = [];
    if (Array.isArray(pageSizes)) {
      pageSizes.forEach(item => {
        if (typeof item === 'number') {
          normalizedItems.push({
            text: item.toString(),
            value: item
          });
        } else {
          normalizedItems.push(item);
        }
      });
    }
    if (this.pageSize && !normalizedItems.some(item => item.value === this.pageSize)) {
      normalizedItems = [{
        text: this.pageSize.toString(),
        value: this.pageSize
      }, ...normalizedItems];
    }
    this._pageSizes = normalizedItems;
  }
  get pageSizes() {
    return this._pageSizes;
  }
  /**
   * @hidden
   *
   * @readonly
   */
  get classes() {
    return true;
  }
  ngAfterViewInit() {
    this.dropDownList.wrapper.nativeElement.setAttribute('aria-label', this.textFor('itemsPerPage'));
    this.ngZone.runOutsideAngular(() => {
      this.element.nativeElement.addEventListener('keydown', this.keyDownHandler.bind(this), true);
    });
  }
  ngOnDestroy() {
    this.element.nativeElement.removeEventListener('keydown', this.keyDownHandler);
  }
  /**
   * @hidden
   */
  pageSizeChange(value, dropdownlist) {
    const event = new PageSizeChangeEvent(value);
    this.pagerContext.changePageSize(event);
    if (event.isDefaultPrevented()) {
      dropdownlist.writeValue(this.pageSize);
    }
  }
  onChanges({
    total,
    skip,
    pageSize
  }) {
    this.total = total;
    this.skip = skip;
    this.pageSize = typeof pageSize === 'number' ? pageSize : this.total;
    this.cd.markForCheck();
  }
  keyDownHandler(ev) {
    if (ev.keyCode === Keys.Escape && this.dropDownList.isOpen) {
      ev.stopPropagation();
      this.dropDownList.toggle(false);
    }
  }
}
PagerPageSizesComponent.ɵfac = function PagerPageSizesComponent_Factory(t) {
  return new (t || PagerPageSizesComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(PagerContextService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
PagerPageSizesComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PagerPageSizesComponent,
  selectors: [["kendo-datapager-page-sizes"]],
  viewQuery: function PagerPageSizesComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.dropDownList = _t.first);
    }
  },
  hostVars: 2,
  hostBindings: function PagerPageSizesComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("k-pager-sizes", ctx.classes);
    }
  },
  inputs: {
    pageSizes: "pageSizes",
    size: "size"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 3,
  vars: 5,
  consts: [["dropdownlist", ""], ["kendoPagerFocusable", "", "textField", "text", "valueField", "value", 3, "valueChange", "size", "data", "valuePrimitive", "value"]],
  template: function PagerPageSizesComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵelementStart(0, "kendo-dropdownlist", 1, 0);
      i0.ɵɵlistener("valueChange", function PagerPageSizesComponent_Template_kendo_dropdownlist_valueChange_0_listener($event) {
        i0.ɵɵrestoreView(_r1);
        const dropdownlist_r2 = i0.ɵɵreference(1);
        return i0.ɵɵresetView(ctx.pageSizeChange($event, dropdownlist_r2));
      });
      i0.ɵɵelementEnd();
      i0.ɵɵtext(2);
    }
    if (rf & 2) {
      i0.ɵɵproperty("size", ctx.size)("data", ctx.pageSizes)("valuePrimitive", true)("value", ctx.pageSize);
      i0.ɵɵadvance(2);
      i0.ɵɵtextInterpolate1(" ", ctx.textFor("itemsPerPage"), " ");
    }
  },
  dependencies: [i3$1.DropDownListComponent, PagerFocusableDirective],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerPageSizesComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      selector: 'kendo-datapager-page-sizes',
      template: `
        <kendo-dropdownlist kendoPagerFocusable
            #dropdownlist
            [size]="size"
            [data]="pageSizes"
            textField="text"
            valueField="value"
            [valuePrimitive]="true"
            [value]="pageSize"
            (valueChange)="pageSizeChange($event, dropdownlist)"></kendo-dropdownlist>
        {{ textFor('itemsPerPage') }}
    `
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: PagerContextService
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, {
    dropDownList: [{
      type: ViewChild,
      args: ['dropdownlist', {
        static: true
      }]
    }],
    pageSizes: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    classes: [{
      type: HostBinding,
      args: ["class.k-pager-sizes"]
    }]
  });
})();

/**
 * Represents the pager template which helps to customize the pager appearance. To define a pager
 * template, nest an `<ng-template>` tag with the `kendoDataPagerTemplate` directive inside `<kendo-datapager>`.
 *
 * The template context provides the following fields:
 * * `currentPage`&mdash;The index of the displayed page.
 * * `pageSize`&mdash;The value of the current `pageSize`.
 * * `skip`&mdash;The current skip value.
 * * `total`&mdash;The total number of records.
 * * `totalPages`&mdash;The total number of available pages.
 *
 * @example
 * ```ts-preview
 *
 * _@Component({
 *     selector: 'my-app',
 *     template: `
 *       <kendo-datapager [total]="total" [pageSize]="pageSize" [skip]="skip" (pageChange)="onPageChange($event)">
 *           <ng-template kendoDataPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
 *               <kendo-datapager-prev-buttons></kendo-datapager-prev-buttons>
 *               <kendo-datapager-numeric-buttons [buttonCount]="10"></kendo-datapager-numeric-buttons>
 *               <kendo-datapager-next-buttons></kendo-datapager-next-buttons>
 *               <kendo-datapager-info></kendo-datapager-info>
 *               Current page: {{currentPage}}
 *           </ng-template>
 *        </kendo-datapager>
 *     `
 * })
 *
 * class AppComponent {
 *     public skip = 0;
 *     public pageSize = 10;
 *     public total = 100;
 *
 *     public onPageChange(e: any): void {
 *         this.skip = e.skip;
 *         this.pageSize = e.take;
 *     }
 * }
 *
 * ```
 */
class PagerTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
PagerTemplateDirective.ɵfac = function PagerTemplateDirective_Factory(t) {
  return new (t || PagerTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef, 8));
};
PagerTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: PagerTemplateDirective,
  selectors: [["", "kendoDataPagerTemplate", ""]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[kendoDataPagerTemplate]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * Displays numeric buttons to enable navigation between the pages.
 */
class PagerNumericButtonsComponent extends PagerElementComponent {
  constructor(localization, cd, pagerContext, renderer) {
    super(localization, pagerContext, cd);
    this.pagerContext = pagerContext;
    this.renderer = renderer;
    this._size = DEFAULT_SIZE;
  }
  /**
   * Specifies the padding of the numeric buttons.
   *
   * The possible values are:
   * * `small`
   * * `medium` (default)
   * * `large`
   * * `none`
   */
  set size(size) {
    const newSize = size ? size : DEFAULT_SIZE;
    this.handleClasses(newSize, 'size');
    this._size = newSize;
  }
  get size() {
    return this._size;
  }
  /**
   * @hidden
   *
   * @readonly
   * @type {number[]}
   * @memberOf PagerNumericButtonsComponent
   */
  get buttons() {
    const result = [];
    for (let idx = this.start; idx <= this.end; idx++) {
      result.push(idx);
    }
    return result;
  }
  /**
   * @hidden
   */
  get end() {
    return Math.min(this.start + this.buttonCount - 1, this.totalPages);
  }
  /**
   * @hidden
   */
  get start() {
    const page = this.currentPage;
    const buttonCount = this.buttonCount;
    if (page > buttonCount) {
      const reminder = page % buttonCount;
      return reminder === 0 ? page - buttonCount + 1 : page - reminder + 1;
    }
    return 1;
  }
  ngAfterViewInit() {
    this.handleClasses(this.size, 'size');
  }
  /**
   * @hidden
   */
  pageLabel(num) {
    const pageText = this.textFor('page');
    if (pageText) {
      return pageText + ' ' + num;
    }
    return num.toString();
  }
  /**
   * @hidden
   */
  onSelectChange(e) {
    const target = e.target;
    const valueAsNumber = Number(target.value);
    if (!Number.isNaN(valueAsNumber)) {
      this.changePage(valueAsNumber - 1);
    } else {
      if (target.value === 'previousButtons') {
        this.changePage(this.start - 2);
      } else {
        this.changePage(this.end);
      }
    }
  }
  onChanges({
    total,
    skip,
    pageSize
  }) {
    this.total = total;
    this.skip = skip;
    this.pageSize = pageSize;
    this.cd.markForCheck();
  }
  get pageChooserLabel() {
    return this.textFor('selectPage');
  }
  handleClasses(value, input) {
    const elem = this.selectElement?.nativeElement;
    const classes = getStylingClasses('picker', input, this[input], value);
    if (!elem) {
      return;
    }
    if (classes.toRemove) {
      this.renderer.removeClass(elem, classes.toRemove);
    }
    if (classes.toAdd) {
      this.renderer.addClass(elem, classes.toAdd);
    }
  }
}
PagerNumericButtonsComponent.ɵfac = function PagerNumericButtonsComponent_Factory(t) {
  return new (t || PagerNumericButtonsComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(PagerContextService), i0.ɵɵdirectiveInject(i0.Renderer2));
};
PagerNumericButtonsComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PagerNumericButtonsComponent,
  selectors: [["kendo-datapager-numeric-buttons"]],
  viewQuery: function PagerNumericButtonsComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c1, 5, ElementRef);
      i0.ɵɵviewQuery(_c2, 5, ElementRef);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.selectElement = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.numbersElement = _t.first);
    }
  },
  inputs: {
    buttonCount: "buttonCount",
    size: "size"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 10,
  vars: 10,
  consts: [["select", ""], ["numbers", ""], ["kendoPagerFocusable", "", 1, "k-dropdown-list", "k-dropdown", "k-picker", "k-picker-solid", "k-rounded-md", 3, "change"], ["value", "previousButtons", 3, "selected", 4, "ngIf"], [3, "value", "selected", "ngClass", 4, "ngFor", "ngForOf"], ["value", "nextButtons", 3, "selected", 4, "ngIf"], [1, "k-pager-numbers"], ["type", "button", "kendoPagerFocusable", "", "kendoButton", "", "fillMode", "flat", "themeColor", "primary", "rounded", "none", 3, "size", "click", 4, "ngIf"], ["type", "button", "kendoPagerFocusable", "", "kendoButton", "", "fillMode", "flat", "themeColor", "primary", "rounded", "none", 3, "size", "selected", "click", 4, "ngFor", "ngForOf"], ["value", "previousButtons", 3, "selected"], [3, "value", "selected", "ngClass"], ["value", "nextButtons", 3, "selected"], ["type", "button", "kendoPagerFocusable", "", "kendoButton", "", "fillMode", "flat", "themeColor", "primary", "rounded", "none", 3, "click", "size"], ["type", "button", "kendoPagerFocusable", "", "kendoButton", "", "fillMode", "flat", "themeColor", "primary", "rounded", "none", 3, "click", "size", "selected"]],
  template: function PagerNumericButtonsComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵelementStart(0, "select", 2, 0);
      i0.ɵɵlistener("change", function PagerNumericButtonsComponent_Template_select_change_0_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onSelectChange($event));
      });
      i0.ɵɵtemplate(2, PagerNumericButtonsComponent_option_2_Template, 2, 2, "option", 3)(3, PagerNumericButtonsComponent_option_3_Template, 2, 8, "option", 4)(4, PagerNumericButtonsComponent_option_4_Template, 2, 2, "option", 5);
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(5, "div", 6, 1);
      i0.ɵɵtemplate(7, PagerNumericButtonsComponent_button_7_Template, 2, 3, "button", 7)(8, PagerNumericButtonsComponent_button_8_Template, 2, 6, "button", 8)(9, PagerNumericButtonsComponent_button_9_Template, 2, 3, "button", 7);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵstyleProp("display", "none");
      i0.ɵɵattribute("title", ctx.pageChooserLabel)("aria-label", ctx.pageChooserLabel);
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.start > 1);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngForOf", ctx.buttons);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.end < ctx.totalPages);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngIf", ctx.start > 1);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngForOf", ctx.buttons);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.end < ctx.totalPages);
    }
  },
  dependencies: [i3$2.NgClass, i3$2.NgForOf, i3$2.NgIf, i3.ButtonComponent, PagerFocusableDirective],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerNumericButtonsComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      selector: 'kendo-datapager-numeric-buttons',
      template: `
        <select kendoPagerFocusable #select
            class="k-dropdown-list k-dropdown k-picker k-picker-solid k-rounded-md"
            [style.display]="'none'"
            [attr.title]="pageChooserLabel"
            [attr.aria-label]="pageChooserLabel"
            (change)="onSelectChange($event)">
            <option *ngIf="start > 1"
                value="previousButtons"
                [selected]="false"
                [attr.aria-label]="pageLabel(start - 1)">...
            </option>
            <option *ngFor="let num of buttons"
                [value]="num.toString()"
                [selected]="num === currentPage"
                [attr.aria-label]="pageLabel(num)"
                [attr.aria-current]="currentPage === num ? 'page' : undefined"
                [ngClass]="{'k-selected':currentPage === num}">
                {{num}}
            </option>
            <option *ngIf="end < totalPages"
                value="nextButtons"
                [selected]="false"
                [attr.aria-label]="pageLabel(end + 1)">...
            </option>
        </select>
        <div class="k-pager-numbers" #numbers>
            <button *ngIf="start > 1"
                type="button"
                kendoPagerFocusable
                kendoButton
                [size]="size"
                fillMode="flat"
                themeColor="primary"
                rounded="none"
                [attr.aria-label]="pageLabel(start - 1)"
                [attr.title]="pageLabel(start - 1)"
                (click)="changePage(start - 2)">...</button>
            <button *ngFor="let num of buttons"
                type="button"
                kendoPagerFocusable
                kendoButton
                [size]="size"
                fillMode="flat"
                themeColor="primary"
                rounded="none"
                [attr.aria-label]="pageLabel(num)"
                [attr.title]="pageLabel(num)"
                [attr.aria-current]="currentPage === num ? 'page' : undefined"
                [selected]="currentPage === num"
                (click)="changePage(num - 1)">
                {{num}}
            </button>
            <button *ngIf="end < totalPages"
                type="button"
                kendoPagerFocusable
                kendoButton
                [size]="size"
                fillMode="flat"
                themeColor="primary"
                rounded="none"
                [attr.aria-label]="pageLabel(end + 1)"
                [attr.title]="pageLabel(end + 1)"
                (click)="changePage(end)">...</button>
        </div>
    `
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: PagerContextService
    }, {
      type: i0.Renderer2
    }];
  }, {
    selectElement: [{
      type: ViewChild,
      args: ['select', {
        read: ElementRef
      }]
    }],
    numbersElement: [{
      type: ViewChild,
      args: ['numbers', {
        read: ElementRef
      }]
    }],
    buttonCount: [{
      type: Input
    }],
    size: [{
      type: Input
    }]
  });
})();

// eslint-disable  no-access-missing-member
/**
 * Displays buttons for navigating to the next and to the last page ([see example]({% slug pager_settings %})).
 */
class PagerNextButtonsComponent extends PagerElementComponent {
  constructor(localization, pagerContext, cd) {
    super(localization, pagerContext, cd);
    /**
     * Specifies the padding of the buttons.
     *
     * The possible values are:
     * * `small`
     * * `medium` (default)
     * * `large`
     * * `none`
     */
    this.size = DEFAULT_SIZE;
  }
  /**
   * @hidden
   *
   * @readonly
   * @type {boolean}
   * @memberOf PagerNextButtonsComponent
   */
  get disabled() {
    return this.currentPage === this.totalPages || !this.total;
  }
  onChanges({
    total,
    skip,
    pageSize
  }) {
    this.total = total;
    this.skip = skip;
    this.pageSize = pageSize;
    this.cd.markForCheck();
  }
}
PagerNextButtonsComponent.ɵfac = function PagerNextButtonsComponent_Factory(t) {
  return new (t || PagerNextButtonsComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(PagerContextService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
PagerNextButtonsComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PagerNextButtonsComponent,
  selectors: [["kendo-datapager-next-buttons"]],
  inputs: {
    size: "size"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 2,
  vars: 12,
  consts: [["kendoButton", "", "kendoPagerFocusable", "", "type", "button", "fillMode", "flat", "rounded", "none", 1, "k-pager-nav", 3, "click", "size", "icon", "svgIcon", "disabled", "title"], ["kendoButton", "", "kendoPagerFocusable", "", "type", "button", "fillMode", "flat", "rounded", "none", 1, "k-pager-nav", "k-pager-last", 3, "click", "size", "icon", "svgIcon", "disabled", "title"]],
  template: function PagerNextButtonsComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "button", 0);
      i0.ɵɵlistener("click", function PagerNextButtonsComponent_Template_button_click_0_listener() {
        return ctx.currentPage !== ctx.totalPages ? ctx.changePage(ctx.currentPage) : false;
      });
      i0.ɵɵelementEnd();
      i0.ɵɵelementStart(1, "button", 1);
      i0.ɵɵlistener("click", function PagerNextButtonsComponent_Template_button_click_1_listener() {
        return ctx.currentPage !== ctx.totalPages ? ctx.changePage(ctx.totalPages - 1) : false;
      });
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("size", ctx.size)("icon", ctx.nextArrowIcons[0])("svgIcon", ctx.nextArrowSVGIcons[0])("disabled", ctx.disabled)("title", ctx.textFor("nextPage"));
      i0.ɵɵattribute("aria-label", ctx.textFor("nextPage"));
      i0.ɵɵadvance();
      i0.ɵɵproperty("size", ctx.size)("icon", ctx.nextArrowIcons[1])("svgIcon", ctx.nextArrowSVGIcons[1])("disabled", ctx.disabled)("title", ctx.textFor("lastPage"));
      i0.ɵɵattribute("aria-label", ctx.textFor("lastPage"));
    }
  },
  dependencies: [i3.ButtonComponent, PagerFocusableDirective],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerNextButtonsComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      selector: 'kendo-datapager-next-buttons',
      template: `
        <button kendoButton kendoPagerFocusable
            type="button"
            [size]="size"
            [icon]="nextArrowIcons[0]"
            [svgIcon]="nextArrowSVGIcons[0]"
            fillMode="flat"
            rounded="none"
            class="k-pager-nav"
            [disabled]="disabled"
            [title]="textFor('nextPage')"
            [attr.aria-label]="textFor('nextPage')"
            (click)="currentPage !== totalPages ? changePage(currentPage) : false">
        </button>
        <button kendoButton kendoPagerFocusable
            type="button"
            [size]="size"
            [icon]="nextArrowIcons[1]"
            [svgIcon]="nextArrowSVGIcons[1]"
            fillMode="flat"
            rounded="none"
            class="k-pager-nav k-pager-last"
            [disabled]="disabled"
            [title]="textFor('lastPage')"
            [attr.aria-label]="textFor('lastPage')"
            (click)="currentPage !== totalPages ? changePage(totalPages-1) : false">
        </button>
    `
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }, {
      type: PagerContextService
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    size: [{
      type: Input
    }]
  });
})();

/**
 * Displays an input element which allows the typing and rendering of page numbers.
 */
class PagerInputComponent extends PagerElementComponent {
  constructor(localization, pagerContext, zone, cd, renderer) {
    super(localization, pagerContext, cd);
    this.pagerContext = pagerContext;
    this.zone = zone;
    this.renderer = renderer;
    /**
     * Specifies the padding of the internal numeric input element.
     *
     * The possible values are:
     * * `small`
     * * `medium` (default)
     * * `large`
     * * `none`
     */
    this.size = DEFAULT_SIZE;
    /**
     * @hidden
     *
     * @param {string} value
     *
     * @memberOf PagerInputComponent
     */
    this.handleKeyDown = event => {
      const incomingValue = this.numericInput.value || this.current;
      // eslint-disable import/no-deprecated
      if (event.keyCode === Keys.Enter) {
        event.preventDefault();
        if (incomingValue !== this.current) {
          this.zone.run(() => {
            this.changePage(incomingValue - 1);
          });
        }
      }
    };
    /**
     * @hidden
     *
     * @param {string} value
     *
     * @memberOf PagerInputComponent
     */
    this.handleBlur = () => {
      const inputValue = this.numericInput.value;
      if (!inputValue) {
        this.numericInput.writeValue(this.current);
        return;
      }
      if (inputValue !== this.current) {
        this.zone.run(() => {
          this.changePage(inputValue - 1);
        });
      }
    };
  }
  /**
   * @hidden
   */
  get current() {
    return this.hasPages ? this.currentPage : 0;
  }
  /**
   * @hidden
   */
  get hasPages() {
    return this.totalPages !== 0;
  }
  ngAfterViewInit() {
    const innerNumericInput = this.numericInput.numericInput.nativeElement;
    this.renderer.setAttribute(innerNumericInput, 'aria-label', this.textFor('inputLabel'));
  }
  onChanges({
    total,
    skip,
    pageSize
  }) {
    this.total = total;
    this.skip = skip;
    this.pageSize = pageSize;
    this.cd.markForCheck();
  }
}
PagerInputComponent.ɵfac = function PagerInputComponent_Factory(t) {
  return new (t || PagerInputComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(PagerContextService), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.Renderer2));
};
PagerInputComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PagerInputComponent,
  selectors: [["kendo-datapager-input"]],
  viewQuery: function PagerInputComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(NumericTextBoxComponent, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.numericInput = _t.first);
    }
  },
  inputs: {
    size: "size"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 4,
  vars: 18,
  consts: [[3, "ngClass"], ["kendoPagerFocusable", "", "format", "n0", 3, "spinners", "decimals", "size", "disabled", "value", "min", "max", "autoCorrect", "title", "kendoEventsOutsideAngular"]],
  template: function PagerInputComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "span", 0);
      i0.ɵɵtext(1);
      i0.ɵɵelement(2, "kendo-numerictextbox", 1);
      i0.ɵɵtext(3);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction0(14, _c4));
      i0.ɵɵadvance();
      i0.ɵɵtextInterpolate1(" ", ctx.textFor("page"), " ");
      i0.ɵɵadvance();
      i0.ɵɵproperty("spinners", false)("decimals", 0)("size", ctx.size)("disabled", !ctx.hasPages)("value", ctx.current)("min", ctx.hasPages ? 1 : 0)("max", ctx.totalPages)("autoCorrect", true)("title", ctx.textFor("pageNumberInputTitle"))("kendoEventsOutsideAngular", i0.ɵɵpureFunction2(15, _c5, ctx.handleKeyDown, ctx.handleBlur));
      i0.ɵɵadvance();
      i0.ɵɵtextInterpolate2(" ", ctx.textFor("of"), " ", ctx.totalPages, " ");
    }
  },
  dependencies: [i3$2.NgClass, i4.NumericTextBoxComponent, i5.EventsOutsideAngularDirective, PagerFocusableDirective],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerInputComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-datapager-input',
      template: `
     <span [ngClass]="{'k-pager-input': true, 'k-label': true}">
        {{textFor('page')}}
        <kendo-numerictextbox kendoPagerFocusable
            [spinners]="false"
            [decimals]="0"
            format="n0"
            [size]="size"
            [disabled]="!hasPages"
            [value]="current"
            [min]="hasPages ? 1 : 0"
            [max]="totalPages"
            [autoCorrect]="true"
            [title]="textFor('pageNumberInputTitle')"
            [kendoEventsOutsideAngular]="{
                keydown: handleKeyDown,
                focusout: handleBlur
            }">
        </kendo-numerictextbox>
        {{textFor('of')}} {{totalPages}}
     </span>
    `
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }, {
      type: PagerContextService
    }, {
      type: i0.NgZone
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: i0.Renderer2
    }];
  }, {
    numericInput: [{
      type: ViewChild,
      args: [NumericTextBoxComponent, {
        static: true
      }]
    }],
    size: [{
      type: Input
    }]
  });
})();

// eslint-disable no-access-missing-member
/**
 * Displays information about the current page and the total number of records ([see example]({% slug pager_settings %})).
 */
class PagerInfoComponent extends PagerElementComponent {
  constructor(localization, cd, pagerContext) {
    super(localization, pagerContext, cd);
    this.pagerContext = pagerContext;
  }
  /**
   * @hidden
   *
   * @readonly
   * @type {number}
   * @memberOf PagerInfoComponent
   */
  get maxItems() {
    return Math.min(this.currentPage * this.pageSize, this.total);
  }
  /**
   * @hidden
   *
   * @readonly
   * @type {number}
   * @memberOf PagerInfoComponent
   */
  get currentPageText() {
    return this.total ? (this.currentPage - 1) * this.pageSize + 1 : 0;
  }
  /**
   * @hidden
   *
   * @readonly
   * @type {boolean}
   * @memberOf PagerInfoComponent
   */
  get classes() {
    return true;
  }
  onChanges({
    total,
    skip,
    pageSize
  }) {
    this.total = total;
    this.skip = skip;
    this.pageSize = pageSize;
    this.cd.markForCheck();
  }
}
PagerInfoComponent.ɵfac = function PagerInfoComponent_Factory(t) {
  return new (t || PagerInfoComponent)(i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(PagerContextService));
};
PagerInfoComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PagerInfoComponent,
  selectors: [["kendo-datapager-info"]],
  hostVars: 2,
  hostBindings: function PagerInfoComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("k-pager-info", ctx.classes);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 1,
  vars: 5,
  template: function PagerInfoComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtext(0);
    }
    if (rf & 2) {
      i0.ɵɵtextInterpolate5("", ctx.currentPageText, " - ", ctx.maxItems, " ", ctx.textFor("of"), " ", ctx.total, " ", ctx.textFor("items"), "");
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerInfoComponent, [{
    type: Component,
    args: [{
      changeDetection: ChangeDetectionStrategy.OnPush,
      selector: 'kendo-datapager-info',
      template: `{{currentPageText}} - {{maxItems}} {{textFor('of')}} {{total}} {{textFor('items')}}`
    }]
  }], function () {
    return [{
      type: i1.LocalizationService
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: PagerContextService
    }];
  }, {
    classes: [{
      type: HostBinding,
      args: ["class.k-pager-info"]
    }]
  });
})();

/**
 * @hidden
 */
const packageMetadata = {
  name: '@progress/kendo-angular-pager',
  productName: 'Kendo UI for Angular',
  productCodes: ['KENDOUIANGULAR', 'KENDOUICOMPLETE'],
  publishDate: 1717587824,
  version: '16.2.0',
  licensingDocsUrl: 'https://www.telerik.com/kendo-angular-ui/my-license/'
};

/**
 * @hidden
 */
const RESPONSIVE_BREAKPOINT_MEDIUM = 600;
/**
 * @hidden
 */
const RESPONSIVE_BREAKPOINT_LARGE = 768;
class PagerComponent {
  constructor(pagerContext, element, localization, renderer, ngZone, navigationService) {
    this.pagerContext = pagerContext;
    this.element = element;
    this.localization = localization;
    this.renderer = renderer;
    this.ngZone = ngZone;
    this.navigationService = navigationService;
    /**
     * Represents the total number of data items in the collection.
     */
    this.total = 0;
    /**
     * Defines the number of data items to be skipped.
     */
    this.skip = 0;
    /**
     * Sets the maximum numeric buttons count before the buttons are collapsed.
     */
    this.buttonCount = 10;
    /**
     * Toggles the information about the current page and the total number of records.
     */
    this.info = true;
    /**
     * Defines the type of the pager.
     */
    this.type = 'numeric';
    /**
     * Toggles the **Previous** and **Next** buttons.
     */
    this.previousNext = true;
    /**
     * Fires when the current page of the Pager is changed ([see example]({% slug overview_pager %}#toc-basic-usage)).
     * You have to handle the event yourself and page the data.
     */
    this.pageChange = new EventEmitter();
    /**
     * Fires when the page size of the Pager is changed.
     * You have to handle the event yourself and page the data.
     * If the event is prevented, the page size will remain unchanged ([see example]({% slug pager_events %})).
     */
    this.pageSizeChange = new EventEmitter();
    this.pagerClass = true;
    this.widgetRole = 'application';
    this.roleDescription = 'pager';
    this.keyShortcuts = 'Enter ArrowRight ArrowLeft';
    this.hostTabindex = '0';
    this.subscriptions = new Subscription();
    this._templateContext = {};
    this._pageSizeValues = DEFAULT_PAGE_SIZE_VALUES;
    this.isInnerNavigationEnabled = false;
    this._navigable = false;
    this._size = DEFAULT_SIZE;
    this.resizeHandler = () => {
      if (this.template?.first) {
        return;
      }
      const width = this.element.nativeElement.offsetWidth;
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          if (this.numericButtons) {
            const selectElement = this.numericButtons.selectElement.nativeElement;
            const numbersElement = this.numericButtons.numbersElement.nativeElement;
            this.renderer.removeStyle(numbersElement, 'display');
            this.renderer.setStyle(selectElement, 'display', 'none');
            this.renderer.addClass(selectElement, 'k-hidden');
            if (width < RESPONSIVE_BREAKPOINT_MEDIUM) {
              this.renderer.removeStyle(selectElement, 'display');
              this.renderer.removeClass(selectElement, 'k-hidden');
              this.renderer.setStyle(numbersElement, 'display', 'none');
            } else {
              this.renderer.addClass(selectElement, 'k-hidden');
            }
          }
          if (this.pagerInfo) {
            this.renderer.removeStyle(this.pagerInfo.nativeElement, 'display');
            if (width < RESPONSIVE_BREAKPOINT_LARGE) {
              this.renderer.setStyle(this.pagerInfo.nativeElement, 'display', 'none');
            }
          }
          if (this.pagerPageSizes) {
            this.renderer.removeStyle(this.pagerPageSizes.nativeElement, 'display');
            if (width < RESPONSIVE_BREAKPOINT_MEDIUM) {
              this.renderer.setStyle(this.pagerPageSizes.nativeElement, 'display', 'none');
            }
          }
        });
      });
    };
    validatePackage(packageMetadata);
    this.direction = localization.rtl ? 'rtl' : 'ltr';
  }
  /**
   * Shows a dropdown for selecting the page size.
   * When set to `true`, the dropdown will contain the default list of options - 5, 10, 20.
   * To customize the list of options, set `pageSizeValues` to an array of the desired values.
   * The array can contain numbers and [PageSizeItem]({% slug api_pager_pagesizeitem %}) objects.
   */
  set pageSizeValues(pageSizeValues) {
    this._pageSizeValues = Array.isArray(pageSizeValues) ? pageSizeValues : pageSizeValues ? DEFAULT_PAGE_SIZE_VALUES : false;
  }
  get pageSizeValues() {
    return this._pageSizeValues;
  }
  /**
   * If set to `true`, the user can use dedicated shortcuts to interact with the Pager ([see example]({% slug keyboard_navigation_pager %})).
   * By default, navigation is disabled and the Pager content is accessible in the normal tab sequence.
   * @default false
   */
  set navigable(value) {
    this._navigable = value;
    this.navigationService.isNavigable = value;
  }
  get navigable() {
    return this._navigable;
  }
  /**
   * Specifies the padding of all Pager elements.
   *
   * The possible values are:
   * * `small`
   * * `medium` (default)
   * * `large`
   * * `none`
   */
  set size(size) {
    const newSize = size ? size : DEFAULT_SIZE;
    this.handleClasses(newSize, 'size');
    this._size = newSize;
  }
  get size() {
    return this._size;
  }
  get dir() {
    return this.direction;
  }
  /**
   * @hidden
   */
  focusHandler(ev) {
    const isInnerNavigationEnabled = ev.target !== this.element.nativeElement;
    this.navigationService.toggleInnerNavigation(isInnerNavigationEnabled);
  }
  get totalPages() {
    return Math.ceil((this.total || 0) / this.pageSize);
  }
  get currentPage() {
    return Math.floor((this.skip || 0) / this.pageSize) + 1;
  }
  get templateContext() {
    const context = this._templateContext;
    context.totalPages = this.totalPages;
    context.total = this.total;
    context.skip = this.skip;
    context.pageSize = this.pageSize;
    context.currentPage = this.currentPage;
    return context;
  }
  ngOnInit() {
    this.subscriptions.add(this.pagerContext.pageChange.subscribe(this.changePage.bind(this)));
    this.subscriptions.add(this.pagerContext.pageSizeChange.subscribe(this.changePageSize.bind(this)));
    this.subscriptions.add(this.resizeSensor.resize.subscribe(() => this.resizeHandler()));
    this.subscriptions.add(this.localization.changes.subscribe(({
      rtl
    }) => {
      this.direction = rtl ? 'rtl' : 'ltr';
    }));
    this.subscriptions.add(this.navigationService.innerNavigationChange.subscribe(this.innerNavigationChange.bind(this)));
    if (this.navigable) {
      const wrapper = this.element.nativeElement;
      this.ngZone.runOutsideAngular(() => {
        this.subscriptions.add(this.renderer.listen(wrapper, 'keydown', this.keyDownHandler.bind(this)));
      });
    }
  }
  ngAfterViewInit() {
    this.resizeHandler();
    this.renderer.setAttribute(this.element.nativeElement, 'aria-label', this.ariaLabel);
    this.subscriptions.add(this.template.changes.subscribe(() => {
      this.resizeHandler();
    }));
    this.handleClasses(this.size, 'size');
  }
  ngOnChanges(changes) {
    if (anyChanged(["pageSize", "skip", "total"], changes, false)) {
      this.pagerContext.notifyChanges({
        pageSize: this.pageSize,
        skip: this.skip,
        total: this.total
      });
      this.renderer.setAttribute(this.element.nativeElement, 'aria-label', this.ariaLabel);
    }
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  /**
   * @hidden
   */
  changePage(event) {
    this.pageChange.emit(event);
  }
  /**
   * @hidden
   */
  changePageSize(event) {
    this.pageSizeChange.emit(event);
    if (!event.isDefaultPrevented()) {
      if (event.newPageSize === 'all') {
        this.pageChange.emit({
          skip: 0,
          take: this.total
        });
      } else {
        this.pageChange.emit({
          skip: 0,
          take: event.newPageSize
        });
      }
    }
  }
  /**
   * @hidden
   */
  onPageSizeChange(event) {
    this.pageSizeChange.emit(event);
    if (!event.isDefaultPrevented()) {
      this.pageChange.emit({
        skip: this.skip,
        take: event.newPageSize
      });
    }
  }
  textFor(value) {
    return this.localization.get(value);
  }
  get ariaLabel() {
    return `${this.textFor('page')} ${this.currentPage} ${this.textFor('of')} ${this.totalPages}`;
  }
  keyDownHandler(e) {
    const target = e.target;
    const wrapper = this.element.nativeElement;
    const isArrowLeftOrPageUp = e.keyCode === Keys.ArrowLeft || e.keyCode === Keys.PageUp;
    const isArrowRightOrPageDown = e.keyCode === Keys.ArrowRight || e.keyCode === Keys.PageDown;
    const isEnter = e.keyCode === Keys.Enter;
    const isHome = e.keyCode === Keys.Home;
    const isEnd = e.keyCode === Keys.End;
    const isEsc = e.keyCode === Keys.Escape;
    const isTab = e.keyCode === Keys.Tab;
    const isFirstPage = this.currentPage === 1;
    const isLastPage = this.currentPage === this.totalPages;
    this.ngZone.run(() => {
      if (isHome) {
        e.preventDefault();
        !isFirstPage && this.pagerContext.changePage(0);
      } else if (isEnd) {
        e.preventDefault();
        !isLastPage && this.pagerContext.changePage(this.totalPages - 1);
      } else if (this.isInnerNavigationEnabled) {
        if (isEsc) {
          this.navigationService.toggleInnerNavigation(false);
          wrapper.focus();
        } else if (isTab) {
          this.navigationService.keepFocusWithinComponent(wrapper, target, e);
        }
      } else {
        if (isArrowLeftOrPageUp) {
          e.preventDefault();
          !isFirstPage && this.pagerContext.prevPage();
        } else if (isArrowRightOrPageDown) {
          e.preventDefault();
          !isLastPage && this.pagerContext.nextPage();
        } else if (isEnter) {
          if (e.target !== wrapper) {
            return;
          }
          e.preventDefault();
          const [firstFocusable] = this.navigationService.getFirstAndLastFocusable(wrapper);
          this.navigationService.toggleInnerNavigation(true);
          firstFocusable?.focus();
        }
      }
    });
  }
  innerNavigationChange(value) {
    this.isInnerNavigationEnabled = value;
  }
  handleClasses(value, input) {
    const elem = this.element.nativeElement;
    const classes = getStylingClasses('pager', input, this[input], value);
    if (classes.toRemove) {
      this.renderer.removeClass(elem, classes.toRemove);
    }
    if (classes.toAdd) {
      this.renderer.addClass(elem, classes.toAdd);
    }
  }
}
PagerComponent.ɵfac = function PagerComponent_Factory(t) {
  return new (t || PagerComponent)(i0.ɵɵdirectiveInject(PagerContextService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.LocalizationService), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(PagerNavigationService));
};
PagerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PagerComponent,
  selectors: [["kendo-datapager"]],
  contentQueries: function PagerComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, PagerTemplateDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.template = _t);
    }
  },
  viewQuery: function PagerComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(ResizeSensorComponent, 7);
      i0.ɵɵviewQuery(PagerInfoComponent, 5, ElementRef);
      i0.ɵɵviewQuery(PagerPageSizesComponent, 5, ElementRef);
      i0.ɵɵviewQuery(PagerNumericButtonsComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.resizeSensor = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.pagerInfo = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.pagerPageSizes = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.numericButtons = _t.first);
    }
  },
  hostVars: 7,
  hostBindings: function PagerComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("focusin", function PagerComponent_focusin_HostBindingHandler($event) {
        return ctx.focusHandler($event);
      });
    }
    if (rf & 2) {
      i0.ɵɵattribute("role", ctx.widgetRole)("aria-roledescription", ctx.roleDescription)("aria-keyshortcuts", ctx.keyShortcuts)("tabindex", ctx.hostTabindex)("dir", ctx.dir);
      i0.ɵɵclassProp("k-pager", ctx.pagerClass);
    }
  },
  inputs: {
    total: "total",
    skip: "skip",
    pageSize: "pageSize",
    buttonCount: "buttonCount",
    info: "info",
    type: "type",
    pageSizeValues: "pageSizeValues",
    previousNext: "previousNext",
    navigable: "navigable",
    size: "size"
  },
  outputs: {
    pageChange: "pageChange",
    pageSizeChange: "pageSizeChange"
  },
  exportAs: ["kendoDataPager"],
  features: [i0.ɵɵProvidersFeature([LocalizationService, PagerContextService, PagerNavigationService]), i0.ɵɵNgOnChangesFeature],
  decls: 4,
  vars: 2,
  consts: () => {
    let i18n_0;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The label for the first page button in the Pager
       * @meaning kendo.pager.firstPage
       */
      const MSG__CODEBUILD_OUTPUT_SRC2378492421_SRC_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2020_PROGRESS_KENDO_ANGULAR_PAGER_MJS_0 = goog.getMsg("Go to the first page");
      i18n_0 = MSG__CODEBUILD_OUTPUT_SRC2378492421_SRC_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2020_PROGRESS_KENDO_ANGULAR_PAGER_MJS_0;
    } else {
      i18n_0 = $localize`:kendo.pager.firstPage|The label for the first page button in the Pager:Go to the first page`;
    }
    let i18n_1;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The label for the previous page button in the Pager
       * @meaning kendo.pager.previousPage
       */
      const MSG__CODEBUILD_OUTPUT_SRC2378492421_SRC_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2020_PROGRESS_KENDO_ANGULAR_PAGER_MJS_1 = goog.getMsg("Go to the previous page");
      i18n_1 = MSG__CODEBUILD_OUTPUT_SRC2378492421_SRC_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2020_PROGRESS_KENDO_ANGULAR_PAGER_MJS_1;
    } else {
      i18n_1 = $localize`:kendo.pager.previousPage|The label for the previous page button in the Pager:Go to the previous page`;
    }
    let i18n_2;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The label for the next page button in the Pager
       * @meaning kendo.pager.nextPage
       */
      const MSG__CODEBUILD_OUTPUT_SRC2378492421_SRC_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2020_PROGRESS_KENDO_ANGULAR_PAGER_MJS_2 = goog.getMsg("Go to the next page");
      i18n_2 = MSG__CODEBUILD_OUTPUT_SRC2378492421_SRC_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2020_PROGRESS_KENDO_ANGULAR_PAGER_MJS_2;
    } else {
      i18n_2 = $localize`:kendo.pager.nextPage|The label for the next page button in the Pager:Go to the next page`;
    }
    let i18n_3;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The label for the last page button in the Pager
       * @meaning kendo.pager.lastPage
       */
      const MSG__CODEBUILD_OUTPUT_SRC2378492421_SRC_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2020_PROGRESS_KENDO_ANGULAR_PAGER_MJS_3 = goog.getMsg("Go to the last page");
      i18n_3 = MSG__CODEBUILD_OUTPUT_SRC2378492421_SRC_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2020_PROGRESS_KENDO_ANGULAR_PAGER_MJS_3;
    } else {
      i18n_3 = $localize`:kendo.pager.lastPage|The label for the last page button in the Pager:Go to the last page`;
    }
    let i18n_4;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The label before the current page number in the Pager
       * @meaning kendo.pager.page
       */
      const MSG__CODEBUILD_OUTPUT_SRC2378492421_SRC_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2020_PROGRESS_KENDO_ANGULAR_PAGER_MJS_4 = goog.getMsg("Page");
      i18n_4 = MSG__CODEBUILD_OUTPUT_SRC2378492421_SRC_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2020_PROGRESS_KENDO_ANGULAR_PAGER_MJS_4;
    } else {
      i18n_4 = $localize`:kendo.pager.page|The label before the current page number in the Pager:Page`;
    }
    let i18n_5;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The label before the total pages number in the Pager
       * @meaning kendo.pager.of
       */
      const MSG__CODEBUILD_OUTPUT_SRC2378492421_SRC_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2020_PROGRESS_KENDO_ANGULAR_PAGER_MJS_5 = goog.getMsg("of");
      i18n_5 = MSG__CODEBUILD_OUTPUT_SRC2378492421_SRC_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2020_PROGRESS_KENDO_ANGULAR_PAGER_MJS_5;
    } else {
      i18n_5 = $localize`:kendo.pager.of|The label before the total pages number in the Pager:of`;
    }
    let i18n_6;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The label for the pager input in the Pager
       * @meaning kendo.pager.pageNumberInputTitle
       */
      const MSG__CODEBUILD_OUTPUT_SRC2378492421_SRC_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2020_PROGRESS_KENDO_ANGULAR_PAGER_MJS_6 = goog.getMsg("Page Number");
      i18n_6 = MSG__CODEBUILD_OUTPUT_SRC2378492421_SRC_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2020_PROGRESS_KENDO_ANGULAR_PAGER_MJS_6;
    } else {
      i18n_6 = $localize`:kendo.pager.pageNumberInputTitle|The label for the pager input in the Pager:Page Number`;
    }
    let i18n_7;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The label after the total pages number in the Pager
       * @meaning kendo.pager.items
       */
      const MSG__CODEBUILD_OUTPUT_SRC2378492421_SRC_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2020_PROGRESS_KENDO_ANGULAR_PAGER_MJS_7 = goog.getMsg("items");
      i18n_7 = MSG__CODEBUILD_OUTPUT_SRC2378492421_SRC_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2020_PROGRESS_KENDO_ANGULAR_PAGER_MJS_7;
    } else {
      i18n_7 = $localize`:kendo.pager.items|The label after the total pages number in the Pager:items`;
    }
    let i18n_8;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The label for the page size chooser in the Pager
       * @meaning kendo.pager.itemsPerPage
       */
      const MSG__CODEBUILD_OUTPUT_SRC2378492421_SRC_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2020_PROGRESS_KENDO_ANGULAR_PAGER_MJS_8 = goog.getMsg("items per page");
      i18n_8 = MSG__CODEBUILD_OUTPUT_SRC2378492421_SRC_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2020_PROGRESS_KENDO_ANGULAR_PAGER_MJS_8;
    } else {
      i18n_8 = $localize`:kendo.pager.itemsPerPage|The label for the page size chooser in the Pager:items per page`;
    }
    let i18n_9;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the title and aria-label attributes applied to the page chooser in the Pager
       * @meaning kendo.pager.selectPage
       */
      const MSG__CODEBUILD_OUTPUT_SRC2378492421_SRC_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2020_PROGRESS_KENDO_ANGULAR_PAGER_MJS_9 = goog.getMsg("Select page");
      i18n_9 = MSG__CODEBUILD_OUTPUT_SRC2378492421_SRC_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2020_PROGRESS_KENDO_ANGULAR_PAGER_MJS_9;
    } else {
      i18n_9 = $localize`:kendo.pager.selectPage|The text of the title and aria-label attributes applied to the page chooser in the Pager:Select page`;
    }
    let i18n_10;
    if (typeof ngI18nClosureMode !== "undefined" && ngI18nClosureMode) {
      /**
       * @desc The text of the aria-label attribute applied to the input element for entering the page number.
       * @meaning kendo.pager.inputLabel
       */
      const MSG__CODEBUILD_OUTPUT_SRC2378492421_SRC_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2020_PROGRESS_KENDO_ANGULAR_PAGER_MJS_10 = goog.getMsg("Type a page number");
      i18n_10 = MSG__CODEBUILD_OUTPUT_SRC2378492421_SRC_NODE_MODULES__PROGRESS_KENDO_ANGULAR_PAGER_FESM2020_PROGRESS_KENDO_ANGULAR_PAGER_MJS_10;
    } else {
      i18n_10 = $localize`:kendo.pager.inputLabel|The text of the aria-label attribute applied to the input element for entering the page number.:Type a page number`;
    }
    return [["kendoDataPagerLocalizedMessages", "", "firstPage", i18n_0, "previousPage", i18n_1, "nextPage", i18n_2, "lastPage", i18n_3, "page", i18n_4, "of", i18n_5, "pageNumberInputTitle", i18n_6, "items", i18n_7, "itemsPerPage", i18n_8, "selectPage", i18n_9, "inputLabel", i18n_10], [3, "ngTemplateOutlet", "ngTemplateOutletContext", 4, "ngIf"], [4, "ngIf"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"], [1, "k-pager-numbers-wrap"], [3, "size", 4, "ngIf"], [3, "size", "buttonCount", 4, "ngIf"], [3, "size", "pageSizes", 4, "ngIf"], [3, "size"], [3, "size", "buttonCount"], [3, "size", "pageSizes"]];
  },
  template: function PagerComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementContainer(0, 0);
      i0.ɵɵtemplate(1, PagerComponent_ng_container_1_Template, 1, 2, "ng-container", 1)(2, PagerComponent_ng_container_2_Template, 8, 6, "ng-container", 2);
      i0.ɵɵelement(3, "kendo-resize-sensor");
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.template.first == null ? null : ctx.template.first.templateRef);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", !(ctx.template == null ? null : ctx.template.first == null ? null : ctx.template.first.templateRef));
    }
  },
  dependencies: [i3$2.NgIf, i3$2.NgTemplateOutlet, i5.ResizeSensorComponent, PagerPrevButtonsComponent, PagerNextButtonsComponent, PagerNumericButtonsComponent, PagerInputComponent, PagerInfoComponent, PagerPageSizesComponent, LocalizedMessagesDirective],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerComponent, [{
    type: Component,
    args: [{
      selector: 'kendo-datapager',
      exportAs: 'kendoDataPager',
      providers: [LocalizationService, PagerContextService, PagerNavigationService],
      template: `
        <ng-container kendoDataPagerLocalizedMessages
            i18n-firstPage="kendo.pager.firstPage|The label for the first page button in the Pager"
            firstPage="Go to the first page"

            i18n-previousPage="kendo.pager.previousPage|The label for the previous page button in the Pager"
            previousPage="Go to the previous page"

            i18n-nextPage="kendo.pager.nextPage|The label for the next page button in the Pager"
            nextPage="Go to the next page"

            i18n-lastPage="kendo.pager.lastPage|The label for the last page button in the Pager"
            lastPage="Go to the last page"

            i18n-page="kendo.pager.page|The label before the current page number in the Pager"
            page="Page"

            i18n-of="kendo.pager.of|The label before the total pages number in the Pager"
            of="of"

            i18n-pageNumberInputTitle="kendo.pager.pageNumberInputTitle|The label for the pager input in the Pager"
            pageNumberInputTitle="Page Number"

            i18n-items="kendo.pager.items|The label after the total pages number in the Pager"
            items="items"

            i18n-itemsPerPage="kendo.pager.itemsPerPage|The label for the page size chooser in the Pager"
            itemsPerPage="items per page"

            i18n-selectPage="kendo.pager.selectPage|The text of the title and aria-label attributes applied to the page chooser in the Pager"
            selectPage="Select page"

            i18n-inputLabel="kendo.pager.inputLabel|The text of the aria-label attribute applied to the input element for entering the page number."
            inputLabel="Type a page number"
         >
        </ng-container>
        <ng-container
            *ngIf="template.first?.templateRef"
            [ngTemplateOutlet]="template.first?.templateRef"
            [ngTemplateOutletContext]="templateContext">
        </ng-container>
        <ng-container *ngIf="!template?.first?.templateRef">
            <div class="k-pager-numbers-wrap">
                <kendo-datapager-prev-buttons [size]="size" *ngIf="previousNext"></kendo-datapager-prev-buttons>
                <kendo-datapager-numeric-buttons
                    [size]="size"
                    *ngIf="type === 'numeric' && buttonCount > 0"
                    [buttonCount]="buttonCount">
                </kendo-datapager-numeric-buttons>
                <kendo-datapager-input [size]="size" *ngIf="type === 'input'"></kendo-datapager-input>
                <kendo-datapager-next-buttons [size]="size" *ngIf="previousNext"></kendo-datapager-next-buttons>
            </div>
            <kendo-datapager-page-sizes *ngIf="pageSizeValues"
                [size]="size"
                [pageSizes]="pageSizeValues">
            </kendo-datapager-page-sizes>
            <kendo-datapager-info *ngIf='info'>
            </kendo-datapager-info>
        </ng-container>
        <kendo-resize-sensor></kendo-resize-sensor>
  `
    }]
  }], function () {
    return [{
      type: PagerContextService
    }, {
      type: i0.ElementRef
    }, {
      type: i1.LocalizationService
    }, {
      type: i0.Renderer2
    }, {
      type: i0.NgZone
    }, {
      type: PagerNavigationService
    }];
  }, {
    resizeSensor: [{
      type: ViewChild,
      args: [ResizeSensorComponent, {
        static: true
      }]
    }],
    pagerInfo: [{
      type: ViewChild,
      args: [PagerInfoComponent, {
        read: ElementRef
      }]
    }],
    pagerPageSizes: [{
      type: ViewChild,
      args: [PagerPageSizesComponent, {
        read: ElementRef
      }]
    }],
    numericButtons: [{
      type: ViewChild,
      args: [PagerNumericButtonsComponent]
    }],
    template: [{
      type: ContentChildren,
      args: [PagerTemplateDirective]
    }],
    total: [{
      type: Input
    }],
    skip: [{
      type: Input
    }],
    pageSize: [{
      type: Input
    }],
    buttonCount: [{
      type: Input
    }],
    info: [{
      type: Input
    }],
    type: [{
      type: Input
    }],
    pageSizeValues: [{
      type: Input
    }],
    previousNext: [{
      type: Input
    }],
    navigable: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    pageChange: [{
      type: Output
    }],
    pageSizeChange: [{
      type: Output
    }],
    pagerClass: [{
      type: HostBinding,
      args: ['class.k-pager']
    }],
    widgetRole: [{
      type: HostBinding,
      args: ['attr.role']
    }],
    roleDescription: [{
      type: HostBinding,
      args: ['attr.aria-roledescription']
    }],
    keyShortcuts: [{
      type: HostBinding,
      args: ['attr.aria-keyshortcuts']
    }],
    hostTabindex: [{
      type: HostBinding,
      args: ['attr.tabindex']
    }],
    dir: [{
      type: HostBinding,
      args: ['attr.dir']
    }],
    focusHandler: [{
      type: HostListener,
      args: ['focusin', ['$event']]
    }]
  });
})();
const importedModules = [CommonModule, NumericTextBoxModule, DropDownListModule, ButtonsModule, EventsModule, ResizeSensorModule];
const INTERNAL_COMPONENTS = [PagerComponent, PagerPrevButtonsComponent, PagerNextButtonsComponent, PagerNumericButtonsComponent, PagerInputComponent, PagerInfoComponent, PagerPageSizesComponent, PagerTemplateDirective, LocalizedMessagesDirective, CustomMessagesComponent, PagerFocusableDirective];
const providers = [{
  provide: L10N_PREFIX,
  useValue: 'kendo.pager'
}];
/**
 * @hidden
 */
class PagerModule {
  static exports() {
    return [PagerComponent, PagerPrevButtonsComponent, PagerNextButtonsComponent, PagerNumericButtonsComponent, PagerInputComponent, PagerInfoComponent, PagerPageSizesComponent, PagerTemplateDirective];
  }
}
PagerModule.ɵfac = function PagerModule_Factory(t) {
  return new (t || PagerModule)();
};
PagerModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: PagerModule
});
PagerModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [providers],
  imports: [importedModules]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PagerModule, [{
    type: NgModule,
    args: [{
      declarations: [INTERNAL_COMPONENTS],
      exports: [INTERNAL_COMPONENTS],
      imports: [...importedModules],
      providers: [providers]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CustomMessagesComponent, LocalizedMessagesDirective, PageSizeChangeEvent, PagerComponent, PagerFocusableDirective, PagerInfoComponent, PagerInputComponent, PagerModule, PagerNextButtonsComponent, PagerNumericButtonsComponent, PagerPageSizesComponent, PagerPrevButtonsComponent, PagerTemplateDirective };