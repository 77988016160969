import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SEGMENTTYPE_SETUP_STATE, SEGMENTTYPE_SETUP_STATE_TYPE_SET, SEGMENTTYPE_SETUP_STATE_TYPE_SET_REMOVE } from '../../shared/constants';
export interface SegmentTypeStateModel {
  selectedSegmentType: string[];
}
export class SetSegmentTypeState {
  static readonly type = SEGMENTTYPE_SETUP_STATE_TYPE_SET;
  constructor(public selectedSegmentType: string[]) {}
}
export class RemoveSegmentTypeState {
  static readonly type = SEGMENTTYPE_SETUP_STATE_TYPE_SET_REMOVE;
}
@State<SegmentTypeStateModel>({
  name: SEGMENTTYPE_SETUP_STATE,
  defaults: {
    selectedSegmentType: [],
  },
})
@Injectable()
export class SegmentTypeState {
  //selector has logic to get data

  @Selector()
  static getSelectedSegmentType(state: SegmentTypeStateModel) {
    return state.selectedSegmentType;
  }

  //actions to do operation
  @Action(SetSegmentTypeState)
  SetSegmentTypeState(
    { patchState }: StateContext<SegmentTypeStateModel>,
    { selectedSegmentType }: SetSegmentTypeState,
  ): void {
    patchState({
      selectedSegmentType: selectedSegmentType,
    });
  }
  @Action(RemoveSegmentTypeState)
  RemoveSegmentTypeState({ setState }: StateContext<SegmentTypeStateModel>): void {
    setState({
      selectedSegmentType: [],
    });
  }
}
