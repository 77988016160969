import { generateCustomQueriesProperty, generateCustomMutationsProperty, generateCustomSubscriptionsProperty } from './internals/generateCustomOperationsProperty.mjs';
import { generateEnumsProperty } from './internals/utils/clientProperties/generateEnumsProperty.mjs';
import { generateModelsProperty } from './internals/utils/clientProperties/generateModelsProperty.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
function addSchemaToClient(client, apiGraphqlConfig, getInternals) {
    client.models = generateModelsProperty(client, apiGraphqlConfig, getInternals);
    client.enums = generateEnumsProperty(apiGraphqlConfig);
    client.queries = generateCustomQueriesProperty(client, apiGraphqlConfig, getInternals);
    client.mutations = generateCustomMutationsProperty(client, apiGraphqlConfig, getInternals);
    client.subscriptions = generateCustomSubscriptionsProperty(client, apiGraphqlConfig, getInternals);
    return client;
}

export { addSchemaToClient };

