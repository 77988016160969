<div class="card">
  <div class="card-body-no-history">
    <p class="message">You don't have access to this page</p>
    <button
      (click)="goBack()"
      class="go-back btn btn-secondary mtx-btn-secondary mtx-btn-custom"
    >
      Go Back
    </button>
  </div>
</div>
