<div class="card template-form">
  <div class="card-body">
    <form [formGroup]="form" class="row g-4">
      <div class="col-md-4">
        <div class="label-container">
          <label for="inputTemplateName" class="form-label">{{'CREATE_TEMPLATE.TEMPLATE_NAME' | translate}}<span class="asterisk">*</span></label>
          <div *ngIf="
              form.get('templateName')?.hasError('required') &&
              form.get('templateName')?.touched
            ">
            <small class="text-danger">{{'CREATE_TEMPLATE.TEMPLATE_NAME_IS_REQUIRED' | translate}}</small>
          </div>
        </div>
        <input maxlength="80" type="text" class="form-control" id="templateName" formControlName="templateName"
          placeholder="Enter Template Name" />
      </div>
      <div class="col-md-4">
        <div class="label-container">
          <label for="inputReportType" class="form-label">{{'CREATE_TEMPLATE.REPORT_TYPE' | translate}}<span class="asterisk">*</span></label>
          <div *ngIf="
              form.get('reportType')?.hasError('required') &&
              form.get('reportType')?.touched
            ">
            <small class="text-danger">{{'CREATE_TEMPLATE.REPORT_TYPE_IS_REQUIRED' | translate}}</small>
          </div>
        </div>

        <select class="form-select" aria-label="Report Type" formControlName="reportType" (change)="updateTiles()">
          <option value="">{{'CREATE_TEMPLATE.SELECT_REPORT_TYPE' | translate}}</option>
          <option *ngFor="let type of reportTypes" [value]="type">
            {{ type }}
          </option>
        </select>
      </div>
      <div class="col-md-4">
        <label for="inputTemplateName" class="form-label">{{'CREATE_TEMPLATE.TEMPLATE_FORMAT' | translate}}</label>
        <button class="btn btn-light dropdown-toggle btn-template" type="button" id="dropdownMenuButton"
          data-bs-toggle="dropdown" aria-expanded="false">
          <span style="color: #3498db"> {{'CREATE_TEMPLATE.SELECT_TEMPLATE_COLUMNS' | translate}}</span>
        </button>
        <ul class="dropdown-menu" style="width: 270px" aria-labelledby="dropdownMenuButton">
          <li class="dropdown-item" *ngIf="allColumnsSelected()">
            {{'CREATE_TEMPLATE.ALL_COLUMNS_ARE_SELECTED' | translate}}
          </li>
          <li class="dropdown-item" *ngFor="let item of getFilteredColumns()" [hidden]="selectedItems[item]">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" [id]="item" [value]="item" [checked]="selectedItems[item]"
                (change)="onCheckboxChange(item, $event)" />
              <label class="form-check-label" [for]="item">
                {{ getColumnDisplayName(item) }}
              </label>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-8">
        <label for="reportDescription" class="form-label">{{'CREATE_TEMPLATE.REPORT_DESCRIPTION' | translate}}</label>
        <textarea class="form-control" id="reportDescription" rows="3" formControlName="reportDescription"></textarea>
      </div>
      <div class="col-12">
        <div class="col-12 column-container">
          <div *ngFor="let selectedItem of getSelectedColumns()" class="badge column-tile me-2"
            style="width: 206px; height: 60px">
            <div class="column-name">
              {{ getColumnDisplayName(selectedItem) }}
              <button type="button" class="btn-close ms-2 p-3" aria-label="Close"
                (click)="removeColumn(selectedItem)"></button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="card-footer d-grid gap-2 d-md-flex justify-content-md-end">
    <button class="btn me-md-2 button btn-cancel" type="button" (click)="cancel()">
      {{'CREATE_TEMPLATE.CANCEL' | translate}}
    </button>
    <button class="btn button mtx-btn-primary" type="submit" (click)="save()">
      {{'CREATE_TEMPLATE.SAVE_TEMPLATE' | translate}}
    </button>
  </div>
</div>