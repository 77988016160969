import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export function levelValidator(translate: TranslateService): ValidatorFn {
  return (form: AbstractControl): ValidationErrors | null => {
    const level1 = form.get('level1')?.value?.trim();
    const level2 = form.get('level2')?.value?.trim();
    const level3 = form.get('level3')?.value?.trim();

    form.get('level1')?.setErrors(null);
    form.get('level2')?.setErrors(null);
    form.get('level3')?.setErrors(null);

    let hasError = false;

    if (level1 && level2 && level1 === level2) {
      form.get('level1')?.setErrors({
        sameLevelValue: translate.instant('HEIRARCHY_SETUP_LEVEL.LEVEL_12_DUP'),
      });
      form.get('level2')?.setErrors({
        sameLevelValue: translate.instant('HEIRARCHY_SETUP_LEVEL.LEVEL_21_DUP'),
      });
      hasError = true;
    }
    if (level1 && level3 && level1 === level3) {
      form.get('level1')?.setErrors({
        sameLevelValue: translate.instant('HEIRARCHY_SETUP_LEVEL.LEVEL_13_DUP'),
      });
      form.get('level3')?.setErrors({
        sameLevelValue: translate.instant('HEIRARCHY_SETUP_LEVEL.LEVEL_31_DUP'),
      });
      hasError = true;
    }
    if (level2 && level3 && level2 === level3) {
      form.get('level2')?.setErrors({
        sameLevelValue: translate.instant('HEIRARCHY_SETUP_LEVEL.LEVEL_23_DUP'),
      });
      form.get('level3')?.setErrors({
        sameLevelValue: translate.instant('HEIRARCHY_SETUP_LEVEL.LEVEL_32_DUP'),
      });
      hasError = true;
    }

    if (level1 && !level2 && !level3) {
      return { level2Required: true };
    }
    if (level2 && !level3) {
      return { level3Required: true };
    }
    if (level1 && level3 && !level2) {
      return { level2RequiredForLevel3: true };
    }
    if (!level3) {
      return { level3isRequired: true };
    }

   return hasError ? { sameLevelValue: true } : null;
  };
}
