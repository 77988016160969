<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>

<mat-dialog-content class="add-route-dialog">
  <div class="dialog-header">
    <div class="route-header-icon"></div>
    <div class="top-close-button col-2" (click)="close()"></div>
  </div>

  <!-- Conditionally change h2 and p content -->
  <h2 class="text-alignment">
    {{
      isEditMode
        ? ('ADD_SURVEY_ROUTE_MODAL.EDIT_SURVEY_ROUTE' | translate)
        : ('ADD_SURVEY_ROUTE_MODAL.CREATE_NEW_SURVEY_ROUTE' | translate)
    }}
  </h2>
  <p style="color: #8e8e8e">
    {{
      isEditMode
        ? ('ADD_SURVEY_ROUTE_MODAL.EDIT_SURVEY_ROUTE_SUBHEADER' | translate)
        : ('ADD_SURVEY_ROUTE_MODAL.CREATE_NEW_SURVEY_ROUTE' | translate)
    }}
  </p>

  <form [formGroup]="createRouteForm">
    <div class="col-md-12">
      <div class="mb-3">
        <div class="label-container">
          <label for="routeName" class="form-label">
            {{ 'ADD_SURVEY_ROUTE_MODAL.ROUTE_NAME' | translate }}
            <span class="asterisk">*</span>
          </label>
          <div
            *ngIf="
              createRouteForm.get('routeName')?.hasError('required') &&
              createRouteForm.get('routeName')?.touched
            "
          >
            <small class="text-danger">{{
              'ADD_SURVEY_ROUTE_MODAL.ROUTE_NAME_REQUIRED' | translate
            }}</small>
          </div>
          <div
            *ngIf="
              createRouteForm.get('routeName')?.hasError('nameExists') &&
              createRouteForm.get('routeName')?.touched
            ">
            <small class="text-danger">Route Name already exists</small>
          </div>
        </div>
        <input
          type="text"
          placeholder="{{
            'ADD_SURVEY_ROUTE_MODAL.ROUTE_NAME_PLACEHOLDER' | translate
          }}"
          maxlength="80"
          formControlName="routeName"
          id="routeName"
          class="form-control"
        />
      </div>
    </div>

    <div class="mb-3">
      <label for="summary" class="form-label">{{
        'ADD_SURVEY_ROUTE_MODAL.ROUTE_DESCRIPTION' | translate
      }}</label>
      <textarea
        id="summary"
        placeholder="{{
          'ADD_SURVEY_ROUTE_MODAL.ROUTE_DESCRIPTION_PLACEHOLDER' | translate
        }}"
        class="form-control"
        formControlName="routeDescription"
      ></textarea>
    </div>

    <div class="row" style="margin-top: 45px">
      <div class="col-md-12">
        <button
          type="button"
          class="btn btn-secondary mtx-btn-secondary"
          style="width: 220px; margin-right: 5px; font-weight: 500"
          (click)="cancel()"
        >
          {{ 'ADD_SURVEY_ROUTE_MODAL.CANCEL' | translate }}
        </button>
        <button
          type="button"
          class="btn"
          style="
            width: 220px;
            margin-left: 5px;
            font-weight: 500;
            color: white;
            background-color: #3498db;
          "
          (click)="saveAndContinue()"
        >
          {{ 'ADD_SURVEY_ROUTE_MODAL.SAVE_CONTINUE' | translate }}
        </button>
      </div>
    </div>
  </form>
</mat-dialog-content>
