import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { CustomToastrService } from '../../shared/ngx-toastr/custom-toastr.service';
import { Router } from '@angular/router';
import moment from 'moment';
import { SetupSegmentAndAssetsService } from '../../core/services/setup-segment-and-assets.service';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import {
  ASSET,
  BOND,
  CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
  EMPTY_STRING,
  ERROR,
  FAILED,
  NO,
  RECTIFIER,
  SETUP_SEGMENTS_ASSETS,
  SURE_CANCEL_HEADER,
  SURE_CANCEL_MSG,
  TEST_POINTS,
  TOASTER_SUCCESS,
} from '../../shared/constants';
import { Store } from '@ngxs/store';
import { SetupHierarchyState } from '../../core/store/setup-hierarchy.state';
import {
  BondColumnsDataType,
  DefaultColumnsDataType,
  RectifierColumnsDataType,
  TestPointsColumnsDataType,
} from '../../shared/enums';
import { PopupDialogService } from '../../shared/popup-dialog/popup-dialog.service';
import { BulkImportStatus, ImportService } from '../../core/services/import.service';
import { FileUploadService } from '../../core/services/file-upload.service';
import { fileUploadEntityType, FileUploadInput, fileUploadType, importInput } from '../../../awsAppSync/API';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';

@Component({
  selector: 'app-import-bulk-asset',
  templateUrl: './import-bulk-asset.component.html',
  styleUrls: ['./import-bulk-asset.component.css'],
})
export class ImportBulkAssetComponent implements OnInit {
  files: any;
  assetType: string | null = EMPTY_STRING;
  assetTypeId: string | null = EMPTY_STRING;
  fileData: any = null;
  fileExtension = '';
  fileName = '';
  file!: File;
  fileToUpload = '';
  currentAssetColumnDataType = '';
  formatDoesNotMatch = false;
  enableSave = false;
  uploadedFileColumnTypes: Record<string, string> = {};
  displayedColumns: string[] = [];
  tableData: any[] = [];
  errorMessages: string[] = [];
  availableOptions: string[] = [];
  selectedColumns: (string | null)[] = [];
  validationErrors: boolean[] = [];
  validationMessages: string[] = [];
  mappedColumns: (string | null)[] = [];
  mappedPreviewData: any[] = [];
  mappedData: any[] = [];
  level3Name$: Observable<string>;
  level4Name$: Observable<string>;
  storeLevel3Name: string = EMPTY_STRING;
  storeLevel4Name: string = EMPTY_STRING;
  assetsMandatoryColumns: string[] = [];
  currentColumnMapped: string[] = [];
  invalidValues: string[] = [];
  isLoading = false;
  importStatusSubscription: Subscription | undefined;
  syrcUserId: string | null = null;
  userId$: Observable<string | null> = of(null);

  constructor(
    private store: Store,
    private toastr: CustomToastrService,
    private router: Router,
    private setupSegmentAndAssetsService: SetupSegmentAndAssetsService,
    private popupDialogService: PopupDialogService,
    private fileUploadService: FileUploadService, 
    private importService: ImportService
  ) {
    this.level3Name$ = this.store.select(SetupHierarchyState.getLevel3Name);
    this.level4Name$ = this.store.select(SetupHierarchyState.getLevel4Name);
    this.userId$ = this.store.select(AuthenticateUserState.getSyrcUserId);
  }

  ngOnInit(): void {
    this.level3Name$.subscribe((level3Name) => {
      this.storeLevel3Name = level3Name;
    });
    this.level4Name$.subscribe((level4Name) => {
      this.storeLevel4Name = level4Name;
    });
    this.userId$.subscribe((userId) => {
      this.syrcUserId = userId ?? '';
    });

    const newKey1 = this.storeLevel3Name;
    const newKey2 = this.storeLevel4Name;
    const newKey3 = this.storeLevel4Name + ' Type';
    const newValue = 'String';

    this.displayedColumns = [
      this.storeLevel3Name,
      this.storeLevel4Name,
      this.storeLevel4Name + ' Type',
      'Asset Name',
      'Primary Seg? (Y/N)',
      'Longitude',
      'Latitude',
      'Milestone',
      'Creation Date',
    ];
    this.files = this.setupSegmentAndAssetsService.files;
    this.assetType = this.setupSegmentAndAssetsService.assetType;
    this.assetTypeId = this.setupSegmentAndAssetsService.assetTypeId;
    this.assetsMandatoryColumns =
      this.setupSegmentAndAssetsService.currentAssetmandatoryColumns;
    this.uploadedFileColumnTypes =
      this.setupSegmentAndAssetsService.columnTypes;
    if (this.assetType == RECTIFIER) {
      Object.keys(RectifierColumnsDataType).forEach((column) => {
        if (!this.displayedColumns.includes(column)) {
          this.displayedColumns.push(column);
        }
      });
      if (!RectifierColumnsDataType[newKey1]) {
        RectifierColumnsDataType[newKey1] = newValue;
      }
      if (!RectifierColumnsDataType[newKey2]) {
        RectifierColumnsDataType[newKey2] = newValue;
      }
      if (!RectifierColumnsDataType[newKey3]) {
        RectifierColumnsDataType[newKey3] = newValue;
      }
    } else if (this.assetType == BOND) {
      Object.keys(BondColumnsDataType).forEach((column) => {
        if (!this.displayedColumns.includes(column)) {
          this.displayedColumns.push(column);
        }
      });
      if (!BondColumnsDataType[newKey1]) {
        BondColumnsDataType[newKey1] = newValue;
      }
      if (!BondColumnsDataType[newKey2]) {
        BondColumnsDataType[newKey2] = newValue;
      }
      if (!BondColumnsDataType[newKey3]) {
        BondColumnsDataType[newKey3] = newValue;
      }
    } else if (this.assetType == TEST_POINTS) {
      Object.keys(TestPointsColumnsDataType).forEach((column) => {
        if (!this.displayedColumns.includes(column)) {
          this.displayedColumns.push(column);
        }
      });
      if (!TestPointsColumnsDataType[newKey1]) {
        TestPointsColumnsDataType[newKey1] = newValue;
      }
      if (!TestPointsColumnsDataType[newKey2]) {
        TestPointsColumnsDataType[newKey2] = newValue;
      }
      if (!TestPointsColumnsDataType[newKey3]) {
        TestPointsColumnsDataType[newKey3] = newValue;
      }
    } else {
      Object.keys(DefaultColumnsDataType).forEach((column) => {
        if (!this.displayedColumns.includes(column)) {
          this.displayedColumns.push(column);
        }
      });
      if (!DefaultColumnsDataType[newKey1]) {
        DefaultColumnsDataType[newKey1] = newValue;
      }
      if (!DefaultColumnsDataType[newKey2]) {
        DefaultColumnsDataType[newKey2] = newValue;
      }
      if (!DefaultColumnsDataType[newKey3]) {
        DefaultColumnsDataType[newKey3] = newValue;
      }
    }
    this.onFileSelected(this.files);
  }

  ngOnDestroy(): void {
    this.removeDataFile();
   }

  onFileSelected(event: any) {
    const file = event[0];
    this.processFile(file);
  }

  public onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      this.processFile(files[0]);
    }
  }

  public onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  public onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  processFile(file: File) {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (fileExtension === 'xlsx' || fileExtension === 'csv') {
      this.fileName = file.name;
      this.fileExtension = fileExtension;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false });
        if (Array.isArray(sheetData[0])) {
          sheetData[0] = sheetData[0].map((cell: string) => {
            return typeof cell === 'string' ? cell.replace(/\*/g, '') : cell;
          });
        }
        this.tableData = sheetData;

        // Filter out columns with all blank values
        const validColumnIndexes = this.tableData[0]
          .map((_: any, columnIndex: any) => columnIndex)
          .filter((columnIndex: number) => !this.isColumnBlank(columnIndex));

        // Update tableData to only include valid columns
        this.tableData = this.tableData.map((row) =>
          validColumnIndexes.map((index: any) => row[index]),
        );

        this.availableOptions = this.tableData[0].map((option: string) =>
          option.replace('*', ''),
        );

        this.selectedColumns = new Array(this.displayedColumns.length).fill(
          null,
        );
        this.mappedColumns = new Array(this.displayedColumns.length).fill(null);
        this.validationErrors = new Array(this.displayedColumns.length).fill(
          false,
        );
        this.validationMessages = new Array(this.displayedColumns.length).fill(
          '',
        );
        this.errorMessages = [];
      };
      reader.readAsArrayBuffer(file);
    } else {
      this.toastr.showWarning(
        'Only XLSX and CSV file formats are supported!',
        'Warning',
      );
    }
  }

  removeFile() {
    this.fileData = null;
    this.fileExtension = '';
    this.fileName = '';
    this.tableData = [];
    this.errorMessages = [];
    this.availableOptions = [];
    this.selectedColumns = new Array(this.displayedColumns.length).fill(null);
    this.mappedColumns = new Array(this.displayedColumns.length).fill(null);
    this.mappedPreviewData = [];
    this.mappedData = [];
    this.validationErrors = [];
    this.router.navigate([SETUP_SEGMENTS_ASSETS], { replaceUrl: true });
    this.setupSegmentAndAssetsService.isFreshRoute = true;
  }

  removeDataFile() {
    this.fileData = null;
    this.fileExtension = '';
    this.fileName = '';
    this.tableData = [];
    this.errorMessages = [];
    this.availableOptions = [];
    this.selectedColumns = new Array(this.displayedColumns.length).fill(null);
    this.mappedColumns = new Array(this.displayedColumns.length).fill(null);
    this.mappedPreviewData = [];
    this.mappedData = [];
    this.validationErrors = [];
    this.router.navigate([SETUP_SEGMENTS_ASSETS], { replaceUrl: true });
  }

  onColumnSelected(index: number, selectedValue: string) {
    this.onColumnUnmapped(index);
    this.validateColumnMapping(index, selectedValue);
    this.updateAvailableOptions(index, selectedValue);
  }

  checkMandatoryColumns(): void {
    this.enableSave = this.assetsMandatoryColumns.every((column) =>
      this.currentColumnMapped.includes(column),
    );
  }

  updateAvailableOptions(index: number, selectedValue: string) {
    // this.availableOptions.splice(this.availableOptions.indexOf(selectedValue) ,1);
    const selectedIndex = this.availableOptions.indexOf(selectedValue);
    if (selectedIndex > -1) {
      this.availableOptions.splice(selectedIndex, 1);
    }

    // Store the index of the selected value in the mappedColumns array
    this.mappedColumns[index] = selectedValue;
  }

  validateColumnMapping(index: number, selectedValue: string): void {
    if (this.selectedColumns.includes(selectedValue)) {
      this.selectedColumns[index] = selectedValue;
      this.validationErrors[index] = true;
      this.validationMessages[index] = 'Already Selected';
    } else {
      this.selectedColumns[index] = selectedValue;
      const column = this.displayedColumns[index];
      this.currentColumnMapped.push(column);
      const columnKey = this.displayedColumns[
        index
      ] as keyof typeof RectifierColumnsDataType;
      if (this.assetType == 'Rectifier') {
        this.currentAssetColumnDataType = RectifierColumnsDataType[columnKey];
      } else if (this.assetType == 'Bond') {
        this.currentAssetColumnDataType = BondColumnsDataType[columnKey];
      } else {
        this.currentAssetColumnDataType = DefaultColumnsDataType[columnKey];
      }
      const selectedColumn = this.selectedColumns[index];
      const columnIndex = this.tableData[0].map((option: string) => option.replace('*', '')).indexOf(selectedColumn);
      const uploadedColumnDataType =
        this.uploadedFileColumnTypes[selectedValue];
      if (columnIndex === -1) {
        this.validationErrors[index] = true;
        this.validationMessages[index] = 'Column not found in the data';
        return;
      }

      const values = this.tableData
        .slice(1)
        .map((row) => this.cleanValue(row[columnIndex]))
        .slice(0, 10); // Consider the first 10 rows for validation

      if (this.allValuesBlank(values)) {
        this.validationErrors[index] = true;
        this.validationMessages[index] = 'Column contains all blank values';
      } else {
        //let invalidValues = [];
        // switch (column) {
        //   case 'Date of Inspection':
        //     invalidValues = values.filter((value) => !this.isValidDate(value));
        //     break;
        //   case 'Latitude':
        //     invalidValues = values.filter(
        //       (value) => !this.isValidLatitude(value),
        //     );
        //     break;
        //   case 'Longitude':
        //     invalidValues = values.filter(
        //       (value) => !this.isValidLongitude(value),
        //     );
        //     break;
        // }
        this.formatDoesNotMatch =
          this.currentAssetColumnDataType == uploadedColumnDataType
            ? false
            : true;
        if (this.formatDoesNotMatch) {
          this.invalidValues.push('True');
        }
        if (this.invalidValues.length > 0) {
          this.validationErrors[index] = true;
          this.validationMessages[index] = "Format doesn't match";
        } else {
          this.validationErrors[index] = false;
          this.validationMessages[index] = '';
        }
      }
      this.mapDataToPreview();
      this.checkMandatoryColumns();
    }
  }

  mapDataToPreview() {
    this.mappedPreviewData = this.tableData
      .slice(1)
      .slice(0, 10)
      .map((row) => {
        const mappedRow: any = {};
        this.selectedColumns.forEach((col, i) => {
          const columnIndex = this.tableData[0].indexOf(col);
          if (columnIndex > -1) {
            mappedRow[this.displayedColumns[i]] = row[columnIndex];
          } else {
            mappedRow[this.displayedColumns[i]] = null;
          }
        });
        return mappedRow;
      });
      this.mappedData = this.tableData
      .slice(1)
      .map((row) => {
        const mappedRow: any = {};
        this.selectedColumns.forEach((col, i) => {
          const columnIndex = this.tableData[0].indexOf(col);
          if (columnIndex > -1) {
            mappedRow[this.displayedColumns[i]] = row[columnIndex];
          } else {
            mappedRow[this.displayedColumns[i]] = null;
          }
        });
        return mappedRow;
      });
    this.mappedColumns = this.selectedColumns;
  }

  cleanValue(value: any): string {
    if (typeof value === 'string') {
      return value.trim();
    }
    return value;
  }

  async downloadExcel() {
    const headers = Object.keys(RectifierColumnsDataType);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      this.mappedData,
      { header: headers },
    );
    
    let workbook: XLSX.WorkBook;

    if (this.assetType === 'Rectifier') {
      workbook = {
        Sheets: { Rectifier: worksheet },
        SheetNames: ['Rectifier'],
      };
      this.fileToUpload = 'rectifier-data.xlsx';
    } else if (this.assetType === 'Bond') {
      workbook = {
        Sheets: { Rectifier: worksheet },
        SheetNames: ['Rectifier'],
      };
      this.fileToUpload = 'bonds-data.xlsx';
    } else {
      workbook = {
        Sheets: { Sheet1: worksheet },
        SheetNames: ['Sheet1'],
      };
      this.fileToUpload = 'asset-data.xlsx';
    }

    // Create the Excel file buffer
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Convert the buffer into a Blob
    const fileBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Convert the Blob into a File object
    this.file = new File([fileBlob], this.fileToUpload, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
}

  isValidDate(dateString: string): boolean {
    // Define a list of possible date formats
    const formats = [
      'YYYY-MM-DD',
      'MM/DD/YYYY',
      'DD/MM/YYYY',
      'YYYY/MM/DD',
      'YYYY-MM-DDTHH:mm:ssZ',
      'MM-DD-YYYY',
      'DD-MM-YYYY',
      'YYYY-MM-DDTHH:mm:ss',
      'YYYY-MM-DDTHH:mm:ss.SSSZ',
      'YYYY-MM-DDTHH:mm:ss.SSS',
      'M/D/YY',
      'MM/DD/YY',
      'DD/MM/YY',
      'YYYY/MM/DD',
      'YY/MM/DD',
    ];

    // Try to parse the date string with the defined formats
    const isValid = formats.some((format) =>
      moment(dateString, format, true).isValid(),
    );

    return isValid;
  }

  isValidNumber(value: string): boolean {
    return !isNaN(parseFloat(value)) && isFinite(parseFloat(value));
  }

  isValidLatitude(latitude: string): boolean {
    const lat = parseFloat(latitude);
    return (
      !isNaN(lat) && lat >= -90 && lat <= 90 && /^-?\d+(\.\d+)?$/.test(latitude)
    );
  }

  isValidLongitude(longitude: string): boolean {
    const lng = parseFloat(longitude);
    return (
      !isNaN(lng) &&
      lng >= -180 &&
      lng <= 180 &&
      /^-?\d+(\.\d+)?$/.test(longitude)
    );
  }

  removeElementInPlace(arr: string[], valueToRemove: string | null): void {
    if (valueToRemove) {
      let index = arr.indexOf(valueToRemove);
      while (index !== -1) {
        arr.splice(index, 1);
        index = arr.indexOf(valueToRemove);
      }
    }
  }

  onColumnUnmapped(index: number) {
    const unmappedValue = this.selectedColumns[index];
    const originalIndex = this.tableData[0].indexOf(unmappedValue);
    this.removeElementInPlace(this.currentColumnMapped, unmappedValue);
    if (unmappedValue && originalIndex > -1) {
      this.availableOptions.splice(originalIndex, 0, unmappedValue);
    }
    this.selectedColumns[index] = null;
    this.validationErrors[index] = false;
    this.mappedColumns[index] = null;
    this.mappedPreviewData = this.mappedPreviewData.map((row) => {
      row[this.displayedColumns[index]] = null;
      return row;
    });
    this.mappedData = this.mappedData.map((row) => {
      row[this.displayedColumns[index]] = null;
      return row;
    });
    this.validationMessages[index] = '';
    this.checkMandatoryColumns();
    this.invalidValues = [];
  }

  formatCellValue(value: any) {
    if (value !== null && this.isValidDate(value)) {
      const date = new Date(value);
      return date.toLocaleDateString();
    }
    return value;
  }

  isDate(value: any): boolean {
    if (typeof value !== 'string' && typeof value !== 'number') {
      return false;
    }

    if (typeof value === 'string') {
      // Check for common date formats using regex
      const datePattern =
        /^\d{2}-\d{2}-\d{4}$|^\d{4}-\d{2}-\d{2}$|^\d{2}\/\d{2}\/\d{4}$/;
      if (!datePattern.test(value)) {
        return false;
      }
    }

    const date = new Date(value);
    return (
      date instanceof Date &&
      !isNaN(date.getTime()) &&
      date.toString() !== 'Invalid Date'
    );
  }

  isColumnBlank(columnIndex: number): boolean {
    return this.tableData
      .slice(1)
      .every(
        (row) =>
          row[columnIndex] === null ||
          row[columnIndex] === '' ||
          row[columnIndex] === undefined,
      );
  }

  allValuesBlank(values: any[]): boolean {
    return values.every(
      (value) => value === null || value === '' || value === undefined,
    );
  }

  onCancel() {
    this.popupDialogService.openDialog(
      SURE_CANCEL_HEADER,
      SURE_CANCEL_MSG,
      FAILED,
      CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
      () => this.router.navigate([SETUP_SEGMENTS_ASSETS], { replaceUrl: true }),
      true,
      NO,
    );
    this.setupSegmentAndAssetsService.isFreshRoute = true;
  }

  async saveFileImport(remainOnSamePage: boolean) {
    const hasValidationErrors = this.validationErrors.some(
      (value) => value === true,
    );
    // const allColumnsMapped = this.selectedColumns.every(
    //   (column) => column !== null,
    // );

    if (this.fileName === '') {
      this.toastr.showWarning('Please upload file', 'Warning');
    } else if (hasValidationErrors) {
      this.toastr.showWarning(
        'Please correct the errors in the column mappings',
        'Warning',
      );
    }
    // else if (!allColumnsMapped) {
    //   this.toastr.showWarning('Please map all the required columns', 'Warning');
    // }
    else {
      this.isLoading = true;
      this.downloadExcel();
      await this.uploadFile();
      if (!remainOnSamePage) {
        this.router.navigate(['setup/import']);
      }
    }
  }

  async uploadFile() {
    const primaryCompanyId = await firstValueFrom(this.store.select(SetupHierarchyState.getPrimaryCompanyId));
    if(primaryCompanyId)
    {
      const _fileInputData : FileUploadInput = {
        filename: this.fileToUpload,
        entityType: fileUploadEntityType.PrimaryCompany,
        entityTypeId: primaryCompanyId,
        fileUploadType: fileUploadType.Import
      }
      const _getPresignedURL = await this.importService.uploadExcelFile(_fileInputData)
      await this.fileUploadService.uploadToS3((String)(_getPresignedURL?.presignedURL), this.file, (String)(_getPresignedURL?.contentType))

      if (this.syrcUserId) {
        this.importStatusSubscription = this.importService
          .executeImportSubscription(this.syrcUserId)
          .subscribe({
            next: (data: BulkImportStatus) => {
              console.log('Subscription data received:', data);
              if (data.status === 'Error') {
                this.setupSegmentAndAssetsService.invalidFileUrl = data?.fileURL ?? '';
                this.toastr.showError('Uploaded File is invalid', ERROR);
                this.router.navigate([SETUP_SEGMENTS_ASSETS]);
                this.isLoading = false;
                this.importStatusSubscription?.unsubscribe();
              } else if (data.status === 'Success') {
                this.toastr.showSuccess(
                  'File Imported Successfully!',
                  TOASTER_SUCCESS,
                );
                this.router.navigate([SETUP_SEGMENTS_ASSETS]);
                this.isLoading = false;
                this.importStatusSubscription?.unsubscribe();
              }
            },
            error: (err: any) => {
              console.error('Subscription error:', err);
              this.importStatusSubscription?.unsubscribe();
            },
          });
      }

      const _input: importInput = {
        fileURL: _getPresignedURL?.fileURL ?? '',
        entityType: ASSET,
        entityValue: JSON.stringify({
          assetTypeId: this.assetTypeId,
          assetType: this.assetType,
          primaryCompanyId: primaryCompanyId,
        }), 
      };

      this.importService.bulkImport(_input);
      this.setupSegmentAndAssetsService.assetType = this.assetType;
      this.setupSegmentAndAssetsService.isFreshRoute = false;
    }
    
  }
}
