import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
  CANCEL_TEMPLATE_HEADING,
  CANCEL_TEMPLATE_MSG_HIERARCHY,
  CLOSE,
  CONTINUE,
  EDIT_HIERARCHY_BTN_CONFIRM_TXT,
  EDIT_HIERARCHY_HEADING,
  EDIT_HIERARCHY_MSG,
  EMPTY_STRING,
  ERROR,
  FAILED,
  FAILURE_SETUP_HIERARCHY_HEADING,
  FAILURE_SETUP_HIERARCHY_HEADING_MSG,
  FAILURE_UPDATE_HIERARCHY_HEADING,
  FAILURE_UPDATE_HIERARCHY_HEADING_MSG,
  LEVEL_1,
  LEVEL_2,
  LEVEL_3,
  LEVEL_4,
  NO,
  PRIMARY_COMPANY,
  SEGMENT_TEXT,
  SETUP_COMPANY_OVERVIEW,
  SETUP_SEGMENTS_ASSETS,
  SUCCESS,
  SUCCESS_SETUP_HIERARCHY_HEADING,
  SUCCESS_SETUP_HIERARCHY_MSG,
  TOASTER_SUCCESS,
} from '../../shared/constants';
import { HierarchyNode } from '../../core/models/heirarchy-tree';
import { PopupDialogService } from '../../shared/popup-dialog/popup-dialog.service';
import { Router } from '@angular/router';
import { SetupHierarchyService } from '../../core/services/setup-hierarchy.service';
import { TranslateService } from '@ngx-translate/core';
import { levelValidator } from '../../shared/helpers/levelValidators';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import {
  SetSetupHierarchy,
  SetupHierarchyState,
} from '../../core/store/setup-hierarchy.state';
import {
  Level1ValueInput,
  Level1ValueValueUpdate,
  Level2ValueInput,
  Level2ValueUpdateInput,
  Level3ValueInput,
  LevelNameCreateInput,
  LevelValueCreateInput,
  LevelValueOutput,
  LevelValueUpdateInput,
} from '../../../awsAppSync/API';
import { CustomToastrService } from '../../shared/ngx-toastr/custom-toastr.service';

@Component({
  selector: 'app-setup-hierarchy-levels',
  templateUrl: './setup-hierarchy-levels.component.html',
  styleUrl: './setup-hierarchy-levels.component.css',
})
export class SetupHierarchyLevelsComponent implements OnInit, OnDestroy {
  form: FormGroup;
  currentStep = 3;
  primaryCompanyId = EMPTY_STRING;
  level1Id = EMPTY_STRING;
  level2Id = EMPTY_STRING;
  level3Id = EMPTY_STRING;
  level4Id = EMPTY_STRING;
  hierarchyTree: HierarchyNode[] = [];
  levels: string[] = [];
  isSaveClicked = false;
  isSaveAndNextClicked = false;
  isSaveAndExitClicked = false;
  isEditClicked = false;
  isLoading = false;
  primaryCompanyId$: Observable<string | null>;
  primaryCompanyName$: Observable<string | null>;
  hierarchyIsEdit$: Observable<boolean>;
  storePrimaryCompanyid: string | null = EMPTY_STRING;
  storePrimaryCompanyName: string | null = EMPTY_STRING;
  levelPrimaryCompanyId = EMPTY_STRING;
  nodeData: HierarchyNode | null = null;
  hierarchyData!: LevelValueCreateInput;
  public levelValues!: LevelValueOutput | null;
  isEdit = false;
  level1Values: any;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private popupDialogService: PopupDialogService,
    private setupHierachyServie: SetupHierarchyService,
    private translate: TranslateService,
    private store: Store,
    private toastr: CustomToastrService,
  ) {
    this.form = this.fb.group(
      {
        primaryCompany: [''],
        level1: [''],
        level2: [''],
        level3: ['', Validators.required],
        level4: [{ value: SEGMENT_TEXT, disabled: true }],
      },
      {
        validators: levelValidator(this.translate),
      },
    );
    this.hierarchyIsEdit$ = this.store.select(
      SetupHierarchyState.getIsEdit,
    );
    this.primaryCompanyId$ = this.store.select(
      SetupHierarchyState.getPrimaryCompanyId,
    );
    this.primaryCompanyName$ = this.store.select(
      SetupHierarchyState.getPrimaryCompanyName,
    );
  }
  async ngOnInit(): Promise<void> {
    this.hierarchyIsEdit$.subscribe((isEdit) => {
      this.isEdit = isEdit;
    });
    
    
    this.primaryCompanyId$.subscribe((id) => {
      this.storePrimaryCompanyid = id;
    });
    
    this.primaryCompanyName$.subscribe((companyName) => {
      this.storePrimaryCompanyName = companyName;
    });
    this.form.patchValue({ primaryCompany: this.storePrimaryCompanyName });
    this.form.get(PRIMARY_COMPANY)?.disable();

    if(this.isEdit){
      this.LoadDataInEditMode();
    }

    
  }


  async LoadDataInEditMode() {
    this.isLoading = true;
    const _levelNames = await this.setupHierachyServie.getAllLevelNameByPrimaryCompany(this.storePrimaryCompanyid ?? '');
    
    this.levelValues = await this.setupHierachyServie.getLevelValuesByPrimaryCompanyId(this.storePrimaryCompanyid ?? '');
    this.level1Values = this.levelValues?.level1Values;
    this.setupHierachyServie.level1Values = this.levelValues?.level1Values;
    // Initialize variables to store level names
    let level1 = '';
    let level2 = '';
    let level3 = '';

    if (_levelNames && _levelNames.items) {
      for (const item of _levelNames.items) {
        if (item?.level1Name) {
          level1 = item.level1Name; 
        }
        if (item?.level2Name) {
          level2 = item.level2Name; 
        }
        if (item?.level3Name) {
          level3 = item.level3Name; 
        }
      }
    }

    if (this.levelValues?.level1Values) {
      this.form.get(LEVEL_1)?.disable();
      this.form.get(LEVEL_2)?.disable();
      this.form.get(LEVEL_3)?.disable();
      this.isSaveClicked = true;
      this.isEditClicked = false;
      this.hierarchyTree = this.createHierarchyTree(this.levelValues.level1Values,this.storePrimaryCompanyName ?? '' , this.storePrimaryCompanyid ?? '', level1, level2, level3);
      this.setupHierachyServie.nodeData = this.hierarchyTree[0];
      
      this.levels = [
        this.storePrimaryCompanyName ?? '',
        level1,
        level2,
        level3,
      ];
      this.setupHierachyServie.levels = this.levels;
      this.setupHierachyServie.primaryCompanyid = this.storePrimaryCompanyid;
    }

    this.form.patchValue({
      level1: level1,
      level2: level2,
      level3: level3
    });
    this.isLoading = false;
  }
  ngOnDestroy() {
    this.setupHierachyServie.isEdit = false;
  }

  private createLevelValueUpdateInput(
    hierarchyTree: HierarchyNode,
    initialData: any,
    primaryCompanyId: string
  ): LevelValueUpdateInput {
    const inputObject: LevelValueUpdateInput = {
      primaryCompanyId: primaryCompanyId,
      level1Values: []
    };
  
    // Handle Default Levels from initialData
    initialData.forEach((initialLevel1: any) => {
      const hierarchyLevel1 = hierarchyTree?.children?.find(
        (level1Node) => level1Node.dbId === initialLevel1.id || level1Node.name === initialLevel1.value
      );
  
      const level1Value: Level1ValueValueUpdate = {
        id: initialLevel1.id,
        pk: initialLevel1.pk,
        sk: initialLevel1.sk,
        value: initialLevel1.value,
        isDeleted: !hierarchyLevel1 ? true : false,
        level2Values: [],
      };
  
      // If level1 is "Default" and has children, handle it differently
      if (initialLevel1.value === "Default" && initialLevel1.level2Values) {
        level1Value.isDeleted = false;
  
        // Merge level2 and level3 values, handling any new additions in the hierarchyTree
        level1Value.level2Values = initialLevel1.level2Values.map((initialLevel2: any) => {
          const hierarchyLevel2 = hierarchyTree?.children?.find(
            (level2Node) => level2Node.dbId === initialLevel2.id || level2Node.name === initialLevel2.value
          );
  
          const level2Value: Level2ValueUpdateInput = {
            id: initialLevel2.id,
            pk: initialLevel2.pk,
            sk: initialLevel2.sk,
            value: initialLevel2.value,
            isDeleted: false,
            level3Values: [],
          };
  
          if (hierarchyLevel2) {
            // Merge existing and new level3 values
            level2Value.level3Values = hierarchyLevel2.children
              ?.filter((level3Node) => !level3Node.name.includes("Add "))
              .map((level3Node) => {
                const initialLevel3 = initialLevel2.level3Values.find(
                  (l3: any) => l3.id === level3Node.dbId || l3.value === level3Node.name
                );
  
                return {
                  id: level3Node.dbId,
                  pk: initialLevel3 ? initialLevel3.pk : null,
                  sk: initialLevel3 ? initialLevel3.sk : null,
                  value: level3Node.name.replace(/^[^:]+:\s*/, ""),
                  isDeleted: false,
                  entityType: initialLevel3?.entityType || null,
                };
              }) || [];

              if(!hierarchyLevel2.children?.length){
                level2Value.level3Values = hierarchyTree.children
              ?.filter((level3Node) => !level3Node.name.includes("Add "))
              .map((level3Node) => {
                const initialLevel3 = initialLevel2.level3Values.find(
                  (l3: any) => l3.id === level3Node.dbId || l3.value === level3Node.name
                );
  
                return {
                  id: level3Node.dbId,
                  pk: initialLevel3 ? initialLevel3.pk : null,
                  sk: initialLevel3 ? initialLevel3.sk : null,
                  value: level3Node.name.replace(/^[^:]+:\s*/, ""),
                  isDeleted: false,
                  entityType: initialLevel3?.entityType || null,
                };
              }) || [];
              }
          }
  
          return level2Value;
        });
  
        // Add any new level2 nodes from the hierarchy that are not in initialData
        const newLevel2Nodes = hierarchyTree?.children
          ?.filter((level2Node) => !level2Node.name.includes("Add ") && !initialLevel1.level2Values.some((l2: any) => l2.id === level2Node.dbId || l2.value === "Default"))
          .map((newLevel2Node) => {
            return {
              id: newLevel2Node.id,
              value: newLevel2Node.name.replace(/^[^:]+:\s*/, ""),
              level3Values: newLevel2Node.children
                ?.filter((level3Node) => !level3Node.name.includes("Add "))
                .map((level3Node) => {
                  return {
                    id: level3Node.id,
                    value: level3Node.name.replace(/^[^:]+:\s*/, ""),
                  };
                }) || [],
              isDeleted: false,
            };
          }) || [];
  
          level1Value.level2Values = [...(level1Value.level2Values || []), ...(newLevel2Nodes || [])];
          

      } else if (hierarchyLevel1) {
        // If level1 is not "Default", merge with non-default values from hierarchyTree.
        level1Value.value = hierarchyLevel1.name.replace(/^[^:]+:\s*/, "");
        level1Value.isDeleted = !hierarchyLevel1.isNodeEdited && initialLevel1 ? true : false;
        level1Value.level2Values = hierarchyLevel1.children
          ?.filter((level2Node) => !level2Node.name.includes("Add "))
          .map((level2Node) => {
            const initialLevel2 = initialLevel1.level2Values.find(
              (l2: any) => l2.id === level2Node.dbId
            );
            const level2Value: Level2ValueUpdateInput = {
              id: level2Node.dbId,
              pk: initialLevel2 ? initialLevel2.pk : null,
              sk: initialLevel2 ? initialLevel2.sk : null,
              value: level2Node.name.replace(/^[^:]+:\s*/, ""),
              isDeleted: false,
              level3Values: level2Node.children
                ?.filter((level3Node) => !level3Node.name.includes("Add "))
                .map((level3Node) => {
                  const initialLevel3 = initialLevel2?.level3Values?.find(
                    (l3: any) => l3.id === level3Node.dbId || l3.value === level3Node.name
                  );
  
                  return {
                    id: level3Node.dbId,
                    pk: initialLevel3 ? initialLevel3.pk : null,
                    sk: initialLevel3 ? initialLevel3.sk : null,
                    value: level3Node.name.replace(/^[^:]+:\s*/, ""),
                    isDeleted: false,
                    entityType: initialLevel3?.entityType || null,
                  };
                }) || [],
            };
  
            return level2Value;
          }) || [];
      }
  
      inputObject.level1Values?.push(level1Value);
    });
  
    hierarchyTree?.children
    ?.filter(
      (level1Node) =>
        !level1Node.name.includes("Add ") &&
        !initialData.some((initialLevel1: any) => initialLevel1.id === level1Node.dbId || initialLevel1.value === "Default")
    )
    .forEach((newLevel1Node) => {
      const newLevel1Value: Level1ValueValueUpdate = {
        id: newLevel1Node.id,
        value: newLevel1Node.name.replace(/^[^:]+:\s*/, ""),
        level2Values: newLevel1Node.children
          ?.filter((level2Node) => !level2Node.name.includes("Add "))
          .map((level2Node) => {
            return {
              id: level2Node.id,
              value: level2Node.name.replace(/^[^:]+:\s*/, ""),
              level3Values: level2Node.children
                ?.filter((level3Node) => !level3Node.name.includes("Add "))
                .map((level3Node) => {
                  return {
                    id: level3Node.id,
                    value: level3Node.name.replace(/^[^:]+:\s*/, ""),
                  };
                }) || [],
            };
          }) || [],
      };

      // New level1 nodes are not deleted, so we set isDeleted to false.
      newLevel1Value.isDeleted = false;
      inputObject.level1Values?.push(newLevel1Value);
    });

  
    return inputObject;
  }
  
  
  
  
  

  private createHierarchyTree(
    level1Values: any[],
    primaryCompany: string,
    primaryCompanyId: string,
    level1Name: string,
    level2Name: string,
    level3Name: string
  ): HierarchyNode[] {
    
    const primaryNode: HierarchyNode = {
      id: primaryCompanyId,
      dbId: primaryCompanyId,
      name: `Primary Company: ${primaryCompany}`,
      pk: '',
      sk: '',
      children: [],
      parent: null,
      isNodeEdited: false,
      isAssetConfigured: false,
    };
  
    primaryNode.children = level1Values.flatMap(level1 => {
      if (level1.value === "Default") {
        return level1.level2Values.flatMap((level2: { pk: any; sk: any; id: any; value: any; level3Values: any[]; }) => {
          if (level2.value === "Default") {
            const level3Nodes = level2.level3Values.map(level3 => ({
              id: level3Name,
              dbId: level3.id,
              name: `${level3Name}: ${level3.value}`,
              pk: level3.pk,
              sk: level3.sk,
              isNodeEdited: true,
              parent: primaryNode,
              children: [],
              isAssetConfigured: level3.isAssetConfigured,
            }));
  
            // Add an empty node for level3 if level3Name is not empty
            if (level3Name) {
              level3Nodes.push({
                id: level3Name,
                dbId: level3Name,
                name: `Add ${level3Name}`,
                pk: '',
                sk: '',
                isNodeEdited: false,
                parent: primaryNode,
                children: [],
                isAssetConfigured: false,
              });
            }
  
            return level3Nodes;
          } else {

            
            const level2Node: HierarchyNode = {
              id: level2Name,
              dbId: level2.id,
              name: `${level2Name}: ${level2.value}`,
              pk: level2.pk,
              sk: level2.sk,
              isNodeEdited: true,
              parent: primaryNode,
              children: [],
              isAssetConfigured: false,
            };
  
            level2Node.children = level2.level3Values.map(level3 => ({
              id: level3Name,
              dbId: level3.id,
              name: `${level3Name}: ${level3.value}`,
              pk: level3.pk,
              sk: level3.sk,
              isNodeEdited: true,
              parent: level2Node,
              children: [],
              isAssetConfigured: level3.isAssetConfigured,
            }));
  
            if (level3Name) {
              level2Node.children ??= [];
              level2Node.children.push({
                id: level3Name,
                dbId: level3Name,
                name: `Add ${level3Name}`,
                pk: '',
                sk: '',
                isNodeEdited: false,
                parent: level2Node,
                children: [],
                isAssetConfigured: false,
              });
            }
            level2Node.isAssetConfigured = level2Node.children?.some(node => node.isAssetConfigured === true);
            return level2Node;
          }
        });
      } else {
        const level1Node: HierarchyNode = {
          id: level1Name,
          dbId: level1.id,
          name: `${level1Name}: ${level1.value}`,
          pk: level1.pk,
          sk: level1.sk,
          isNodeEdited: true,
          parent: primaryNode,
          children: [],
          isAssetConfigured: false,
        };
  
        level1Node.children = level1.level2Values.flatMap((level2: { pk: any; sk: any; id: any; value: any; level3Values: any[]; }) => {
          const level2Node: HierarchyNode = {
              id: level2Name,
              dbId: level2.id,
              name: `${level2Name}: ${level2.value}`,
              pk: level2.pk,
              sk: level2.sk,
              isNodeEdited: true,
              parent: level1Node,
              children: [],
              isAssetConfigured: false,
            };
          if (level2.value === "Default") {
            const level3Nodes = level2.level3Values.map(level3 => ({
              id: level3Name,
              dbId: level3.id,
              name: `${level3Name}: ${level3.value}`,
              pk: level3.pk,
              sk: level3.sk,
              isNodeEdited: true,
              parent: level1Node,
              children: [],
              isAssetConfigured: level3.isAssetConfigured,
            }));
  
            if (level3Name) {
              level3Nodes.push({
                id: level3Name,
                dbId: level3Name,
                name: `Add ${level3Name}`,
                pk: '',
                sk: '',
                isNodeEdited: false,
                parent: level1Node,
                children: [],
                isAssetConfigured: false,
              });
            }
  
            return level3Nodes;
          } else {
            
  
            level2Node.children = level2.level3Values.map(level3 => ({
              id: level3Name,
              dbId: level3.id,
              name: `${level3Name}: ${level3.value}`,
              pk: level3.pk,
              sk: level3.sk,
              isNodeEdited: true,
              parent: level2Node,
              children: [],
              isAssetConfigured: level3.isAssetConfigured,
            }));
  
            if (level3Name) {
              level2Node.children ??= [];
              level2Node.children.push({
                id: level3Name,
                dbId: level3Name,
                name: `Add ${level3Name}`,
                pk: '',
                sk: '',
                isNodeEdited: false,
                parent: level2Node,
                children: [],
                isAssetConfigured: false,
              });
            }

            level2Node.isAssetConfigured = level2Node.children?.some(node => node.isAssetConfigured === true);
  
            return level2Node;
          }
          
        });

        if (level2Name) {

          const emptyLevel2Node: HierarchyNode = {
            id: level2Name,
            dbId: level2Name,
            name: `Add ${level2Name}`,
            pk: '',
            sk: '',
            isNodeEdited: false,
            parent: level1Node,
            children: [],
            isAssetConfigured: false,
          };
  
          const emptyLevel3Node: HierarchyNode = {
            id: level3Name,
            dbId: level3Name,
            name: `Add ${level3Name}`,
            pk: '',
            sk: '',
            isNodeEdited: false,
            parent: emptyLevel2Node,
            children: [],
            isAssetConfigured: false,
          };
         
          emptyLevel2Node.children?.push(emptyLevel3Node);
          level1Node.children?.push(emptyLevel2Node);
        }
        level1Node.isAssetConfigured = level1Node.children?.some(node => node.isAssetConfigured === true) ?? false;
        return level1Node;
      }
    });
    //Code for new empyty nodes for level1 and level2
    if (!level1Name && level2Name) {

      const emptyLevel2Node: HierarchyNode = {
        id: level2Name,
        dbId: level2Name,
        name: `Add ${level2Name}`,
        pk: '',
        sk: '',
        isNodeEdited: false,
        parent: primaryNode,
        children: [],
        isAssetConfigured: false,
      };
      
      const emptyLevel3Node: HierarchyNode = {
        id: level3Name,
        dbId: level3Name,
        name: `Add ${level3Name}`,
        pk: '',
        sk: '',
        isNodeEdited: false,
        parent: emptyLevel2Node,
        children: [],
        isAssetConfigured: false,
      };
      // Add an empty node for level2 if level2Name is not empty.
      emptyLevel2Node.children?.push(emptyLevel3Node);
      primaryNode.children.push(emptyLevel2Node);
    }

    if (level1Name) {
      const level1Node: HierarchyNode = {
        id: level1Name,
        dbId: level1Name,
        name: `Add ${level1Name}`,
        pk: '',
        sk: '',
        isNodeEdited: false,
        parent: primaryNode,
        children: [],
        isAssetConfigured: false,
      };
      // Create empty level2 node
      const emptyLevel2Node: HierarchyNode = {
        id: level2Name,
        dbId: level2Name,
        name: `Add ${level2Name}`,
        pk: '',
        sk: '',
        isNodeEdited: false,
        parent: level1Node,
        children: [],
        isAssetConfigured: false,
      };

      // Create empty level3 node
      const emptyLevel3Node: HierarchyNode = {
        id: level3Name,
        dbId: level3Name,
        name: `Add ${level3Name}`,
        pk: '',
        sk: '',
        isNodeEdited: false,
        parent: emptyLevel2Node,
        children: [],
        isAssetConfigured: false,
      };

      // Attach empty level3 node as a child of empty level2
      emptyLevel2Node.children?.push(emptyLevel3Node);

      // Attach empty level2 node as a child of level1
      level1Node.children?.push(emptyLevel2Node);

      // Attach level1 to the primaryNode
      primaryNode.children.push(level1Node);
    }
  
  
    return [primaryNode];
  }
  


  onLevelsSave() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.primaryCompanyId = this.form.get(PRIMARY_COMPANY)?.value;
      this.level1Id = this.form.get(LEVEL_1)?.value?.trim() || EMPTY_STRING;
      this.level2Id = this.form.get(LEVEL_2)?.value?.trim() || EMPTY_STRING;
      this.level3Id = this.form.get(LEVEL_3)?.value.trim();
      this.level4Id = this.form.get(LEVEL_4)?.value.trim();

      this.levels = [
        this.primaryCompanyId,
        this.level1Id,
        this.level2Id,
        this.level3Id,
      ];
      this.store.dispatch(
        new SetSetupHierarchy(
          this.storePrimaryCompanyid!,
          this.storePrimaryCompanyName!,
          this.level3Id,
          this.level4Id,
          false
        ),
      );
      this.setupHierachyServie.levels = this.levels;
      this.setupHierachyServie.primaryCompanyid = this.storePrimaryCompanyid;
      this.createAllLevels(
        this.storePrimaryCompanyid,
        this.level1Id,
        this.level2Id,
        this.level3Id,
        this.level4Id,
      );
      this.hierarchyTree = this.buildHierarchyTree(
        this.primaryCompanyId,
        this.level1Id,
        this.level2Id,
        this.level3Id,
      );

      this.form.get(LEVEL_1)?.disable();
      this.form.get(LEVEL_2)?.disable();
      this.form.get(LEVEL_3)?.disable();
      this.isSaveClicked = true;
      this.isEditClicked = false;
    }
  }

  async createAllLevels(
    primaryCompanyId: string | null,
    level1: string,
    level2: string,
    level3: string,
    level4: string,
  ) {
    try {
      const input: LevelNameCreateInput = {
        primaryCompanyId: primaryCompanyId,
        level1Name: level1,
        level2Name: level2,
        level3Name: level3,
        level4Name: level4,
      };
      await this.setupHierachyServie.createAllLevelNames(input);
    } catch (error) {
      console.error(error);
    }
  }
  
  onEdit() {
    const dialogRef = this.popupDialogService.openDialog(
      EDIT_HIERARCHY_HEADING,
      EDIT_HIERARCHY_MSG,
      FAILED,
      EDIT_HIERARCHY_BTN_CONFIRM_TXT,
      () => this.router.navigate([]),
      true,
      NO,
    );
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === EDIT_HIERARCHY_BTN_CONFIRM_TXT) {
        this.isEditClicked = true;
        this.isSaveClicked = false;
        this.form.get(LEVEL_1)?.enable();
        this.form.get(LEVEL_2)?.enable();
        this.form.get(LEVEL_3)?.enable();
      }
    });
  }

  buildHierarchyTree(
    primaryCompany: string,
    level1: string,
    level2: string,
    level3: string,
  ): HierarchyNode[] {
    const tree: HierarchyNode[] = [];

    const primaryNode: HierarchyNode = {
      id: this.primaryCompanyId,
      dbId: '',
      name: `Primary Company: ${primaryCompany}`,
      pk: '',
      sk: '',
      children: [],
      parent: null,
      isNodeEdited: false,
      isAssetConfigured: false,
    };

    if (level1) {
      const level1Node: HierarchyNode = {
        id: this.level1Id,
        dbId: '',
        name: `Add ${level1}`,
        pk: '',
        sk: '',
        children: [],
        parent: primaryNode,
        isNodeEdited: false,
        isAssetConfigured: false,
      };

      const level2Node: HierarchyNode = {
        id: this.level2Id,
        dbId: '',
        name: `Add ${level2}`,
        pk: '',
        sk: '',
        children: [],
        parent: level1Node,
        isNodeEdited: false,
        isAssetConfigured: false,
      };

      const level3Node: HierarchyNode = {
        id: this.level3Id,
        dbId: '',
        name: `Add ${level3}`,
        pk: '',
        sk: '',
        children: [],
        parent: level2Node,
        isNodeEdited: false,
        isAssetConfigured: false,
      };
      level2Node.children = [level3Node];
      level1Node.children = [level2Node];
      primaryNode.children = [level1Node];
    } else if (level2) {
      const level2Node: HierarchyNode = {
        id: this.level2Id,
        dbId: '',
        name: `Add ${level2}`,
        pk: '',
        sk: '',
        children: [],
        parent: primaryNode,
        isNodeEdited: false,
        isAssetConfigured: false,
      };

      const level3Node: HierarchyNode = {
        id: this.level3Id,
        dbId: '',
        name: `Add ${level3}`,
        pk: '',
        sk: '',
        children: [],
        parent: level2Node,
        isNodeEdited: false,
        isAssetConfigured: false,
      };
      level2Node.children = [level3Node];
      primaryNode.children = [level2Node];
    } else if (level3) {
      const level3Node: HierarchyNode = {
        id: this.level3Id,
        dbId: '',
        name: `Add ${level3}`,
        pk: '',
        sk: '',
        children: [],
        parent: primaryNode,
        isNodeEdited: false,
        isAssetConfigured: false,
      };
      primaryNode.children = [level3Node];
    }

    tree.push(primaryNode);
    return tree;
  }

  saveHierarchy() {
    this.nodeData = this.setupHierachyServie.nodeData;
    if (this.level2Id && this.level3Id && !this.level1Id) {
      if (
        this.nodeData &&
        this.nodeData.children &&
        this.nodeData.children.length > 0
      ) {
        let allGrandChildrenHaveNoChildren = true;
        this.nodeData.children.forEach((child) => {
          if (child.children && child.children.length > 0) {
            const firstGrandChild = child.children[0];
            const grandChildrenLength = firstGrandChild.children?.length;
            if (grandChildrenLength && grandChildrenLength > 0) {
              allGrandChildrenHaveNoChildren = false;
            }
          }
        });
        if (allGrandChildrenHaveNoChildren) {
          const newDefaultNode: HierarchyNode = {
            id: 'Level-1-default-id',
            dbId: '',
            name: 'Default',
            pk: '',
            sk: '',
            children: [...this.nodeData.children],
            parent: this.nodeData,
            isNodeEdited: false,
            isAssetConfigured: false,
          };
          this.nodeData.children = [newDefaultNode];
        }
      }
    }
    if (!this.level2Id && this.level3Id && !this.level1Id) {
      if (
        this.nodeData &&
        this.nodeData.children &&
        this.nodeData.children.length > 0
      ) {
        const newDefaultNode: HierarchyNode = {
          id: 'Level-2-default-id',
          dbId: '',
          name: 'Default',
          pk: '',
          sk: '',
          children: [...this.nodeData.children],
          parent: this.nodeData,
          isNodeEdited: false,
          isAssetConfigured: false,
        };
        const parentDefaultNode: HierarchyNode = {
          id: 'Level-1-default-id',
          dbId: '',
          name: 'Default',
          pk: '',
          sk: '',
          children: [newDefaultNode],
          parent: this.nodeData,
          isNodeEdited: false,
          isAssetConfigured: false,
        };
        if (this.nodeData?.children) {
          this.nodeData.children = [parentDefaultNode];
        }
      }
    }
    if (this.nodeData) {
      const newHierarchyTree: LevelValueCreateInput = {
        primaryCompanyId: this.storePrimaryCompanyid!,
        level1Values: [],
      };

      if (!this.nodeData!.children) {
        this.nodeData!.children = [];
      }
      this.nodeData!.children.forEach((level1Node) => {
        const level1Data = this.collectHierarchyData(level1Node);
        newHierarchyTree.level1Values.push(level1Data);
      });
      this.hierarchyData = newHierarchyTree;
    }
  }

  collectHierarchyData(node: HierarchyNode): Level1ValueInput {
    const level1Data: Level1ValueInput = {
      value: node.name,
      level2Values: [],
    };

    if (node.children && node.children.length > 0) {
      node.children.forEach((level2Node) => {
        const level2Data: Level2ValueInput = {
          value: level2Node.name,
          level3Values: [],
        };

        if (level2Node.children && level2Node.children.length > 0) {
          level2Node.children.forEach((level3Node) => {
            const level3Data: Level3ValueInput = {
              value: level3Node.name,
            };
            level2Data.level3Values.push(level3Data);
          });
        }

        level1Data.level2Values.push(level2Data);
      });
    }

    return level1Data;
  }

  popupForSuccess() {
    this.popupDialogService.openDialog(
      SUCCESS_SETUP_HIERARCHY_HEADING,
      SUCCESS_SETUP_HIERARCHY_MSG,
      SUCCESS,
      CONTINUE,
      () => this.router.navigate([SETUP_COMPANY_OVERVIEW]),
    );
  }

  toasterForSuccess() {
    this.toastr.showSuccess(SUCCESS_SETUP_HIERARCHY_HEADING, TOASTER_SUCCESS);
    this.router.navigate([SETUP_SEGMENTS_ASSETS]);
  }

  onCancel() {
    this.popupDialogService.openDialog(
      CANCEL_TEMPLATE_HEADING,
      CANCEL_TEMPLATE_MSG_HIERARCHY,
      FAILED,
      CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
      () => this.router.navigate([SETUP_COMPANY_OVERVIEW]),
      true,
      NO,
    );
  }

  onSaveAndNext() {
    this.isSaveAndNextClicked = true;
      this.isLoading = true;
      this.saveHierarchy();
      if (this.hierarchyData) {
        this.createAllLevelValuesForPrimaryCompany(this.hierarchyData);
      } else {
        this.toasterForSuccess();
      }
  }

  onSaveAndExit() {
    if(this.isEdit){
      this.isLoading = true;
      if(!this.setupHierachyServie.nodeData){
        this.storeTreeData();
      }
      if(this.setupHierachyServie.nodeData){
        this.isSaveAndExitClicked = true;
        const _input = this.createLevelValueUpdateInput(this.setupHierachyServie.nodeData, this.levelValues?.level1Values, this.storePrimaryCompanyid ?? '')
        if(_input){
          this.updateAllLevelValuesForPrimaryCompany(_input);
        }
      }
    }else{
      this.isLoading = true;
    this.isSaveAndExitClicked = true;
    this.saveHierarchy();
    if (this.hierarchyData) {
      this.createAllLevelValuesForPrimaryCompany(this.hierarchyData);
    } else {
      this.popupForSuccess();
    }
    }
    
  }

  storeTreeData() {
    const rootNode = this.findRootNode(this.hierarchyTree[0]);

    const allTreeData = this.collectTreeData(rootNode);

    const rootNodeData = allTreeData.find((node) => node.parent === null);
    const rootNodeCopy: HierarchyNode = this.deepCopyWithoutParent(
      rootNodeData!,
    );

    // Process the deep copy to remove nodes and modify names
    const processedNode = this.processTreeData(rootNodeCopy);
    if (processedNode) {
      this.setupHierachyServie.nodeData = processedNode;
    } else {
      console.log('Root node was deleted because it did not contain a colon.');
    }
  }
  findRootNode(node: HierarchyNode): HierarchyNode {
    let currentNode: HierarchyNode | undefined = node;

    while (currentNode && currentNode.parent !== null) {
      currentNode = currentNode.parent;
    }
    if (!currentNode) {
      throw new Error('Root node not found');
    }
    return currentNode;
  }

  deepCopyWithoutParent(node: HierarchyNode): HierarchyNode {
    const copy: HierarchyNode = {
      id: node.id,
      dbId: node.dbId,
      name: node.name,
      pk: node.pk,
      sk:node.sk,
      isNodeEdited: node.isNodeEdited,
      children: node.children
        ? node.children.map((child) => this.deepCopyWithoutParent(child))
        : [],
        isAssetConfigured:false,
    };

    return copy;
  }

  processTreeData(node: HierarchyNode | null): HierarchyNode | null {
    if (!node || !node.name.includes(': ')) {
      return null;
    }
    const colonIndex = node.name.indexOf(': ');
    node.name = node.name.substring(colonIndex + 1).trim();

    if (node.children && node.children.length > 0) {
      node.children = node.children
        .map((child) => this.processTreeData(child))
        .filter((child) => child !== null) as HierarchyNode[];
    }

    return node;
  }

  collectTreeData(node: HierarchyNode): HierarchyNode[] {
    let nodes: HierarchyNode[] = [node];

    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        nodes = nodes.concat(this.collectTreeData(child));
      }
    }

    return nodes;
  }


  async createAllLevelValuesForPrimaryCompany(input: LevelValueCreateInput) {
    try {
      const response =
        await this.setupHierachyServie.createAllLevelValuesForPrimaryCompany(
          input,
        );
      if (response && this.isSaveAndExitClicked) {
        this.popupForSuccess();
      } else if (response && this.isSaveAndNextClicked) {
        this.toasterForSuccess();
      } else {
        this.popupDialogService.openDialog(
          FAILURE_SETUP_HIERARCHY_HEADING,
          FAILURE_SETUP_HIERARCHY_HEADING_MSG,
          FAILED,
          CLOSE,
        );
      }
      this.isLoading = false;
    } catch (error) {
      console.log(error);
    }
  }

  async updateAllLevelValuesForPrimaryCompany(input: LevelValueUpdateInput) {
    try {
      const response =
        await this.setupHierachyServie.updateAllLevelValuesForPrimaryCompany(
          input,
        );
      if(response?.status == ERROR && response.error?.message){
        this.toastr.showError(response.error?.message, ERROR);
      }
      else if (response && this.isSaveAndExitClicked) {
        this.popupForSuccess();
      } else if (response && this.isSaveAndNextClicked) {
        this.toasterForSuccess();
      } else {
        this.popupDialogService.openDialog(
          FAILURE_UPDATE_HIERARCHY_HEADING,
          FAILURE_UPDATE_HIERARCHY_HEADING_MSG,
          FAILED,
          CLOSE,
        );
      }
      this.isLoading = false;
    } catch (error) {
      console.log(error);
    }
  }
}
