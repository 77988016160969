import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  EMPTY_STRING,
} from '../../shared/constants';
import { Injectable } from '@angular/core';

export interface SurveyRouteDataStateModel {
  routeName: string | null;
  routeDescription: string | null;
  selectedRoute: any | null;
}

export class SetSurveyRouteData {
  static readonly type = "[SurveyRouteData] Set Survey";
  constructor(
    public routeName: string,
    public routeDescription: string,
    public selectedRoute: any,
  ) {}
}

export class RemoveSurveyRouteData {
  static readonly type = "[SurveyRouteData] Remove Survey";
}

@State<SurveyRouteDataStateModel>({
  name: "surveyRouteDataSetup",
  defaults: {
    routeName: null,
    routeDescription: null,
    selectedRoute: null,
  },
})
@Injectable()
export class SurveyRouteDataState {
  @Selector()
  static getrouteName(state: SurveyRouteDataStateModel) {
    return state.routeName;
  }

  @Selector()
  static getrouteDescription(state: SurveyRouteDataStateModel) {
    return state.routeDescription;
  }

  @Selector()
  static getselectedRoute(state: SurveyRouteDataStateModel) {
    return state.selectedRoute;
  }

  @Action(SetSurveyRouteData)
  SetSurveyRouteData(
    { patchState }: StateContext<SurveyRouteDataStateModel>,
    {
      routeName,
      routeDescription,
      selectedRoute,
    }: SetSurveyRouteData,
  ): void {
    patchState({
      routeName: routeName,
      routeDescription: routeDescription,
      selectedRoute: selectedRoute,
    });
  }
  @Action(RemoveSurveyRouteData)
  RemoveSurveyRouteData({
    setState,
  }: StateContext<SurveyRouteDataStateModel>): void {
    setState({
      routeName: EMPTY_STRING,
      routeDescription: EMPTY_STRING,
      selectedRoute: EMPTY_STRING,
    });
  }
}
