import { LogLevel } from "angular-auth-oidc-client";

export const environment = {
  production: false,
  graphqlEndpoint: 'https://wuqi565qdnhtbmpjvkeahixwvi.appsync-api.ca-central-1.amazonaws.com/graphql',
  graphqlRegion: 'ca-central-1',
  graphqlApiKey: 'da2-j5b376ldpneijh3fjkp26cskoi',
  authConfig: {
    authority: 'https://sts.mtx-staging.corviewng.cortalk.com',
    redirectUrl: 'https://qa.corview.report/auth-return',
    postLogoutRedirectUri: 'https://qa.corview.report/',
    clientId: 'syrcDashboard',
    scope: 'openid profile corview.api corview.api.readonly offline_access',
    responseType: 'code',
    //silentRenewUrl: `${window.location.origin}/silent-renew.html`,
    startCheckSession: false,
    silentRenew: true,
    useRefreshToken: true,
    renewTimeBeforeTokenExpiresInSeconds: 30,
    logLevel: LogLevel.Debug,
    historyCleanupOff: true,
    secureRoutes: ['https://qa.corview.report', 'https://qa.corview.report/dashboard'],
  }
};
