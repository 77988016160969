import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { SELECTED_USER_STATE, SELECTED_USER_TYPE_SET, SELECTED_USER_TYPE_SET_REMOVE} from "../../shared/constants";

export interface SelectedUser {
    isEditable: boolean;
    selectedUserId: string | null;
}
export class SetSelectedUser {
    static readonly type = SELECTED_USER_TYPE_SET;
    constructor(
        public isEditable: boolean,
        public selectedUserId: string,
    ) {}
}
export class RemoveSelectedUser {
    static readonly type = SELECTED_USER_TYPE_SET_REMOVE;
}
@State<SelectedUser>({
    name: SELECTED_USER_STATE,
    defaults: {
        isEditable: false,
        selectedUserId: null
    }
})

@Injectable()
export class SelectedUserState {
    @Selector()
    static getSelectedUserId(state: SelectedUser) {
        return state.selectedUserId;
    }
    @Selector()
    static getSelectedUserIsEdited(state: SelectedUser) {
        return state.isEditable;
    }

    @Action(SetSelectedUser)
    SetSelectedUser(
        { patchState }: StateContext<SelectedUser>,
        {
            isEditable,
            selectedUserId,
        }: SetSelectedUser,
    ): void {
        patchState({
            isEditable: isEditable,
            selectedUserId : selectedUserId
        });
    }
    @Action(RemoveSelectedUser)
    RemoveSelectedUser({
        setState,
    }: StateContext<SelectedUser>): void {
        setState({
            isEditable: false,
            selectedUserId: null
        });
    }
}