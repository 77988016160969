import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges, OnChanges,
} from '@angular/core';
@Component({
  selector: 'app-custom-frequency',
  templateUrl: './custom-frequency.component.html',
  styleUrl: './custom-frequency.component.css',
})
export class CustomFrequencyComponent implements OnChanges {
  @Input() OpenDialog = false;
  @Output() isDialogOpenFlag=new EventEmitter<boolean>();
  @Output() customFrequency = new EventEmitter<{
    formattedDate: string | null;
    repeatValue: string;
    repeatNumber: number;
    customFrequencyString: string;
  }>();
  
  public startDate: Date = new Date();
  public repeatOptions: string[] = ['Days', 'Weeks', 'Months', 'Years'];
  public selectedRepeatOption = 'Months';
  public repeatInterval = 2;
  isDialogOpen = false; // Initially, the dialog is closed

  customVal = '';

  constructor(private datePipe: DatePipe) {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes['OpenDialog']) {
      this.isDialogOpen = this.OpenDialog;
    }
  }

  public open(): void {
    this.isDialogOpen = true;
  }

  public closePopup() {
    this.isDialogOpen = false;
    this.isDialogOpenFlag.emit(this.isDialogOpen)
  }

  public onSave(): void {
    this.isDialogOpen = false;
    this.generateCustomValue();
  }

  public generateCustomValue(): void {
    const date = new Date(this.startDate);

    // Format the date as dd-MM-yyyy using DatePipe
    const formattedDate = this.datePipe.transform(date, 'dd-MM-yyyy');
    const customFrequencyString = `Occurs every ${this.repeatInterval} ${this.selectedRepeatOption.toLowerCase()} starting from ${formattedDate}`;
    // this.customFrequency.emit(this.customVal);

    const frequencyDetails = {
      formattedDate: formattedDate,
      repeatValue: this.selectedRepeatOption.toLowerCase(),
      repeatNumber: this.repeatInterval,
      customFrequencyString: customFrequencyString,
    };
    this.customFrequency.emit(frequencyDetails);
  }

  public onCancel(): void {
    this.isDialogOpen = false;
    this.isDialogOpenFlag.emit(this.isDialogOpen)
  }
}
