import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Country, State, City } from 'country-state-city';
import {
  CompanyOption,
  fileUploadEntityType,
  FileUploadInput,
  fileUploadType,
  PrimaryCompany,
  PrimaryCompanyCreateInput,
  PrimaryCompanyResponse,
  PrimaryCompanyUpdateInput,
  TimeZoneOption,
} from '../../../awsAppSync/API';
import { CompanyService } from '../../core/services/company.service';
import {
  CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
  CANCEL_TEMPLATE_HEADING,
  CANCEL_TEMPLATE_MSG,
  CLOSE,
  CREATE_ASSET_ERROR,
  EMPTY_STRING,
  FAILED,
  NO,
  PRIMARY_COMPANY_ADDED_FAILED,
  PRIMARY_COMPANY_ADDED_SUCCESS,
  PRIMARY_COMPANY_FAILURE_DUPLICATE_MSG,
  PRIMARY_COMPANY_FAILURE_MSG,
  PRIMARY_COMPANY_UPDATE_FAILED,
  REDIRECT_ADD_USER,
  SETUP_COMPANY_OVERVIEW,
  TOASTER_SUCCESS,
  UPDATE_ASSET_ERROR,
} from '../../shared/constants';
import { PopupDialogService } from '../../shared/popup-dialog/popup-dialog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploadService } from '../../core/services/file-upload.service';
import { imageFileValidator, noWhitespaceValidator } from '../../shared/helper';
import { Store } from '@ngxs/store';
import { SetSetupHierarchy } from '../../core/store/setup-hierarchy.state';
import { CustomToastrService } from '../../shared/ngx-toastr/custom-toastr.service';
import { atLeastOneFieldRequiredValidator } from '../../shared/validators/validators';
import { SetPCUserData } from '../../core/store/user-admin.state.state';
@Component({
  selector: 'app-create-primary-company',
  templateUrl: './create-primary-company.component.html',
  styleUrl: './create-primary-company.component.css',
})
export class CreatePrimaryCompanyComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput!: ElementRef;
  companyForm: FormGroup;
  countries: any[] = [];
  states: any[] = [];
  cities: any[] = [];
  allTimeZone: (TimeZoneOption | null)[] | null | undefined;
  companyOptions: (CompanyOption | null)[] | null | undefined;
  selectedTimeZone = '';
  selectedCountry = '';
  selectedCountryCode = '';
  selectedCountryName = '';
  selectedState = '';
  selectedStateName = '';
  selectedCity = '';
  existingPrimaryCompanyName: string | null | undefined = '';
  uploadedFileName = '';
  uploadedFileUrl: string | ArrayBuffer | null = null;
  file!: File;
  isDisabled = true;
  primaryCompanyId: string | null | undefined;
  newPrimaryCompanyName: string | null = null;
  officeNumber: string | null = null;
  phoneNumber: string | null = null;
  zipCode: string | null = null;
  address: string | null = null;
  newlyCreatePrimaryCompanyId = EMPTY_STRING;
  isEdit = false;
  isLoading = false;
  isTimeZoneDisabled = false; // Control if the dropdown is disabled
  imageURL = '';
  pcId = '';
  currentStep = 1;
  constructor(
    private fb: FormBuilder,
    private companyService: CompanyService,
    private popupDialogService: PopupDialogService,
    private router: Router,
    private fileUploadService: FileUploadService,
    private store: Store,
    private toastr: CustomToastrService,
    private route: ActivatedRoute,
  ) {
    this.companyForm = this.fb.group(
      {
        newPrimaryCompanyName: ['', [noWhitespaceValidator()]],
        existingPrimaryCompany: [''],
        corporateOfficeNumber: [
          '',
          [Validators.required, Validators.pattern(/^[\d+-]+$/)],
        ],
        phoneNumber: ['', Validators.pattern(/^[\d+-]+$/)],
        country: [''],
        stateProvince: [''],
        postalZipCode: ['', [noWhitespaceValidator()]],
        city: [''],
        address: ['', [noWhitespaceValidator()]],
        timeZone: ['', Validators.required],
        newCompanyLogo: ['', [Validators.required, imageFileValidator()]],
      },
      {
        validators: atLeastOneFieldRequiredValidator([
          'newPrimaryCompanyName',
          'existingPrimaryCompany',
        ]),
      }, // Pass fields dynamically
    );
  }
  ngOnInit(): void {
    this.isEdit = false;
    // Retrieve the company ID from the query parameters
    this.route.queryParams.subscribe((params) => {
      this.pcId = params['Id'];
      if (this.pcId) {
        this.isEdit = true;
      }
    });
    this.countries = Country.getAllCountries();
    if (this.isEdit) {
      this.getPrimaryCompanyDetails(this.pcId);
      this.companyForm.get('timeZone')?.disable();
      this.companyForm.get('existingPrimaryCompany')?.disable();
      this.companyForm.get('newPrimaryCompanyName')?.disable();
    }

    this.companyForm.valueChanges.subscribe((values) => {
      this.isDisabled = this.areAllFieldsEmpty(values);
    });

    this.loadCompanyOptions();
    this.loadTimeZone();
    this.handleFormChanges();

    if (this.isEdit) {
      const newCompanyLogoControl = this.companyForm.get('newCompanyLogo');
      newCompanyLogoControl?.clearValidators(); // This removes all validators
      newCompanyLogoControl?.updateValueAndValidity(); // Recalculate the control's validation statu
    }
  }

  ngOnDestroy() {
    this.isEdit = false;
    this.resetForm();
  }

  handleFormChanges(): void {
    this.companyForm
      .get('newPrimaryCompanyName')
      ?.valueChanges.subscribe((value) => {
        if (value) {
          this.companyForm.get('existingPrimaryCompany')?.disable();
        } else {
          this.companyForm.get('existingPrimaryCompany')?.enable();
        }
      });

    this.companyForm
      .get('existingPrimaryCompany')
      ?.valueChanges.subscribe((value) => {
        if (value) {
          this.companyForm.get('newPrimaryCompanyName')?.disable();
        } else {
          this.companyForm.get('newPrimaryCompanyName')?.enable();
        }
      });
  }

  resetForm() {
    this.companyForm.reset();
    this.companyForm.get('newPrimaryCompanyName')?.setValue('');
    this.companyForm.get('phoneNumber')?.setValue('');
    this.companyForm.get('corporateOfficeNumber')?.setValue('');
    this.companyForm.get('postalZipCode')?.setValue('');
    this.companyForm.get('country')?.setValue('');
    this.companyForm.get('state')?.setValue('');
    this.companyForm.get('city')?.setValue('');
    this.companyForm.get('address')?.setValue('');
    this.companyForm.get('postalZipCode')?.setValue('');
    this.companyForm.get('timeZone')?.setValue('');
    this.removeFile();
  }

  areAllFieldsEmpty(values: any): boolean {
    return Object.values(values).every(
      (value) => value === '' || value === null || value === undefined,
    );
  }

  async bindPrimaryCompanyDetails(details: PrimaryCompany | null) {
    if (details) {
      if (details.primaryCompany) {
        this.newPrimaryCompanyName = details.primaryCompany;
      }
      if (details.primaryCompany) {
        this.companyForm
          .get('newPrimaryCompanyName')
          ?.setValue(details.primaryCompany);
      }

      if (details.officeNumber) {
        this.companyForm
          .get('corporateOfficeNumber')
          ?.setValue(details.officeNumber);
      }

      if (details.phoneNumber) {
        this.companyForm.get('phoneNumber')?.setValue(details.phoneNumber);
      }

      if (details.zipCode) {
        this.companyForm.get('postalZipCode')?.setValue(details.zipCode);
      }
      if (details.address) {
        this.companyForm.get('address')?.setValue(details.address);
      }

      if (details.country) {
        const selectedCountry = this.countries.find(
          (country) => country.name === details.country,
        );

        if (selectedCountry) {
          this.selectedCountry = selectedCountry.name;
          this.selectedCountryCode = selectedCountry.isoCode;
          this.companyForm.get('country')?.setValue(selectedCountry.name);

          this.states = State.getStatesOfCountry(selectedCountry.isoCode);
        }
      }

      if (details.state) {
        if (details.state && this.selectedCountry) {
          const selectedState = this.states.find(
            (state) => state.name === details.state,
          );

          if (selectedState) {
            this.selectedState = selectedState.isoCode;
            this.companyForm.get('stateProvince')?.setValue(selectedState.name);

            this.cities = City.getCitiesOfState(
              this.selectedCountryCode,
              selectedState.isoCode,
            );
          }
        }
      }

      if (details.city) {
        if (details.city && this.selectedState) {
          this.selectedCity = details.city;
          this.companyForm.get('city')?.setValue(details.city);
        }
      }

      if (details.timeZone) {
        if (details.timeZoneId) {
          this.selectedTimeZone = details.timeZoneId;
          this.companyForm.get('timeZone')?.setValue(details.timeZoneId);
        }
      }
      if (details.imageURL) {
        this.imageURL = details.imageURL;
        const presignedURL =
          (await this.getPreSignedURLForUploadedFile(this.imageURL)) ?? '';
        this.uploadedFileUrl = presignedURL;
      }
    }
  }

  onCountryChange(event: Event): void {
    const selectedCountryName = (event.target as HTMLSelectElement).value;
    const selectedCountry = this.countries.find(
      (country) => country.name === selectedCountryName,
    );

    if (selectedCountry) {
      this.selectedCountryName = selectedCountry.name;
      this.selectedCountry = selectedCountry.isoCode;
      this.states = State.getStatesOfCountry(selectedCountry.isoCode);
      this.cities = [];
    }
  }

  onStateChange(event: Event): void {
    const selectedStateName = (event.target as HTMLSelectElement).value;
    const selectedState = this.states.find(
      (state) => state.name === selectedStateName,
    );

    if (selectedState) {
      this.selectedStateName = selectedState.name;
      this.cities = City.getCitiesOfState(
        this.selectedCountry,
        selectedState.isoCode,
      );
    }
  }

  onTimeZoneChange(event: Event): void {
    this.selectedTimeZone = (event.target as HTMLSelectElement).value;
  }

  async createPrimaryCompany() {
    try {
      const input: PrimaryCompanyCreateInput = {
        primaryCompany:
          this.companyForm.get('newPrimaryCompanyName')?.value ?? '',
        corViewCompanyId:
          this.companyForm.get('existingPrimaryCompany')?.value ?? '',
        imageName: this.uploadedFileName,
        phoneNumber: this.companyForm.get('phoneNumber')?.value,
        officeNumber: this.companyForm.get('corporateOfficeNumber')?.value,
        country: this.companyForm.get('country')?.value,
        state: this.companyForm.get('stateProvince')?.value,
        zipCode: this.companyForm.get('postalZipCode')?.value,
        city: this.companyForm.get('city')?.value,
        address: this.companyForm.get('address')?.value,
        timeZone: this.companyForm.get('timeZone')?.value,
      };
      this.isLoading = true;
      const _createdPrimaryCompany =
        await this.companyService.createPrimaryCompany(input);
      return _createdPrimaryCompany;
    } finally {
      this.isLoading = false;
    }
  }

  async updatePrimaryCompany() {
    try {
      const input: PrimaryCompanyUpdateInput = {
        id: this.pcId,
        imageName: this.uploadedFileName,
        phoneNumber: this.companyForm.get('phoneNumber')?.value,
        officeNumber: this.companyForm.get('corporateOfficeNumber')?.value,
        country: this.companyForm.get('country')?.value,
        state: this.companyForm.get('stateProvince')?.value,
        zipCode: this.companyForm.get('postalZipCode')?.value,
        city: this.companyForm.get('city')?.value,
        address: this.companyForm.get('address')?.value,
      };
      this.isLoading = true;
      const _updatedPrimaryCompany =
        await this.companyService.updatePrimaryCompany(input);
      return _updatedPrimaryCompany;
    } finally {
      this.isLoading = false;
    }
  }

  // async onSubmit() {
  //   this.companyForm.markAllAsTouched();
  //   if (this.companyForm.valid) {
  //     this.isLoading = true;
  //     try {
  //       let response: PrimaryCompanyResponse | null;
  //       if (this.isEdit) {
  //         response = await this.updatePrimaryCompany();
  //       } else {
  //         response = await this.createPrimaryCompany();
  //       }
  //       if (response?.status === 'Success') {
  //         if (this.uploadedFileName !== '') {
  //           this.uploadImage(response);
  //         }
  //         const dialogRef = this.popupDialogService.openDialog(
  //           this.isEdit == true
  //             ? PRIMARY_COMPANY_UPDATED_SUCCESSFULLY
  //             : PRIMARY_COMPANY_ADDED_SUCCESSFULLY,
  //           SUCCESS_SETUP_HIERARCHY_MSG,
  //           SUCCESS,
  //           CONTINUE,
  //           () => this.router.navigate([SETUP_COMPANY_OVERVIEW]),
  //         );
  //         dialogRef.afterClosed().subscribe(() => {
  //           this.isLoading = false;
  //         });
  //       } else {
  //         let msgFlag = PRIMARY_COMPANY_FAILURE_MSG;
  //         if (
  //           response?.error &&
  //           response?.error?.message?.includes('Duplicate')
  //         ) {
  //           msgFlag = PRIMARY_COMPANY_FAILURE_DUPLICATE_MSG;
  //         }
  //         this.popupDialogService.openDialog(
  //           this.isEdit == true
  //             ? PRIMARY_COMPANY_UPDATE_FAILED
  //             : PRIMARY_COMPANY_ADDED_FAILED,
  //           msgFlag,
  //           FAILED,
  //           CLOSE,
  //         );
  //         this.isLoading = false;
  //       }
  //     } catch (error) {
  //       this.isLoading = false;
  //       console.error(
  //         this.isEdit == true ? UPDATE_ASSET_ERROR : CREATE_ASSET_ERROR,
  //         error,
  //       );
  //     }
  //   } else {
  //     console.log('Form is  invalid');
  //   }
  //   this.newlyCreatePrimaryCompanyId = this.companyService.primaryCompanyId;
  //   const primaryCompanyName = this.companyForm.get('newPrimaryCompanyName')
  //     ?.value
  //     ? this.companyForm.get('newPrimaryCompanyName')?.value
  //     : this.existingPrimaryCompanyName;
  //   this.store.dispatch(
  //     new SetSetupHierarchy(
  //       this.newlyCreatePrimaryCompanyId,
  //       primaryCompanyName,
  //       EMPTY_STRING,
  //       EMPTY_STRING,
  //       false,
  //     ),
  //   );
  // }

  async uploadImage(_createdAsset: PrimaryCompanyResponse) {
    const _imageInputData: FileUploadInput = {
      filename: this.uploadedFileName,
      entityType: fileUploadEntityType.PrimaryCompany,
      entityTypeId: String(_createdAsset.item?.id),
      fileUploadType: fileUploadType.Normal,
    };
    const _getPresignedURL =
      await this.companyService.uploadPrimaryCompanyImage(_imageInputData);
    const _uploadImg = await this.fileUploadService.uploadToS3(
      String(_getPresignedURL?.presignedURL),
      this.file,
      String(_getPresignedURL?.contentType),
    );
  }

  onCancel(): void {
    this.popupDialogService.openDialog(
      CANCEL_TEMPLATE_HEADING,
      CANCEL_TEMPLATE_MSG,
      FAILED,
      CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
      () => this.router.navigate([SETUP_COMPANY_OVERVIEW]),
      true,
      NO,
    );
  }
  async getPrimaryCompanyDetails(primaryCompanyId: string) {
    this.isLoading = true;
    try {
      const primaryCompanyDetails =
        await this.companyService.getPrimaryCompanyDetailsById(
          primaryCompanyId,
        );
      this.bindPrimaryCompanyDetails(primaryCompanyDetails);
    } catch (error) {
      console.error('Error fetching company details', error);
      // Optionally, handle the error (e.g., show a notification)
    } finally {
      this.isLoading = false; // Ensures loader is hidden after the API call
    }
  }

  async dispatchUserDetails(company: PrimaryCompanyResponse) {
    const copmanyId = company.item?.id ?? '';
    const companyName = company.item?.primaryCompany
      ? company.item?.primaryCompany
      : this.existingPrimaryCompanyName;
    const isPCAdmin = true;
    const coreViewCompanyId = company.item?.corViewCompanyId ?? '';
    this.store.dispatch(
      new SetPCUserData(
        isPCAdmin,
        companyName ?? '',
        copmanyId,
        coreViewCompanyId,
      ),
    );
  }
  async onSaveAndaddAdmin() {
    this.companyForm.markAllAsTouched();
    if (this.companyForm.valid) {
      this.isLoading = true;
      try {
        let response: PrimaryCompanyResponse | null;
        if (this.isEdit) {
          response = await this.updatePrimaryCompany();
        } else {
          response = await this.createPrimaryCompany();
        }
        if (response?.status === 'Success') {
          if (this.uploadedFileName !== '') {
            this.uploadImage(response);
          }
          this.toastr.showSuccess(
            PRIMARY_COMPANY_ADDED_SUCCESS,
            TOASTER_SUCCESS,
          );

          await this.dispatchUserDetails(response);

          this.router.navigate([REDIRECT_ADD_USER]);
          this.isLoading = false;
        } else {
          let msgFlag = PRIMARY_COMPANY_FAILURE_MSG;

          if (
            response?.error &&
            response?.error?.message?.includes('Duplicate')
          ) {
            msgFlag = PRIMARY_COMPANY_FAILURE_DUPLICATE_MSG;
          }

          this.popupDialogService.openDialog(
            this.isEdit == true
              ? PRIMARY_COMPANY_UPDATE_FAILED
              : PRIMARY_COMPANY_ADDED_FAILED,
            msgFlag,
            FAILED,
            CLOSE,
          );
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        console.error(
          this.isEdit == true ? UPDATE_ASSET_ERROR : CREATE_ASSET_ERROR,
          error,
        );
      }
    } else {
      console.log('Form is  invalid');
    }
    this.newlyCreatePrimaryCompanyId = this.companyService.primaryCompanyId;
    const primaryCompanyName = this.companyForm.get('newPrimaryCompanyName')
      ?.value
      ? this.companyForm.get('newPrimaryCompanyName')?.value
      : this.existingPrimaryCompanyName;
    this.store.dispatch(
      new SetSetupHierarchy(
        this.newlyCreatePrimaryCompanyId,
        primaryCompanyName,
        EMPTY_STRING,
        EMPTY_STRING,
        false,
      ),
    );
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    const acceptedImageTypes = ['image/jpeg', 'image/png'];

    if (file && acceptedImageTypes.includes(file.type)) {
      this.file = file;
      this.uploadedFileName = file.name;

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.uploadedFileUrl = e.target.result;
      };
      reader.readAsDataURL(file);

      this.companyForm.get('newCompanyLogo')?.setValue(file);
      this.companyForm.get('newCompanyLogo')?.updateValueAndValidity();
    } else {
      // Clear the file input if the format is invalid
      // this.uploadedFileName = null;
      // this.file = null;
      this.companyForm.get('newCompanyLogo')?.setValue(null);
      this.companyForm
        .get('newCompanyLogo')
        ?.setErrors({ invalidImageFormat: true });
    }
  }

  get newCompanyLogoControl(): FormControl {
    return this.companyForm.get('newCompanyLogo') as FormControl;
  }

  removeFile(): void {
    this.uploadedFileName = '';
    this.uploadedFileUrl = null;
    this.companyForm.get('newCompanyLogo')?.setValue(null);
    this.fileInput.nativeElement.value = '';
  }

  async loadCompanyOptions() {
    const response = await this.companyService.getAllCompanyOption();
    this.companyOptions = response?.items;
  }

  async loadTimeZone() {
    const response = await this.companyService.getAllTimeZoneOptions();
    this.allTimeZone = response?.items;
  }

  existingPrimaryCompanyChange(event: any) {
    const selectedCorViewCompanyId = (event.target as HTMLSelectElement).value;
    // Find the company by corViewCompanyId
    const selectedCompany = this.companyOptions?.find(
      (company) => company?.corViewCompanyId === selectedCorViewCompanyId,
    );
    this.existingPrimaryCompanyName = selectedCompany?.name;

    if (selectedCompany) {
      this.companyForm.get('newPrimaryCompanyName')?.setValue('');
      this.companyForm.get('newPrimaryCompanyName')?.disable();

      // Find the time zone using timeZoneId
      const timeZoneOption = this.allTimeZone?.find(
        (timeZone) => timeZone?.id === selectedCompany.timeZoneId,
      );

      if (timeZoneOption) {
        // Set the default value in the form control for timeZone
        this.companyForm.get('timeZone')?.setValue(timeZoneOption.id);

        // Disable the timeZone dropdown
        this.isTimeZoneDisabled = true;
        this.companyForm.get('timeZone')?.disable(); // Alternatively, use this to disable the form control
      } else {
        console.log('No matching time zone found');
      }
    } else {
      console.log('No matching company found');
      // Reset the dropdown and enable it for manual selection
      this.companyForm.get('timeZone')?.setValue(''); // Reset the form control (sets it back to default)
      this.isTimeZoneDisabled = false; // Enable the dropdown
      this.companyForm.get('timeZone')?.enable(); // Enable the form control for manual selection
      this.companyForm.get('newPrimaryCompanyName')?.enable();
    }
  }

  async getPreSignedURLForUploadedFile(imageURL: string) {
    const response =
      await this.companyService.getPreSignedURLForUploadedFile(imageURL);
    return response?.presignedURL;
  }
}
