<!-- <div class="pagination-container">
    <button (click)="goToFirstPage()" [disabled]="currentPage === 1"><span class="fast-forward-left-icon"></span></button>
    <button (click)="goToPreviousPage()" [disabled]="currentPage === 1"><span class="previous-icon"></span></button>
    <span>Showing {{startItem}} to {{endItem}} out of {{totalItems}}</span>
    <button (click)="goToNextPage()" [disabled]="currentPage === totalPages"><span class="next-icon"></span></button>
    <button (click)="goToLastPage()" [disabled]="currentPage === totalPages"><span class="fast-forward-right-icon"></span></button>
  </div> -->

<div style="display: flex;">
  <button (click)="goToFirstPage()" class="fast-forward-left-icon nav-button" [disabled]="currentPage === 1"></button>
  <button (click)="goToPreviousPage()" class="previous-icon nav-button" [disabled]="currentPage === 1"></button>
  <span class="page-count">Showing <strong>{{startItem}} to {{endItem}} out of {{totalItems}}</strong></span>
  <button (click)="goToNextPage()" class="next-icon nav-button" [disabled]="currentPage === totalPages"></button>
  <button (click)="goToLastPage()" class="fast-forward-right-icon nav-button"
    [disabled]="currentPage === totalPages"></button>
  <div class="input-field1">
    <div class="input-container">
      <!-- custom-pagination -->
      <kendo-dropdownlist class="input-field-wrapper" [data]="pageSizes" [(ngModel)]="pageSize" [textField]="'text'"
        [valueField]="'value'" (valueChange)="onPageSizeChange($event)">
      </kendo-dropdownlist>
    </div>
  </div>
</div>