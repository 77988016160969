<div class="container-fluid py-2" style="margin-top: -28px;">
<div class="container-fluid">
  <div class="row align-items-center justify-content-between">
    <div class="col-auto">
      <h5 class="mtx-text-primary" style="margin-left: -12px; margin-top: 22px;">Reports Management</h5>
    </div>
    <div class="col-auto ml-auto">
      <div class="d-flex flex-wrap align-items-center gap-2 justify-content-end">
        <div style="width: 330px; margin-right: -10px;">
          <app-search
            [placeholder]="searchPlaceholder"
            (searchInput)="handleSearchInput($event)"
          ></app-search>
        </div>
        <div *ngIf="permission" class="col-xs-6 col-sm-auto col-md-auto col-lg-auto">
          <button class="template-modal-button btn-background-color" (click)="navigateToCreateNewTemplate()">
            <div class="template-modal-text">
              <span class="template-modal-text">
                <span class="button-text-alignment">{{'REPORTS_TEMPLATE.NEW_TEMPLATE' | translate}}</span>
              </span>
            </div>
          </button>
        </div>
        <div *ngIf="permission" class="col-xs-6 col-sm-auto col-md-auto col-lg-auto">  
          <button id="createReport" class="template-modal-button" (click)="onCreateReportClick()"
            [disabled]="!selectedTemplate">
            <div class="template-modal-text">
              <span class="template-modal-text">
                <span class="button-text-alignment">{{'REPORTS_TEMPLATE.CREATE_REPORT' | translate}}</span>
              </span>
            </div>
          </button>
        </div>
      </div>
    </div>
    </div>
  </div>    

  <div class="mt-3">
    <kendo-grid [kendoGridBinding]="gridData" class="grid-shadow" [height]="410">
      <!-- Radio Button Column -->
      <kendo-grid-column [width]="25" [headerClass]="'firstColGridHeader'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <input type="radio" name="templateSelection" [checked]="dataItem === selectedTemplate"
            (change)="onTemplateSelect(dataItem)" />
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column [width]="100" field="templateName" [title]="'REPORTS_TEMPLATE.TEMPLATE_NAME' | translate"></kendo-grid-column>
      <kendo-grid-column [width]="250" field="description" [title]="'REPORTS_TEMPLATE.TEMPLATE_DESCRIPTION' | translate">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="isExpanded(dataItem.id)">
            {{ dataItem.description }}
          </span>
          <span *ngIf="!isExpanded(dataItem.id)">
            {{ dataItem.description | slice:0:50 }}
            <button class="see-more" (click)="toggleDescription(dataItem.id)">
              {{'REPORTS_TEMPLATE.SEE_MORE' | translate}}
            </button>
          </span>
        </ng-template>

      </kendo-grid-column>
      <kendo-grid-column [width]="100" field="type" [title]="'REPORTS_TEMPLATE.TYPE' | translate"></kendo-grid-column>
      <kendo-grid-column [width]="100" field="lastUsedOn" [title]="'REPORTS_TEMPLATE.LAST_USED_ON' | translate"></kendo-grid-column>
      <!-- Detail Template -->
      <ng-template kendoGridDetailTemplate let-dataItem>
        <div class="colum-table">
          <div class="inner-grid-container">
            <span *ngFor="let detail of dataItem.templateColumns" class="template-column">
              {{ detail }}
            </span>
          </div>
        </div>
      </ng-template>
    </kendo-grid>
  </div>
</div>