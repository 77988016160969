import {
  Component,
  Input,
  OnInit,
  ElementRef,
  SimpleChanges,
  OnChanges,
  HostListener,
} from '@angular/core';
//import * as d3 from 'd3';
import { AssetHistoryService } from '../../../core/services/asset-history.service';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AssetSetupDataState } from '../../../core/store/asset.state';
import Plotly from 'plotly.js-dist';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
@Component({
  selector: 'app-asset-history-chart',
  templateUrl: './asset-history-chart.component.html',
  styleUrls: ['./asset-history-chart.component.css'],
})
export class AssetHistoryChartComponent implements OnInit, OnChanges {
  @Input() assetId?: string; // New input for filtering data by assetId
  @Input() data: any;
  @Input() inputStartDate: Date | null = new Date();
  @Input() inputEndDate: Date | null = new Date();
  @Input() reloadChart = false;
  measurementTypeArray: string[] = [];
  selectedChartType = 'scatter';
  colors: string[] = [
    '#3498DB', // Blue
    '#FFA500', // Orange
    '#28A745', // Green
    '#FF4136', // Red
    '#8E44AD', // Purple
    '#F1C40F', // Yellow
    '#E67E22', // Deep Orange
    '#1ABC9C', // Teal
    '#C0392B', // Dark Red
    '#9B59B6', // Light Purple
    '#2ECC71', // Light Green
    '#FF69B4', // Hot Pink
    '#34495E', // Navy Blue
    '#BDC3C7', // Light Grey
    '#7F8C8D', // Dark Grey
    '#F39C12', // Amber
    '#D35400', // Burnt Orange
    '#2980B9', // Deep Blue
    '#16A085', // Emerald Green
    '#E74C3C', // Bright Red
  ];

  colorsLight: string[] = [
    '#B2D8FF', // Very Light Blue
    '#FFE4B3', // Very Light Orange
    '#A3E4D7', // Very Light Green
    '#FF9999', // Very Light Red
    '#D9B5E6', // Very Light Purple
    '#FCE67D', // Very Light Yellow
    '#F9C8A3', // Very Light Deep Orange
    '#A1F7F1', // Very Light Teal
    '#F2B0B0', // Very Light Dark Red
    '#E3C3E9', // Very Light Light Purple
    '#A9E1A2', // Very Light Light Green
    '#FFCCE5', // Very Light Hot Pink
    '#A2B5C8', // Very Light Navy Blue
    '#E0E3E6', // Very Light Grey
    '#BCC6C8', // Very Light Dark Grey
    '#FBE0A1', // Very Light Amber
    '#F5A28D', // Very Light Burnt Orange
    '#A8D3E5', // Very Light Deep Blue
    '#C7E6D8', // Very Light Emerald Green
    '#FFB8A8', // Very Light Bright Red
  ];

  isStacked = true; // need to add toggle later

  isFilterOpen = false;
  selectedMeasurementsWithoutSegment: Measurement[] = [];
  graphData: any;
  measurements: Measurement[] = [];
  selectedMeasurements: Measurement[] = this.measurements.filter(
    (m) => !m.selected,
  );
  isDateChanged = false;

  startDate$: Observable<Date | null>;
  endDate$: Observable<Date | null>;
  storestartDate: Date = new Date();
  storeendDate: Date = new Date();
  minDateForGridData: Date | null = new Date();
  maxDateForGridData: Date | null = new Date();

  constructor(
    private elementRef: ElementRef,
    private store: Store,
    private assetHistoryService: AssetHistoryService,
  ) {
    this.startDate$ = this.store.select(AssetSetupDataState.getAssetStartDate);
    this.endDate$ = this.store.select(AssetSetupDataState.getAssetEndDate);
  }

  private parsedData: { date: Date; value: number }[] = [];

  ngOnInit(): void {
    setTimeout(() => {
      this.generateChartData();
    }, 5000);
    this.createLinePlot();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['inputStartDate'] ||
      changes['inputEndDate'] ||
      changes['reloadChart']
    ) {
      this.startDate$.subscribe((startDate) => {
        this.storestartDate = startDate!;
      });
      this.endDate$.subscribe((endDate) => {
        this.storeendDate = endDate!;
      });
      this.generateChartData();
      this.selectedMeasurements = [];
      this.graphData = [];
      this.parsedData = [];
      this.selectedMeasurementsWithoutSegment = [];
      this.createScatterPlot();
      this.isDateChanged = true;
    }
  }

  generateChartData() {
    this.graphData = this.assetHistoryService.graphData;
    this.measurementTypeArray = this.assetHistoryService.measurementTypeArray;
    this.measurements = this.measurementTypeArray.map((type) => ({
      name: type,
      selected: false,
    }));
  }

  generateChartPlotting() {
    this.graphData = this.assetHistoryService.graphData;
    const dataForPlot = this.segregateDataByMeasurementType(this.graphData);
    const valueWithSegment = Object.keys(dataForPlot)[0];
    this.parsedData = dataForPlot[valueWithSegment].map((d) => ({
      date: new Date(d.dateTime),
      value: +d.value,
    }));
  }

  segregateDataByMeasurementType(data: any) {
    const segregatedData: Record<
      string,
      { dateTime: string; value: string }[]
    > = {};
    for (const item of data) {
      const { measurementType, dateTime, value } = item;
      if (!segregatedData[measurementType]) {
        segregatedData[measurementType] = [];
      }
      segregatedData[measurementType].push({ dateTime, value });
    }
    return segregatedData;
  }

  getDotColor(index: number): string {
    return this.colors[index % this.colors.length];
  }

  getChartBackgroundColor(index: number): string {
    return this.colorsLight[index % this.colorsLight.length];
  }

  ShowPopUp(event: MouseEvent) {
    this.isFilterOpen = !this.isFilterOpen;
    event.stopPropagation(); 
  }

@HostListener('document:click', ['$event'])
onClickOutside(event: MouseEvent) {
  const clickedInsidePopup = this.elementRef.nativeElement.contains(event.target);
  if (!clickedInsidePopup) {
    this.isFilterOpen = false;
  }
}


  onMeasurementChange(measurement: Measurement) {
    if (this.isDateChanged) {
      this.generateChartPlotting();
    }
    this.graphData = this.assetHistoryService.graphData;
    measurement.selected = !measurement.selected;
    this.selectedMeasurements = this.measurements.filter((m) => m.selected);
    this.selectedMeasurementsWithoutSegment = this.selectedMeasurements.map(
      (item) => {
        const updatedName = item.name.split(' - ')[0];
        return { ...item, name: updatedName };
      },
    );
    if (this.isStacked) {
      const chartContainer = document.getElementById('chart-container');
      chartContainer!.innerHTML = '';

      this.selectedMeasurementsWithoutSegment.forEach((measurement, index) => {
        const chartId = `chart-${index}`;
        const chartDiv = document.createElement('div');
        chartDiv.id = chartId;
        chartDiv.style.height = '400px';
        chartDiv.style.marginBottom = '20px';
        chartContainer!.appendChild(chartDiv);

        if (this.selectedChartType === 'scatter') {
          this.createStackedScatterPlot(measurement, chartId, index);
        } else {
          this.createStackedLinePlot(measurement, chartId, index);
        }
      });
    } else {
      if (this.selectedChartType == 'scatter') {
        this.createScatterPlot();
      } else {
        this.createLinePlot();
      }
    }
  }

  onChartTypeChange(event: Event): void {
    const selectedChartType = (event.target as HTMLSelectElement).value;
    this.selectedChartType = selectedChartType;
    if (this.isStacked) {
      const chartContainer = document.getElementById('chart-container');
      if (chartContainer) {
        chartContainer.innerHTML = '';
      }

      this.selectedMeasurementsWithoutSegment.forEach((measurement, index) => {
        const chartId = `chart-${measurement.name}-${index}`;
        const chartDiv = document.createElement('div');
        chartDiv.id = chartId;
        chartDiv.style.height = '400px';
        chartDiv.style.marginBottom = '20px';
        chartContainer?.appendChild(chartDiv);

        if (selectedChartType === 'line') {
          this.createStackedLinePlot(measurement, chartId, index);
        } else if (selectedChartType === 'scatter') {
          this.createStackedScatterPlot(measurement, chartId, index);
        }
      });
    } else {
      if (selectedChartType === 'line') {
        this.createLinePlot();
      } else if (selectedChartType === 'scatter') {
        this.createScatterPlot();
      }
    }
  }

  createStackedScatterPlot(
    measurement: Measurement,
    chartId: string,
    index: number,
  ): void {
    const dataForSelectedTypes = this.selectedMeasurementsWithoutSegment.map(
      (type) => ({
        type: type.name,
        data: this.segregateDataByMeasurementType(this.graphData)[
          type.name
        ].map((d) => ({
          date: new Date(d.dateTime),
          value: +d.value,
        })),
      }),
    );

    const currentMeasurementData = dataForSelectedTypes.find(
      (dataSet) => dataSet.type === measurement.name,
    );

    if (!currentMeasurementData) {
      console.warn(`No data found for measurement: ${measurement.name}`);
      return;
    }

    const scatterData = [
      {
        x: currentMeasurementData.data.map((d) => d.date),
        y: currentMeasurementData.data.map((d) => d.value),
        mode: 'markers',
        type: 'scatter',
        name: measurement.name,
        marker: { size: 8, color: this.getDotColor(index) },
      },
    ];
    const backgroundColor = this.getChartBackgroundColor(index);

    const layout: Partial<Plotly.Layout> = {
      title: '',
      xaxis: { title: 'Date', type: 'date', tickformat: '%b %d, %Y' },
      yaxis: { title: measurement.name, side: 'left' },
      margin: { t: 30 },
      dragmode: 'pan',
      plot_bgcolor: backgroundColor,
    };

    const config: Partial<Plotly.Config> = {
      scrollZoom: true,
      responsive: true,
      displayModeBar: false,
    };

    Plotly.newPlot(chartId, scatterData, layout, config);
  }

  createStackedLinePlot(
    measurement: Measurement,
    chartId: string,
    index: number,
  ): void {
    const dataForSelectedTypes = this.selectedMeasurementsWithoutSegment.map(
      (type) => ({
        type: type.name,
        data: this.segregateDataByMeasurementType(this.graphData)[
          type.name
        ].map((d) => ({
          date: new Date(d.dateTime),
          value: +d.value,
        })),
      }),
    );

    const currentMeasurementData = dataForSelectedTypes.find(
      (dataSet) => dataSet.type === measurement.name,
    );

    if (!currentMeasurementData) {
      console.warn(`No data found for measurement: ${measurement.name}`);
      return;
    }

    const lineData = [
      {
        x: currentMeasurementData.data.map((d) => d.date),
        y: currentMeasurementData.data.map((d) => d.value),
        mode: 'lines+markers',
        type: 'scatter',
        name: measurement.name,
        line: {
          color: this.getDotColor(index),
          width: 2,
        },
        marker: { size: 6 },
      },
    ];

    const backgroundColor = this.getChartBackgroundColor(index);

    const layout: Partial<Plotly.Layout> = {
      title: '',
      xaxis: { title: 'Date', type: 'date', tickformat: '%b %d, %Y' },
      yaxis: { title: measurement.name, side: 'left' },
      margin: { t: 30 },
      dragmode: 'pan',
      plot_bgcolor: backgroundColor,
    };

    const config: Partial<Plotly.Config> = {
      scrollZoom: true,
      responsive: true,
      displayModeBar: false,
    };

    Plotly.newPlot(chartId, lineData, layout, config);
  }

  createScatterPlot(): void {
    const dataForSelectedTypes = this.selectedMeasurementsWithoutSegment.map(
      (type) => ({
        type,
        data: this.segregateDataByMeasurementType(this.graphData)[
          type.name
        ].map((d) => ({
          date: new Date(d.dateTime),
          value: +d.value,
        })),
      }),
    );

    const scatterData: any = dataForSelectedTypes.map((dataSet, index) => ({
      x: dataSet.data.map((d) => new Date(d.date)),
      y: dataSet.data.map((d) => d.value),
      mode: 'markers',
      type: 'scatter',
      name: dataSet.type.name,
      marker: {
        size: 8,
        color: this.getDotColor(index),
      },
    }));

    const layout: Partial<Plotly.Layout> = {
      title: '',
      xaxis: {
        title: 'DATE',
        type: 'date',
        tickformat: '%b %d, %Y',
      },
      yaxis: {
        title: 'VALUE',
      },
      dragmode: 'pan',
      hovermode: 'closest',
    };
    const config: Partial<Plotly.Config> = {
      scrollZoom: true,
      displayModeBar: false,
      responsive: true,
    };
    Plotly.newPlot('chart-container', scatterData, layout, config);
  }

  createLinePlot(): void {
    const dataForSelectedTypes = this.selectedMeasurementsWithoutSegment.map(
      (type) => ({
        type,
        data: this.segregateDataByMeasurementType(this.graphData)[
          type.name
        ].map((d) => ({
          date: new Date(d.dateTime),
          value: +d.value,
        })),
      }),
    );

    const lineData = dataForSelectedTypes.map((dataSet, index) => ({
      x: dataSet.data.map((d) => new Date(d.date)),
      y: dataSet.data.map((d) => d.value),
      mode: 'lines+markers',
      type: 'scatter',
      name: dataSet.type.name,
      line: {
        color: this.getDotColor(index),
        width: 2,
      },
      marker: {
        size: 6,
      },
    }));

    const layout: Partial<Plotly.Layout> = {
      title: '',
      xaxis: {
        title: 'Date',
        type: 'date',
        tickformat: '%b %d, %Y',
      },
      yaxis: {
        title: 'Value',
      },
      dragmode: 'pan',
      autosize: true,
      hovermode: 'closest',
    };

    const config: Partial<Plotly.Config> = {
      scrollZoom: true,
      displayModeBar: false,
      responsive: true,
      modeBarButtonsToAdd: ['zoom2d', 'pan2d', 'resetScale2d'],
    };
    Plotly.newPlot('chart-container', lineData, layout, config);
  }

  onPrintClick(): void {
    const chartContainer = document.getElementById('chart-container');
    if (!chartContainer) {
      console.error('Chart container not found!');
      return;
    }
    html2canvas(chartContainer).then((canvas) => {
      const pdf = new jsPDF();
      const imgData = canvas.toDataURL('image/png');
      pdf.addImage(imgData, 'PNG', 20, 20, 180, 160);
      pdf.save('charts.pdf');
    });
  }
}
interface Measurement {
  name: string;
  selected: boolean;
}
