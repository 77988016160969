import { templateType } from '../../awsAppSync/API';
import { TemplateBase } from '../core/models/template';

//User declaration
//export const USER_ID = 'user1'; // Replace with actual user ID from local storage once login implementation is completed

//Store
export const AUTHENTICATE_USER_STATE = 'user';
export const AUTHENTICATE_USER_STATE_TYPE_SET =
  '[AuthenticateUser] Set Auth User';
export const AUTHENTICATE_USER_STATE_TYPE_REMOVE =
  '[AuthenticateUser] Remove Auth User';
export const ASSET_SETUP_STATE = 'assetSetup';
export const ASSET_SETUP_DATA_STATE = 'assetDataSetup';
export const SURVEY_OVERVIEW_DATA_STATE = 'surveyOverviewDataSetup';
export const USER_PROFILE_DATA_STATE = 'userProfileDataSetup';
export const ASSET_SETUP_STATE_TYPE_SET = '[Asset] Set Asset';
export const ASSET_SETUP_DATA_STATE_TYPE_SET = '[AssetData] Set Asset';
export const SURVEY_OVERVIEW_DATA_STATE_TYPE_SET = '[SurveyData] Set Survey';
export const ASSET_SETUP_STATE_TYPE_REMOVE = '[AssetS] Remove Asset';
export const USER_PROFILE_STATE_TYPE_SET = '[UserProfile] Set UserProfile';
export const USER_PROFILE_STATE_TYPE_REMOVE =
  '[UserProfile] Remove UserProfile';
export const ASSET_SETUP_DATA_STATE_TYPE_REMOVE = '[AssetData] Remove Asset';
export const SURVEY_OVERVIEW_DATA_STATE_TYPE_REMOVE =
  '[SurveyData] Remove Survey';
export const COMPANY_SETUP_STATE = 'companySetup';
export const COMPANY_SETUP_STATE_TYPE_SET = '[Company] Set';
export const COMPANY_SETUP_STATE_TYPE_SET_REMOVE = '[Company] Remove company';
export const REGION_SETUP_STATE = 'regionSetup';
export const REGION_SETUP_STATE_TYPE_SET = '[Region] Set';
export const REGION_SETUP_STATE_TYPE_SET_REMOVE = '[Region] Remove region';
export const SEGMENT_SETUP_STATE = 'segmentSetup';
export const SEGMENT_SETUP_STATE_TYPE_SET = '[Segment] Set';
export const SEGMENT_SETUP_STATE_TYPE_SET_REMOVE = '[Segment] Remove segment';
export const SurveyROUTE_SETUP_STATE = 'SurveyrouteSetup';
export const SurveyROUTE_SETUP_STATE_TYPE_SET = '[Surveyroute] Set';
export const SurveyROUTE_SETUP_STATE_TYPE_SET_REMOVE =
  '[Surveyroute] Remove Surveyroute';
export const ASSETTYPE_SETUP_STATE = 'assettypeSetup';
export const ASSETTYPE_SETUP_STATE_TYPE_SET = '[assettype] Set';
export const ASSETTYPE_SETUP_STATE_TYPE_SET_REMOVE =
  '[assettype] Remove assettype';
export const TEMPLATE_SETUP_STATE = 'templateSetup';
export const PRIMARY_COMPANY_SETUP_STATE = 'primaryCompanySetup';
export const TEMPLATE_STATE_TYPE_SET = '[Template] Set Template';
export const TEMPLATE_STATE_TYPE_SET_REMOVE = '[Template] Remove Template';
export const TEMPLATE = 'template';
export const COMPANY_OVERVIEW = 'Company';
export const STRING = 'string';
export const ADD = 'and';
export const SEGMENTTYPE_SETUP_STATE = 'SegmenTypeState';
export const SEGMENTTYPE_SETUP_STATE_TYPE_SET = '[SegmenTypeState] Set';
export const SEGMENTTYPE_SETUP_STATE_TYPE_SET_REMOVE =
  '[SegmenTypeState] Remove SegmenTypeState';
export const LEVEL3_STATE = 'Level3';
export const LEVEL3_STATE_TYPE_SET = '[Level3] Set';
export const LEVEL3_STATE_TYPE_SET_REMOVE = '[Level3] Remove Level3';
export const LEVEL2_STATE = 'Level2';
export const LEVEL2_STATE_TYPE_SET = '[Level2] Set';
export const LEVEL2_STATE_TYPE_SET_REMOVE = '[Level2] Remove Level2';
export const LEVEL1_STATE = 'Level1';
export const LEVEL1_STATE_TYPE_SET = '[Level1] Set';
export const LEVEL1_STATE_TYPE_SET_REMOVE = '[Level1] Remove Level1';
export const LEVEL_NAME_STATE = 'LevelName';
export const LEVEL_NAME_STATE_TYPE_SET = '[LevelName] Set';
export const LEVEL_NAME_STATE_TYPE_SET_REMOVE = '[LevelName] Remove LevelName';
export const SELECTED_USER_STATE = 'SelectedUser';
export const SELECTED_USER_TYPE_SET = '[SelectedUser] Set';
export const SELECTED_USER_TYPE_SET_REMOVE =
  '[SelectedUser] Remove SelectedUser';
export const SELECTED_MTX_Member_STATE = 'SelectedMTX_Member';
export const SELECTED_MTX_Member_TYPE_SET = '[SelectedMTX_Member] Set';
export const SELECTED_MTX_Member_TYPE_SET_REMOVE =
  '[SelectedMTX_Member] Remove SelectedMTX_Member';
export const PC_ADMIN__STATE = 'pcAdminDataSetup';
export const PC_ADMIN_TYPE_SET = '[PCAdmin] Set';
export const PC_ADMIN_TYPE_SET_REMOVE = '[PCAdmin] Remove PCAdmin';
export const LEVEL1_LEVELNAME_STATE = 'Level1LevelName';
export const LEVEL1_LEVELNAME_SET = '[Level1LevelName] Set';
export const LEVEL1_LEVELNAME_SET_REMOVE =
  '[Level1LevelName] Remove Level1LevelName';
export const LEVEL2_LEVELNAME_STATE = 'Level2LevelName';
export const LEVEL2_LEVELNAME_SET = '[Level2LevelName] Set';
export const LEVEL2_LEVELNAME_SET_REMOVE =
  '[Level2LevelName] Remove Level2LevelName';
export const LEVEL3_LEVELNAME_STATE = 'Level3LevelName';
export const LEVEL3_LEVELNAME_SET = '[Level3LevelName] Set';
export const LEVEL3_LEVELNAME_SET_REMOVE =
  '[Level3LevelName] Remove Level3LevelName';

//Input screen
export const INSPECTION = 'inspection';
export const EXCEPTION = 'exception';
export const ASSETSETUP = 'asset-setup';
export const ASSETHISTORY = 'asset-history';
export const REPORT = 'report';
export const SURVEYOVERVIEW = 'survey-overview';
export const USEROVERVIEW = 'user-overview';
export const SURVEYROUTEMANAGEMENT = 'survey-route-management';
export const REPORTDETAILS = 'report-details';
export const MOBILTEXMEMBERS = 'mobiltex-members-overview';
export const ADDMOBILTEXMEMBERS = 'AddMTXMemberComponent';

//Field names
export const ASSET = 'Asset';
export const ASSET_MEASUREMENT = 'AssetMeasurement';
export const ASSET_TYPE = 'assetType';
export const ASSET_TYPE_TEXT = 'Asset Type';
export const ASSET_NAME = 'assetName';
export const ASSET_NAME_TEXT = 'Asset Name';
export const STATION = 'Station';
export const COMPLIANCE_CRITERIA = 'ComplianceCriteria';
export const COMPLIANCE_CRITERIA_SIMPLE = 'Criteria';
export const COMPLIANCE_CRITERIA_TEXT = 'Compliance Criteria';
export const COMPLAINCE_STATUS = 'complianceStatus';
export const COMPLAINCE_STATUS_SIMPLE = 'Status';
export const COMPLAINCE_STATUS_TEXT = 'Compliance Status';
export const REPORT_TYPE = 'reportType';
export const NEXT_INSPECTION_DATE = 'NextInspectionDate';
export const NEXT_INSPECTION_DATE_TEXT = 'Next Inspection Date';
export const LAST_COMPLAINCE_READ = 'LastComplianceRead';
export const LAST_COMPLAINCE_READ_TEXT = 'Last Compliance Read';
export const TECHNICIAN = 'technician';
export const ASSIGNEDTO = 'assignedTo';
export const ASSIGNEDTO_TEXT = 'Assignee';
export const TECHNICIAN_TEXT = 'Technician';
export const LAST_MEASUREMENT = 'LastMeasurement';
export const LAST_MEASUREMENT_TEXT = 'Last Measurement';
export const ASSET_TYPE_EXCEPTION = 'assetType';
export const ASSET_NAME_EXCEPTION = 'assetName';
export const STATION_EXCEPTION = 'station';
export const MILEPOST = 'milePost';
export const MILEPOST_TEXT = 'Milepost';
export const DAYS_UNTIL_DELINQUENT = 'daysUntilDelinquent';
export const DAYS_UNTIL_DELINQUENT_TEXT = 'Days Until Delinquent';
export const LAST_INSPECTION_DATE = 'lastInspectionDate';
export const LAST_INSPECTION_DATE_TEXT = 'Last Inspection Date';
export const INSPECTION_TARGET_DATE = 'inspectionTargetDate';
export const INSPECTION_TARGET_DATE_TEXT = 'Inspection Target Date';
export const ASSET_DELINQUENCY_DATE = 'assetDelinquencyDate';
export const ASSET_DELINQUENCY_DATE_TEXT = 'Asset Delinquency Date';
export const COMPLIANCE_CRITERIA_EXCEPTION = 'complianceCriteria';
export const WORKFLOW_STATUS = 'workFlowStatus';
export const WORKFLOW_STATUS_TEXT = 'Workflow Status';
export const COMMENTS = 'Comments';
export const TECHNICIAN_EXCEPTION = 'technician';
export const SPECIALIST = 'specialist';
export const SPECIALIST_TEXT = 'Specialist';
export const ROOT_CAUSE = 'rootCause';
export const ROOT_CAUSE_TEXT = 'Root Cause';
export const PRIMARY_COMPANY = 'primaryCompany';
export const PRIMARY_COMPANY_TEXT = 'Primary Company';
export const COMPANY = 'company';
export const COMPANY_TEXT = 'Company';
export const REGION = 'region';
export const SEGMENT = 'segment';
export const REGION_TEXT = 'Region';
export const SEGMENT_TEXT = 'Segment';
export const LATITUDE = 'Latitude';
export const ROUTE = 'Route';
export const LONGITUDE = 'Longitude';
export const INSPECTION_DATE = 'Inspection Date';
export const MEASUREMENT_FREQUENCY = 'measurementFrequency';
export const MEASUREMENT_FREQUENCY_TEXT = 'Measurement Frequency';
export const ASSET_TYPE_TEMPLATE = 'assetTypeTemplate';
export const ASSET_TYPE_TEMPLATE_TEXT = 'Asset Type Template';
export const GPS_COORDINATE = 'gpsCoordinate';
export const GPS_COORDINATE_TEXT = 'GPS Coordinate';
export const NEXT_DELINQUENCY_DATE = 'nextDelinquencyDate';
export const NEXT_DELINQUENCY_DATE_TEXT = 'Next Inspection Date';
export const DATE = 'date';
export const DATE_TEXT = 'Date';
export const AUDIT = 'Audit';
export const PERFORMANCE = 'Performance';
export const AUDIT_PERFORMANCE = 'Audit,Performance';
export const SURVEYNAME = 'surveyName';
export const SURVEYNAME_TEXT = 'Survey Name';
export const SURVEYTYPE = 'surveyType';
export const SURVEYTYPE_TEXT = 'Survey Type';
export const SURVEYPRIORITY = 'surveyPriority';
export const SURVEYPRIORITY_TEXT = 'Survey Priority';
export const SURVEYSTATUS = 'surveyStatus';
export const SURVEYSTATUS_TEXT = 'Survey Status';
export const DUEDATE = 'dueDate';
export const DUEDATE_TEXT = 'Due Date';
export const DATECOLLECTION = 'dataCollectionType';
export const DATECOLLECTION_TEXT = 'Data Collection Type';
export const SUPERVISOR = 'Supervisor';
export const DATEOFCOMPLETION = 'dateOfCompletion';
export const DATEOFCOMPLETION_TEXT = 'Date of Completion';
export const SURVEYDESCRIPTION = 'surveyDescription';
export const SURVEYDESCRIPTION_TEXT = 'Survey Description';

//File Formats
export const GRID_DATA_CSV = 'grid-data.csv';
export const GRID_DATA_PDF = 'grid-data.pdf';
export const CSV_TYPE = 'text/csv;charset=utf-8;';
//Global template
export const GLOBAL_TEMPLATE_ID = 'Template1';
export const GLOBAL_TEMPLATE_TYPE = templateType.global;
export const GLOBAL_TEMPLATE: TemplateBase = {
  id: GLOBAL_TEMPLATE_ID,
  templateName: 'Template 1',
  templateType: GLOBAL_TEMPLATE_TYPE,
  templateConfiguration: JSON.stringify({}),
  isGlobal: true,
};
export const GLOBAL_TEMPLATE_ITEM_INSPECTION = {
  Template1: [
    { id: 1, checked: true },
    { id: 2, checked: true },
    { id: 3, checked: true },
    { id: 4, checked: true },
    { id: 5, checked: true },
    { id: 6, checked: true },
    { id: 7, checked: true },
    { id: 8, checked: true },
    { id: 9, checked: true },
  ],
};
export const GLOBAL_TEMPLATE_ITEM_EXCEPTION = {
  Template1: [
    { id: 1, checked: true },
    { id: 2, checked: true },
    { id: 3, checked: true },
    { id: 4, checked: true },
    { id: 5, checked: true },
    { id: 6, checked: true },
    { id: 7, checked: true },
    { id: 8, checked: true },
    { id: 9, checked: true },
    { id: 10, checked: true },
    { id: 11, checked: true },
    { id: 12, checked: true },
    { id: 13, checked: true },
  ],
};

export const GLOBAL_TEMPLATE_ITEM_ASSETSETUP = {
  Template1: [
    { id: 1, checked: true },
    { id: 2, checked: true },
    { id: 3, checked: true },
    { id: 4, checked: true },
    { id: 5, checked: true },
    { id: 6, checked: true },
    { id: 7, checked: true },
    { id: 8, checked: true },
    { id: 9, checked: true },
    { id: 10, checked: true },
    { id: 11, checked: true },
  ],
};
export const GLOBAL_TEMPLATE_ITEM_SURVEY_OVERVIEW = {
  Template1: [
    { id: 1, checked: true },
    { id: 2, checked: true },
    { id: 3, checked: true },
    { id: 4, checked: true },
    { id: 5, checked: true },
    { id: 6, checked: true },
    { id: 7, checked: true },
    { id: 8, checked: true },
    { id: 9, checked: true },
    { id: 10, checked: true },
  ],
};
export const ASSET_SETUP_FILTER_INPUT = {
  primaryCompany: '01J30BV3FPHM5MJVKCKN9GWXT0',
  company: '01J30DHX3DJG4ZFN1XJYFDPV6J',
  region: '01J30ERA1WREVZ79B1EY9VKQMZ',
};

//Entity type declaration
export const ET_TEMPLATE = 'Template';

//Error message
export const FETCHING_TEMPLATES_ERROR = 'Error fetching templates:';
export const FETCHING_PRIMARY_COMPANY_ERROR = 'Error fetching primary company:';
export const FETCHING_COMPANY_ERROR = 'Error fetching company:';
export const FETCHING_SURVEY_ROUTE_ERROR = 'Error fetching survey route:';
export const ERROR_FETCHING_SURVEY_OVERVIEW = 'Error fetching survey';
export const FETCHING_REGION_ERROR = 'Error fetching region:';
export const FETCHING_SEGMENT_ERROR = 'Error fetching segment:';
export const FETCHING_ASSET_TYPE_ERROR = 'Error fetching asset type:';
export const CREATE_TEMPLATE = 'Failed to create template.';
export const UPDATE_TEMPLATE = 'Failed to update template.';
export const DELETE_TEMPLATE = 'Failed to delete template.';
export const EMPTY_TEMPLATE_NAME = 'Please enter template name';
export const DUPLICATE_TEMPLATE_NAME =
  'Same template name already exists. Please enter different name.';
export const CREATE_TEMPLATE_ERROR = 'Error creating template:';
export const CREATE_ASSET_ERROR = 'Error creating asset:';
export const UPDATE_ASSET_ERROR = 'Error updating asset:';
export const CREATE_PRIMARY_COMPANY_ERROR = 'Error creating primary company:';
export const CREATE_SURVEY_ERROR = 'Error creating survey:';
export const UPDATE_PRIMARY_COMPANY_ERROR = 'Error updating primary company:';
export const CREATE_PRIMARY_COMPANY_LEVEL_ERROR = 'Error creating levels:';
export const CREATE_PRIMARY_COMPANY_LEVEL_TREE_ERROR =
  'Error creating level tree:';
export const UPDATE_PRIMARY_COMPANY_LEVEL_TREE_ERROR =
  'Error updating level tree:';
export const DELETE_ASSET_ERROR = 'Error deleting asset:';
export const UPDATE_TEMPLATE_ERROR = 'Error updating template:';
export const FETCHING_ASSET_SETUP_ERROR = 'Error fetching Asset Setup data:';
export const FETCHING_INSPECTION_DATA_ERROR =
  'Error fetching Inspection Grid data:';
export const DELETE_ASSET = 'Delete Asset?';
export const DELETE_ASSET_CONFIRMATION_MSG =
  'This will permanently remove the asset "{0}" from your system. Are you sure you want to proceed?';
export const DELETE_ASSET_SUCCESSFULL_MSG =
  'Asset  successfully removed from the system';
export const DELETE_SUCCESSFULL = 'Delete Successful';
export const API_ERROR = 'Api Error';
export const API_ERROR_MSG = 'An error occurred';
export const FILE_UPLOAD_ERROR = 'File Format Error';
export const FILE_UPLOAD_ERROR_MSG =
  'Invalid file format. Only CSV and XLSX files are allowed.';
export const DELETE_FAILED = 'Delete Failed';
export const CANCEL_BTN_TEXT = 'Cancel';
export const DELETE_ASSET_FAILED__MSG =
  'Asset could not be removed from the system, please try again.';
export const CANCEL_TEMPLATE_HEADING = 'Are you sure you want to Cancel?';
export const EDIT_HIERARCHY_HEADING = 'Are you sure you want to proceed?';
export const EDIT_HIERARCHY_HEADING_FOR_DELETE =
  'Are you sure you want to delete a record?';
export const CANCEL_TEMPLATE_MSG =
  'Cancelling now will undo all the changes you made. You still want to continue cancelling?';
export const CANCEL_TEMPLATE_MSG_HIERARCHY =
  'Cancelling now will undo all the changes except deleted records. You still want to continue cancelling?';
export const EDIT_HIERARCHY_MSG =
  'Editing hierarchy will erase the current hierarchy design to implement a new hierarchy with updated level names';
export const EDIT_HIERARCHY_MSG_FOR_DELETE =
  'This will remove the record from the system and cannot be reversed even if you cancel the Edit process. ';
export const CANCEL_TEMPLATE_BTN_CONFIRM_TXT = 'Yes, Cancel';
export const EDIT_HIERARCHY_BTN_CONFIRM_TXT = 'Yes, Continue';
export const NO = 'No';
export const SUCCESS_TEMPLATE_HEADING = 'Template Created Successfully!';
export const SUCCESS_TEMPLATE_MSG =
  "You'll be redirected to Report Templates Library list page";
export const VALID_EMAIL = 'Please enter a valid email address.';
export const EMAIL_ALREADY_EXISTS = 'Email already added.';
export const ADD_EMAIL = 'Please add at least one valid email.';
export const PDF_EXPORT_FAILED_MESG = 'PDFExportComponent is not initialized.';
export const EXCEL_EXPORT_FAILED_MESG =
  'ExcelExportComponent is not initialized.';
export const FAILED_ASSET_LOAD = 'Failed to load assets:';
export const ASSET_LOAD_COMPLETE = 'Asset loading complete.';
export const FETCHED_ASSEST_UNDEFINED = 'Fetched asset is undefined.';
export const FAILED_ASSET_FETCH = 'Failed to fetch assets:';
export const FAILED_ASSET_UPDATE = 'Failed to update assets:';
export const ASSET_NOT_FOUND_ERROR = (id: string | number): string =>
  `Asset with id ${id} not found`;
export const SAVE_TEMPLATE_HEADING = 'Save Survey Route';
export const SAVE_TEMPLATE_MSG =
  'Are you sure you want to save this route setting?';
export const SUCCESS_SETUP_HIERARCHY_MSG =
  "You'll be redirected to Company Overview list page";
export const SUCCESS_ASSET_IMPORT_MSG =
  "You'll be redirected to Setup Segments & Assets";
export const SUCCESS_SETUP_HIERARCHY_HEADING =
  'Hierarchy & Levels Created Successfully!';
export const FAILURE_SETUP_HIERARCHY_HEADING =
  'Hierarchy & Levels Created Failed!';
export const FAILURE_UPDATE_HIERARCHY_HEADING =
  'Hierarchy & Levels Update Failed!';
export const FAILURE_SETUP_HIERARCHY_HEADING_MSG =
  'An unexpected error occurred. Please try creating hierarchy & levels again.';
export const FAILURE_UPDATE_HIERARCHY_HEADING_MSG =
  'An unexpected error occurred. Please try updating hierarchy & levels again.';
export const SUCCESS_ASSET_IMPORT_HEADING = 'File Imported Successfully!';
export const GENERIC_FAILED_ERROR = 'Error fetching data.';
export const SUCCESS_CREATE_ASSET_SETUP =
  'Asset Type and Measurement Criteria have been successfully saved!';
export const FAILED_CREATE_ASSET_SETUP =
  'Failed to create Asset type and Measurement Criteria';
export const FAILED_CREATE_ASSET_MINIMUM_ONE_PRIMARY =
  'Minimum one primary asset is required';
export const FAILED_CREATE_ASSET_MINIMUM_ONE_MEASUREMENT =
  'Minimum one measurement and criteria for asset is required';
export const FAILED_CREATE_ASSET_MINIMUM_ONE_ASSET =
  'Minimum one asset hierarchy is required';
export const FAILED_CREATE_ASSET_MINIMUM_ONE_IMAGE =
  'Minimum one asset image required';
export const FAILED_CREATE_PRIMARY_COMPANY = 'Failed to create Primary Company';
export const FAILED_UPDATE_PRIMARY_COMPANY = 'Failed to update Primary Company';
export const FAILED_CREATE_SURVEY = 'Failed to create Survey';
export const FETCHING_PRIMARY_COMPANY_BYID_ERROR =
  'Error fetching primary company data:';
export const FETCHING_PRESIGNED_IMGURL_ERROR =
  'Error fetching Presigned imageUrl:';
//Exception Scenarios while exporting
export const SUCCESS = 'success';
export const SUCCESS1 = 'Success';
export const SUCCESS_EXCLAIM = 'Success!';
export const USER_ADDED_SUCCESSFLLY = 'User added sucessfully.';
export const FAILED = 'failed';
export const RETRY = 'Retry';
export const CONNECTION = 'connection';
export const HELP = 'help';
export const ERROR_FETCHING_ASSET_TYPE = 'Error fetching asset type';
export const ERROR_FETCHING_ASSET_TYPE_CATEGORY = 'Error fetching asset type category';
export const ERROR_FETCHING_GET_MEASUREMENT_BY_ASSETCATEGORY='Error fetching getAllCoreMeasurementTypesByAssetCategory'
export const RECORD_DELETED = 'Record deleted successfully.';
export const HIERARCHY_DUPLICATE_RECORD_TITLE = 'Duplicate Entry';
export const HIERARCHY_DUPLICATE_RECORD_MESSAGE =
  'Entered name already exists in the hierarchy.';
export const FETCHING_SYRC_USERS_ERROR = 'Error fetching SYRC_USERS';
//Export
export const FORMATANDOPTION = 'Please select both a format and an option.';
export const VALIDDATAOPTION = 'Please select a valid data option.';
export const VALIDFORMAT = 'Please select a valid format.';
export const INITIATED = 'Export Initiated';
export const NETWORK = 'Network Unstable';
export const NETWORKMSG =
  'Your internet connection appears to be unstable. Please check your connection and try again.';
export const FAILURE = 'Export failed!';
export const FAILUREMSG =
  'An unexpected error occurred. Please try exporting your data again.';
export const DATEFORTMAT1 = 'EEEE';
export const DATEFORTMAT2 = 'hh:mm a';
export const TEMPUSER = 'John';
export const DEFAULTPAGESIZE = 50;
export const ZERO = 0;
export const EMPTY_STRING = '';
export const CREATE_SINGLE_ASSET = 'Create Single Asset';
export const EDIT_ASSET = 'Edit Asset';
export const ID = 'id';

//Extras
export const DEFAULT = 'default';
export const LONG = 'long';
export const SHORT = 'short';
export const TH = 'th';
export const ST = 'st';
export const ND = 'nd';
export const RD = 'rd';

//Placeholders
export const SEARCHINSPECTION = 'search inspections by asset, company';
export const SEARCHEXCEPTION = 'search exceptions by asset, company';
export const SEARCHASSETSETUP = 'search assets';
export const SEARCHSURVEYS = 'search surveys';
export const SEARCHUSERS = 'search users';
export const SEARCHREPORTS = 'search reports';
export const SEARCHASSETHISTORY = 'search asset history.....';
export const ADD_NEW_TEMPLATE = 'Input Template';
export const CLOSE = 'Close';
export const CONTINUE = 'Continue';
export const DONE = 'Done';
export const CONFIRM = 'Confirm';
export const SEARCHMTXMEMBERS = 'search members';

//Filter popup Ids
export const LEVEL1_ID = 1;
export const LEVEL2_ID = 2;
export const LEVEL3_ID = 3;
export const SEGMENT_TYPE_ID = 4;
export const SEGMENT_ID = 5;
export const ASSET_TYPE_ID = 6;
export const SURVEY_ROUTE_ID = 7;
export const TECHNICIAN_ID = 8;

//Permissions
export const FULLACCESS = 'fullAccess';
export const READACCESS = 'readAccess';
export const NOACCESS = 'noAccess';

// Asset creation types
export const CREATE_ASSET_NEW = 'new';
export const CREATE_ASSET_IMPORT = 'import';
export const ASSET_ADDED_SUCCESSFULLY = 'Asset added successfully!';
export const ASSET_UPDATED_SUCCESSFULLY = 'Asset updated successfully!';
export const PRIMARY_COMPANY_ADDED_SUCCESSFULLY =
  'Primary Company Added Successfully!';
export const PRIMARY_COMPANY_UPDATED_SUCCESSFULLY =
  'Primary Company Updated Successfully!';
export const ASSET_SUCCESSFUL_MSG = "You'll be redirected to asset list page";
export const ASSET_SUCCESSFUL_MSG2 =
  'Click on Continue to keep Adding a New Asset';
export const ASSET_FAILURE_MSG =
  'An unexpected error occurred. Please try adding your asset again.';
export const PRIMARY_COMPANY_FAILURE_MSG =
  'An unexpected error occurred. Please try adding your priamry company again.';
export const PRIMARY_COMPANY_FAILURE_DUPLICATE_MSG = 'Company already exists.';
export const ASSET_ADDED_FAILED = 'Adding Asset Failed!';
export const ASSET_UPDATED_FAILED = 'Updating Asset Failed!';
export const PRIMARY_COMPANY_ADDED_FAILED = 'Adding Primary Company Failed!';
export const PRIMARY_COMPANY_ADDED_SUCCESS =
  'Primary Company Created Successfully!';
export const CREATE_SURVEY_SUCCESS = 'Survey Created Successfully!';
export const UPDATE_SURVEY_SUCCESS = 'Survey Updated Successfully!';
export const PRIMARY_COMPANY_UPDATE_FAILED = 'Updating Primary Company Failed!';
export const DATA_ITEM_NOT_FOUND =
  'No dataItem found. You might need to navigate from the grid.';
export const CREATE_SINGLE_ASSET_SUBHEADING = 'to create a Single New Asset';
export const CREATE_NEW_REPORT_SUBHEADING = 'to Create a Report';
export const CREATE_SURVEY = 'to Create a Survey';
export const EDIT_REPORT_SUBHEADING = 'to Edit the Report';
export const EDIT_ASSET_SUBHEADING = 'to Edit the Asset';
export const HIGHLIGHT = 'highlight';
export const CREATE_REPORT = 'createReport';
export const VIEW_MODE_REPORT = 'viewReport';
export const EDIT_MODE_REPORT = 'editReport';
export const FROM_TEMPLATE_LIB = 'fromTemplateLibrary';
export const COMPLIANT_AH = 'Compliant';
export const EXCEPTION_AH = 'Exception';
export const DELINQUENT_AH = 'Delinquent';
export const COMPLIANT_CLASS = 'status-compliant';
export const EXCEPTION_CLASS = 'status-exception';
export const DELINQUENT_CLASS = 'status-delinquent';
export const ERROR_FETCHING_COMPANIES = 'Error fetching primary companies';
export const SEARCH_PLACEHOLDER_COMPANY = 'search companies';
export const FILTER_STATE = 'state';
export const FILTER_COUNTRY = 'country';
export const ERROR_CREATE_ASSET_TYPE =
  'Fill all the required fields before saving';
export const ERROR_FETCHING_EXISTING_COMPANIES =
  'Error fetching existing primary companies';
export const ERROR_FETCHING_TIME_ZONE = 'Error fetching time zone';
export const SURE_CANCEL_HEADER = 'Are you sure want to Cancel?';
export const SURE_CANCEL_MSG =
  'Are you sure you want to cancel? Any progress made will be lost.';

//Redirect Links or Routes
export const REDIRECT_MAPS = '/maps';
export const REDIRECT_NONE = '';
export const REDIRECT_ASSET_HISTORY = '/assets/asset-history';
export const REDIRECT_ASSET_SETUP = '/assets/asset-setup';
export const REDIRECT_INSPECTION = '/compliance/inspection';
export const EDIT_ASSET_ROUTE = '/assets/asset-setup/edit';
export const ASSET_DETAILS_ROUTE = '/assets/asset-setup/details';
export const REDIRECT_EXCEPTION = '/compliance/exception';
export const REDIRECT_DATA_PROCESSING = '/survey/data-processing';
export const REDIRECT_REPORT_MANAGEMENT = '/reports/report-management';
export const REDIRECT_REPORT_TEMPLATE_LIBRARY =
  '/reports/report-templates-library';
export const REDIRECT_EDIT_REPORT = '/reports/report-management/edit';
export const REDIRECT_CREATE_REPORT =
  '/reports/report-templates-library/create-report';
export const REDIRECT_CREATE_REPORT_FROM_MANAGEMENT =
  '/reports/report-management/create';
export const REDIRECT_SURVEY_OVERVIEW = '/survey/survey-overview';
export const REDIRECT_CREATE_SURVEY = '/survey/survey-overview/create';
export const REDIRECT_DETAILS_FROM_MANAGEMENT =
  '/reports/report-management/details';
export const REDIRECT_ASSET_SELECTION =
  '/survey/survey-overview/asset-selection';
export const REDIRECT_SURVEY_CREATE_ROUTE = '/survey/route-management/create';
export const REDIRECT_SURVEY_EDIT_ROUTE = '/survey/route-management/edit';
export const REDIRECT_ROUTE_MANAGEMENT = '/survey/route-management';
export const SETUP_COMPANY_OVERVIEW = '/setup/company-overview';
export const SETUP_HIERARCHY_LEVELS = '/setup/setup-hierarchy-levels';
export const CREATE_PRIMARY_COMPANY = '/setup/company-overview/create';
export const REDIRECT_CREATE_ASSET_SETUP = '/setup/create-asset-type';
export const REDIRECT_ADD_USER = '/setup/users/add';
export const EDIT_PRIMARY_COMPANY = '/setup/company-overview/edit';
export const SETUP_SEGMENTS_ASSETS = '/setup/setup-segments-assets';
export const SETUP_IMPORT_INSPECTION_DATA =
  '/setup/import-bulk-inspection-data';
export const SETUP_IMPORT_SEGMENTS = '/setup/import';
export const SETUP_IMPORT_INSPECTION = '/setup/import-inspection-data';
export const USER_OVERVIEW = '/setup/users';
export const SETUP_ASSET_OVERVIEW = '/setup/asset-overview';
export const MOBILTEX_MEMBER_OVERVIEW = '/mobiltex_members';
export const ADD_MOBILTEX_MEMBER = '/mobiltex_members/add';
//Row items List fields
export const PIN_ROW = 'Pin Row';
export const REDIRECT_EDIT_SURVEY = '';
export const PIN_IMAGE = 'pin';
export const EDIT = 'Edit';
export const EDIT_IMAGE = 'edit';
export const EDIT_NOTE_IMAGE = 'edit_note';
export const DETAIL_IMAGE = 'details';
export const DELETE_IMAGE = 'deleteIicon';
export const CHECK_IMAGE = 'check';
export const CALENDAR_IMAGE = 'calendar-icon';
export const REDIRECT_IMAGE = 'redirect';
export const SURVEY_BACKLOG_IMAGE = 'surveyBacklog';
export const DETAILS = 'Details';
export const ASSIGN_RMU = 'Assign RMU';
export const GOTO_ASSET_HISTORY = 'Go to Asset History';
export const GOTO_MAP = 'Go To Map';
export const GOTO_INSPECTION = 'Go to Inspections';
export const DELETE = 'Delete';
export const PENDING_IMAGE = 'pending';
export const USERHISTORY = 'User History';
export const USERHISTORY_IMAGE = 'userHistory';
export const LOGINAS = 'Login As';
export const LOGINAS_IMAGE = 'loginAs';

export const EXCEPTIONS = 'Exceptions';
export const FIELD_DATA_SUMMARY = 'Field Data Summary';
export const INSPECTIONS = 'Inspections';
export const DATA_PROCESSING = 'Data Processing';
export const MAP = 'Map';
export const ASSET_SETUP = 'Asset Setup';
export const VIEW_REPORT = 'View Report';
export const ADD_TO_FAV = 'Add to Favourite';
export const RUN_REPORT = 'Run Report';
export const VIEW_IMAGE = 'view';
export const FAV_IMAGE = 'fav';
export const RUN_REPORT_IMAGE = 'timer';
export const EDIT_SURVEY = 'Edit Survey';
export const FIELD_DATA_REVIEW = 'Field Data Review';
export const EDIT_SURVEY_ROUTE = 'Edit Survey Route';
export const EDIT_COMPANY_DETAILS = 'Edit Company Details';
export const SETUP_ASSET_TYPES = 'Setup Asset Types';
export const SETUP_HIERARCHY = 'Setup Hierarchy';
export const SETUP_SEGMENT_ASSET = 'Setup Segments & Assets';
export const ASSET_OVERVIEW = 'Asset Overview';
export const ASSET_OVERVIEW_SCREEN = 'AssetOverview';
export const SURVEY_OVERVIEW_SCREEN = 'SurveyOverview';
export const SURVEY_ROUTE_SCREEN = 'RouteManagement';
export const COMPANY_OVERVIEW_SCREEN = 'CompanyOverview';
export const USER_OVERVIEW_SCREEN = 'UserOverview';
export const INSPECTION_SCREEN = 'Inspections';
export const EXCEPTION_SCREEN = 'Exceptions';
export const ASSET_HISTORY_SCREEN = 'AssetHistory';
export const REPORT_MANAGEMENT_SCREEN = 'ReportManagement';
export const REPORT_TEMPLATE_SCREEN = 'ReportTemplates';
export const MOBILTEX_MEMBER_SCREEN = 'MobiltexMember';

//Static CSS Values
export const ASSET_HISTORY_ELLIPSIS_WIDTH = '265px';
export const REPORT_ELLIPSIS_WIDTH = '200px';
export const GLOBAL_ELLIPSIS_WIDTH = '280px';
export const SURVEYOVERVIEW_ELLIPSIS_WIDTH = '220px';
export const COMPANYOVERVIEW_ELLIPSIS_WIDTH = '290px';
export const EXCEPTION_ELLIPSIS_WIDTH = '310px';

//Global Classes
export const CARD_BODY = '.card-body';
export const BUTTON_BACKGROUND_COLOR = 'btn-background-color';
//Method Names
export const DELETE_ASSEST_METHOD = 'deleteAsset';

//Pagination
export const PAGINATION_ARRAY: any = {
  buttonCount: 3,
  info: true,
  pageSizes: [50, 100, 500],
  previousNext: true,
  position: 'bottom',
};

//integer const
export const PAGEHEIGHT = 780;
export const PAGEHEIGHT_WITH_TRAY = 780;

//boolean const
export const TRUE = true;
export const FALSE = false;

//Heirarchical filter type name
export const COMPANY_TYPE_NAME = 'Company';
export const REGION_TYPE_NAME = 'Region';
export const SEGMENT_TYPE_NAME = 'Segment';
export const SERVEYROUTE_TYPE_NAME = 'SurveyRoute';
export const ASSETTYPE_TYPE_NAME = 'AssetType';
//Toastr Msgs
export const WARNING = 'Warning';
export const INFO = 'Info';
export const ERROR = 'Error';
export const TOASTER_SUCCESS = 'Success';

// Maps Constants
export const MAPS_SEARCH_PLACEHOLDER = 'search assets by name';
export const LEGEND_TEST_POINTS = 'Test Points';
export const LEGEND_TEST_POINT = 'Test Point';
export const LEGEND_BONDS = 'Bonds';
export const LEGEND_BOND = 'Bond';
export const LEGEND_RECTIFIERS = 'Rectifiers';
export const LEGEND_RECTIFIER = 'Rectifier';

//Regular Expressions
export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

//Hierarchy
export const BULLET_POINT_1 =
  'Group the name that will be used for the Assets in Mobiltex. The default status (Ready) will be applied to each Asset. The default status can be changed in account settings under Assets statuses.';
export const BULLET_POINT_2 = 'Supported file format XLSX & CSV';
export const SUB_BULLET_POINT_1 =
  'Relationship between level 1 and 2 is n to n.';
export const SUB_BULLET_POINT_2 =
  'Relationship between level 1 and 2 is n to n.';
export const LEVEL_1 = 'level1';
export const LEVEL_2 = 'level2';
export const LEVEL_3 = 'level3';
export const LEVEL_4 = 'level4';
export const ERROR_LEVEL = 'No parent node found.';

//setu-assets-segments
export const VERIFY_HEADER = 'Download File Format & Verify';
export const VERIFY_SUBHEADER =
  'Format your file according to the following instructions before uploading';
export const UPLOAD_HEADER = 'Upload Files';
export const UPLOAD_SUBHEADER = 'Upload your file and verify';
export const RECTIFIER = 'Rectifier';
export const BOND = 'Bond';
export const TEST_POINTS = 'Test Points';
export const TEST_STATION = 'Test Station';
export const ER_PROBES = 'ER Probes';
export const TEST_POINT = 'Test Point';
export const CASING = 'Casing';
export const ANODE = 'Anode';
export const CTS = 'Coupon Test Station';
export const ER_PROBE = 'ER Probe';
export const ISOLATIONJOINT = 'Isolation Joint';

export const MEASUREMENT_TYPES: any = [
  { text: 'P/S ON AC', value: 'P/S ON AC', unit: 'V' },
  { text: 'P/S ON DC', value: 'P/S ON DC', unit: 'V' },
  { text: 'P/S IR Free (OFF) AC', value: 'P/S IR Free (OFF) AC', unit: 'V' },
  { text: 'P/S IR Free (OFF) DC', value: 'P/S IR Free (OFF) DC', unit: 'V' },
  {
    text: 'P/S Native (Depolarized) AC',
    value: 'P/S Native (Depolarized) AC',
    unit: 'V',
  },
  {
    text: 'P/S Native (Depolarized) DC',
    value: 'P/S Native (Depolarized) DC',
    unit: 'V',
  },
  { text: 'AC Current', value: 'AC Current', unit: 'A' },
  { text: 'DC Current', value: 'DC Current', unit: 'A' },
  { text: 'Bond AC Current', value: 'Bond AC Current', unit: 'A' },
  { text: 'Bond DC Current', value: 'Bond DC Current', unit: 'A' },
  { text: 'Shunt Current AC', value: 'Shunt Current AC', unit: 'A' },
  { text: 'Shunt Current DC', value: 'Shunt Current DC', unit: 'A' },
  { text: 'Mitigation Current AC', value: 'Mitigation Current AC', unit: 'A' },
  { text: 'Mitigation Current DC', value: 'Mitigation Current DC', unit: 'A' },
  { text: 'C/S ON AC', value: 'C/S ON AC', unit: 'V' },
  { text: 'C/S ON DC', value: 'C/S ON DC', unit: 'V' },
  { text: 'C/S IR Free (OFF) AC', value: 'C/S IR Free (OFF) AC', unit: 'V' },
  { text: 'C/S IR Free (OFF) DC', value: 'C/S IR Free (OFF) DC', unit: 'V' },
  {
    text: 'C/S Native (Depolarized) AC',
    value: 'C/S Native (Depolarized) AC',
    unit: 'V',
  },
  {
    text: 'C/S Native (Depolarized) DC',
    value: 'C/S Native (Depolarized) DC',
    unit: 'V',
  },
  {
    text: 'Casing to Pipe Voltage Delta (C/S - P/S) ON DC',
    value: 'Casing to Pipe Voltage Delta (C/S - P/S) ON DC',
    unit: 'V',
  },
  {
    text: 'Casing to Pipe Voltage Delta (C/S - P/S) OFF DC',
    value: 'Casing to Pipe Voltage Delta (C/S - P/S) OFF DC',
    unit: 'V',
  },
  {
    text: 'Output Voltage AC Found',
    value: 'Output Voltage AC Found',
    unit: 'V',
  },
  {
    text: 'Output Voltage AC Left',
    value: 'Output Voltage AC Left',
    unit: 'V',
  },
  {
    text: 'Output Voltage DC Found',
    value: 'Output Voltage DC Found',
    unit: 'V',
  },
  {
    text: 'Output Voltage DC Left',
    value: 'Output Voltage DC Left',
    unit: 'V',
  },
  { text: 'Output Amperage AC', value: 'Output Amperage AC', unit: 'A' },
  { text: 'Output Amperage DC', value: 'Output Amperage DC', unit: 'A' },
  { text: 'Drain Current AC', value: 'Drain Current AC', unit: 'A' },
  { text: 'Drain Current DC', value: 'Drain Current DC', unit: 'A' },
  {
    text: 'Bond Current - mV Drop',
    value: 'Bond Current - mV Drop',
    unit: 'mV',
  },
  {
    text: 'Output Anode Current - mV Drop',
    value: 'Output Anode Current - mV Drop',
    unit: 'mV',
  },
];

export const FREQUENCIES: any = [
  { text: 'Weekly', value: 'weekly' },
  { text: 'Monthly', value: 'monthly' },
  { text: 'Yearly', value: 'yearly' },
  { text: 'Custom', value: 'custom' }, // Set the custom string here
];

// Add this in your component
export const OPERATOR_OPTIONS: any = [
  { text: 'equal', value: 'equal' },
  { text: 'greater than', value: 'greater than' },
  { text: 'less than', value: 'less than' },
  { text: 'equal or greater than', value: 'equal or greater than' },
  { text: 'equal or less than', value: 'equal or less than' },
  { text: 'between', value: 'between' },
];

export const SEGMENT_RELATION: any = [
  { text: 'Primary', value: 'Primary' },
  { text: 'Associated', value: 'Associated' },
];

export const DATE_OF_COMMISSIONING = 'dateOfCommissioning';
export const GROUNDBED_DATE_OF_COMMISSIONING = 'groundbedDateOfCommissioning';
export const ELECTRODE_INSTALLATION_DATE = 'electrodeInstallationDate';
export const INSTALL_DATE = 'installDate';
export const BOND_SHUNT_VALUE = 'bondShuntValue';
export const RECTIFIER_MANUFACTURER = 'rectifierManufacturer';
export const RECTIFIER_TYPE = 'rectifierType';
export const OUTPUT_VOLTAGE = 'outputVoltage';
export const OUTPUT_AMPERAGE = 'outputAmperage';
export const SHUNT_VALUES = 'shuntValues';
export const METER_NUMBER = 'meterNumber';
export const GROUNDBED_TYPE = 'groundbedType';
export const GROUNDBED_ORIENTATION = 'groundbedOrientation';
export const ANODE_TYPE = 'anodeType';
export const NUMBER_OF_ANODES = 'numberOfAnodes';
export const BACKFILL_MASS = 'backfillMass';
export const BACKFILL_DIAMETER = 'backfillDiameter';
export const MAINTENANCE_DETAILS = 'maintenanceDetails';
export const ELECTRODE_MANUFACTURER = 'electrodeManufacturer';
export const ELECTRODE_TYPE = 'electrodeType';
export const ELECTRODE_CHEMISTRY = 'electrodeChemistry';
export const AC_COUPON_SIZE = 'acCouponSize';
export const DC_COUPON_SIZE = 'dcCouponSize';
export const PROBE_MANUFACTURER = 'probeManufacturer';
export const PROBE_TYPE = 'probeType';

export const LATITUDEVALIDATOR = '^[+-]?([1-8]?[0-9](\\.\\d+)?|90(\\.0+)?)$';
export const LONGITUDEVALIDATOR =
  '^[+-]?((1[0-7][0-9](\\.\\d+)?)|([1-9]?[0-9](\\.\\d+)?)|180(\\.0+)?)$';
