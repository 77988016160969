import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { SVGIcon, searchIcon } from '@progress/kendo-svg-icons';
@Component({
  selector: 'app-custom-grid-filter',
  templateUrl: './custom-grid-filter.component.html',
  styleUrl: './custom-grid-filter.component.css',
})
export class CustomGridFilterComponent implements OnChanges {
  @Input() inputElements: any[] = [];
  @Input() fieldName = '';
  @Input() parentDiv!: ElementRef<HTMLDivElement>;
  @Input() state!: { filter: CompositeFilterDescriptor };
  @Output() selectedValues = new EventEmitter<any[]>();

  public svgSearch: SVGIcon = searchIcon;

  private clickListener: (() => void) | undefined;
  public searchValue = '';
  public filteredItems: any[] = []; // Initialize with all items
  public allSelected = false;
  public hasComplainceFilter = false;
  ngOnChanges(changes: SimpleChanges) {
    if (changes['inputElements']) {
      this.filteredItems = [...this.inputElements];
      this.allSelected = this.filteredItems.every((item) => item.selected);
    }
    if (changes['state']) {
      this.checkForComplianceStatus();
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.parentDiv && this.parentDiv.nativeElement) {
      const targetElement = event.target as Node;
      const clickedInside =
        this.parentDiv.nativeElement.parentElement!.contains(targetElement);
      if (clickedInside) {
        //console.log('Clicked inside the parent div');
      } else {
        if (!this.hasComplainceFilter) {
          this.clearAll();
        } else {
          if (this.state.filter && this.state.filter.filters) {
            const columnFilters = this.extractColumnFilters(
              this.state.filter,
              this.fieldName,
            );
            // Extract the filter values from columnFilters
            const filterValues = columnFilters.map((filter) => filter.value);

            // Iterate through filteredItems and set selected to true if text matches any filter value
            this.filteredItems.forEach((item) => {
              if (filterValues.includes(item.text)) {
                item.selected = true;
              }
            });
          }
        }
      }
    }
  }

  private extractColumnFilters(
    compositeFilter: CompositeFilterDescriptor,
    field: string,
  ): FilterDescriptor[] {
    const filters: FilterDescriptor[] = [];
    compositeFilter.filters.forEach((filter) => {
      if (this.isCompositeFilterDescriptor(filter)) {
        filters.push(
          ...this.extractColumnFilters(
            filter as CompositeFilterDescriptor,
            field,
          ),
        );
      } else if ((filter as FilterDescriptor).field === field) {
        filters.push(filter as FilterDescriptor);
      }
    });
    return filters;
  }
  private isCompositeFilterDescriptor(
    filter: any,
  ): filter is CompositeFilterDescriptor {
    return filter.filters !== undefined;
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  checkForComplianceStatus() {
    this.hasComplainceFilter = this.hasFilterForField(
      this.state.filter,
      this.fieldName,
    );
  }

  private hasFilterForField(
    filter: CompositeFilterDescriptor,
    field: string,
  ): boolean {
    return filter.filters.some(
      (f: FilterDescriptor | CompositeFilterDescriptor) => {
        if ((f as CompositeFilterDescriptor).filters) {
          return this.hasFilterForField(f as CompositeFilterDescriptor, field);
        } else {
          return (f as FilterDescriptor).field === field;
        }
      },
    );
  }
  //this method use to invoke on check of [All] checkbox
  selectAll() {
    this.filteredItems.forEach((item) => (item.selected = true));
    this.selectedValues.emit(this.filteredItems);
  }

  //this method use to invoke on uncheck of [All] checkbox
  clearAll() {
    this.allSelected = false;
    this.searchValue = ''; // Clear the search input
    this.searchFilter(); // Reset the filtered items
    this.filteredItems.forEach((item) => (item.selected = false));
    this.selectedValues.emit(this.filteredItems);
  }

  //this method use to invoke to check if all the val of drop-dwon are selected we will make [All] as checked
  updateAllSelected() {
    this.allSelected = this.filteredItems.every((item) => item.selected);
    this.selectedValues.emit(this.inputElements); 
  }

  //this method will invoke on serach box change
  searchFilter() {
    this.filteredItems = this.inputElements.filter((item) =>
      item.text.toLowerCase().includes(this.searchValue.toLowerCase()),
    );
    if (this.searchValue == '') {
      if (this.allSelected) {
        this.selectAll();
      }
    }
  }
}
