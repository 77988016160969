import { Action, Selector, State, StateContext } from "@ngxs/store";
import { COMPANY_SETUP_STATE, COMPANY_SETUP_STATE_TYPE_SET, COMPANY_SETUP_STATE_TYPE_SET_REMOVE } from "../../shared/constants";
import { Injectable } from "@angular/core";

export interface CompanyModel {
    selectedCompanyIds: string [];
}
export class SetCompanyModel {
    static readonly type = COMPANY_SETUP_STATE_TYPE_SET;
    constructor(
      public selectedCompanyIds: string [],
    ) {}
}

export class RemoveSelectedCompanyIds {
    static readonly type = COMPANY_SETUP_STATE_TYPE_SET_REMOVE;
}

@State<CompanyModel>({
    name: COMPANY_SETUP_STATE,
    defaults: {
        selectedCompanyIds: []
    }
})

@Injectable()
export class CompanyState {
    @Selector()
    static getSelectedCompanyIds(state: CompanyModel) {
        return state.selectedCompanyIds;
    }

    @Action(SetCompanyModel)
    SetCompanyModel(
        { patchState }: StateContext<CompanyModel>,
        {
            selectedCompanyIds,
        }: SetCompanyModel,
    ): void {
        patchState({
            selectedCompanyIds : selectedCompanyIds
        });
    }
    @Action(RemoveSelectedCompanyIds)
    RemoveSelectedCompanyIds({
        setState,
    }: StateContext<CompanyModel>): void {
        setState({
            selectedCompanyIds:[]
        });
    }
}