/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PopupDialogService } from '../../shared/popup-dialog/popup-dialog.service';
import {
  CANCEL_TEMPLATE_HEADING,
  CANCEL_TEMPLATE_MSG,
  FAILED,
  CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
  NO,
  SETUP_COMPANY_OVERVIEW,
  SUCCESS_CREATE_ASSET_SETUP,
  SETUP_HIERARCHY_LEVELS,
  FAILED_CREATE_ASSET_SETUP,
  ERROR_CREATE_ASSET_TYPE,
  ERROR,
  FREQUENCIES,
  MEASUREMENT_TYPES,
  OPERATOR_OPTIONS,
  TOASTER_SUCCESS,
  EMPTY_STRING,
  ERROR_FETCHING_ASSET_TYPE,
} from '../../shared/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomToastrService } from '../../shared/ngx-toastr/custom-toastr.service';
import { CreateAssetTypeService } from '../../core/services/create-asset-type.service';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { SetupHierarchyState } from '../../core/store/setup-hierarchy.state';
import { DatePipe } from '@angular/common';
import {
  // AssetTypeTemplateCreateInput,
  AssetTypeTemplateUpdateInput,
  AssetTypeTemplateResponse,
  CoreAssetType,
  CreateAssetTypeTemplateInput,
  UpdateAssetTypeTemplateInput,
  AssetType,
} from '../../../awsAppSync/API';

@Component({
  selector: 'app-create-asset-type',
  templateUrl: './create-asset-type.component.html',
  styleUrl: './create-asset-type.component.css',
})
export class CreateAssetTypeComponent implements OnInit, OnDestroy {
  assets: any;
  currentStep = 2;
  createAssetTypeMC: CreateAssetTypeTemplateInput;
  updateAssetTypeMC: UpdateAssetTypeTemplateInput;
  isLoader = false;
  public frequencies: { text: string; value: string }[] = [];
  measurementTypes = MEASUREMENT_TYPES;
  // Add this in your component
  operatorOptions = OPERATOR_OPTIONS;
  frequenciedefaultItem = { text: 'Measurement Frequency', value: '' };
  operatorDefaultItem = { text: 'Select Operator', value: '' };
  categoryDefaultItem = { id: '', assetType: 'Select Asset Type Category' };
  measurementTypesdefaultItem = {
    measurementType: 'Select Measurement Type',
    id: '',
  };
  isSaveClicked = false;
  isDialogOpen = false;
  primaryCompanyId!: string;
  primaryCompanyId$: Observable<string | null>;
  isEdit = false;
  annualComplainceFlag = true;
  periodicComplainceFlag = true;
  customFrequencyStartDateISOFormat = EMPTY_STRING;

  assetTemplateData: any = [];
  isLeftArrowDisabled = false;
  isRightArrowDisabled = false;
  templateData: any = [];
  assetTypeCategory = 'Test';
  assetTypeCategoryList: (CoreAssetType | null)[] | null = [];
  currentIndex = 0; // Track the currently displayed data
  frequencyIndexFlag = 0;
  nextBtnFlag = true;
  originalFormData={}
  isFormChanged=false
  ngOnInit() {
    this.getAssetTypeCategory();
    this.isEdit = false;
    this.route.queryParams.subscribe((params) => {
      this.primaryCompanyId = params['id'];
      if (params['edit'] && params['edit'].toLocaleLowerCase() == 'true') {
        this.isEdit = true;
      }
    });
    this.assets = {
      primaryCompanyId: this.primaryCompanyId,
      createdBy: '',
      assetTypeInputs: [
        {
          assetType: '',
          assetTypeId: '',
          measurementTypesList: [],
          assetTypeTemplateInputs: [
            {
              pk: '',
              sk: '',
              id: '',
              assetTypeTemplateName: '',
              customFrequencyRepeatEveryNumber: '',
              customFrequencyRepeatValue: '',
              customFrequencyStartDate: '',
              customFrequencyString: null,
              customFrequencyBtnFlag: false,
              measurementFrequency: '',
              isDeleted: false,
              activeMeasurementCriteriasCount: 0,
              isAnnualCompliance: true,
              isPeriodicCompliance: true,
              isAssetConfigured: false,
              measurementCriteriaInputs: [
                {
                  pk: '',
                  sk: '',
                  id: '',
                  compliance: false,
                  viewInMainGrid: false,
                  measurementSource: '',
                  measurementType: '',
                  isDeleted: false,
                },
              ],
            },
          ],
        },
      ],
    };
    this.frequencies = FREQUENCIES;

    this.originalFormData = structuredClone(this.assets.assetTypeInputs[this.currentIndex]);
  }
  constructor(
    private popupDialogService: PopupDialogService,
    private router: Router,
    private toastr: CustomToastrService,
    private route: ActivatedRoute,
    private assetTypeService: CreateAssetTypeService,
    private store: Store,
    private datePipe: DatePipe,
  ) {
    this.primaryCompanyId$ = this.store.select(
      SetupHierarchyState.getPrimaryCompanyId,
    );
    this.updateAssetTypeMC = {
      primaryCompanyId: this.primaryCompanyId,
      updatedBy: '',
      assetTypeUpdateInputs: [
        {
          assetType: '',
          assetTypeId: '',
          assetTypeTemplateUpdateInputs: [
            {
              id: '',
              pk: '',
              sk: '',
              assetTypeTemplateName: '',
              isAnnualCompliance: true,
              isPeriodicCompliance: true,
              isAssetConfigured: false,
              customFrequencyRepeatEveryNumber: '',
              customFrequencyRepeatValue: '',
              customFrequencyStartDate: '',
              measurementFrequency: '',
              //  isDeleted: false,
              measurementTypeUpdateInputs: [
                {
                  id: '',
                  pk: '',
                  sk: '',
                  compliance: false,
                  viewInMainGrid: false,
                  measurementSource: '',
                  measurementType: '',
                  isDeleted: false,
                },
              ],
            },
          ],
        },
      ],
    };

    this.createAssetTypeMC = {
      primaryCompanyId: this.primaryCompanyId,
      createdBy: '',
      assetTypeInputs: [
        {
          assetType: '',
          assetTypeId: '',
          assetTypeTemplateInputs: [
            {
              customFrequencyRepeatEveryNumber: '',
              customFrequencyRepeatValue: '',
              customFrequencyStartDate: '',
              measurementFrequency: '',
              assetTypeTemplateName: '',
              isAnnualCompliance: true,
              isPeriodicCompliance: true,
              isAssetConfigured: false,
              measurementTypeInputs: [
                {
                  compliance: false,
                  viewInMainGrid: false,
                  measurementSource: '',
                  measurementType: '',
                },
              ],
            },
          ],
        },
      ],
    };
  }

  get currentAssets() {
    return this.assets?.assetTypeInputs?.[this.currentIndex] || [];
  }
  ngOnDestroy(): void {
    this.isEdit = false;
  }

  /**
   * This function displays the existing asset type templates
   * for the current primary company
   */
  // async getAssetTypeTemplate() {
  //   try {
  //     this.isLoader = true;
  //     const response =
  //       await this.assetTypeService.getAssetTypeByPrimayCompanyId(
  //         this.primaryCompanyId!,
  //       );
  //     if (response && response.length > 0) {
  //       this.assets.assetTypeInputs = [];

  //       for (const item of response) {
  //       // const measurementCriterias = [];

  //         // if (item?.measurementTypes) {
  //         //   for (const measurement of item.measurementCriterias) {
  //         //     let criteria1 = '';
  //         //     let criteria2 = '';

  //         //     // Check if the measurement operator is "between"
  //         //     if (
  //         //       measurement?.measurementOperator === 'between' &&
  //         //       measurement?.criteria
  //         //     ) {
  //         //       const [first, second] = measurement.criteria.split(',');
  //         //       criteria1 = first?.trim() || ''; // Assign first value to criteria1
  //         //       criteria2 = second?.trim() || ''; // Assign second value to criteria2
  //         //     }

  //         //     measurementCriterias.push({
  //         //       pk: measurement?.pk || '',
  //         //       sk: measurement?.sk || '',
  //         //       id: measurement?.id || '',
  //         //       compliance: measurement?.compliance || false,
  //         //       criteria: measurement?.criteria || '',
  //         //       criteria1: criteria1,
  //         //       criteria2: criteria2,
  //         //       measurementOperator: measurement?.measurementOperator || '',
  //         //       measurementSource: measurement?.measurementSource || '',
  //         //       measurementType: measurement?.measurementType || '',
  //         //       unit: measurement?.unit || 'V', // Default to Volts
  //         //       isDeleted: measurement?.isDeleted,
  //         //     });
  //         //   }
  //         // }

  //         const customFrequencyString = this.getCustomFrequencyString(
  //           item?.customFrequencyStartDate,
  //           item?.customFrequencyRepeatValue,
  //           item?.customFrequencyRepeatEveryNumber,
  //         );

  //         const assetType = {
  //           pk: item?.pk || '',
  //           sk: item?.sk || '',
  //           id: item?.id || '',
  //          // assetType: item?.assetType,
  //           customFrequencyRepeatEveryNumber:
  //             item?.customFrequencyRepeatEveryNumber || '',
  //           customFrequencyRepeatValue: item?.customFrequencyRepeatValue || '',
  //           customFrequencyStartDate: item?.customFrequencyStartDate || '',
  //           customFrequencyString: customFrequencyString,
  //           customFrequencyBtnFlag: customFrequencyString !== 'Custom',
  //           measurementFrequency: item?.measurementFrequency || '',
  //           isAssetConfigured: item?.isAssetConfigured || false,
  //           // measurementCriteriaInputs: measurementCriterias,
  //           // isDeleted: item?.isDeleted,
  //           // activeMeasurementCriteriasCount:
  //           //   this.getActiveMeasurementCriteriasCount(measurementCriterias),
  //         };

  //         this.assets.assetTypeInputs.push(assetType);
  //       }
  //     }
  //     this.isLoader = false;
  //   } catch (error) {
  //     this.isLoader = false;
  //     console.error(ERROR_FETCHING_ASSET_TYPE, error);
  //   }
  // }

  /**
   * This function return the custom meaurement frequency string
   * @param startDate start date for measurement frequency
   * @param repeatInterval repeat interval for measurement frequency
   * @param selectedRepeatOption repeat option for measurement frequency (Days, Weeks, Months Or Years)
   * @returns
   */
  getCustomFrequencyString(
    startDate: string | null | undefined,
    repeatInterval: string | null | undefined,
    selectedRepeatOption: string | null | undefined,
  ) {
    if (!startDate && !repeatInterval && !selectedRepeatOption) {
      return 'Custom';
    }

    const date = new Date(startDate!);

    // Format the date as dd-MM-yyyy using DatePipe
    const formattedDate = this.datePipe.transform(date, 'dd-MM-yyyy');
    return `Occurs every ${repeatInterval} ${selectedRepeatOption!.toLowerCase()} starting from ${formattedDate}`;
  }

  addAssetType() {
    const newAssetType = {
      pk: '',
      sk: '',
      id: '',
      assetTypeTemplateName: '',
      customFrequencyRepeatEveryNumber: '',
      customFrequencyRepeatValue: '',
      customFrequencyStartDate: '',
      customFrequencyString: null,
      customFrequencyBtnFlag: false,
      measurementFrequency: '',
      isDeleted: false,
      activeMeasurementCriteriasCount: 0,
      isAnnualCompliance: true,
      isPeriodicCompliance: true,
      isAssetConfigured: false,
      measurementCriteriaInputs: [
        {
          pk: '',
          sk: '',
          id: '',
          compliance: false,
          viewInMainGrid: false,
          measurementSource: '',
          measurementType: '',
          isDeleted: false,
        },
      ],
    };

    this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs = [
      ...this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs,
      newAssetType,
    ];

    this.checkFormChanges()
  }
  complianceCheckBoxChange(assetIndex: number, measurementIndex: number) {
    this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
      assetIndex
    ].measurementCriteriaInputs.forEach((x: any) => (x.compliance = false));
    this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
      assetIndex
    ].measurementCriteriaInputs[measurementIndex].compliance = true;
    this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
      assetIndex
    ].measurementCriteriaInputs[measurementIndex].viewInMainGrid = true;
  }

  addMeasurement(assetIndex: number) {
    this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
      assetIndex
    ].measurementCriteriaInputs.push({
      pk: '',
      sk: '',
      id: '',
      compliance: false,
      viewInMainGrid: false,
      measurementSource: '',
      measurementType: '',
      isDeleted: false,
    });
    this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
      assetIndex
    ].activeMeasurementCriteriasCount = this.getActiveMeasurementCriteriasCount(
      this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
        assetIndex
      ].measurementCriteriaInputs,
    );
    this.checkFormChanges()
  }

  deleteMeasurement(assetIndex: number, measurementIndex: number) {
    this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
      assetIndex
    ].measurementCriteriaInputs[measurementIndex].isDeleted = true;
    if (
      !this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
        assetIndex
      ].measurementCriteriaInputs[measurementIndex].pk
    ) {
      this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
        assetIndex
      ].measurementCriteriaInputs.splice(measurementIndex, 1);
    }
    this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
      assetIndex
    ].activeMeasurementCriteriasCount = this.getActiveMeasurementCriteriasCount(
      this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
        assetIndex
      ].measurementCriteriaInputs,
    );

    this.checkFormChanges()
  }

  // Update activeMeasurementCriteriasCount based on actual counts
  getActiveMeasurementCriteriasCount(measurementCriteriaInputs: any) {
    const activeItemsCount = measurementCriteriaInputs.filter(
      (item: { isDeleted: any }) => !item.isDeleted,
    ).length;
    return activeItemsCount;
  }

  cancel() {
    this.popupDialogService.openDialog(
      CANCEL_TEMPLATE_HEADING,
      CANCEL_TEMPLATE_MSG,
      FAILED,
      CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
      () => this.router.navigate([SETUP_COMPANY_OVERVIEW]),
      true,
      NO,
      true,
    );
  }

  // async saveAndExit(form: any) {
  //   // Object.keys(form.controls).forEach((field) => {
  //   //   const control = form.controls[field];
  //   //   control.markAsTouched({ onlySelf: true });
  //   // });
  //   if (form.valid) {
  //     const isValid = await this.saveAssetSetup();
  //     // if (isValid) {
  //     //   this.router.navigate([SETUP_COMPANY_OVERVIEW]);
  //     // }
  //   }
  // }

  async saveAndExit(form: any) {
    // Object.keys(form.controls).forEach((field) => {
    //   const control = form.controls[field];
    //   control.markAsTouched({ onlySelf: true });
    // });
    // if (form.valid) {
    //   const isValid = await this.buildCreateInput().then(() => {
    //     return this.router.navigate([SETUP_COMPANY_OVERVIEW]);
    //   });
    // }
    return this.router.navigate([SETUP_COMPANY_OVERVIEW]);
  }

  async saveAssetSetup() {
    const isValid = this.checkValidation();
    // let assetTypeTemplateResp: AssetTypeTemplateResponse | null = null;

    if (isValid) {
      this.isLoader = true; // Enable the loader before the API call
      try {
        // if (this.isEdit) {
        //   this.buildUpdateInput();
        //   // const updateAssetTypeMCObject = JSON.parse(
        //   //   JSON.stringify(this.updateAssetTypeMC),
        //   // );
        //   // Make the API call and wait for the response
        //   assetTypeTemplateResp =
        //     // await this.assetTypeService.updateAssetTypeAndMC(
        //     //   updateAssetTypeMCObject,
        //     // );
        // } else {
        //   this.buildCreateInput();
        //   // const createAssetTypeMCObject = JSON.parse(
        //   //   JSON.stringify(this.createAssetTypeMC),
        //   // );
        //   // Make the API call and wait for the response
        //   assetTypeTemplateResp =
        //     // await this.assetTypeService.createAssetTypeAndMC(
        //     //   createAssetTypeMCObject,
        //     // );
        // }
        // Handle the response based on the result
        // if (assetTypeTemplateResp?.status?.toLocaleLowerCase() === 'success') {
        //   this.toastr.showSuccess(SUCCESS_CREATE_ASSET_SETUP, TOASTER_SUCCESS);
        // } else {
        //   this.toastr.showError(FAILED_CREATE_ASSET_SETUP, ERROR);
        // }
      } catch (error) {
        // Handle any errors that occur during the API call
        console.error('Error saving asset setup:', error);
        this.toastr.showError(FAILED_CREATE_ASSET_SETUP, ERROR);
      } finally {
        // Disable the loader after the API call (whether it succeeds or fails)
        this.isLoader = false;
      }
    }
    // return (
    //   isValid &&
    //   //assetTypeTemplateResp?.status?.toLocaleLowerCase() === 'success'
    // );
  }
  // async saveAndNext(form: any) {
  //   Object.keys(form.controls).forEach((field) => {
  //     const control = form.controls[field];
  //     control.markAsTouched({ onlySelf: true });
  //   });
  //   if (form.valid) {
  //     const isValid = await this.saveAssetSetup();
  //     // if (isValid) {
  //     //   this.router.navigate([SETUP_HIERARCHY_LEVELS], {
  //     //     queryParams: { id: this.primaryCompanyId },
  //     //   });
  //     // }
  //   }
  // }

  async saveAndNext(form: any) {
    Object.keys(form.controls).forEach((field) => {
      const control = form.controls[field];
      control.markAsTouched({ onlySelf: true });
    });
    if (form.valid) {
      const isValid = await this.buildCreateInput().then(() => {
        return this.router.navigate([SETUP_HIERARCHY_LEVELS], {
          queryParams: { id: this.primaryCompanyId },
        });
      });
    }
  }

  // Handle measurement frequency change
  onMeasurementFrequencyChange(frequency: any) {
    const index = this.assets.assetTypeInputs.length - 1;
    // this.assets.measurementFrequency = frequency;
    if (frequency === 'custom') {
      this.isDialogOpen = true;
    } else {
      this.isDialogOpen = false;
      if (this.assets)
        this.assets.assetTypeInputs[index].customFrequencyBtnFlag = false;
    }
  }
  setCustomFrequency(event: {
    formattedDate: string | null;
    repeatValue: string;
    repeatNumber: number;
    customFrequencyString: string;
  }) {
    if (event.formattedDate) {
      const dateString = event.formattedDate;
      const [day, month, year] = dateString.split('-').map(Number);
      const date = new Date(Date.UTC(year, month - 1, day));
      this.customFrequencyStartDateISOFormat = date.toISOString();
    }
    this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
      this.frequencyIndexFlag
    ].customFrequencyRepeatEveryNumber = event.repeatNumber;
    this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
      this.frequencyIndexFlag
    ].customFrequencyRepeatValue = event.repeatValue;
    this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
      this.frequencyIndexFlag
    ].customFrequencyStartDate = this.customFrequencyStartDateISOFormat;
    this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
      this.frequencyIndexFlag
    ].customFrequencyString = event.customFrequencyString;
    this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
      this.frequencyIndexFlag
    ].customFrequencyBtnFlag = true;
    // this.updateCustomFrequencyString();
    this.checkFormChanges()
    this.isDialogOpen = false;
  }

  //Set isDeleted field to true of assetType and its all the measurement criteria
  deleteAssetType(index: number): void {
    this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
      index
    ].isDeleted = true;
    this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
      index
    ].measurementCriteriaInputs.forEach(
      (criteria: { isDeleted: boolean }) => (criteria.isDeleted = true),
    );
    if (
      !this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
        index
      ].pk &&
      this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs
        .length > 1
    ) {
      this.assets.assetTypeInputs[
        this.currentIndex
      ].assetTypeTemplateInputs.splice(index, 1);
    }

    this.checkFormChanges()
  }

  // onTypeChange(measurement: any) {
  //   // Find the corresponding measurement in the existing array
  //   const foundMeasurement = this.measurementTypes.find(
  //     (m: any) => m.text === measurement.measurementType,
  //   );
  //   // If found, assign the unit
  //   if (foundMeasurement) {
  //     measurement.unit = foundMeasurement.unit; // Assign the unit from the existing measurement
  //   } else {
  //     measurement.unit = 'V'; // Default to Volts if not found
  //   }
  // }

  checkValidation() {
    let isValid = true;
    this.assets.assetTypeInputs[
      this.currentIndex
    ].assetTypeTemplateInputs.forEach((asset: any) => {
      // Check if the asset type is empty
      if (
        !this.assets.assetTypeInputs[this.currentIndex].assetType ||
        this.assets.assetTypeInputs[this.currentIndex].assetType.trim() === ''
      ) {
        this.toastr.showError(ERROR_CREATE_ASSET_TYPE, 'Error');
        isValid = false;
      } else if (
        !asset.assetTypeTemplateName ||
        asset.assetTypeTemplateName.trim() === ''
      ) {
        this.toastr.showError(ERROR_CREATE_ASSET_TYPE, 'Error');
        isValid = false;
      }
      // else if (
      //   !asset.measurementFrequency ||
      //   asset.measurementFrequency.trim() === ''
      // ) {
      //   this.toastr.showError(ERROR_CREATE_ASSET_TYPE, 'Error');
      //   isValid = false;
      // }

      if (isValid == true) {
        // Loop through measurement criteria inputs for each asset
        asset.measurementCriteriaInputs.forEach((measurement: any) => {
          // Validate measurement source
          if (
            !measurement.measurementSource ||
            measurement.measurementSource.trim() === ''
          ) {
            this.toastr.showError(ERROR_CREATE_ASSET_TYPE, 'Error');
            isValid = false;
          }

          // Validate measurement type
          else if (
            !measurement.measurementType ||
            measurement.measurementType.trim() === ''
          ) {
            this.toastr.showError(ERROR_CREATE_ASSET_TYPE, 'Error');
            isValid = false;
          }

          // Validate operator
          // else if (
          //   !measurement.measurementOperator ||
          //   measurement.measurementOperator.trim() === ''
          // ) {
          //   this.toastr.showError(ERROR_CREATE_ASSET_TYPE, 'Error');
          //   isValid = false;
          // }

          // Validate criteria based on operator
          // else if (measurement.measurementOperator === 'between') {
          //   if (!measurement.criteria1 || !measurement.criteria2) {
          //     this.toastr.showError(ERROR_CREATE_ASSET_TYPE, 'Error');
          //     isValid = false;
          //   }
          // } else if (
          //   !measurement.criteria ||
          //   measurement.criteria.trim() === ''
          // ) {
          //   this.toastr.showError(ERROR_CREATE_ASSET_TYPE, 'Error');
          //   isValid = false;
          // }
        });
      }
    });
    return isValid;
  }

  allowOnlyNumbersAndSymbols(event: KeyboardEvent) {
    const allowedCharacters = /[0-9+\-.]/; // Allow digits, +, -, and .
    const inputChar = String.fromCharCode(event.keyCode || event.which);

    if (!allowedCharacters.test(inputChar)) {
      event.preventDefault(); // Prevent the character from being entered
    }
  }

  // // Update the custom frequency string dynamically
  // updateCustomFrequencyString() {
  //   debugger
  //   this.frequencies = this.frequencies.map((item) => {
  //     if (item.value === 'custom') {
  //       return { ...item, text: this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[].customFrequencyString };
  //     }
  //     return item;
  //   });
  // }

  // build the AssetTypeTemplateUpdateInput
  buildUpdateInput() {
    // this.updateAssetTypeMC.primaryCompanyId = this.primaryCompanyId;
    // this.updateAssetTypeMC.assetTypeInputs = [];

    for (const item of this.assets.assetTypeInputs) {
      const measurementCriterias = [];
      if (item?.measurementCriteriaInputs) {
        for (const measurement of item.measurementCriteriaInputs) {
          measurementCriterias.push({
            pk: measurement?.pk || '',
            sk: measurement?.sk || '',
            id: measurement?.id || '',
            compliance: measurement?.compliance || false,
            viewInMainGrid: measurement?.viewInMainGrid || false,
            measurementSource: measurement?.measurementSource || '',
            measurementType: measurement?.measurementType || '',
            isDeleted: measurement?.isDeleted,
          });
        }
      }

      const assetType = {
        pk: item?.pk || '',
        sk: item?.sk || '',
        id: item?.id || '',
        assetType: item?.assetType,
        customFrequencyRepeatEveryNumber:
          item?.customFrequencyRepeatEveryNumber || '',
        customFrequencyRepeatValue: item?.customFrequencyRepeatValue || '',
        customFrequencyStartDate: item?.customFrequencyStartDate || '',
        measurementFrequency: item?.measurementFrequency || '',
        isAssetConfigured: item?.isAssetConfigured || false,
        measurementCriteriaInputs: measurementCriterias,
        isDeleted: item?.isDeleted,
      };

      //.updateAssetTypeMC.assetTypeInputs.push(assetType);
    }
  }
  // build the AssetTypeTemplateCreateInput
  async buildCreateInput() {
    try {
      this.createAssetTypeMC.assetTypeInputs = [];
      const transformedAssets: CreateAssetTypeTemplateInput = {
        primaryCompanyId: this.primaryCompanyId,
        createdBy: this.assets.createdBy,
        assetTypeInputs: [
          {
            assetType:
              this.assets.assetTypeInputs[this.currentIndex]?.assetType || '',
            assetTypeId:
              this.assets.assetTypeInputs[this.currentIndex]?.assetTypeId || '',
            assetTypeTemplateInputs: (
              this.assets.assetTypeInputs[this.currentIndex]
                ?.assetTypeTemplateInputs || []
            ).map((templateInput: any) => ({
              measurementFrequency: templateInput?.measurementFrequency || '',
              assetTypeTemplateName: templateInput?.assetTypeTemplateName || '',
              customFrequencyRepeatEveryNumber:
                templateInput?.customFrequencyRepeatEveryNumber || '',
              customFrequencyRepeatValue:
                templateInput?.customFrequencyRepeatValue || '',
              customFrequencyStartDate:
                templateInput?.customFrequencyStartDate || '',
              isAnnualCompliance: templateInput?.isAnnualCompliance || false,
              isPeriodicCompliance:
                templateInput?.isPeriodicCompliance || false,
              isAssetConfigured: templateInput?.isAssetConfigured || '',
              measurementTypeInputs: (
                templateInput?.measurementCriteriaInputs || []
              ).map((criteriaInput: any) => ({
                compliance: criteriaInput?.compliance || false,
                viewInMainGrid: criteriaInput?.viewInMainGrid || false,
                measurementSource: criteriaInput?.measurementSource || '',
                measurementType: criteriaInput?.measurementType || '',
              })),
            })),
          },
        ],
      };
      const response =
        await this.assetTypeService.createAssetTypeAndMC(transformedAssets);
      if (response?.status?.toLocaleLowerCase() === 'success') {
        this.toastr.showSuccess(SUCCESS_CREATE_ASSET_SETUP, TOASTER_SUCCESS);
        this.currentIndex++;
        this.addNewAssetTemplate();
      } else {
        this.toastr.showError(FAILED_CREATE_ASSET_SETUP, ERROR);
      }
    } catch (error) {
      console.error('Error saving asset setup:', error);
      this.toastr.showError(FAILED_CREATE_ASSET_SETUP, ERROR);
    } finally {
      this.isLoader = false;
    }
  }

  openMeasurementFrequencyPopup(index: number) {
    this.frequencyIndexFlag = index;
    this.isDialogOpen = true;
  }
  closeMeasurementFrequencyPopup() {
    this.isDialogOpen = false;
  }
  // saveData(assetTypeCategory: string, templateData: any[]) {
  //   this.savedData.push({ assetTemplateData: templateData });
  //   this.currentIndex = this.savedData.length - 1; // Set the current index to the latest saved data
  //   this.updateArrowButtonStates();
  // }

  // updateArrowButtonStates() {
  //   this.isLeftArrowDisabled = this.currentIndex === 0;
  //   this.isRightArrowDisabled = this.currentIndex === this.savedData.length - 1;
  // }

  showPreviousData() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.originalFormData = structuredClone(this.assets.assetTypeInputs[this.currentIndex]);
     this.checkFormChanges()
    }
  }
  showNextData() {
    if (this.currentIndex < this.assets.assetTypeInputs.length - 1) {
      this.currentIndex++;
      this.originalFormData = structuredClone(this.assets.assetTypeInputs[this.currentIndex]);
      this.checkFormChanges()
    }
  }
  checkFormChanges() {
    const currentIndexFormData=this.assets.assetTypeInputs[this.currentIndex];
    // Compare current form data with original data
    this.isFormChanged = JSON.stringify(currentIndexFormData) !== JSON.stringify(this.originalFormData);
  }
  async getAssetTypeCategory() {
    this.assetTypeCategoryList =
      await this.assetTypeService.getAssetTypeCategoy();
  }

  saveAssetDetails() {
    this.nextBtnFlag = false;
    const isValid = this.checkValidation();
    const assetTypeTemplateResp: AssetTypeTemplateResponse | null = null;
    if (isValid) {
      this.isLoader = true;
      this.buildCreateInput();
    }
  }

  addNewAssetTemplate() {
    const newTemplate = {
      assetType: '',
      assetTypeId: '',
      measurementTypesList: [],
      assetTypeTemplateInputs: [
        {
          pk: '',
          sk: '',
          id: '',
          assetTypeTemplateName: '',
          customFrequencyRepeatEveryNumber: '',
          customFrequencyRepeatValue: '',
          customFrequencyStartDate: '',
          customFrequencyString: null,
          customFrequencyBtnFlag: false,
          measurementFrequency: '',
          isDeleted: false,
          activeMeasurementCriteriasCount: 0,
          isAnnualCompliance: true,
          isPeriodicCompliance: true,
          isAssetConfigured: false,
          measurementCriteriaInputs: [
            {
              pk: '',
              sk: '',
              id: '',
              compliance: false,
              viewInMainGrid: false,
              measurementSource: '',
              measurementType: '',
              isDeleted: false,
            },
          ],
        },
      ],
    };

    this.assets.assetTypeInputs[this.currentIndex] = newTemplate;
  }

  async onAssetTypeChange(selectedValue: string): Promise<void> {
    try {
      this.isLoader = true;
      if (selectedValue) {
        const selectedItem = this.assetTypeCategoryList?.find(
          (item) => item?.id === selectedValue,
        );
        // this.assets.assetTypeInputs[this.currentIndex].assetType = selectedItem
        //   ? selectedItem.id
        //   : '';

        const templateValues =
          await this.assetTypeService.getAllCoreAssetTemplatesByAssetCategory(
            selectedValue,
          );
        if (
          templateValues?.assetTypeTemplates?.length &&
          templateValues?.assetTypeTemplates?.length > 0
        ) {
          await this.mapAssetTemplate(templateValues);
        } else {
          await this.addNewAssetTemplate();
          this.assets.assetTypeInputs[this.currentIndex].assetTypeId =
            selectedValue ?? '';
          this.assets.assetTypeInputs[this.currentIndex].assetType =
            selectedItem?.assetType ?? '';
        }
      } else {
        this.addNewAssetTemplate();
      }
    } finally {
      const measurementValues =
        await this.assetTypeService.getAllCoreMeasurementTypesByAssetCategory(
          selectedValue,
        );
      this.assets.assetTypeInputs[this.currentIndex].measurementTypesList =
        measurementValues;
       this.isLoader = false;
    }
  }
  async mapAssetTemplate(input: AssetType) {
    const newAssetTemplate = {
      assetType: input.assetType || '',
      assetTypeId: input.id || '',
      measurementTypesList: [],
      assetTypeTemplateInputs: input?.assetTypeTemplates?.map((template) => ({
        pk: template?.pk || '',
        sk: template?.sk || '',
        id: template?.id || '',
        assetTypeTemplateName: template?.assetTypeTemplateName || '',
        customFrequencyRepeatEveryNumber:
          template?.customFrequencyRepeatEveryNumber || '',
        customFrequencyRepeatValue: template?.customFrequencyRepeatValue || '',
        customFrequencyStartDate: template?.customFrequencyStartDate || '',
        customFrequencyString: null, // Not provided in the input, so set to null
        customFrequencyBtnFlag: false, // Default value
        measurementFrequency: template?.measurementFrequency || '',
        isDeleted: template?.isDeleted || false,
        activeMeasurementCriteriasCount: input?.assetTypeTemplates?.length, // Default value, not in the input
        isAnnualCompliance: template?.isAnnualCompliance || false,
        isPeriodicCompliance: template?.isPeriodicCompliance || false,
        isAssetConfigured: template?.isAssetConfigured || false,
        measurementCriteriaInputs: template?.measurementTypes?.map(
          (measurement) => ({
            pk: measurement?.pk || '',
            sk: measurement?.sk || '',
            id: measurement?.id || '',
            compliance: measurement?.compliance || false,
            viewInMainGrid:
              measurement?.viewInMainGrid || measurement?.compliance || false,
            measurementSource: measurement?.measurementSource || '',
            measurementType: measurement?.measurementType || '',
            isDeleted: measurement?.isDeleted || false,
          }),
        ),
      })),
    };
    this.assets.assetTypeInputs[this.currentIndex] = newAssetTemplate;
  }

  isUniqueAssetName(value: string, index: number): boolean {
    return (
      this.assets.assetTypeInputs[
        this.currentIndex
      ].assetTypeTemplateInputs.findIndex(
        (asset: any, i: number) =>
          i !== index && asset.assetTypeTemplateName === value,
      ) === -1
    );
  }

  validateAssetTypeName(value: string, index: number): void {
    const isUnique = this.isUniqueAssetName(value, index);
    this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
      index
    ].isNotUnique = !isUnique;
    this.checkFormChanges()
  }
}
