import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import { HeirarchyFilterServiceService } from '../../shared/service/heirarchy-filter-service/heirarchy-filter-service.service';
import { RemoveLevel1LevelName, SetLevel1LevelName } from '../../core/store/filter-level1Name.state';
import { RemoveLevel2LevelName, SetLevel2LevelName } from '../../core/store/filter-level2Name.state';
import { RemoveLevel3LevelName, SetLevel3LevelName } from '../../core/store/filter-level3Name.state';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrl: './dashboard.component.css',
})

export class DashboardComponent implements OnInit{
  public isLoading=false;
  defaultLoad = true;
  isAuthenticated$: Observable<boolean | null | undefined> | undefined;
  storePrimaryCompanyID$: Observable<string | null>;
  storePrimaryCompnayId = '';

  constructor(private store: Store, private _heirarchyFilterService : HeirarchyFilterServiceService) 
  {
    this.storePrimaryCompanyID$= this.store.select(AuthenticateUserState.getSyrcPrimaryCompanyId);
  }

  ngOnInit(): void {
    this.isAuthenticated$ = this.store.select(AuthenticateUserState.getIsAuthenticated);
    this.generateLevelNames();
  }

  async generateLevelNames() {
    this.isLoading = true;
    await this._heirarchyFilterService.initialize();
    this.isLoading = false;
    this.store.dispatch(new RemoveLevel1LevelName());
    this.store.dispatch(new RemoveLevel2LevelName());
    this.store.dispatch(new RemoveLevel3LevelName());
    const levelNameList = await this._heirarchyFilterService.getLevelNamesByPrimaryComapany();
    if(levelNameList && levelNameList.items){
      levelNameList.items?.forEach(item => {
        if(item?.level1Name != null){
          this.store.dispatch(new SetLevel1LevelName(item.level1Name));
        }
        if(item?.level2Name != null){
          this.store.dispatch(new SetLevel2LevelName(item.level2Name));
        }
        if(item?.level3Name != null){
          this.store.dispatch(new SetLevel3LevelName(item.level3Name));
        }
      });
    }
  }


  // refreshSessionCheckSession(): void {
  //   this.oidcSecurityService.authorize();
  // }

  // forceRefreshSession(): void {
  //   this.oidcSecurityService
  //     .forceRefreshSession()
  //     .subscribe((result: any) => console.log(result));
  // }
}
