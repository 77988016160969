import { Injectable } from '@angular/core';
import { BaseAWSRequestService } from '../../shared/service/base-services/base-aws-request.service';
import { DataExportInput, InitializeDataExportMutation } from '../../../awsAppSync/API';
import { initializeDataExport } from '../../../awsAppSync/graphql/mutations';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  constructor(private awsService: BaseAWSRequestService) {}

  /**
   * Initializes a data export and returns the export status
   * @param input - Input parameters for the data export request
   * @returns - Data export status or null in case of an error
   */
  async initializeDataExport(input: DataExportInput): Promise<InitializeDataExportMutation | null> {
    try {
      const variables = { input };

      // Execute the mutation to initialize the data export
      let result = await this.awsService.executeMutation<InitializeDataExportMutation>(
        initializeDataExport,
        variables
      );

      result = result?.initializeDataExport || null;

      if (result) {
        return result; // Return the result if data export was initialized successfully
      }

      return null; // Return null if initialization failed
    } catch (error) {
      console.error('Error during data export initialization', error);
      return null; // Return null in case of an error
    }
  }
 
}
