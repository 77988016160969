import { Component, OnInit } from '@angular/core';
import { columnNames, reportColumns } from '../../shared/enums';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PopupDialogService } from '../../shared/popup-dialog/popup-dialog.service';
import {
  AUDIT,
  CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
  CANCEL_TEMPLATE_HEADING,
  CANCEL_TEMPLATE_MSG,
  CONTINUE,
  FAILED,
  NO,
  PERFORMANCE,
  REDIRECT_REPORT_TEMPLATE_LIBRARY,
  SUCCESS,
  SUCCESS_TEMPLATE_HEADING,
  SUCCESS_TEMPLATE_MSG,
} from '../../shared/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-new-template',
  templateUrl: './create-new-template.component.html',
  styleUrl: './create-new-template.component.css',
})
export class CreateNewTemplateComponent implements OnInit {
  form!: FormGroup;
  columnOptions: Record<string, string[]> = {};
  selectedItems: Record<string, boolean> = {};
  tiles: string[] = [];
  reportTypes = [AUDIT, PERFORMANCE];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private popupDialogService: PopupDialogService,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      templateName: ['', Validators.required],
      reportType: ['', Validators.required],
      reportDescription: [''],
      selectedColumns: [[]],
    });

    this.columnOptions = this.convertEnumToOptions();
    this.updateTiles();

    this.form.get('reportType')?.valueChanges.subscribe(() => {
      this.handleReportTypeChange();
    });
  }

  convertEnumToOptions(): Record<string, string[]> {
    const options: Record<string, string[]> = {};
    for (const key in reportColumns) {
      if (Object.prototype.hasOwnProperty.call(reportColumns, key)) {
        const types = reportColumns[key as keyof typeof reportColumns]
          .split(',')
          .filter((type) => type); // Remove empty types
        options[key] = types;
      }
    }
    return options;
  }

  handleReportTypeChange(): void {
    this.selectedItems = {};
    this.tiles = [];
    this.form.get('selectedColumns')?.setValue([]);
  }

  getFilteredColumns(): string[] {
    const reportType = this.form.get('reportType')?.value || AUDIT;
    return Object.keys(this.columnOptions).filter((column) =>
      this.columnOptions[column].includes(reportType),
    );
  }

  getSelectedColumns(): string[] {
    return Object.keys(this.selectedItems).filter(
      (item) => this.selectedItems[item],
    );
  }

  onCheckboxChange(item: string, event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    const isChecked = checkbox.checked;
    this.selectedItems[item] = isChecked;
    if (isChecked) {
      this.tiles.push(item);
    } else {
      this.tiles = this.tiles.filter((selectedItem) => selectedItem !== item);
    }
    this.form.get('selectedColumns')?.setValue(this.tiles);
  }

  removeColumn(item: string): void {
    this.selectedItems[item] = false;
    this.tiles = this.tiles.filter((selectedItem) => selectedItem !== item);
    this.form.get('selectedColumns')?.setValue(this.tiles);
  }

  updateTiles(): void {
    this.tiles = this.getSelectedColumns();
  }

  allColumnsSelected(): boolean {
    const filteredColumns = this.getFilteredColumns();
    return (
      filteredColumns.length === this.tiles.length && filteredColumns.length > 0
    );
  }

  getColumnDisplayName(key: string): string {
    return columnNames[key as keyof typeof columnNames] || key;
  }

  cancel(): void {
    this.popupDialogService.openDialog(
      CANCEL_TEMPLATE_HEADING,
      CANCEL_TEMPLATE_MSG,
      FAILED,
      CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
      () => this.router.navigate([REDIRECT_REPORT_TEMPLATE_LIBRARY]),
      true,
      NO,
    );
  }

  save(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      //Add the API to save the data
      this.popupDialogService.openDialog(
        SUCCESS_TEMPLATE_HEADING,
        SUCCESS_TEMPLATE_MSG,
        SUCCESS,
        CONTINUE,
        () => this.router.navigate([REDIRECT_REPORT_TEMPLATE_LIBRARY]),
      );
    }
  }
}
