import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { SELECTED_MTX_Member_STATE, SELECTED_MTX_Member_TYPE_SET, SELECTED_MTX_Member_TYPE_SET_REMOVE} from "../../shared/constants";

export interface MTX_Member {
    isEditable: boolean;
    selectedMTX_MemberId: string | null;
}
export class SetMTX_Member {
    static readonly type = SELECTED_MTX_Member_TYPE_SET;
    constructor(
        public isEditable: boolean,
        public selectedMTX_MemberId: string,
    ) {}
}
export class RemoveMTX_Member {
    static readonly type = SELECTED_MTX_Member_TYPE_SET_REMOVE;
}
@State<MTX_Member>({
    name: SELECTED_MTX_Member_STATE,
    defaults: {
        isEditable: false,
        selectedMTX_MemberId: null
    }
})

@Injectable()
export class SelectedMTX_MemberState {
    @Selector()
    static getSelectedMTX_MemberId(state: MTX_Member) {
        return state.selectedMTX_MemberId;
    }
    @Selector()
    static getSelectedMTX_MemberIsEdited(state: MTX_Member) {
        return state.isEditable;
    }

    @Action(SetMTX_Member)
    SetSelectedMTX_Member(
        { patchState }: StateContext<MTX_Member>,
        {
            isEditable,
            selectedMTX_MemberId,
        }: SetMTX_Member,
    ): void {
        patchState({
            isEditable: isEditable,
            selectedMTX_MemberId : selectedMTX_MemberId
        });
    }
    @Action(RemoveMTX_Member)
    RemoveSelectedMTX_Member({
        setState,
    }: StateContext<MTX_Member>): void {
        setState({
            isEditable: false,
            selectedMTX_MemberId: null
        });
    }
}