import { ChangeDetectorRef, Component } from '@angular/core';
import {
  CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
  EMPTY_STRING,
  FAILED,
  FILE_UPLOAD_ERROR,
  FILE_UPLOAD_ERROR_MSG,
  NO,
  SETUP_COMPANY_OVERVIEW,
  SETUP_IMPORT_INSPECTION_DATA,
  SURE_CANCEL_HEADER,
  SURE_CANCEL_MSG,
  UPLOAD_HEADER,
  UPLOAD_SUBHEADER,
  VERIFY_HEADER,
  VERIFY_SUBHEADER,
} from '../../shared/constants';
import { CustomToastrService } from '../../shared/ngx-toastr/custom-toastr.service';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { PopupDialogService } from '../../shared/popup-dialog/popup-dialog.service';
import { HeirarchyFilterServiceService } from '../../shared/service/heirarchy-filter-service/heirarchy-filter-service.service';
import { AssetType } from '../../../awsAppSync/API';
import { Observable } from 'rxjs';
import { SetupHierarchyState } from '../../core/store/setup-hierarchy.state';
import { CreateAssetTypeService } from '../../core/services/create-asset-type.service';
import * as XLSX from 'xlsx';
import { ImportInspectionDataService } from '../../core/services/import-inspection-data.service';

export interface FileModel {
  name: string;
  isValid: boolean;
}
export interface SegmentAssetModel {
  segmentName: string;
  assetTypeId: string;
  files: FileModel[];
}

@Component({
  selector: 'app-import-asset-inspection-data',
  templateUrl: './import-asset-inspection-data.component.html',
  styleUrl: './import-asset-inspection-data.component.css',
})
export class ImportAssetInspectionDataComponent {
  isLoading = false;
  currentStep = 5;
  header = VERIFY_HEADER;
  subHeader = VERIFY_SUBHEADER;
  markBtnVisibility = false;
  isDisabled = true;
  listOfSegmentsAndAssets: SegmentAssetModel[] = [];
  primaryCompanyId$: Observable<string | null>;
  storePrimaryCompanyid: string | null = EMPTY_STRING;
  extractedData: any;
  sheetData: string[][] = [];
  currentMandatoryColumns: any;
  invalidFileURL = '';
  uploadedFiles: { name: any; isInvalid?: boolean }[][] = [];
  fileUploaded = false;

  constructor(
    private store: Store,
    private toastr: CustomToastrService,
    private router: Router,
    private importInspectionData: ImportInspectionDataService,
    private popupDialogService: PopupDialogService,
    private assetTypeService: CreateAssetTypeService,
    private hierarchyFilterService: HeirarchyFilterServiceService,
    private cdr: ChangeDetectorRef,
  ) {
    this.primaryCompanyId$ = this.store.select(
      SetupHierarchyState.getPrimaryCompanyId,
    );
  }
  async ngOnInit(): Promise<void> {
    this.primaryCompanyId$.subscribe((id) => {
      this.storePrimaryCompanyid = id;
    });

    await this.getAllAssetTypesByPrimaryCompany(this.storePrimaryCompanyid!);
    this.getMeasurementData();
    if (!this.importInspectionData.isFreshRoute) {
      if (this.importInspectionData.uploadedFiles.length > 0) {
        if (this.importInspectionData.invalidFileUrl) {
          this.listOfSegmentsAndAssets.forEach((segment) => {
            if (segment.segmentName === this.importInspectionData.assetType) {
              if (this.importInspectionData.uploadedFiles.length > 0) {
                this.importInspectionData.uploadedFiles.forEach(
                  (uploadedFileArray, _index) => {
                    if (uploadedFileArray && uploadedFileArray.length > 0) {
                      segment.files = uploadedFileArray.map(
                        (file: FileModel) => ({
                          ...file,
                          isValid:
                            this.importInspectionData.fileName === file.name
                              ? false
                              : file.isValid,
                        }),
                      );
                    }
                  },
                );
              }
            }
          });
          this.isDisabled = true;
          this.invalidFileURL = this.importInspectionData.invalidFileUrl;
        } else {
          this.listOfSegmentsAndAssets.forEach((segment) => {
            if (segment.segmentName === this.importInspectionData.assetType) {
              if (this.importInspectionData.uploadedFiles.length > 0) {
                this.importInspectionData.uploadedFiles.forEach(
                  (uploadedFileArray, _index) => {
                    if (uploadedFileArray && uploadedFileArray.length > 0) {
                      segment.files = uploadedFileArray.map(
                        (file: FileModel) => ({
                          ...file,
                          isValid:
                            this.importInspectionData.fileName === file.name
                              ? true
                              : file.isValid,
                        }),
                      );
                    }
                  },
                );
              }
            }
          });
          this.isDisabled = false;
        }
        this.markBtnVisibility = true;
        this.importInspectionData.isFreshRoute = true;
      }
    }
  }

  async getMeasurementData() {
    // const response = await this.assetTypeService.getAssetTypeByPrimayCompanyId(
    //   this.storePrimaryCompanyid!,
    // );
      // Commenting asset Type Template schema change 
    // this.extractedData = response?.map((item) => ({
    //   assetType: item?.assetType,
    //   measurementCriterias: item?.measurementCriterias?.map((criteria) => ({
    //     measurementSource: criteria?.measurementSource,
    //     measurementType: criteria?.measurementType,
    //     compliance: criteria?.compliance,
    //   })),
    // }));
  }

  async getAllAssetTypesByPrimaryCompany(id: string) {
    this.isLoading = true;
    try {
      const response =
        await this.hierarchyFilterService.getAllAssetTypesByPrimaryCompany(id);

      if (response && response.items) {
        this.listOfSegmentsAndAssets = [];

          // removed isAssetConfigured Condition 
        response.items.forEach((item: AssetType | null) => {
          if (item && item.assetType) {
            const newSegment: SegmentAssetModel = {
              segmentName: item.assetType,
              assetTypeId: item.id,
              files: [],
            };

            this.listOfSegmentsAndAssets.push(newSegment);
          }
        });
        this.isLoading = false;
      } else {
        console.error('No items found in the response');
      }
    } catch (error) {
      console.error('Error fetching asset types:', error);
      this.isLoading = false;
    }
  }

  getMandatoryColumns(assetType: string) {
    const asset = this.extractedData.find(
      (item: any) => item.assetType === assetType,
    );

    if (asset) {
      const result = [];

      result.push('Asset Name');
      result.push('Segment');

      asset.measurementCriterias
        .filter((item: any) => item.compliance === true)
        .forEach((item: any) => {
          result.push(`${item.measurementSource} ${item.measurementType}`);
        });

      result.push('Date of Inspection');
      result.push('Survey Name');
      result.push('Technician');

      return result;
    } else {
      return [];
    }
  }

  onFileSelected(event: any, index: number, value: string, id: string) {
    this.setColumns(value);
    this.currentMandatoryColumns = this.getMandatoryColumns(value);
    const fileInput = event.target;
    const files = event.target.files;
    const allowedExtensions = ['csv', 'xlsx'];
    this.importInspectionData.files = files;
    this.importInspectionData.currentAssetmandatoryColumns =
      this.currentMandatoryColumns;
    this.importInspectionData.assetType = value;
    this.importInspectionData.assetTypeId = id;

    if (!this.listOfSegmentsAndAssets[index].files) {
      this.listOfSegmentsAndAssets[index].files = [];
    }

    for (const file of files) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];

          const jsonSheet = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          const recordCount = jsonSheet.length;

          // Check for record limit
          if (recordCount > 10000) {
            this.toastr.showError(
              'File contains more than 10K records. Please upload a smaller file.',
              'File Upload Error',
            );
            fileInput.value = '';
            return;
          }

          let fileHeaders = jsonSheet[0] as string[];
          fileHeaders = fileHeaders.map((column) => column.replace('*', ''));

          // Check for missing mandatory columns
          const missingColumns = this.currentMandatoryColumns.filter(
            (column: any) => !fileHeaders.includes(column),
          );

          if (missingColumns.length > 0) {
            const missingColumnsStr = missingColumns.join(', ');
            this.toastr.showError(
              `Missing mandatory columns: ${missingColumnsStr}`,
              'File Upload Error',
            );
            fileInput.value = '';
            return;
          }

          const columnTypes: Record<string, string> = {};
          fileHeaders.forEach((header) => {
            columnTypes[header] = 'Unknown';
          });
          fileHeaders.forEach((header, colIndex) => {
            for (let i = 1; i < jsonSheet.length; i++) {
              const row = jsonSheet[i] as any[];
              const cellValue = row[colIndex];

              if (cellValue !== undefined && cellValue !== null) {
                const type = typeof cellValue;

                if (header.toLowerCase().includes('date')) {
                  columnTypes[header] = 'Date';
                } else if (type === 'string') {
                  if (this.isValidDate(cellValue)) {
                    columnTypes[header] = 'Date';
                  } else if (!isNaN(Number(cellValue))) {
                    columnTypes[header] = 'Number';
                  } else {
                    columnTypes[header] = 'String';
                  }
                } else if (type === 'number') {
                  columnTypes[header] = 'Number';
                } else if (type === 'object') {
                  columnTypes[header] = 'Object';
                } else {
                  columnTypes[header] = 'String';
                }
              }
            }
          });
          this.importInspectionData.columnTypes = columnTypes;

          if (!this.importInspectionData.uploadedFiles[index]) {
            this.importInspectionData.uploadedFiles[index] = [];
          }
          const newFile: FileModel = {
            name: file.name,
            isValid: true,
          };
          this.importInspectionData.fileName = file.name;

          this.listOfSegmentsAndAssets[index].files.push(newFile);
          this.importInspectionData.uploadedFiles[index].push(newFile);
          this.isDisabled = false;

          if (this.listOfSegmentsAndAssets[index].files.length > 0) {
            this.router.navigate([SETUP_IMPORT_INSPECTION_DATA]);
          }
        };
        reader.onerror = () => {
          this.toastr.showError('Error reading the file.', 'File Upload Error');
        };
        reader.readAsBinaryString(file);
      } else {
        this.toastr.showError(FILE_UPLOAD_ERROR_MSG, FILE_UPLOAD_ERROR);
      }
    }
  }

  removeFile(segmentIndex: number, fileIndex: number) {
    this.uploadedFiles[segmentIndex].splice(fileIndex, 1);
  }

  downloadInvalidFile(): void {
    const link = document.createElement('a');
    link.href = this.invalidFileURL;
    link.download = '';
    link.click();
  }

  isValidDate(value: string): boolean {
    // Regular expressions to match different date formats
    const mmddyyyyRegex =
      /\b(0?[1-9]|1[0-2])\/(0?[1-9]|[12]\d|3[01])\/(19|20)\d{2}\b/; // MM/DD/YYYY
    const ddmmyyyyRegex =
      /\b(0?[1-9]|[12]\d|3[01])\/(0?[1-9]|1[0-2])\/(19|20)\d{2}\b/; // DD/MM/YYYY
    const yyyymmddRegex =
      /\b(19|20)\d{2}-(0?[1-9]|1[0-2])-(0?[1-9]|[12]\d|3[01])\b/; // YYYY-MM-DD

    // Check if the value matches any of the date formats
    const isMMDDYYYY = mmddyyyyRegex.test(value);
    const isDDMMYYYY = ddmmyyyyRegex.test(value);
    const isYYYYMMDD = yyyymmddRegex.test(value);

    return isMMDDYYYY || isDDMMYYYY || isYYYYMMDD;
  }

  downloadFileFormat(value: string) {
    const columns = this.setColumns(value);
    this.sheetData = [columns];
    this.downloadCSVFile(value);
    this.downloadExcelFile(value);
  }

  setColumns(value: string) {
    const filteredData = this.extractedData.find(
      (item: any) => item.assetType === value,
    );

    const columns = [
      'Segment*',
      'Asset Name*',
      ...filteredData.measurementCriterias.map(
        (criteria: any) =>
          `${criteria.measurementSource} ${criteria.measurementType}${
            criteria.compliance ? '*' : ''
          }`,
      ),
      'Date of Inspection*',
      'Survey Name*',
      'Technician*',
    ];

    this.importInspectionData.displayedColumns = columns.map((column) =>
      column.replace('*', ''),
    );

    return columns;
  }

  downloadCSVFile(value: string) {
    const csvData = this.createCSVData();
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    this.downloadBlob(blob, value + '.csv');
  }

  createCSVData(): string {
    return this.sheetData
      .map((row) => row.map((cell) => `"${cell}"`).join(','))
      .join('\n');
  }

  downloadExcelFile(value: string) {
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.sheetData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, value);

    const excelData = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    this.downloadBlob(blob, value + '.xlsx');
  }

  downloadBlob(blob: Blob, filename: string) {
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  nextBtnClick(): void {
    this.markBtnVisibility = true;
    this.header = UPLOAD_HEADER;
    this.subHeader = UPLOAD_SUBHEADER;
  }

  saveAndExitBtnClick(): void {
    this.router.navigate([SETUP_COMPANY_OVERVIEW]);
  }

  onCancel() {
    this.popupDialogService.openDialog(
      SURE_CANCEL_HEADER,
      SURE_CANCEL_MSG,
      FAILED,
      CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
      () => this.router.navigate([SETUP_COMPANY_OVERVIEW]),
      true,
      NO,
    );
  }
}
