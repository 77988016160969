import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  public data: any[] = [];
  public _selectedColumns: string[] = [];
  public flag = '';
  private subject: Subject<string> = new Subject<string>();
  public fileName = '';
  
  public say(data: any) {
    this.subject.next(data);
  }
  public hear(): Observable<any> {
    return this.subject.asObservable();
  }
}
