import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-export-map-modal',
  templateUrl: './export-map-modal.component.html',
  styleUrl: './export-map-modal.component.css'
})
export class ExportMapModalComponent {
  selectedType = '';
  includeLegendsMarkers = true;
  @Output() exportTriggered = new EventEmitter<{ fileType: string; includeLegendsMarkers: boolean }>();

  constructor( private dialogRef: MatDialogRef<ExportMapModalComponent>){}


  selection(type: string) {
    this.selectedType = type;
  }

  close(): void {
    this.dialogRef.close();
  }

  export() {
    if (this.selectedType) {
      this.exportTriggered.emit({
        fileType: this.selectedType,
        includeLegendsMarkers: this.includeLegendsMarkers
      });
      this.dialogRef.close({ fileType: this.selectedType, includeLegendsMarkers: this.includeLegendsMarkers });
    }
  }
}
