import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { EMPTY_STRING, PC_ADMIN__STATE } from '../../shared/constants';

export interface PCUserDataStateModel {
  isPCAdmin: boolean | null;
  primaryCompanyName: string | null;
  primaryCompanyId: string | null;
  coreViewCompanyId: string | null;
}

export class SetPCUserData {
  static readonly type = '[PCAdminData] Set AdminDetails';
  constructor(
    public isPCAdmin: boolean,
    public primaryCompanyName: string,
    public primaryCompanyId: string,
    public coreViewCompanyId: string,
  ) {}
}

export class RemovePCUserData {
  static readonly type = '[PCAdminData] Remove AdminDetails';
}

@State<PCUserDataStateModel>({
  name: PC_ADMIN__STATE,
  defaults: {
    isPCAdmin: false,
    primaryCompanyName: null,
    primaryCompanyId: null,
    coreViewCompanyId: null,
  },
})
@Injectable()
export class PCAdminDataState {
  @Selector()
  static getisPCAdmin(state: PCUserDataStateModel) {
    return state.isPCAdmin;
  }

  @Selector()
  static getprimaryCompanyId(state: PCUserDataStateModel) {
    return state.primaryCompanyId;
  }

  @Selector()
  static getprimaryCompanyName(state: PCUserDataStateModel) {
    return state.primaryCompanyName;
  }
  @Selector()
  static getcoreViewCompanyId(state: PCUserDataStateModel) {
    return state.coreViewCompanyId;
  }

  @Action(SetPCUserData)
  SetPCUserData(
    { patchState }: StateContext<PCUserDataStateModel>,
    {
      isPCAdmin,
      primaryCompanyId,
      primaryCompanyName,
      coreViewCompanyId,
    }: SetPCUserData,
  ): void {
    patchState({
      isPCAdmin: isPCAdmin,
      primaryCompanyId: primaryCompanyId,
      primaryCompanyName: primaryCompanyName,
      coreViewCompanyId: coreViewCompanyId,
    });
  }
  @Action(RemovePCUserData)
  RemovePCUserData({ setState }: StateContext<PCUserDataStateModel>): void {
    setState({
      isPCAdmin: false,
      primaryCompanyId: EMPTY_STRING,
      primaryCompanyName: EMPTY_STRING,
      coreViewCompanyId: EMPTY_STRING,
    });
  }
}
