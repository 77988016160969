import { Component, EventEmitter, Input, Output, OnInit, OnChanges, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { LastEvaluatedKeyModel } from '../../core/models/lastevaluatedkey';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() pageLoad = false;      
  @Input() currentPage = 1;                // Current page number (defaults to 1)
  @Input() pageSize = 10;                  // Items per page
  @Input() nextKey: LastEvaluatedKeyModel | null = null;          // Key for the next page
  @Input() previousKey: LastEvaluatedKeyModel | null = null;      // Key for the previous page
  @Output() pageChanged = new EventEmitter<{ page: number, pageSize: number, nextKey: LastEvaluatedKeyModel | null }>();

  displayedPages: number[] = [];  // List of pages to be displayed
  pageKeys = new Map();  // Store nextKeys for each page

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    // Initialize pagination state on component load
    this.updateDisplayedPages();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.currentPage === 1 && this.nextKey) {
      // Set the nextKey for page 1
      this.pageKeys = new Map();
      this.pageKeys.set(this.currentPage, this.nextKey);
    }

    if (changes["pageLoad"]){
      if(changes["pageLoad"].firstChange == false){
        this.pageKeys = new Map();
        this.currentPage = 1;
        this.displayedPages = [1];
        this.updateDisplayedPages();
      }
    }

    
  }

  // Updates the pages to be displayed based on currentPage and nextKey
  updateDisplayedPages(): void {
     // Always start with page 1
    // If nextKey exists, dynamically add the next pages
    if (this.nextKey) {
      let nextPage = 2;  // Start showing from page 2
      this.displayedPages = [1];
      // Dynamically add subsequent pages (e.g., page 2, page 3, etc.)
      while (this.pageKeys.has(nextPage) || (this.pageKeys.size === 0 && nextPage === 2)) {
        //this.pageKeys.set(nextPage, this.nextKey); // Store the nextKey for each page
        this.displayedPages.push(nextPage);
        nextPage++;
      }
    }

    // Ensure we only show the last 5 pages along with page 1
    const totalPages = this.displayedPages.length;

    if (totalPages > 6) {
      this.displayedPages = [1, ...this.displayedPages.slice(totalPages - 5)];
    }

    if(this.currentPage == 1){
      this.displayedPages =  this.displayedPages = [1];
    }

    // Trigger change detection to update the UI
    this.cdRef.detectChanges();
    this.cdRef.markForCheck();
  }

  // Handle the "Previous" page click

  goToPreviousPage(): void {
    
    if(this.currentPage != 1){
      this.currentPage --;
    }

    this.updatePagesForPreviousNavigation();
    
    // if (this.currentPage > 1) {
    //   // First, update the displayed pages based on the previous page navigation
      
    //   this.currentPage --;
    //   //this.updatePagesForPreviousNavigation();
      
    //   // Emit the page change event after updating the displayed pages to reflect the changes
    //   setTimeout(() => {
    //     // Emit the page change event with the updated nextKey (previous page)
    //     const prevKey = this.pageKeys.get(this.currentPage) || null;
    //     this.pageChanged.emit({ page: this.currentPage, pageSize: this.pageSize, nextKey: prevKey });
        
    //     // Manually trigger change detection to update the UI after emitting the event
    //     this.cdRef.detectChanges();
    //     this.cdRef.markForCheck();
    //     //this.updatePagesForPreviousNavigation();
    //   });

    //   console.log("goToPreviousPage :pageKeys");
    //   console.log(this.pageKeys);
    // }
}
  // New method to handle the display logic when navigating to the previous page
  updatePagesForPreviousNavigation(): void {

    setTimeout(() => {
        const prevKey = this.pageKeys.get(this.currentPage) || null;
        this.pageChanged.emit({ page: this.currentPage, pageSize: this.pageSize, nextKey: prevKey });
    }, 200); // Delay of 300ms
    
    if(this.currentPage == 1){
      this.displayedPages = [1];
    }
    if (this.displayedPages.length <= 1) return; // No pages to go back to

    // Remove the last page from displayedPages
    if(this.currentPage > 5){
      this.displayedPages.pop();

      // Calculate the previous page: Subtract 1 from the second page in the array
      const previousPage = this.displayedPages[1] - 1;
  
      // Insert the previous page at the second position (index 1)
      this.displayedPages.splice(1, 0, previousPage);
    }
    // Ensure that the displayed pages list always shows at most the last 5 pages plus 1
    const totalPages = this.displayedPages.length;
    if (totalPages > 6) {
      this.displayedPages = [1, ...this.displayedPages.slice(1, totalPages - 1)];
    }
    this.cdRef.detectChanges();

  }

  // Go to a specific page
  goToPage(page: number): void {
    if (page < 1 || page > Math.max(...this.displayedPages)) return; // Ensure valid page range

    // Update current page and emit event
    this.currentPage = page;

    const nextKey: LastEvaluatedKeyModel | null = this.pageKeys.get(page) || null;

    // Emit the page change event with the updated nextKey
    this.pageChanged.emit({ page, pageSize: this.pageSize, nextKey });

    // Update displayed pages after page change
    if(page == 6){
      this.displayedPages = [1,2,3,4,5,6];
    }else if(page == 5){
      this.displayedPages = [1,2,3,4,5];
    }
    else if(page == 4){
      this.displayedPages = [1,2,3,4];
    }
    else if(page == 3){
      this.displayedPages = [1,2,3];
    }
    else if(page == 2){
      this.displayedPages = [1,2];
    }
    else{
      this.updateDisplayedPages();
    }
    console.log("goToPage :pageKeys");
    console.log(this.pageKeys);
  }

  // Handle the "Next" page click
  goToNextPage(): void {
    if (this.nextKey) {
      this.currentPage++;
      this.pageKeys.set(this.currentPage, this.nextKey);  // Store the next key for the new page
      this.updateDisplayedPages();
      this.pageChanged.emit({ page: this.currentPage, pageSize: this.pageSize, nextKey: this.nextKey });
     
      console.log("goToPage :pageKeys");
      console.log(this.pageKeys);
    }
  }

  // Handle page size change
  onPageSizeChange(event: Event): void {
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.pageSize = parseInt(selectedValue, 10);
    this.currentPage = 1;
    this.displayedPages= [1];
    this.pageKeys = new Map();
    this.pageChanged.emit({ page: 1, pageSize: this.pageSize, nextKey: null });
  }
}
