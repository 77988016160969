import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-excel-export-component',
  templateUrl: './excel-export-component.component.html',
  styleUrl: './excel-export-component.component.css'
})
export class ExcelExportComponentComponent {
  //public dynamicColumns: any[] = [];
  @Input() filteredGridData: any[] = [];
  //@Input() fileName: string = 'Excel';
  @Input() selectedColumns: string[] = [];

  constructor(){
   // this.fetchDynamicData();
  }

  // fetchDynamicData() {
  //   // Dynamically extract column names from the first row of data
  //   this.dynamicColumns = Object.keys(this.dynamicData[0]).map((key) => ({
  //     field: key,
  //     title: key.replace(/([A-Z])/g, ' $1').trim() // Convert camelCase to title case
  //   }));
  // }
}
