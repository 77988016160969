import { NgModule } from '@angular/core';
import { AuthModule } from 'angular-auth-oidc-client';
import { environment } from '../environments/environment';

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: {
        authority: environment.authConfig.authority,
        redirectUrl: environment.authConfig.redirectUrl,
        postLogoutRedirectUri: environment.authConfig.postLogoutRedirectUri,
        clientId: environment.authConfig.clientId,
        scope: environment.authConfig.scope,
        responseType: environment.authConfig.responseType,
        //silentRenewUrl: environment.authConfig.silentRenewUrl,
        startCheckSession: environment.authConfig.startCheckSession,
        silentRenew: environment.authConfig.silentRenew,
        useRefreshToken: environment.authConfig.useRefreshToken,
        renewTimeBeforeTokenExpiresInSeconds: environment.authConfig.renewTimeBeforeTokenExpiresInSeconds,
        logLevel: environment.authConfig.logLevel,
        historyCleanupOff: environment.authConfig.historyCleanupOff,
        secureRoutes: environment.authConfig.secureRoutes,
      },
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule { }
