<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="container-fluid py-2" style="margin-top: -28px;">
<div class="container-fluid">
<div class="card-body">
  <div class="row-container">
    <div class="col-auto">
      <h5 class="mtx-text-primary" style="margin-left: -12px; margin-top: 22px;">{{ title | translate }}</h5>
    </div>
    <div style="margin-right: 15px;" *ngIf="currentStep === 1">
      <app-heirarchy-filter-buttons (filterSearchButtonClicked)="filterSearchButtonClicked()" [isToggleFilterButtonVisible] = "false"> </app-heirarchy-filter-buttons>
      <app-heirarchy-filter-popup></app-heirarchy-filter-popup>
    </div>
  </div>
  <div class="row" style="margin-top: -10px;">
    <div class="col-md-2" style=" margin-left: -12px;">
      <div class="progress-container mb-4 bs-stepper">
        <div class="step-container">
          <span style="color: #737373;">Step {{currentStep}}/2</span>
          <div class="progress-bars">
            <div class="progress-bar" [ngClass]="{'active-bar': currentStep >= 1, 'inactive-bar': currentStep < 1}"
              role="progressbar">
            </div>
            <div class="progress-bar" [ngClass]="{'active-bar': currentStep === 2, 'inactive-bar': currentStep < 2}"
              role="progressbar">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
  <div class="mt-3" style="margin-left: -12px;">
    <div class="map-container">
      <div class="map" #infoCard #map></div>
    </div>
  <div>
    <div class="legend-container">
      <div class="legend-item">
        <span class="legends-text">Legends: </span>
      </div>
      <div class="legend-item">
        <img
          class="legend-icon"
          src="assets/images/Compliant.svg"
          alt="location"
        />
        <span class="legend-text">Compliant</span>
      </div>
      <div class="legend-item">
        <img
          class="legend-icon"
          src="assets/images/Exception.svg"
          alt="location"
        />
        <span class="legend-text">Exception</span>
      </div>
      <div class="legend-item">
        <img
          class="legend-icon"
          src="assets/images/Delinquent.svg"
          alt="location"
        />
        <span class="legend-text">Delinquent</span>
      </div>
    </div>
  </div >
    <div style="margin-top: 20px;">
      <div cdkDropList (cdkDropListDropped)="drop($event)">
        <kendo-grid [data]="assets" style="width: 100%; height: 400px;" [resizable]="true">
          <kendo-grid-column field="type" title="Asset Type" [minResizableWidth]="60"  [headerStyle]="{ background: '#F1F8FF' }"></kendo-grid-column>
          <kendo-grid-column field="name" title="Asset Name" [minResizableWidth]="60" [headerStyle]="{ background: '#F1F8FF' }"></kendo-grid-column>
          <kendo-grid-column field="lastInspectionDate" title="Last Inspection Date" [minResizableWidth]="60"  [headerStyle]="{ background: '#F1F8FF' }"></kendo-grid-column>
          <kendo-grid-column field="daysUntilDelinquent" title="Days Until Delinquent" [minResizableWidth]="60" [headerStyle]="{ background: '#F1F8FF' }"></kendo-grid-column>
          <kendo-grid-column field="coordinates" title="Coordinates" [headerStyle]="{ background: '#F1F8FF' }"[minResizableWidth]="60" >
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.latitude }}, {{ dataItem.longitude }}
            </ng-template>
          </kendo-grid-column>
  
          <kendo-grid-column *ngIf="!displayOrderSelection" [width]="50" [resizable]="false">
            <ng-template kendoGridHeaderTemplate>
              <input type="checkbox" id="selectAllCheckbox" (change)="onSelectAllChange($event)" [checked]="allSelected"
              [(ngModel)]="allSelected" />
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <input
                type="checkbox"
                #associatedAssetId
                [attr.data-asset-id]="dataItem.id"
                (change)="onCheckboxChange($event, dataItem)"
                [checked]="dataItem.selected"
              />
            </ng-template>
          </kendo-grid-column>
  
          <kendo-grid-column *ngIf="displayOrderSelection" [width]="50">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
             
              <div cdkDrag cdkDragHandle class="order-selection">
                <!-- Drag Preview -->
                <ng-template cdkDragPreview>
                  <div class="order-selection drag-preview order-icon">
                    <mat-icon class="icon">menu</mat-icon>
                    <span class="order-number">{{ rowIndex + 1 }}</span>
                  </div>
                </ng-template>

                <!-- Placeholder -->
                <ng-template cdkDragPlaceholder>
                  <div class="order-selection drag-placeholder"></div>
                </ng-template>

                <!-- Original Element -->
                <div class="order-icon">
                  <mat-icon class="icon">menu</mat-icon>
                  <span class="order-number">{{ rowIndex + 1 }}</span>
                </div>
              </div>
            </ng-template>
          </kendo-grid-column>
          <ng-template kendoGridNoRecordsTemplate>
            <div *ngIf="!filterDataPresent" >{{ 'GRID.NO_RECORDS' | translate }}</div>
            <div *ngIf="filterDataPresent">{{ 'GRID.NO_RECORDS_FILTER' | translate }}</div>
         </ng-template>
        </kendo-grid>
      </div>
    </div>
    <div class="col-md-12 text-end" *ngIf="!displayOrderSelection">
      <button [disabled]="!isOrderButtonEnabled" type="button" class="btn" style="width: 150px; margin-right: 0px;margin-top: 20px; font-weight: 500; color: white;background-color: #3498db;" (click)="toggleOrderSelection()">Order Selection</button>
    </div>
    <div class="col-md-12 text-end" *ngIf="displayOrderSelection">
      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-8 text-end">
          <button class="circular-button" style="margin-right: 10px; margin-top: 10px;" (click)="back()">
            <mat-icon style="margin-right: 10px; justify-content: center; text-align: center;">keyboard_arrow_left</mat-icon>
          </button>
          <button type="button" class="btn btn-secondary mtx-btn-secondary" style="width: 150px; margin-right: 10px; margin-top: -10px; font-weight: 500;" (click)="cancel()">Cancel</button>
          <button type="button" class="btn" style="width: 150px; margin-right: 10px;margin-top: -10px; font-weight: 500;color: white;background-color: #3498db;" (click)="saveAndContinue()">Save Route</button>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</div>
  