import { CanActivateFn, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Store } from '@ngxs/store';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import { of, from } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { inject } from '@angular/core';
import { PermissionService } from '../services/permission.service';

export const AuthGuard: CanActivateFn = (_route, _state) => {
  const store = inject(Store);
  const oidcSecurityService = inject(OidcSecurityService);
  const router = inject(Router);
  const permissionService = inject(PermissionService);

  return store.select(AuthenticateUserState.getIsAuthenticated).pipe(
    switchMap((isAuthenticated) => {
      if (isAuthenticated) {
        const feature = _route.data['screen'];
        const requiredPermission = _route.data['requiredPermission'];
        if (feature) {
          if (permissionService.hasAnyPermission(feature, requiredPermission)) {
            return of(true);
          } else {
            router.navigate(['/no-access']);
            return of(false);
          }
        } else {
          return of(true);
        }
      } else {
        return from(oidcSecurityService.checkAuth()).pipe(
          switchMap(({ isAuthenticated }) => {
            if (isAuthenticated) {
              return of(true);
            } else {
              oidcSecurityService.authorize();
              return of(false);
            }
          }),
          catchError((error) => {
            console.error('Auth check error', error);
            router.navigate(['/unauthorized']);
            return of(false);
          }),
        );
      }
    }),
  );
};
