import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { LEVEL1_STATE, LEVEL1_STATE_TYPE_SET, LEVEL1_STATE_TYPE_SET_REMOVE } from '../../shared/constants';
export interface Level1StateModel {
  selectedLevel1: string[];
}
export class SetLevel1State {
  static readonly type = LEVEL1_STATE_TYPE_SET;
  constructor(public selectedLevel1: string[]) {}
}
export class RemoveLevel1State {
  static readonly type = LEVEL1_STATE_TYPE_SET_REMOVE;
}
@State<Level1StateModel>({
  name: LEVEL1_STATE,
  defaults: {
    selectedLevel1: [],
  },
})
@Injectable()
export class Level1State {
  //selector has logic to get data

  @Selector()
  static getSelectedLevel1Name(state: Level1StateModel) {
    return state.selectedLevel1;
  }

  //actions to do operation
  @Action(SetLevel1State)
  SetLevel1State(
    { patchState }: StateContext<Level1StateModel>,
    { selectedLevel1 }: SetLevel1State,
  ): void {
    patchState({
      selectedLevel1: selectedLevel1,
    });
  }
  @Action(RemoveLevel1State)
  RemoveLevel1State({ setState }: StateContext<Level1StateModel>): void {
    setState({
      selectedLevel1: [],
    });
  }
}
