import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { HierarchyNode } from '../../../core/models/heirarchy-tree';
import { paperPlaneIcon, SVGIcon } from '@progress/kendo-svg-icons';
import {
  EDIT_HIERARCHY_BTN_CONFIRM_TXT,
  EDIT_HIERARCHY_HEADING_FOR_DELETE,
  EDIT_HIERARCHY_MSG_FOR_DELETE,
  EMPTY_STRING,
  ERROR_LEVEL,
  FAILED,
  HIERARCHY_DUPLICATE_RECORD_MESSAGE,
  HIERARCHY_DUPLICATE_RECORD_TITLE,
  NO,
  PRIMARY_COMPANY_TEXT,
  RECORD_DELETED,
  SUCCESS1,
  TOASTER_SUCCESS,
} from '../../../shared/constants';
import { SetupHierarchyService } from '../../../core/services/setup-hierarchy.service';
import { LevelValueDeleteInput } from '../../../../awsAppSync/API';
import { CustomToastrService } from '../../../shared/ngx-toastr/custom-toastr.service';
import { PopupDialogService } from '../../../shared/popup-dialog/popup-dialog.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hierarchy-node',
  templateUrl: './hierarchy-node.component.html',
  styleUrl: './hierarchy-node.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HierarchyNodeComponent implements OnInit {
  @Input() node!: HierarchyNode;
  currentNode = EMPTY_STRING;
  isEditing = false;
  showInput = true;
  editNode = false;
  newName = EMPTY_STRING;
  public svgAdd: SVGIcon = paperPlaneIcon;
  isExpanded = true;
  levels: string[] = [];
  primaryCompanyId: string | null = EMPTY_STRING;
  treeData: HierarchyNode | null = null;
  isLoading = false;

  constructor(private setupHierachyServie: SetupHierarchyService, private toastr: CustomToastrService, private router: Router, private popupDialogService: PopupDialogService,) {}

  ngOnInit(): void {
    this.levels = this.setupHierachyServie.levels;
    this.primaryCompanyId = this.setupHierachyServie.primaryCompanyid;
  }

  toggleEdit(id: string) {
    this.currentNode = id;
    if (this.node.name.startsWith(PRIMARY_COMPANY_TEXT)) {
      return;
    }
    if (!this.isPrimaryCompanyNode() && !this.node.name.includes(':')) {
      this.isEditing = !this.isEditing;
    }
    this.isExpanded = !this.isExpanded;
  }

  save() {
    if (this.setupHierachyServie.nodeData) {
      const isNameExists = this.checkNameExists(this.setupHierachyServie.nodeData, this.newName);
      if (isNameExists) {
        this.toastr.showError(HIERARCHY_DUPLICATE_RECORD_MESSAGE, HIERARCHY_DUPLICATE_RECORD_TITLE);
      } else {
        this.addDataToTree();
      }
    }
    else{
      this.addDataToTree();
    }
  }
  addDataToTree() {
    if (this.newName.trim() !== '') {
      const nodeName = this.node.name;
      const nodeLevelName = this.levels.indexOf(this.currentNode);
      this.node.name = this.levels[nodeLevelName] + ': ' + this.newName;

      if (this.node.name.includes(':')) {
        this.node.isNodeEdited = true;
      }

      const parentNode = this.node.parent;
      if (parentNode) {
        if (!parentNode.children) {
          parentNode.children = [];
        }

        const newChildNode: HierarchyNode = {
          id: this.currentNode,
          dbId: this.node.dbId,
          name: nodeName,
          pk: this.node.pk,
          sk: this.node.sk,
          children: [],
          parent: parentNode,
          isNodeEdited: false,
          isAssetConfigured: false,
        };

        const currentNodeIndex = this.levels.indexOf(newChildNode.id);

        if (!newChildNode.children) {
          newChildNode.children = [];
        }
        if (currentNodeIndex !== -1) {
          if (currentNodeIndex + 1 < this.levels.length) {
            const childNode1: HierarchyNode = {
              id: this.levels[currentNodeIndex + 1],
              dbId: this.levels[currentNodeIndex + 1],
              name: `Add ${this.levels[currentNodeIndex + 1]}`,
              pk: '',
              sk: '',
              children: [],
              parent: newChildNode,
              isNodeEdited: false,
              isAssetConfigured: false,
            };
            newChildNode.children.push(childNode1);

            if (!childNode1.children) {
              childNode1.children = [];
            }

            if (currentNodeIndex + 2 < this.levels.length) {
              const childNode2: HierarchyNode = {
                id: this.levels[currentNodeIndex + 2],
                dbId: this.levels[currentNodeIndex + 2],
                name: `Add ${this.levels[currentNodeIndex + 2]}`,
                pk: '',
                sk: '',
                children: [],
                parent: childNode1,
                isNodeEdited: false,
                isAssetConfigured: false,
              };
              childNode1.children.push(childNode2);
            }
          }
        }

        parentNode.children.push(newChildNode);

        this.isEditing = false;
      } else {
        console.error(ERROR_LEVEL);
      }

      this.showInput = !this.showInput;
      this.storeTreeData();
    }
  }
  private checkNameExists(node: HierarchyNode, nameToCheck: string): boolean {
    const actualName = this.extractActualName(node.name);

    if (actualName === nameToCheck) {
      return true;
    }

    if (node.children) {
      for (const child of node.children) {
        if (this.checkNameExists(child, nameToCheck)) {
          return true;
        }
      }
    }
    return false;
  }

  private extractActualName(fullName: string): string {
    return fullName.includes(':') ? fullName.split(':')[1].trim() : fullName.trim();
  }

  storeTreeData() {
    const rootNode = this.findRootNode(this.node);

    const allTreeData = this.collectTreeData(rootNode);

    const rootNodeData = allTreeData.find((node) => node.parent === null);
    const rootNodeCopy: HierarchyNode = this.deepCopyWithoutParent(
      rootNodeData!,
    );

    // Process the deep copy to remove nodes and modify names
    const processedNode = this.processTreeData(rootNodeCopy);
    if (processedNode) {
      this.setupHierachyServie.nodeData = processedNode;
    } else {
      console.log('Root node was deleted because it did not contain a colon.');
    }
  }

  deepCopyWithoutParent(node: HierarchyNode): HierarchyNode {
    const copy: HierarchyNode = {
      id: node.id,
      dbId: node.dbId,
      name: node.name,
      pk: node.pk,
      sk: node.sk,
      isNodeEdited: node.isNodeEdited,
      children: node.children
        ? node.children.map((child) => this.deepCopyWithoutParent(child))
        : [],
        isAssetConfigured:false,
    };

    return copy;
  }

  processTreeData(node: HierarchyNode | null): HierarchyNode | null {
    if (!node || !node.name.includes(': ')) {
      return null;
    }
    const colonIndex = node.name.indexOf(': ');
    node.name = node.name.substring(colonIndex + 1).trim();

    if (node.children && node.children.length > 0) {
      node.children = node.children
        .map((child) => this.processTreeData(child))
        .filter((child) => child !== null) as HierarchyNode[];
    }

    return node;
  }

  collectTreeData(node: HierarchyNode): HierarchyNode[] {
    let nodes: HierarchyNode[] = [node];

    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        nodes = nodes.concat(this.collectTreeData(child));
      }
    }

    return nodes;
  }

  findRootNode(node: HierarchyNode): HierarchyNode {
    let currentNode: HierarchyNode | undefined = node;

    while (currentNode && currentNode.parent !== null) {
      currentNode = currentNode.parent;
    }
    if (!currentNode) {
      throw new Error('Root node not found');
    }
    return currentNode;
  }

  updateNode(id: string) {
    if (this.newName.trim() !== '') {
      const nodeLevelName = this.levels.indexOf(id);
      this.node.name = this.levels[nodeLevelName] + ': ' + this.newName;
    }
    this.editNode = !this.editNode;
    this.storeTreeData();
  }

  isPrimaryCompanyNode(): boolean {
    return this.node.name.startsWith(PRIMARY_COMPANY_TEXT);
  }

  isLastNode(): boolean {
    return !this.node.children || this.node.children.length === 0;
  }

  toggleExpand() {
    if (!this.isPrimaryCompanyNode()) {
      this.isExpanded = !this.isExpanded;
    }
  }

  onNodeEdit(node: HierarchyNode) {
    this.newName = node.name.replace(/^[^:]+:\s*/, "");
    this.editNode = !this.editNode;
    this.isExpanded = !this.isExpanded;
  }

  async onNodeDelete(node: HierarchyNode , event: MouseEvent) {
    event.stopPropagation();
    
    if(node.pk && node.sk){
      this.isLoading = true;
      const dialogRef = this.popupDialogService.openDialog(
        EDIT_HIERARCHY_HEADING_FOR_DELETE,
        EDIT_HIERARCHY_MSG_FOR_DELETE,
        FAILED,
        EDIT_HIERARCHY_BTN_CONFIRM_TXT,
        () => this.router.navigate([]),
        true,
        NO,
      );
      dialogRef.afterClosed().subscribe( async (result: string) => {
        if (result === EDIT_HIERARCHY_BTN_CONFIRM_TXT) {
          this.removeNode(node);
          const _deleteInput: LevelValueDeleteInput = {
            pk: node.pk || null, 
            sk: node.sk || null, 
            childrenExist: node.children && node.children.length > 1 ? true : false
          };
      
          if(node.sk && node.pk){
            this.removeNodeFromLevel1Values(node.pk, node.sk);
            const _response = await this.setupHierachyServie.deleteLevelValueForPrimaryCompany(_deleteInput);
            if(_response?.status === SUCCESS1){
              this.toastr.showSuccess(RECORD_DELETED, TOASTER_SUCCESS);
              this.isLoading = false;
            }
          }
        }
      });

    }
    else{
      this.removeNode(node);
    }
    
  }
  removeNode(node: HierarchyNode) {
    const parentNode = node.parent;
      if (parentNode) {
      if (!parentNode.children) {
        parentNode.children = [];
      }
      parentNode.children = parentNode.children.filter(
        (child) => child !== node,
      );
    }
  }

  removeNodeFromLevel1Values(pk: string, sk: string) {
    if (this.setupHierachyServie?.level1Values) {
      this.setupHierachyServie.level1Values = this.setupHierachyServie.level1Values.filter(level1 => {
        if (level1?.level2Values) {
          level1.level2Values.forEach(level2 => {
            if (level2?.level3Values) {
              level2.level3Values = level2.level3Values.filter(level3 => 
                level3?.pk !== pk || level3.sk !== sk
              );
            }
          });
  
          level1.level2Values = level1.level2Values.filter(level2 => 
            level2?.pk !== pk || level2.sk !== sk
          );
        }
  
        return level1?.pk !== pk || level1.sk !== sk;
      });
    }
  }
  
}
