import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  columnNames,
  reportColumnsForDynamicColumns,
} from '../../shared/enums';
import { Router } from '@angular/router';
import {
  AUDIT,
  CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
  CANCEL_TEMPLATE_HEADING,
  CANCEL_TEMPLATE_MSG,
  CREATE_NEW_REPORT_SUBHEADING,
  EDIT_MODE_REPORT,
  EMPTY_STRING,
  FAILED,
  FROM_TEMPLATE_LIB,
  NO,
  REDIRECT_REPORT_MANAGEMENT,
  REDIRECT_REPORT_TEMPLATE_LIBRARY,
  VIEW_MODE_REPORT,
} from '../../shared/constants';
import { CreateReportServiceService } from './service/create-report-service.service';
import { PopupDialogService } from '../../shared/popup-dialog/popup-dialog.service';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { RemoveTemplate, TemplateState } from '../../core/store/template.state';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-report',
  templateUrl: './create-report.component.html',
  styleUrl: './create-report.component.css',
})
export class CreateReportComponent implements OnInit, OnDestroy {
  reportForm: FormGroup;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  emails: string[] = [];
  currentStep = 1;
  selectedFormat = '';
  templates = [
    {
      name: 'Template 1',
      type: 'Audit',
      columns: [
        'Asset Type',
        'Asset Name',
        'Station',
        'Next Inspection Date',
        'Compliance Criteria',
        'Compliance Status',
        'Last Compliance Read',
        'Technician',
      ],
    },
    {
      name: 'Template 2',
      type: 'Performance',
      columns: [
        'Asset Type',
        'Asset Name',
        'Station',
        'Next Inspection Date',
        'Compliance Criteria',
      ],
    },
    {
      name: 'Template 3',
      type: 'Audit',
      columns: [
        'Asset Type',
        'Asset Name',
        'Station',
        'Next Inspection Date',
        'Compliance Criteria',
        'Compliance Status',
      ],
    },
    {
      name: 'Template 4',
      type: 'Performance',
      columns: [
        'Asset Type',
        'Asset Name',
        'Station',
        'Next Inspection Date',
        'Compliance Criteria',
        'Compliance Status',
      ],
    },
    {
      name: 'Template 5',
      type: 'Audit',
      columns: [
        'Asset Type',
        'Asset Name',
        'Station',
        'Compliance Criteria',
        'Compliance Status',
      ],
    },
  ];

  displayedColumns: { field: string, type: string }[] = [];
  repeatEveryValue: number | undefined;
  tableData: any[] = [];
  selectedColumns: string[] = [];
  isScheduleOn: any;
  mappedColumns: (string | null)[] = [];
  public isPopupOpen = false;
  selectedItems: Record<string, boolean> = {};
  columnOptions: Record<string, string[]> = {};
  public gridData: any[] = [];
  selectedTemplate: { name: string; columns: string[] } | undefined;
  templateName: string | undefined;
  selectedFormats: string[] = [];
  reportSubheading = CREATE_NEW_REPORT_SUBHEADING;
  isFrom$: Observable<string | null>;
  templateName$: Observable<string | null>;
  reportType$: Observable<string | null>;
  isFromWhere: string | null = EMPTY_STRING;
  state: { filter: CompositeFilterDescriptor } = {
    filter: { logic: 'and', filters: [] },
  };
  isTemplateSelected = false;

  constructor(
    private fb: FormBuilder,
    private popupDialogService: PopupDialogService,
    private store: Store,
    public service: CreateReportServiceService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private elementRef: ElementRef,
    private translate: TranslateService
  ) {
    this.reportForm = this.fb.group({
      selectedTemplate: [''],
      reportName: ['', Validators.required],
      reportType: ['', Validators.required],
      reportCreatorName: ['', Validators.required],
      reportDescription: [''],
      recurringSchedule: [false],
      emailList: this.fb.array([this.fb.control('', Validators.email)]),
      repeatEvery: ['', Validators.required],
      repeatFrequency: ['Daily', Validators.required],
      repeatTime: ['11:00 AM', Validators.required],
      reportFormat: ['PDF', Validators.required],
      summary: [''],
    });
    this.isFrom$ = this.store.select(TemplateState.getIsFrom);
    this.templateName$ = this.store.select(TemplateState.getTemplateName);
    this.reportType$ = this.store.select(TemplateState.getReportType);
    // this.heirarchyFilterService.setIsTrayButtonVisible(false);
  }

  ngOnInit(): void {
    this.initializeForm();
    this.loadInitialData();
    this.updateTemplateName();
  
    this.isTemplateSelected = !!this.reportForm.get('selectedTemplate')?.value;
  
    this.handleTemplateChanges();
    this.handleReportTypeChanges();
  }
  
  initializeForm(): void {
    this.reportForm = this.fb.group({
      selectedTemplate: [''], 
      reportName: ['', Validators.required],
      reportType: ['', Validators.required],
      reportCreatorName: ['', Validators.required],
      reportDescription: [''],
      recurringSchedule: [false],
      emailList: this.fb.array([this.fb.control('', Validators.email)]),
      repeatEvery: ['', Validators.required],
      repeatFrequency: ['Daily', Validators.required],
      repeatTime: ['11:00 AM', Validators.required],
      reportFormat: ['PDF', Validators.required],
      summary: [''],
    });
  }
  
  loadInitialData(): void {
    this.getTemplateData();
    this.updateSelectState();
  
    this.columnOptions = this.convertEnumToOptions();
    this.updateTiles();
  
    this.reportForm.get('selectedTemplate')?.valueChanges.subscribe((selectedTemplateName) => {
      this.updateReportTypeBasedOnTemplate(selectedTemplateName);
    });
  }

  handleReportTypeChanges(): void {
    const selectedReportType = this.reportForm.get('reportType')?.value;
  
    if (!selectedReportType) {
      this.reportForm.patchValue({ reportType: '' });
    }
  }
  
  handleTemplateChanges(): void {
    const selectedTemplate = this.reportForm.get('selectedTemplate')?.value;
  
    if (!selectedTemplate) {
      this.reportForm.patchValue({ selectedTemplate: '' });
    }
  
    this.reportSubheading = this.isFromWhere === EDIT_MODE_REPORT
      ? this.translate.instant("CREATE_REPORT.REPORT_SUBHEADING_EDIT")
      : this.translate.instant("CREATE_REPORT.REPORT_SUBHEADING_CREATE");
    
    // this.heirarchyFilterService.setIsTrayButtonVisible(false);
  }

  ngOnDestroy() {
    this.store.dispatch(new RemoveTemplate());
    this.reportForm.reset(); 
  }

  getTemplateData() {
    this.isFrom$.subscribe((isFrom) => {
      this.isFromWhere = isFrom;
    });

    this.templateName$.subscribe((templateName) => {
      this.reportForm.patchValue({
        selectedTemplate: templateName,
      });
      this.onTemplateChange();
    });

    this.reportType$.subscribe((reportType) => {
      this.reportForm.patchValue({
        reportType: reportType,
      });
    });
  }

  updateReportTypeBasedOnTemplate(templateName: string): void {
    const selectedTemplate = this.templates.find(
      (template) => template.name === templateName,
    );

    if (selectedTemplate) {
      this.reportForm
        .get('reportType')
        ?.setValue(selectedTemplate.type, { emitEvent: false });
    }
  }

  updateSelectState(): void {
    if (this.isFromWhere === VIEW_MODE_REPORT) {
      this.reportForm.disable();
    } else if (this.isFromWhere === FROM_TEMPLATE_LIB) {
      this.reportForm.get('reportType')?.disable();
    }
  }

  convertEnumToOptions(): Record<string, string[]> {
    const options: Record<string, string[]> = {};
    for (const key in reportColumnsForDynamicColumns) {
      if (
        Object.prototype.hasOwnProperty.call(
          reportColumnsForDynamicColumns,
          key,
        )
      ) {
        const types = reportColumnsForDynamicColumns[
          key as keyof typeof reportColumnsForDynamicColumns
        ]
          .split(',')
          .filter((type) => type);
        options[key] = types;
      }
    }
    return options;
  }

  togglePopup(): void {
    this.isPopupOpen = !this.isPopupOpen;
  }

  getFilteredColumns(): string[] {
    const reportType = this.reportForm.get('reportType')?.value || AUDIT;
    return Object.keys(this.columnOptions).filter((column) =>
      this.columnOptions[column].includes(reportType),
    );
  }

  getSelectedColumns(): string[] {
    return Object.keys(this.selectedItems).filter(
      (item) => this.selectedItems[item],
    );
  }

  onCheckboxChange(item: string, event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    const isChecked = checkbox.checked;
    this.selectedItems[item] = isChecked;
  
    if (isChecked) {
      const columnType = this.getColumnType(item);
      this.displayedColumns.push({ field: item, type: columnType });
  
      this.gridData = this.gridData.length
        ? this.gridData.map((row) => ({
            ...row,
            [item]:
              this.service.gridData.find(
                (dataRow) => dataRow.rowId === row.rowId,
              )?.[item] || null,
          }))
        : this.service.gridData.map((row) => ({
            rowId: row.rowId,
            [item]: row[item],
          }));
    } else {
      this.displayedColumns = this.displayedColumns.filter(
        (selectedItem) => selectedItem.field !== item,
      );
  
      this.gridData = this.gridData.map((row) => {
        const { [item]: _, ...rest } = row;
        return rest;
      });
    }
  
    this.updateGridWidth();
  
    this.reportForm.get('selectedColumns')?.setValue(this.displayedColumns);
  }

  getColumnType(item: string): string {
    return item.toLowerCase().includes('date') || item.toLowerCase().includes('read') ? 'date' : 'text';
  }

  updateTiles(): void {
    this.displayedColumns = this.getSelectedColumns().map(column => ({
      field: column,
      type: this.getColumnType(column)
    }));
  }

  allColumnsSelected(): boolean {
    const filteredColumns = this.getFilteredColumns();
    return (
      filteredColumns.length === this.displayedColumns.length &&
      filteredColumns.length > 0
    );
  }

  removeColumn(field: string): void {
    this.selectedItems[field] = false;
    this.displayedColumns = this.displayedColumns.filter(column => column.field !== field);

    this.gridData = this.gridData.map((row) => {
      const { [field]: _, ...rest } = row;
      return rest;
    });

    this.updateGridWidth();

    this.reportForm.get('selectedColumns')?.setValue(this.displayedColumns);
  }
  
  updateGridWidth(): void {
    const columnWidth = 250; // Set each column's width to 100px
    const totalWidth = this.displayedColumns.length * columnWidth;
    const gridElement = document.querySelector('.kendo-grid-container');
    
    if (gridElement) {
      (gridElement as HTMLElement).style.width = `${totalWidth}px`;
    }
  }

  getColumnDisplayName(key: string): string {
    return columnNames[key as keyof typeof columnNames] || key;
  }

  onToggleChange(event: any) {
    const _isOn = this.reportForm.get('recurringSchedule')?.value;
    this.isScheduleOn = event.target.checked;
    this.cd.detectChanges();
  }

  cancel() {
    if (this.isFromWhere === FROM_TEMPLATE_LIB) {
      this.popupDialogService.openDialog(
        CANCEL_TEMPLATE_HEADING,
        CANCEL_TEMPLATE_MSG,
        FAILED,
        CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
        () => this.router.navigate([REDIRECT_REPORT_TEMPLATE_LIBRARY]),
        true,
        NO,
        true,
      );
    } else {
      this.popupDialogService.openDialog(
        CANCEL_TEMPLATE_HEADING,
        CANCEL_TEMPLATE_MSG,
        FAILED,
        CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
        () => this.router.navigate([REDIRECT_REPORT_MANAGEMENT]),
        true,
        NO,
        true,
      );
      
    }
  }

  cancelOfStep2() {
    if (this.isFromWhere === FROM_TEMPLATE_LIB) {
      this.popupDialogService.openDialog(
        CANCEL_TEMPLATE_HEADING,
        CANCEL_TEMPLATE_MSG,
        FAILED,
        CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
        () => this.router.navigate([REDIRECT_REPORT_TEMPLATE_LIBRARY]),
        true,
        NO,
        true,
      );
    } else {
      this.popupDialogService.openDialog(
        CANCEL_TEMPLATE_HEADING,
        CANCEL_TEMPLATE_MSG,
        FAILED,
        CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
        () => this.router.navigate([REDIRECT_REPORT_MANAGEMENT]),
        true,
        NO,
        true,
      );
      
    }
    
  }

  back() {
    this.currentStep = 1;
  }

  goToStep(step: number) {
    // if (step === 2 && this.reportForm.valid) {
    //   this.currentStep = step;
    // }
    if (step === 2) {
      this.currentStep = step;
    }
  }

  onRepeatEveryChange(event: any): void {
    const value = event.target.valueAsNumber;

    this.repeatEveryValue = value < 0 ? 0 : value;
  }

  onSaveAndContinue() {
    this.goToStep(2);
  }

  selection(value: string): void {
    const index = this.selectedFormats.indexOf(value);
    if (index > -1) {
      this.selectedFormats.splice(index, 1);
    } else {
      this.selectedFormats.push(value);
    }
  }

  onTemplateChange(): void {
    const selectedTemplateName = this.reportForm.get('selectedTemplate')?.value;
  
    this.isTemplateSelected = !!selectedTemplateName;
  
    if (selectedTemplateName) {
      this.selectedTemplate = this.templates.find(
        (template) => template.name === selectedTemplateName
      );
  
      if (this.selectedTemplate) {
        this.templateName = this.selectedTemplate.name;
        this.selectedColumns = this.selectedTemplate.columns;
  
        this.displayedColumns = this.selectedTemplate.columns.map((column) => ({
          field: column,
          type: this.getColumnType(column),
        }));
  
        this.gridData = this.service.gridData.map((row) => {
          const filteredRow: any = {};
          this.selectedTemplate?.columns.forEach((column) => {
            filteredRow[column] = row[column] || null;
          });
          return filteredRow;
        });
        this.reportForm.get('reportType')?.disable();
        if (this.router.url.includes('report-template-library')) {
          this.reportForm.get('reportType')?.disable();
          this.reportForm.get('reportType')?.reset();
        }
      } else {
        this.resetTemplateSelection();
      }
    } else {
      this.resetTemplateSelection();
      this.isTemplateSelected = false; // Reset the flag
  
      const reportTypeControl = this.reportForm.get('reportType');
      reportTypeControl?.enable();
      reportTypeControl?.setValue('');
    }
  }
  
  
  updateTemplateName(): void {
    const selectedTemplateName = this.reportForm.get('selectedTemplate')?.value;
    this.templateName = selectedTemplateName ? selectedTemplateName : null;
  }
  
  onFilterChange(filter: CompositeFilterDescriptor): void {
    const hasFieldFilter = this.hasFilterForField(filter, 'ComplianceStatus');
    if (!hasFieldFilter) {
      // this.complaiceStatusFilterValues.forEach(
      //   (item) => (item.selected = false),
      // );
      // alert('Filter cleared for yourField');
    }
    this.state = { ...this.state, filter };
  }

  private hasFilterForField(
    filter: CompositeFilterDescriptor,
    field: string,
  ): boolean {
    return filter.filters.some(
      (f: FilterDescriptor | CompositeFilterDescriptor) => {
        if ((f as CompositeFilterDescriptor).filters) {
          return this.hasFilterForField(f as CompositeFilterDescriptor, field);
        } else {
          return (f as FilterDescriptor).field === field;
        }
      },
    );
  }
  
  resetTemplateSelection(): void {
    this.selectedColumns = [];
    this.displayedColumns = [];
    this.gridData = [];
  }

  addChip(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.emails.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  removeChip(email: string): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }
}
