import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PopupDialogComponent } from './popup-dialog.component';
import { CANCEL_TEMPLATE_BTN_CONFIRM_TXT, CONFIRM, CONTINUE } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class PopupDialogService {
  isNavigate = false;
  constructor(private dialog: MatDialog) {}

  getButtonClicked(buttonClicked: string): void {
    this.isNavigate= buttonClicked === CONFIRM || CANCEL_TEMPLATE_BTN_CONFIRM_TXT || CONTINUE ? true : false;
  }

  openDialog(
    title: string,
    message: string,
    icon: string,
    buttonText: string,
    callback?: () => void, 
    isCancelBtnRequired?: boolean,
    cancelButtonText?: string,
    isConfirmEvent?: boolean,
    confirmMethod?: string,
  ): MatDialogRef<any> {
    const dialogRef = this.dialog.open(PopupDialogComponent, {
      data: {
        title,
        message,
        icon,
        buttonText,
        isCancelBtnRequired,
        cancelButtonText,
        isConfirmEvent,
        confirmMethod,
      },
    });

    dialogRef.afterClosed().subscribe((_result: string) => {
      if (_result === buttonText && callback && this.isNavigate) {
        callback(); 
      }
    });

    return dialogRef;
  }

}
