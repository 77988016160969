import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { EMPTY_STRING } from '../../shared/constants';
export interface SetupHierarchyModel {
  primaryCompanyNameId: string | null;
  primaryCompanyName: string | null;
  level3Name: string ;
  level4Name: string ;
  isEdit: boolean;
}
export class SetSetupHierarchy {
  static readonly type = '[SetupHierarchy] Set Setup Hierarchy';
  constructor(
    public primaryCompanyNameId: string,
    public primaryCompanyName: string,
    public level3Name: string,
  public level4Name: string,
  public isEdit:boolean,

  ) {}
}
export class RemoveSetupHierarchy {
  static readonly type = '[SetupHierarchy] Remove Setup Hierarchy';
}
@State<SetupHierarchyModel>({
  name: 'setupHierarchy',
  defaults: {
    primaryCompanyNameId: null,
    primaryCompanyName: null,
    level3Name: "",
    level4Name: "",
    isEdit: false,
  },
})
@Injectable()
export class SetupHierarchyState {
  @Selector()
  static getPrimaryCompanyId(state: SetupHierarchyModel) {
    return state.primaryCompanyNameId;
  }

  @Selector()
  static getPrimaryCompanyName(state: SetupHierarchyModel) {
    return state.primaryCompanyName;
  }
  @Selector()
  static getLevel3Name(state: SetupHierarchyModel) {
    return state.level3Name;
  }

  @Selector()
  static getLevel4Name(state: SetupHierarchyModel) {
    return state.level4Name;
  }

  @Selector()
  static getIsEdit(state: SetupHierarchyModel) {
    return state.isEdit;
  }
  //actions to do operation
  @Action(SetSetupHierarchy)
  SetSetupHierarchy(
    { patchState }: StateContext<SetupHierarchyModel>,
    { primaryCompanyNameId, primaryCompanyName, level3Name, level4Name, isEdit }: SetSetupHierarchy,
  ): void {
    patchState({
      primaryCompanyNameId: primaryCompanyNameId,
      primaryCompanyName: primaryCompanyName,
      level3Name: level3Name,
      level4Name: level4Name,
      isEdit: isEdit
    });
  }
  @Action(RemoveSetupHierarchy)
  RemoveSetupHierarchySetup({
    setState,
  }: StateContext<SetupHierarchyModel>): void {
    setState({
      primaryCompanyNameId: EMPTY_STRING,
      primaryCompanyName: EMPTY_STRING,
      level3Name: EMPTY_STRING,
      level4Name: EMPTY_STRING,
      isEdit: false,
    });
  }
}
