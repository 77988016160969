import { Injectable } from '@angular/core';
import { EMPTY_STRING } from '../../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class ImportInspectionDataService {
  public files: any;
  assetType: string | null = EMPTY_STRING;
  assetTypeId: string | null = EMPTY_STRING;
  columnTypes: Record<string, string> = {};
  currentAssetmandatoryColumns: string[] = [];
  public isFreshRoute = true;
  displayedColumns: string[] = [];
  importMessage = EMPTY_STRING;
  importStatus = EMPTY_STRING;
  public uploadedFiles: any[][] = [];
  public fileName = '';
  public invalidFileUrl: string | null = null;
}
