import { inject, LOCALE_ID, NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpLoaderFactory } from './app.translate-loader';
import { InspectionComponent } from './feature/inspection/inspection.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { LoaderComponent } from './shared/loader/loader.component';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ExportComponent } from './shared/export/export.component';
import { ExportModalComponent } from './shared/export-modal/export-modal.component';
import { PdfExportComponentComponent } from './shared/pdf-export-component/pdf-export-component.component';
import { TemplateComponent } from './shared/template/template.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';

import {
  DropDownListModule,
  DropDownsModule,
  MultiSelectModule,
} from '@progress/kendo-angular-dropdowns';
import { PopupModule } from '@progress/kendo-angular-popup';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { RowActionsComponent } from './shared/row-actions/row-actions.component';
import { PagerModule } from '@progress/kendo-angular-pager';
import { GridPaginationComponent } from './shared/grid-pagination/grid-pagination.component';
import { DashboardComponent } from './feature/dashboard/dashboard.component';
import { SearchComponent } from './shared/search/search.component';
import { ColumnadjustmentComponent } from './shared/columnadjustment/columnadjustment.component';
import { AuthConfigModule } from './auth-config.module';
import { ExceptionComponent } from './feature/exception/exception.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CustomGridFilterComponent } from './shared/custom-grid-filter/custom-grid-filter.component';
import { PopupDialogComponent } from './shared/popup-dialog/popup-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxFileDropModule } from 'ngx-file-drop';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { AssetHistoryComponent } from './feature/asset-history/asset-history.component';
import { AssetSetupComponent } from './feature/asset-setup/asset-setup.component';
import { FieldDataComponent } from './feature/field-data/field-data.component';
import { DataProcessingComponent } from './feature/data-processing/data-processing.component';
import { PendingSurveyComponent } from './feature/pending-survey/pending-survey.component';
import { HeirarchyFilterButtonsComponent } from './shared/heirarchy-filter-buttons/heirarchy-filter-buttons.component';
import { HeirarchyFilterPopupComponent } from './shared/heirarchy-filter-popup/heirarchy-filter-popup.component';
import { MapsComponent } from './feature/maps/maps.component';
import { ResolutionComponent } from './feature/resolution/resolution.component';
import { SurveyBacklogComponent } from './feature/survey-backlog/survey-backlog.component';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { states, storageSettingForStore } from './core/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NotificationComponent } from './shared/notification/notification.component';
import { MatMenuModule } from '@angular/material/menu';
import { TopNavigationComponent } from './shared/navigation-bar/top-navigation/top-navigation.component';
import { LeftNavigationComponent } from './shared/navigation-bar/left-navigation/left-navigation.component';
import { SidebarComponent } from './shared/navigation-bar/sidebar/sidebar.component';
import { AddAssetModalComponent } from './feature/add-asset-modal/add-asset-modal.component';
import { CreateSingleAssetComponent } from './feature/create-single-asset/create-single-asset.component';
import { AuthInterceptor, EventTypes, PublicEventsService } from 'angular-auth-oidc-client';
import { filter } from 'rxjs';
import { UnauthorizedComponent } from './feature/unauthorized/unauthorized.component';
import { ImportBulkAssetComponent } from './feature/import-bulk-asset/import-bulk-asset.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { AssetHistoryChartComponent } from './feature/asset-history/asset-history-chart/asset-history-chart.component';
import { CreateReportComponent } from './feature/create-report/create-report.component';
import { CreateNewTemplateComponent } from './feature/create-new-template/create-new-template.component';
import { ReportTemplatesLibraryComponent } from './feature/report-templates-library/report-templates-library.component';
import { ReportManagementComponent } from './feature/report-management/report-management.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { TimePickerModule } from '@progress/kendo-angular-dateinputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { ExportMapModalComponent } from './shared/export-map-modal/export-map-modal.component';
import { RunReportComponent } from './shared/run-report/run-report.component';
import { MatListModule } from '@angular/material/list';
import { ReportDetailComponent } from './feature/report-detail/report-detail.component';
import { SurveyOverviewComponent } from './feature/survey-overview/survey-overview.component';
import { SurveyRouteManagementComponent } from './feature/survey-route-management/survey-route-management.component';
import { AddSurveyRouteModalComponent } from './feature/add-survey-route-modal/add-survey-route-modal.component';
import { CreateRouteComponent } from './feature/create-route/create-route.component';
import { L10N_PREFIX, LocalizationService } from '@progress/kendo-angular-l10n';
// import { IntlModule } from '@progress/kendo-angular-intl';
import { LocalizationLoaderService } from './localization-loader.service';
import { CreateSurveyComponent } from './feature/create-survey/create-survey.component';
import { AssetSelectionComponent } from './feature/asset-selection/asset-selection.component';
import { SurveyRouteEditModalComponent } from './shared/survey-route-edit-modal/survey-route-edit-modal.component';
import { SetupHierarchyLevelsComponent } from './feature/setup-hierarchy-levels/setup-hierarchy-levels.component';
import { HierarchyNodeComponent } from './feature/setup-hierarchy-levels/hierarchy-node/hierarchy-node.component';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { ExcelExportComponentComponent } from './shared/excel-export-component/excel-export-component.component';
import { CompanyOverviewComponent } from './feature/company-overview/company-overview.component';
import { CreateAssetTypeComponent } from './feature/create-asset-type/create-asset-type.component';
import { CustomFrequencyComponent } from './shared/custom-frequency/custom-frequency.component';
import { DatePipe } from '@angular/common';
import { CreatePrimaryCompanyComponent } from './feature/create-primary-company/create-primary-company.component';
import { SetupSegmentsAndAssetsComponent } from './feature/setup-segments-and-assets/setup-segments-and-assets.component';
import { UserOverviewComponent } from './feature/user-overview/user-overview.component';
import { AddNewUserComponent } from './feature/add-new-user/add-new-user.component';
import { CustomPaginationComponent } from './shared/custom-pagination/custom-pagination.component';
import { MTXMemberOverviewComponent } from './feature/mtx-member-overview/mtx-member-overview.component';
import { AddMTXMemberComponent } from './feature/add-mtx-member/add-mtx-member.component';
import { NoAccessComponent } from './feature/no-access/no-access.component';
import { ProfileMenuComponent } from './feature/profile-menu/profile-menu.component';
import { ImportAssetInspectionDataComponent } from './feature/import-asset-inspection-data/import-asset-inspection-data.component';
import { ImportBulkAssetInspectionDataComponent } from './feature/import-bulk-asset-inspection-data/import-bulk-asset-inspection-data.component';
import { PaginationComponent } from './shared/pagination/pagination.component';
import { AuthReturnComponent } from './feature/auth-return/auth-return.component';


@NgModule({
  declarations: [
    AppComponent,
    AuthReturnComponent,
    InspectionComponent,
    LoaderComponent,
    ExportComponent,
    ExportModalComponent,
    PdfExportComponentComponent,
    TemplateComponent,
    RowActionsComponent,
    GridPaginationComponent,
    DashboardComponent,
    SearchComponent,
    ColumnadjustmentComponent,
    ExceptionComponent,
    CustomGridFilterComponent,
    PopupDialogComponent,
    CustomDatePipe,
    AssetHistoryComponent,
    AssetSetupComponent,
    FieldDataComponent,
    DataProcessingComponent,
    PendingSurveyComponent,
    HeirarchyFilterButtonsComponent,
    HeirarchyFilterPopupComponent,
    PendingSurveyComponent,
    MapsComponent,
    ResolutionComponent,
    SurveyBacklogComponent,
    TopNavigationComponent,
    LeftNavigationComponent,
    SidebarComponent,
    NotificationComponent,
    AddAssetModalComponent,
    CreateSingleAssetComponent,
    UnauthorizedComponent,
    ImportBulkAssetComponent,
    BreadcrumbComponent,
    AssetHistoryComponent,
    AssetHistoryChartComponent,
    CreateReportComponent,
    CreateNewTemplateComponent,
    ReportTemplatesLibraryComponent,
    ReportManagementComponent,
    ExportMapModalComponent,
    RunReportComponent,
    ReportDetailComponent,
    SurveyOverviewComponent,
    SurveyRouteManagementComponent,
    AddSurveyRouteModalComponent,
    CreateRouteComponent,
    CreateSurveyComponent,
    AssetSelectionComponent,
    SurveyRouteEditModalComponent,
    SetupHierarchyLevelsComponent,
    HierarchyNodeComponent,
    ExcelExportComponentComponent,
    CompanyOverviewComponent,
    CreateAssetTypeComponent,
    CustomFrequencyComponent,
    CreatePrimaryCompanyComponent,
    SetupSegmentsAndAssetsComponent,
    UserOverviewComponent,
    AddNewUserComponent,
    CustomPaginationComponent,
    MTXMemberOverviewComponent,
    AddMTXMemberComponent,
    NoAccessComponent,
    ProfileMenuComponent,
    ImportAssetInspectionDataComponent,
    ImportBulkAssetInspectionDataComponent,
    PaginationComponent
  ],
  imports: [
    BrowserModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    AuthConfigModule,
    GridModule,
    BrowserAnimationsModule,
    PDFExportModule,
    FormsModule,
    ButtonsModule,
    DropDownListModule,
    PopupModule,
    InputsModule,
    LayoutModule,
    FormsModule,
    DropDownsModule,
    DialogsModule,
    IconsModule,
    ListViewModule,
    PagerModule,
    DragDropModule,
    MultiSelectModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatCardModule,
    MatMenuModule,
    MatSelectModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    NgxFileDropModule,
    SwitchModule,
    DateInputsModule,
    NgxsModule.forRoot(states),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      keys: storageSettingForStore,
    }),
    ReactiveFormsModule,
    MatFormFieldModule,
    MatChipsModule,
    TimePickerModule,
    LabelModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatListModule,
    ExcelExportModule
  ],
  exports: [
    CustomDatePipe,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, 
      useClass: AuthInterceptor, 
      multi: true 
    },
    CustomDatePipe,
    DatePipe,
    provideAnimationsAsync('noop'),
    { provide: L10N_PREFIX, useValue: 'kendo.grid' },
    LocalizationService,   // Provide the Kendo LocalizationService here
    LocalizationLoaderService,
    { provide: LOCALE_ID, useValue: "en" },
  ],
  bootstrap: [AppComponent],
})

export class AppModule {
  private readonly eventService = inject(PublicEventsService);

  constructor() {
    this.eventService
      .registerForEvents()
      .pipe(filter((notification) => notification.type === EventTypes.ConfigLoaded))
  }
}
