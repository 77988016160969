import { TemplateFilterInput, templateType } from '../../awsAppSync/API';
import { AbstractControl, ValidatorFn } from '@angular/forms';

export const PrepareTemplateFilter = (
  templateType: templateType | undefined,
): TemplateFilterInput => {
  return {
    templateType: {
      eq: templateType, // Filter templates where templateType is equal to the provided value
    },
  };
};

export function latLongValidator(isLatitude: boolean): ValidatorFn {
  return (control: AbstractControl): Record<string, any> | null => {
    const value = control.value;
    const latPattern = /^[+-]?([1-8]?[0-9](\.\d+)?|90(\.0+)?)$/;
    const longPattern = /^[+-]?((1[0-7][0-9](\.\d+)?)|([1-9]?[0-9](\.\d+)?)|180(\.0+)?)$/;

    if (control.value === null || control.value === '') {
      return null; // Do not validate empty fields
    }

    if (isLatitude ? latPattern.test(control.value) : longPattern.test(control.value)) {
      return null; // Valid
    }

    return { invalidLatLong: { value } }; // Invalid
  };
}

// Custom validator function
export function imageFileValidator(): ValidatorFn {
  return (control: AbstractControl): Record<string, boolean> | null => {
    const file = control.value;
    if (file) {
      const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
      if (!acceptedImageTypes.includes(file.type)) {
        return { invalidImageFormat: true };
      }
    }
    return null;
  };
}

export function noWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const value = control.value || '';
    
    const isWhitespace = typeof value === 'string' && value.trim().length === 0 && value.length > 0;
    const isValid = !isWhitespace;
    
    return isValid ? null : { whitespace: true };
  };
}
