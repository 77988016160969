import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  API_ERROR_MSG,
  CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
  CLOSE,
  EMAIL_REGEX,
  ERROR,
  FAILED,
  NO,
  SUCCESS,
  SURE_CANCEL_HEADER,
  SURE_CANCEL_MSG,
  USER_OVERVIEW,
  WARNING,
} from '../../shared/constants';
import { City, Country, State } from 'country-state-city';
import {
  CompanyOption,
  Level3Value,
  Role,
  TimeZoneOption,
  UserCreateInput,
  UserOption,
  UserPrimaryCompanyInput,
  UserRoleInput,
  UserUpdateInput,
} from '../../../awsAppSync/API';
import { CompanyService } from '../../core/services/company.service';
import { TranslateService } from '@ngx-translate/core';
import { PopupDialogService } from '../../shared/popup-dialog/popup-dialog.service';
import { Router } from '@angular/router';
import { passwordValidator } from '../../shared/helpers/passwordValidators';
import { UserService } from '../../core/user.service';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import { HeirarchyFilterServiceService } from '../../shared/service/heirarchy-filter-service/heirarchy-filter-service.service';
import {
  RemoveSelectedUser,
  SelectedUserState,
  SetSelectedUser,
} from '../../core/store/selectedUser.state';
import { UserRegionModel } from '../../core/models/user.module';
import { CustomToastrService } from '../../shared/ngx-toastr/custom-toastr.service';
import {
  PCAdminDataState,
  RemovePCUserData,
} from '../../core/store/user-admin.state.state';

@Component({
  selector: 'app-add-new-user',
  templateUrl: './add-new-user.component.html',
  styleUrl: './add-new-user.component.css',
})
export class AddNewUserComponent implements OnInit, OnDestroy {
  @ViewChild('activeCheckbox', { static: true }) activeCheckbox!: ElementRef;
  form: FormGroup;
  isPCAdmin = false;
  adminFlag = false;
  PCAdminText = NO;
  isActive = false;
  isActiveText = 'OFF';
  primaryCompanyName = 'TC Energy';
  regionAccess: UserRegionModel[] = [];
  existingUserDropdown: UserOption[] = [];
  countries: any[] = [];
  states: any[] = [];
  cities: any[] = [];
  allTimeZone: (TimeZoneOption | null)[] | null | undefined;
  companyOptions: (CompanyOption | null)[] | null | undefined;
  selectedTimeZone = '';
  selectedCountry = '';
  selectedCountryCode = '';
  selectedCountryName = '';
  selectedState = '';
  selectedStateName = '';
  selectedCity = '';
  selectedCityName = '';
  selectedRegionName = '';
  showPassword = false;
  showConfirmPassword = false;
  public isLoading = false;
  primaryCompanyName$: Observable<string | null | undefined> | undefined;
  selectedExistingUser: UserOption | undefined;
  selectedRegions: string[] = [];
  loggedUserName$: Observable<string | null | undefined> | undefined;
  loggedUserName = '';
  loggedInCorViewUserId$: Observable<string | null | undefined> | undefined;
  loggedInCorViewUserId = '';
  loggedInCorViewUserName$: Observable<string | null | undefined> | undefined;
  loggedInCorViewUserName = '';
  primaryCompanyID$: Observable<string | null | undefined> | undefined;
  primaryCompanyID = '';
  selectedUserID$: Observable<string | null | undefined> | undefined;
  selectedUserID = '';
  selectedUserIsEditable$: Observable<boolean | null | undefined> | undefined;
  selectedUserIsEditable = false;
  regionSelected: any;
  userName = '';
  selectedExistingUserID = '';
  filteredUsers: UserOption[] = [];
  adminToggle = false;
  roles: (Role | null)[] = [];
  level3Values: (Level3Value | null)[] | null | undefined = [];
  pcAdminRoleId = '';
  isEditUserIsAdmin = false;
  userPrimaryCompanyName$: Observable<string | null | undefined> | undefined;
  coreViewCompanyId$: Observable<string | null | undefined> | undefined;
  userPrimaryCompanyId$: Observable<string | null | undefined> | undefined;
  adminFlag$: Observable<boolean | null | undefined> | undefined;
  userPrimaryCompanyName = '';
  userPrimaryCompanyId = '';
  coreViewCompanyId = '';
  displayCompanyName = '';

  selectedRole: string | null = null; // To store the selected role
  constructor(
    private router: Router,
    private popupDialogService: PopupDialogService,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private translate: TranslateService,
    private _userService: UserService,
    private store: Store,
    private _hierarchyService: HeirarchyFilterServiceService,
    private toastr: CustomToastrService,
  ) {
    this.loggedUserName$ = this.store.select(
      AuthenticateUserState.getLoggedInName,
    );
    this.loggedInCorViewUserId$ = this.store.select(
      AuthenticateUserState.getCoreViewPrimaryCompanyId,
    );
    this.loggedInCorViewUserName$ = this.store.select(
      AuthenticateUserState.getCoreViewPrimaryCompanyName,
    );
    this.primaryCompanyID$ = this.store.select(
      AuthenticateUserState.getSyrcPrimaryCompanyId,
    );
    this.primaryCompanyName$ = this.store.select(
      AuthenticateUserState.getSyrcPrimaryCompanyName,
    );
    this.selectedUserID$ = this.store.select(
      SelectedUserState.getSelectedUserId,
    );
    this.selectedUserIsEditable$ = this.store.select(
      SelectedUserState.getSelectedUserIsEdited,
    );

    this.userPrimaryCompanyId$ = this.store.select(
      PCAdminDataState.getprimaryCompanyId,
    );
    this.userPrimaryCompanyName$ = this.store.select(
      PCAdminDataState.getprimaryCompanyName,
    );
    this.coreViewCompanyId$ = this.store.select(
      PCAdminDataState.getcoreViewCompanyId,
    );
    this.adminFlag$ = this.store.select(PCAdminDataState.getisPCAdmin);

    this.form = this.fb.group(
      {
        userType: ['newUser'],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        isPCAdmin: [''],
        userRole: [],
        primaryCompany: [{ value: this.primaryCompanyName, disabled: true }],
        existingUserDropdown: [''],
        userName: ['', Validators.required],
        emailId: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
        phoneNumber: ['', Validators.pattern(/^[\d+-]+$/)],
        corporateOfficeNumber: [
          '',
          [Validators.required, Validators.pattern(/^[\d+-]+$/)],
        ],
        address: [''],
        city: [''],
        stateProvince: [''],
        postalZipCode: [''],
        country: [''],
        timeZone: ['', Validators.required],
        active: [true],
        password: ['', [Validators.required, passwordValidator()]],
        confirmPassword: ['', Validators.required],
        regions: this.fb.array(
          [this.createRegionGroup()],
          [this.noDuplicateRegionsValidator()],
        ),
      },
      { validators: this.passwordsMatchValidator },
    );
  }
  // Custom Validator: No duplicate regions
  noDuplicateRegionsValidator() {
    return (formArray: AbstractControl) => {
      const controls = (formArray as FormArray).controls;

      const regionValues = controls.map(
        (control) => control.get('region')?.value,
      );
      const duplicateRegions = regionValues.filter(
        (region, index) => region && regionValues.indexOf(region) !== index,
      );

      return duplicateRegions.length > 0 ? { duplicateRegions: true } : null;
    };
  }

  // Getter for regions form array
  get regions(): FormArray {
    return this.form.get('regions') as FormArray;
  }

  createRegionGroup(): FormGroup {
    return this.fb.group({
      region: ['', Validators.required],
      selectedRole: ['', Validators.required],
    });
  }

  updateRegionGroup(userRoleMapping: any): FormGroup {
    return this.fb.group({
      region: [userRoleMapping.region || '', Validators.required],
      selectedRole: [userRoleMapping.selectedRole || '', Validators.required],
    });
  }

  addRegion() {
    this.regions.push(this.createRegionGroup());
  }

  // Remove region form group from regions form array by index
  removeRegion(index: number): void {
    this.regions.removeAt(index);
  }

  ngOnInit(): void {
    this.activeCheckbox.nativeElement.click();
    this.countries = Country.getAllCountries();

    this.primaryCompanyID$?.subscribe((id) => {
      this.primaryCompanyID = id!;
    });
    this.primaryCompanyName$?.subscribe((name) => {
      this.primaryCompanyName = name!;
    });
    this.userPrimaryCompanyId$?.subscribe((value) => {
      this.userPrimaryCompanyId = value ?? '';
    });
    this.userPrimaryCompanyName$?.subscribe((value) => {
      this.userPrimaryCompanyName = value ?? '';
    });
    this.coreViewCompanyId$?.subscribe((value) => {
      this.coreViewCompanyId = value ?? '';
    });
    this.adminFlag$?.subscribe((value) => {
      this.adminFlag = value ?? false;
      if (this.adminFlag) {
        this.validationCheckOnRegions();
        this.PCAdminText='YES'
      }
    });

    this.form
      .get('primaryCompany')
      ?.setValue(
        this.adminFlag ? this.userPrimaryCompanyName : this.primaryCompanyName,
      );
    this.loggedUserName$?.subscribe((name) => {
      this.loggedUserName = name!;
    });
    this.loggedInCorViewUserId$?.subscribe((name) => {
      this.loggedInCorViewUserId = name!;
    });
    this.loggedInCorViewUserName$?.subscribe((name) => {
      this.loggedInCorViewUserName = name!;
    });
    this.selectedUserID$?.subscribe((id) => {
      this.selectedUserID = id!;
    });
    this.selectedUserIsEditable$?.subscribe((value) => {
      this.selectedUserIsEditable = value!;
    });

    this.loadCompanyOptions();
    this.loadTimeZone();
    this.bindLevel3Values();

    this.loadRegions();
    this.getUserRoles().then(() => {
      if (this.selectedUserIsEditable) {
        this.loadData();
      }
    });
    this.selectedRole = 'User';

    this.displayCompanyName = this.adminFlag
      ? this.userPrimaryCompanyName
      : this.primaryCompanyName;
  }

  async getUserRoles() {
    const userroles = await this._userService.getAllUserRoles(false);
    if (userroles && userroles.items) {
      this.roles = userroles.items.filter((val) => val?.isPCAdmin !== true);
      this.pcAdminRoleId =
        userroles.items.find((val) => val?.isPCAdmin === true)?.id || '';
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(new SetSelectedUser(false, ''));
    this.store.dispatch(new RemovePCUserData());
  }

  async loadData() {
    this.isLoading = true;
    const selectedUser = await this._userService.getUserByCoreViewUserId(
      this.selectedUserID,
    );
    this.isEditUserIsAdmin =
      selectedUser?.userRoles?.some((x) => x?.roleId === this.pcAdminRoleId) ??
      false;
    this.isPCAdmin = this.isEditUserIsAdmin;

    this.loggedInCorViewUserId = selectedUser?.coreViewUserId ?? '';
    this.selectedUserID = selectedUser?.id ?? '';
    const regionsUserRole =
      selectedUser?.userRoles?.map((item) => ({
        region: item?.level3Value?.level3ValueId || '',
        selectedRole: item?.roleId || '',
      })) || [];

    this.form = this.fb.group({
      userType: ['existingUser'],
      primaryCompany: [
        {
          value:
            selectedUser?.coreViewPrimaryCompany.displayName != ''
              ? selectedUser?.coreViewPrimaryCompany.displayName
              : '',
          disabled: true,
        },
      ],
      userName: [
        {
          value: selectedUser?.username != '' ? selectedUser?.username : '',
          disabled: true,
        },
      ],
      emailId: [
        {
          value: selectedUser?.email != '' ? selectedUser?.email : '',
          disabled: true,
        },
      ],
      phoneNumber: [
        {
          value: selectedUser?.cellPhone != '' ? selectedUser?.cellPhone : '',
          disabled: true,
        },
      ],
      corporateOfficeNumber: [
        {
          value:
            selectedUser?.officePhone != '' ? selectedUser?.officePhone : '',
          disabled: true,
        },
      ],
      address: [
        {
          value: selectedUser?.addr1 != '' ? selectedUser?.addr1 : '',
          disabled: true,
        },
      ],
      city: [
        {
          value: selectedUser?.addrCity != '' ? selectedUser?.addrCity : '',
          disabled: true,
        },
      ],
      stateProvince: [
        {
          value:
            selectedUser?.stateProvince != ''
              ? selectedUser?.stateProvince
              : '',
          disabled: true,
        },
      ],
      postalZipCode: [
        {
          value: selectedUser?.postalZip != '' ? selectedUser?.postalZip : '',
          disabled: true,
        },
      ],
      country: [
        {
          value: selectedUser?.country != '' ? selectedUser?.country : '',
          disabled: true,
        },
      ],
      timeZone: [
        {
          value: selectedUser?.timeZone != '' ? selectedUser?.timeZone : '',
          disabled: true,
        },
      ],
      active: [{ value: selectedUser?.active, disabled: true }],
      regions: this.fb.array([], [this.noDuplicateRegionsValidator()]),
    });

    const regionsArray = this.form.get('regions') as FormArray;
    regionsArray.clear();
    if (regionsUserRole.length) {
      regionsUserRole.forEach((regionData: any) => {
        regionsArray.push(this.updateRegionGroup(regionData));
      });
    }

    this.userName = selectedUser?.firstName + ' ' + selectedUser?.lastName;
    this.isActive = selectedUser?.active ?? false;
    this.form.get('active')?.setValue(this.isActive);

    if (selectedUser?.country) {
      const selectedCountry = this.countries.find(
        (country) => country.isoCode === selectedUser.country?.toUpperCase(),
      );

      if (selectedCountry) {
        this.selectedCountry = selectedCountry.name;
        this.selectedCountryCode = selectedCountry.isoCode;
        this.form.get('country')?.setValue(selectedCountry.name);
        this.states = State.getStatesOfCountry(selectedCountry.isoCode);
      }
    }
    if (selectedUser?.stateProvince) {
      if (selectedUser.stateProvince && this.selectedCountry) {
        const selectedState = this.states.find(
          (state) =>
            state.isoCode === selectedUser.stateProvince?.toUpperCase(),
        );

        if (selectedState) {
          this.selectedState = selectedState.isoCode;
          this.form.get('stateProvince')?.setValue(selectedState.name);
          this.cities = City.getCitiesOfState(
            this.selectedCountryCode,
            selectedState.isoCode,
          );
        }
      }
    }
    if (selectedUser?.addrCity) {
      if (selectedUser.addrCity) {
        this.selectedCity = selectedUser.addrCity;
        this.form.get('city')?.setValue(selectedUser.addrCity);
      }
    }
    if (selectedUser?.timeZone) {
      if (selectedUser.timeZoneId) {
        this.selectedTimeZone = selectedUser.timeZoneId;
        this.form.get('timeZone')?.setValue(selectedUser.timeZoneId);
      }
    }
    this.isLoading = false;
  }

  async loadRegions() {
    const regionList = this._hierarchyService.level3List;
    if (regionList) {
      regionList.items?.forEach((element) => {
        const region: UserRegionModel = {
          id: element?.id,
          displayName: element?.level3Value,
        };
        this.regionAccess.push(region);
      });
    }
  }

  async bindLevel3Values() {
    const level3Values =
      await this._userService.getAllLevel3ValueByPrimaryCompany(
        this.primaryCompanyID ?? '',
      );
    if (level3Values) {
      this.level3Values = level3Values.items;
    }
  }

  async loadExistingUserData() {
    this.isLoading = true;
    const exsistingUserData = await this._userService.getAllUserOption();
    if (exsistingUserData && exsistingUserData.items) {
      this.existingUserDropdown = exsistingUserData.items.filter(
        (item): item is UserOption => item !== null && item !== undefined,
      );
      this.isLoading = false;
      this.filteredUsers = [...this.existingUserDropdown];
    }
  }

  async loadCompanyOptions() {
    const response = await this.companyService.getAllCompanyOption();
    this.companyOptions = response?.items;
  }

  async loadTimeZone() {
    const response = await this.companyService.getAllTimeZoneOptions();
    this.allTimeZone = response?.items;
  }

  onUserTypeChange(value: string) {
    this.form.get('userType')?.setValue(value);
    if (this.form.get('userType')?.value === 'existingUser') {
      this.loadExistingUserData();
      this.resetForm();
      this.form.get('userType')?.setValue(value);
    } else {
      this.resetForm();
    }
  }

  resetForm() {
    this.form = this.fb.group(
      {
        userType: ['newUser'],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        isPCAdmin: [''],
        userRole: [],
        primaryCompany: [
          {
            value: this.adminFlag
              ? this.userPrimaryCompanyName
              : this.primaryCompanyName,
            disabled: true,
          },
        ],
        // regionAccess: ['', Validators.required],
        existingUserDropdown: [''],
        userName: ['', Validators.required],
        emailId: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
        phoneNumber: ['', Validators.pattern(/^[\d+-]+$/)],
        corporateOfficeNumber: [
          '',
          [Validators.required, Validators.pattern(/^[\d+-]+$/)],
        ],
        address: [''],
        city: [''],
        stateProvince: [''],
        postalZipCode: [''],
        country: [''],
        timeZone: ['', Validators.required],
        active: [true],
        password: ['', [Validators.required, passwordValidator()]],
        confirmPassword: ['', Validators.required],
        regions: this.fb.array([this.createRegionGroup()]),
      },
      { validators: this.passwordsMatchValidator },
    );
  }

  onUserRoleChange(value: string) {
    this.form.get('userRole')?.setValue(value);
  }

  toggleActive() {
    if (this.isActive) {
      this.isActiveText = 'OFF';
      this.isActive = false;
    } else if (!this.isActive) {
      this.isActiveText = 'ON';
      this.isActive = true;
    }
  }

  validationCheckOnRegions() {
    const regions = this.form.get('regions') as FormArray;

    regions.controls.forEach((regionGroup) => {
      if (this.isPCAdmin || this.adminFlag || this.selectedUserIsEditable) {
        // Remove validators
        regionGroup.get('region')?.clearValidators();
        regionGroup.get('selectedRole')?.clearValidators();
      } else {
        // Add required validators
        regionGroup.get('region')?.setValidators([Validators.required]);
        regionGroup.get('selectedRole')?.setValidators([Validators.required]);
      }

      // Update validity of fields
      regionGroup.get('region')?.updateValueAndValidity();
      regionGroup.get('selectedRole')?.updateValueAndValidity();
    });
  }

  togglePCAdmin() {
    this.isPCAdmin = !this.isPCAdmin;
    this.PCAdminText = this.translate.instant(
      this.isPCAdmin ? 'USER_OVERVIEW.YES' : 'USER_OVERVIEW.NO',
    );

    this.validationCheckOnRegions();
    // const regions = this.form.get('regions') as FormArray;

    // regions.controls.forEach((regionGroup) => {
    //   if (this.isPCAdmin || this.adminFlag) {
    //     // Remove validators
    //     regionGroup.get('region')?.clearValidators();
    //     regionGroup.get('selectedRole')?.clearValidators();
    //   } else {
    //     // Add required validators
    //     regionGroup.get('region')?.setValidators([Validators.required]);
    //     regionGroup.get('selectedRole')?.setValidators([Validators.required]);
    //   }

    //   // Update validity of fields
    //   regionGroup.get('region')?.updateValueAndValidity();
    //   regionGroup.get('selectedRole')?.updateValueAndValidity();
    // });

    if (this.isPCAdmin || this.adminFlag) {
      this.clearRegions();
    } else {
      const regions = this.form.get('regions') as FormArray;
      regions.clear(); // This clears the existing values in the regions array
      regions.push(this.createRegionGroup()); // This adds a new empty region
    }
  }

  clearRegions() {
    const regions = this.form.get('regions') as FormArray;
    regions.clear(); // Clears all FormGroups in the FormArray
  }

  onCountryChange(event: Event): void {
    const selectedCountryName = (event.target as HTMLSelectElement).value;
    const selectedCountry = this.countries.find(
      (country) => country.name === selectedCountryName,
    );

    if (selectedCountry) {
      this.selectedCountryName = selectedCountry.name;
      this.selectedCountry = selectedCountry.isoCode;
      this.states = State.getStatesOfCountry(selectedCountry.isoCode);
      this.cities = [];
    }
  }

  onCityChange(event: Event): void {
    this.selectedCityName = (event.target as HTMLSelectElement).value;
  }

  onStateChange(event: Event): void {
    const selectedStateName = (event.target as HTMLSelectElement).value;
    const selectedState = this.states.find(
      (state) => state.name === selectedStateName,
    );

    if (selectedState) {
      this.selectedStateName = selectedState.name;
      this.cities = City.getCitiesOfState(
        this.selectedCountry,
        selectedState.isoCode,
      );
    }
  }

  onTimeZoneChange(event: Event): void {
    this.selectedTimeZone = (event.target as HTMLSelectElement).value;
  }

  passwordsMatchValidator(group: AbstractControl) {
    const password = group.get('password')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;
    return password === confirmPassword ? null : { passwordMismatch: true };
  }

  togglePasswordVisibility(field: string): void {
    if (field === 'password') {
      this.showPassword = !this.showPassword;
    } else if (field === 'confirmPassword') {
      this.showConfirmPassword = !this.showConfirmPassword;
    }
  }

  onCancel() {
    this.popupDialogService.openDialog(
      SURE_CANCEL_HEADER,
      SURE_CANCEL_MSG,
      FAILED,
      CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
      () => {
        this.store.dispatch(new RemoveSelectedUser());
        this.router.navigate([USER_OVERVIEW]);
      },
      true,
      NO,
    );
  }

  async saveAndExit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      try {
        this.isLoading = true;
        let response;
        if (this.selectedUserIsEditable) {
          const updateInput = this.updateUser();
          this.validationCheckOnRegions()
          response = await this._userService.updateUser(updateInput);
        } else {
          const addInput = this.createUser();
          response = await this._userService.createUser(addInput);
        }
        if (response?.status === 'Success') {
          const dialogRef = this.popupDialogService.openDialog(
            this.selectedUserIsEditable == true
              ? 'User Updated Successfully!'
              : 'User Created Successfully!',
            "You'll be redirected to User Overview list page",
            SUCCESS,
            CLOSE,
            () => this.router.navigate([USER_OVERVIEW]),
          );
          dialogRef.afterClosed().subscribe(() => {
            this.isLoading = false;
            this.store.dispatch(new RemoveSelectedUser());
          });
        } else {
          this.popupDialogService.openDialog(
            this.selectedUserIsEditable == true
              ? 'Updating User Failed!'
              : 'Creating User Failed!',
            'An unexpected error occurred. Please try adding your User again.',
            FAILED,
            CLOSE,
          );
          this.toastr.showError(
            this.selectedUserIsEditable == true
              ? 'Failed to update User'
              : response?.error?.message ?? API_ERROR_MSG,
            ERROR,
          );
          console.log(response?.error?.message);
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    } else {
      this.popupDialogService.openDialog(
        ERROR,
        'Please enter valid details.',
        WARNING,
        CLOSE,
      );
    }
  }

  updateUser() {
    const updatedUser: UserUpdateInput = {
      coreViewUserId: this.loggedInCorViewUserId,
      id: this.selectedUserID,
      updatedBy: this.loggedUserName,
      isMTXUser: false,
      isUserPCAdmin: this.isEditUserIsAdmin,
      roleId: this.isEditUserIsAdmin ? this.pcAdminRoleId : '',
      userRolesInput: this.mapUserRoles(),
    };
    return updatedUser;
  }

  mapUserRoles() {
    const userRoleMapping: UserRoleInput[] = [];
    const selectedRegionsAndRoles = this.form.get('regions')?.value ?? [];

    if (selectedRegionsAndRoles.length > 0) {
      selectedRegionsAndRoles.forEach((item: any) => {
        const matchedValue = this.level3Values?.find(
          (level3: any) => level3.id === item.region,
        );

        if (matchedValue) {
          // Map the details into the UserRoleInput array
          const userRole: UserRoleInput = {
            roleId: item.selectedRole,
            level1ValueId: matchedValue.level1ValueId ?? null,
            level2ValueId: matchedValue.level2ValueId ?? null,
            level3ValueId: matchedValue.id,
          };
          userRoleMapping.push(userRole);
        }
      });
    }
    return userRoleMapping;
  }

  createUser() {
    const input: UserCreateInput = {
      firstName: this.form.get('firstName')?.value ?? '',
      lastName: this.form.get('lastName')?.value ?? '',
      active: this.isActive,
      username: (this.form.get('userName')?.value ?? '')
        .trim()
        .replace(/\s+/g, ''),
      email: this.form.get('emailId')?.value ?? '',
      postalZip: this.form.get('postalZipCode')?.value ?? '',
      addr1: this.form.get('address')?.value ?? '',
      addrCity: '',
      cellPhone: this.form.get('phoneNumber')?.value ?? '',
      officePhone: this.form.get('corporateOfficeNumber')?.value ?? '',
      country: this.selectedCountryName ?? '',
      stateProvince: this.selectedStateName ?? '',
      timeZone: this.selectedTimeZone ?? '',
      timeZoneId: this.selectedTimeZone ?? '',
      password: this.form.get('password')?.value ?? '',
      createdBy: this.loggedUserName,
      confirmPassword: this.form.get('confirmPassword')?.value ?? '',
      coreViewUserId:
        this.form.get('userType')?.value === 'existingUser'
          ? this.selectedExistingUser?.corViewUserId
          : null,
      coreViewPrimaryCompany:
        this.form.get('userType')?.value === 'existingUser'
          ? ({
              id: this.adminFlag
                ? this.coreViewCompanyId
                : this.loggedInCorViewUserId,
              //  : this.selectedExistingUser?.corViewUserId,
              displayName: this.adminFlag
                ? ''
                : //: this.selectedExistingUser?.primaryCompany?.displayName,
                  this.loggedInCorViewUserName,
            } as UserPrimaryCompanyInput)
          : ({
              id: this.adminFlag
                ? this.coreViewCompanyId
                : this.loggedInCorViewUserId,
              displayName: this.adminFlag ? '' : this.loggedInCorViewUserName,
            } as UserPrimaryCompanyInput),
      syrcPrimaryCompany: {
        id: this.adminFlag ? this.userPrimaryCompanyId : this.primaryCompanyID,
        displayName: this.adminFlag
          ? this.userPrimaryCompanyName
          : this.primaryCompanyName,
      } as UserPrimaryCompanyInput,
      isMTXUser: false,
      isUserPCAdmin: this.isPCAdmin || this.adminFlag,
      roleId: this.isPCAdmin || this.adminFlag ? this.pcAdminRoleId : '',
      userRolesInput: this.mapUserRoles(),
    };
    return input;
  }

  existingUserChange(event: any) {
    const selectedUser = event as UserOption;
    this.selectedExistingUser = this.existingUserDropdown.find(
      (user) => user.corViewUserId === selectedUser.corViewUserId,
    );
    this.selectedExistingUserID = selectedUser.corViewUserId ?? '';
    this.form = this.fb.group({
      userType: ['existingUser'],
      userName: [
        {
          value:
            this.selectedExistingUser?.username != ''
              ? this.selectedExistingUser?.username
              : '',
          disabled: true,
        },
      ],
      emailId: [
        {
          value:
            this.selectedExistingUser?.email != ''
              ? this.selectedExistingUser?.email
              : '',
          disabled: true,
        },
      ],
      phoneNumber: [
        {
          value:
            this.selectedExistingUser?.cellPhone != ''
              ? this.selectedExistingUser?.cellPhone
              : '',
          disabled: true,
        },
      ],
      corporateOfficeNumber: [
        {
          value:
            this.selectedExistingUser?.officePhone != ''
              ? this.selectedExistingUser?.officePhone
              : '',
          disabled: true,
        },
      ],
      address: [
        {
          value:
            this.selectedExistingUser?.addr1 != ''
              ? this.selectedExistingUser?.addr1
              : '',
          disabled: true,
        },
      ],
      city: [
        {
          value:
            this.selectedExistingUser?.addrCity != ''
              ? this.selectedExistingUser?.addrCity
              : '',
          disabled: true,
        },
      ],
      stateProvince: [
        {
          value:
            this.selectedExistingUser?.stateProvince != ''
              ? this.selectedExistingUser?.stateProvince
              : '',
          disabled: true,
        },
      ],
      postalZipCode: [
        {
          value:
            this.selectedExistingUser?.postalZip != ''
              ? this.selectedExistingUser?.postalZip
              : '',
          disabled: true,
        },
      ],
      country: [
        {
          value:
            this.selectedExistingUser?.country != ''
              ? this.selectedExistingUser?.country
              : '',
          disabled: true,
        },
      ],
      timeZone: [
        {
          value:
            this.selectedExistingUser?.timeZone != ''
              ? this.selectedExistingUser?.timeZone
              : '',
          disabled: true,
        },
      ],
      regions: this.fb.array(
        [this.createRegionGroup()],
        [this.noDuplicateRegionsValidator()],
      ),
      active: [{ value: this.selectedExistingUser?.active, disabled: true }],
      // regionAccess: ['', Validators.required],
    });

    this.validationCheckOnRegions();

    if (this.selectedExistingUser?.country) {
      const selectedCountry = this.countries.find(
        (country) =>
          country.isoCode === this.selectedExistingUser?.country?.toUpperCase(),
      );

      if (selectedCountry) {
        this.selectedCountry = selectedCountry.name;
        this.selectedCountryCode = selectedCountry.isoCode;
        this.form.get('country')?.setValue(selectedCountry.name);
        this.states = State.getStatesOfCountry(selectedCountry.isoCode);
      }
    }
    if (this.selectedExistingUser?.stateProvince) {
      if (this.selectedExistingUser.stateProvince && this.selectedCountry) {
        const selectedState = this.states.find(
          (state) =>
            state.isoCode ===
            this.selectedExistingUser?.stateProvince?.toUpperCase(),
        );

        if (selectedState) {
          this.selectedState = selectedState.isoCode;
          this.form.get('stateProvince')?.setValue(selectedState.name);
          this.cities = City.getCitiesOfState(
            this.selectedCountryCode,
            selectedState.isoCode,
          );
        }
      }
    }
    if (this.selectedExistingUser?.addrCity) {
      if (this.selectedExistingUser.addrCity && this.selectedState) {
        this.selectedCity = this.selectedExistingUser.addrCity;
        this.form.get('city')?.setValue(this.selectedExistingUser.addrCity);
      }
    }
    if (this.selectedExistingUser?.timeZone) {
      if (this.selectedExistingUser.timeZoneId) {
        this.selectedTimeZone = this.selectedExistingUser.timeZoneId;
        this.form
          .get('timeZone')
          ?.setValue(this.selectedExistingUser.timeZoneId);
      }
    }
  }

  regionChange(event: Event) {
    const selectedRegionId = (event.target as HTMLSelectElement).value;
    this.selectedRegionName = selectedRegionId;
    this.selectedRegions.push(selectedRegionId);
  }

  isCheckboxDisabled(): boolean {
    const userType = this.form.get('userType')?.value;
    return userType === 'existingUser' || this.selectedUserIsEditable;
  }

  onDropdownFilterChange(filterValue: string): void {
    // Filter the users based on the input search value
    this.filteredUsers = this.existingUserDropdown.filter((user) =>
      user?.username?.toLowerCase().includes(filterValue.toLowerCase()),
    );

    // If the selected user is still in the filtered list, keep it, otherwise reset the selection
    const selectedUserExists = this.filteredUsers.some(
      (user) => user.corViewUserId === this.selectedExistingUserID,
    );

    if (!selectedUserExists && this.filteredUsers.length > 0) {
      // If the selected user is not in the filtered list, select the first user
      this.selectedExistingUserID = this.filteredUsers[0].corViewUserId ?? '';
      this.existingUserChange(this.filteredUsers[0]);
    } else if (this.filteredUsers.length === 0) {
      // If no users match the filter, reset the selection
      this.selectedExistingUserID = '';
    }
  }

  onRoleChange(index: number, role: Role | null) {
    if (role) {
      this.regions.at(index).get('selectedRole')?.setValue(role.id);
    }
  }
}
