import { Component, OnInit } from '@angular/core';
import { NotificationService } from './notification.service';

interface INotification {
  avatar: string;
  name: string;
  fileName: string;
  fileSize: string;
  timestamp: string;
  isNew: boolean;
}

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.css'
})
export class NotificationComponent implements OnInit {
  notifications: INotification[] = [];

  constructor(private notificationService: NotificationService) { 
    
  }

  ngOnInit(): void {
    this.notificationService.notifications$.subscribe(notifications => {
      this.notifications = notifications;
    });
  }
}