<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="col-auto">
  <h5 class="mtx-text-primary" style="margin-left: 10px; margin-top: 6px">
    <!-- {{ 'ASSET_SETUP_PAGE.IMPORT_ASSETS' | translate }} -->
    Import Asset Inspection Data
  </h5>
</div>
<div class="card card-shadow mt-4">
  <div class="card-body">
    <div class="card mtx-card-bg-primary mtx-card-info">
      <div class="card-body mtx-padding-bottom-0">
        <div class="row g-1">
          <div class="col-sm-12 col-md-8 d-flex">
            <h5 class="mtx-text-primary" style="margin-right: 5px">
              Column Mapping
            </h5>
            <a
              class="mtx-theme-primary-color mtx-font-15"
              style="vertical-align: top;  margin-top: 5px;"
              >Watch video to learn more</a
            >
            <span
              class="material-icons mtx-theme-primary-color mtx-font-15"
              style="vertical-align: middle; margin-top: 10px"
              >play_circle</span
            >
          </div>
          <div
            class="col-sm-12 col-md-4 pull-right mtx-text-end mtx-padding-right-0 mtx-font-15"
          >
            <a class="mtx-theme-primary-color">View Documentation</a>
            <span
              class="material-icons mtx-theme-primary-color"
              style="vertical-align: middle"
              >open_in_new</span
            >
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <ul>
              <li class="mtx-sub-text-primary mtx-font-14">
                Column mapping is used to correctly support incoming data and
                properly map it to a structure supported by SYRC. <br>
                Users will
                notice that the columns supported by SYRC are highlighted in
                blue and have the capability of selecting a corresponding column
                header from the incoming file.
              </li>
              <li  class="mtx-sub-text-primary mtx-font-14">
                Please select the column header that best matches the SYRC
                column.
              </li>
              <li  class="mtx-sub-text-primary mtx-font-14">
                Users will have a chance to review and approve their selections
                before proceeding with the file import.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3 g-1" *ngIf="tableData.length > 0">
      <div class="col-6">
        <span class="mtx-text-primary" style="font-weight: 600"
          >Column Mapping</span
        >
      </div>
      <div class="col-6 text-end">
        <a class="mtx-theme-primary-color align-self-center">{{ fileName }}</a>
        <span class="material-icons mtx-cross-icon" (click)="removeFile()"
          >highlight_off</span
        >
      </div>
    </div>

    <div
      class="card mt-3"
      *ngIf="tableData.length > 0"
      style="
        border-color: #9adaf8;
        border-radius: 2px;
        overflow-x: scroll;
        min-height: 300px;
      "
    >
      <div class="card-body" style="padding: 5px; width: max-content">
        <div class="row g-1">
          <div
            *ngFor="let column of displayedColumns; let i = index"
            class="col card p-1"
            style="
              border-color: #9adaf8;
              background-color: #f1f8ff;
              margin-right: 5px;
              font-size: 12px;
              text-align: start;
              vertical-align: middle;
              min-height: 52px;
              border-radius: 2px;
            "
          >
            <div class="card-body" style="padding: 5px; font-weight: 650">
              {{ column }}
            </div>
          </div>
        </div>
        <div class="row g-1 mt-1">
          <div
            *ngFor="let column of displayedColumns; let i = index"
            class="col card p-1"
            style="
              margin-right: 5px;
              max-height: 50px;
              font-size: 12px;
              text-align: start;
              border-radius: 2px;
              border-color: #9adaf8;
            "
          >
            <div class="card-body" style="padding: 0px">
              <div class="dropdown">
                <button
                  class="btn btn-light dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton{{ column }}"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style="border-radius: 2px; color: #1d2433; font-size: 14px"
                >
                  Column Imported - {{ fileExtension }}
                </button>
                <ul class="dropdown-menu">
                  <li
                    *ngFor="let option of availableOptions"
                    style="padding-left: 5px"
                  >
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="{{ column }}Options"
                        id="{{ column.trim() }}{{ option }}"
                        (click)="onColumnSelected(i, option)"
                      />
                      <label
                        class="form-check-label"
                        for="{{ column.trim() }}{{ option }}"
                        style="color: #5b5b5b"
                      >
                        {{ option }}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row g-1 mt-1">
          <div
            *ngFor="let column of selectedColumns; let i = index"
            [ngClass]="{ 'border-danger': validationErrors[i] }"
            class="col card p-1"
            style="
              margin-right: 5px;
              font-size: 14px;
              text-align: start;
              vertical-align: middle;
              height: 45px;
              border-radius: 2px;
              line-height: 25px;
            "
          >
            <div
              class="card-body"
              style="padding: 5px; font-weight: 400; white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 150px;"
            >
            {{ column }}
              <span
                *ngIf="selectedColumns[i]"
                class="material-icons"
                (click)="onColumnUnmapped(i)"
                style="
                  color: #a3a3a3;
                  font-size: 20px;
                  cursor: pointer;
                  position: absolute;
                  right: 5px;
                  top: 50%;
                  transform: translateY(-50%);
                "
                >clear</span
              >
              <!-- <span *ngIf="validationErrors[i]" class="material-icons exclamatory-icon" style="color: #E5AB09; position: absolute; right: 5px; top: 50%; transform: translateY(-50%);">error_outline</span> -->
            </div>
            <div
              *ngIf="validationErrors[i]"
              class="validation-error"
              style="
                color: #e5ab09;
                margin-top: 26px;
                padding-left: 45px;
                font-weight: 500;
              "
            >
              {{ validationMessages[i] }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3 g-1" *ngIf="mappedPreviewData.length > 0">
      <div class="col">
        <span class="mtx-text-primary" style="font-weight: 600"
          >Preview of {{ fileExtension }} File</span
        >
      </div>
    </div>

    <div
      *ngIf="mappedPreviewData.length > 0"
      class="table-responsive mt-3 mtx-font-14"
    >
      <table class="table table-bordered">
        <thead>
          <tr>
            <th *ngFor="let col of displayedColumns">{{ col }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of mappedPreviewData">
            <td *ngFor="let col of displayedColumns">
              {{ formatCellValue(row[col]) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      class="row mt-3 g-1"
      *ngIf="tableData.length > 0 && mappedPreviewData.length === 0"
    >
      <div class="col">
        <span class="mtx-text-primary" style="font-weight: 600"
          >Uploaded {{ fileExtension }} File</span
        >
      </div>
    </div>

    <div
      *ngIf="tableData.length > 0 && mappedPreviewData.length === 0"
      class="table-responsive mt-3 mtx-font-14"
    >
      <table class="table table-bordered">
        <thead>
          <tr>
            <th *ngFor="let col of tableData[0]">{{ col }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of tableData.slice(1, 10)">
            <td *ngFor="let cell of row">{{ formatCellValue(cell) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="card-footer bg-transparent text-end mtx-card-footer">
    <button
      type="button"
      class="btn btn-info mtx-btn-secondary"
      (click)="onCancel()"
    >
      &nbsp;&nbsp;Cancel&nbsp;&nbsp;
    </button>
    <button
      [disabled]="!enableSave"
      [ngClass]="{ 'disable-btn-savenext': !enableSave }"
      type="button"
      class="btn btn-info mtx-btn-primary ms-2"
      (click)="saveFileImport(true)"
    >
      &nbsp;&nbsp;Save & Next&nbsp;&nbsp;
    </button>
  </div>
</div>
