import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { REGION_SETUP_STATE, REGION_SETUP_STATE_TYPE_SET, REGION_SETUP_STATE_TYPE_SET_REMOVE } from "../../shared/constants";

export interface RegionModel {
    selectedRegionIds: string [];
}
export class SetRegionModel {
    static readonly type = REGION_SETUP_STATE_TYPE_SET;
    constructor(
      public selectedRegionIds: string [],
    ) {}
}
export class RemoveSelectedRegionIds {
    static readonly type = REGION_SETUP_STATE_TYPE_SET_REMOVE;
}
@State<RegionModel>({
    name: REGION_SETUP_STATE,
    defaults: {
        selectedRegionIds: []
    }
})

@Injectable()
export class RegionState {
    @Selector()
    static getSelectedRegionIds(state: RegionModel) {
        return state.selectedRegionIds;
    }

    @Action(SetRegionModel)
    SetRegionModel(
        { patchState }: StateContext<RegionModel>,
        {
            selectedRegionIds,
        }: SetRegionModel,
    ): void {
        patchState({
            selectedRegionIds : selectedRegionIds
        });
    }
    @Action(RemoveSelectedRegionIds)
    RemoveSelectedRegionIds({
        setState,
    }: StateContext<RegionModel>): void {
        setState({
            selectedRegionIds:[]
        });
    }
}