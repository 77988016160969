<div class="sidebar" [class.shrink]="isShrunk">    
  <ul>
    <li>
      <span (click)="toggleSidebar()" class="double-arrows-icon" [ngClass]="isShrunk ? 'double-arrows-collapse-icon' : 'double-arrows-expand-icon'">
      </span>
    </li>      
    <li *ngFor="let item of menuItems" [class.active]="item.active" [class.shrunk]="isShrunk">
      <div (click)="toggleSubmenu(item)" *ngIf="item.submenu" class="menu-item">
        <mat-icon class="menu-icon" [ngClass]="item.icon"></mat-icon>
        <span class="menu-label" *ngIf="!isShrunk">{{ item.label | translate }}</span>
        <mat-icon *ngIf="!isShrunk" class="submenu-icon">{{ item.active ? 'expand_less' : 'expand_more' }}</mat-icon>
      </div>
      <a *ngIf="!item.submenu" [routerLink]="item.route" routerLinkActive="active-link" (click)="activateLink(item)" class="menu-item">
        <mat-icon class="menu-icon" [ngClass]="item.icon"></mat-icon>
        <span class="menu-label" *ngIf="!isShrunk">{{ item.label | translate }}</span>
      </a>
      <div *ngIf="isShrunk && item.submenu" class="menu-collapsed" (click)="toggleSubmenu(item)">
        <div class="menu-collapsed-text">
          <span class="menu-label">{{ item.label | translate }}</span>
          <mat-icon class="submenu-icon shrink-mat-icon-seize" style="margin-right: -15px;">arrow_forward_ios</mat-icon>
        </div>
      </div>
      <div *ngIf="isShrunk && !item.submenu" class="menu-collapsed">
        <span class="menu-label">{{ item.label | translate }}</span>
      </div>
      <ul *ngIf="item.submenu && item.active && !isShrunk" class="submenu">
        <li *ngFor="let subItem of item.submenu" [class.sub-active]="subItem.active">
          <a [routerLink]="subItem.route" routerLinkActive="active-link" (click)="activateSubLink(subItem, item)" class="submenu-item">{{ subItem.label | translate }}</a>
        </li>
      </ul>
    </li>
  </ul>
  
  <!-- Bottom section for 'setup' with nested submenu -->
  <div class="bottom-section">
    <ul style="width: 100%;">
      <li [class.active]="setup.active" [class.shrunk]="isShrunk">
        <!-- Menu item -->
        <div (click)="toggleSetupSubmenu()" class="menu-item setup-menu">
          <mat-icon class="menu-icon setupIcon"></mat-icon>
          <span class="menu-label" *ngIf="!isShrunk">{{ setup.label | translate }}</span>
          <mat-icon *ngIf="!isShrunk" class="submenu-icon">{{ setup.active ? 'expand_more' : 'expand_less' }}</mat-icon>
        </div>
  
        <!-- Collapsed setup menu -->
        <div *ngIf="isShrunk" class="menu-collapsed setup-menu" (click)="toggleSetupSubmenu()">
          <div class="menu-collapsed-text">
            <span class="menu-label">{{ setup.label | translate }}</span>
            <mat-icon class="submenu-icon shrink-mat-icon-seize">arrow_forward_ios</mat-icon>
          </div>
        </div>
      </li>
    </ul>
  </div>
  
 <!-- First-level submenu for 'setup' -->
<div class="first-level-submenu">
  <ul *ngIf="setup.submenu && setup.active && !isShrunk" class="submenu">
    <li *ngFor="let subItem of setup.submenu" >
      <!-- First-level subitem -->
      <div style="display: flex; margin-top: 10px;" (click)="activateSubLink(subItem, setup)">
        <a [routerLink]="subItem.route" routerLinkActive="active-link"  class="submenu-item">
          {{ subItem.label | translate }}
        </a>
        <mat-icon style="margin-top: 15px; font-size: 15px;" *ngIf="!isShrunk">{{ subItem.active ? 'expand_less' : 'expand_more' }}</mat-icon>
      </div>
      
      <!-- Nested submenu directly under its subitem -->
      <ul *ngIf="subItem.submenu && subItem.active" class="nested-submenu">
        <li *ngFor="let nestedSubItem of subItem.submenu" [class.sub-active]="nestedSubItem.active">
          <a [routerLink]="nestedSubItem.route" routerLinkActive="active-link" (click)="activateNestedSubLink(nestedSubItem, subItem)" class="submenu-item" style="margin-left: 10px;">
            {{ nestedSubItem.label | translate }}
          </a>
        </li>
      </ul>
    </li>
  </ul>
</div>
  
</div>
