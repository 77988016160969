import { Injectable } from '@angular/core';
import {
  CreateSurveyOverview,
  SurveyOverview,
  UpdateSurveyOverview,
} from '../models/survey-overview.module';
import { BaseAWSRequestService } from '../../shared/service/base-services/base-aws-request.service';
import {
  SurveyConnection,
  SurveyFilterInput,
  GetAllSurveyQueryVariables,
  SurveyCreateInput,
  CreateSurveyMutationVariables,
  CreateSurveyMutation,
  SurveyUpdateInput,
  UpdateSurveyMutation,
  UpdateSurveyMutationVariables,
  Survey,
  GetSurveyByNameQueryVariables,
  GetSurveyByNameQuery,
} from '../../../awsAppSync/API';

import { getAllSurvey, getSurveyByName } from '../../../awsAppSync/graphql/queries';

import {
  CREATE_SURVEY_ERROR,
  CREATE_SURVEY_SUCCESS,
  ERROR,
  ERROR_FETCHING_SURVEY_OVERVIEW,
  FAILED_CREATE_SURVEY,
  REDIRECT_SURVEY_OVERVIEW,
  SUCCESS,
  TOASTER_SUCCESS,
  UPDATE_SURVEY_SUCCESS,
} from '../../shared/constants';
import { Assets } from '../models/assets';
import { createSurvey, updateSurvey } from '../../../awsAppSync/graphql/mutations';
import { Router } from '@angular/router';
import { CustomToastrService } from '../../shared/ngx-toastr/custom-toastr.service';

@Injectable({
  providedIn: 'root',
})
export class SurveyOverviewService {
  private storageKey = 'surveyData';
  constructor(
    private awsService: BaseAWSRequestService,
    private router: Router,
    private toastr: CustomToastrService,
  ) {}
  generateRandomData(count: number): SurveyOverview[] {
    // Retrieve existing data from local storage
    const storedData = localStorage.getItem(this.storageKey);
    let existingData: SurveyOverview[] = [];

    if (storedData) {
      // If data exists in local storage, parse it
      existingData = JSON.parse(storedData);
    }

    // Generate new random data
    const surveyNames = ['Survey Name 1', 'Survey Name 2', 'Survey Name 3'];
    const surveyTypes = [
      '2021 Annual Survey',
      '2022 Mid-Year Survey',
      '2023 End-Year Survey',
    ];
    const priorities = ['High', 'Medium', 'Low'];
    const statuses = [
      'Inspection Assigned',
      'Inspection Underway',
      'Inspection Verified',
      'Inspection Review',
    ];
    const collectionTypes = ['Compliance', 'Exception', 'Maintenance'];
    const technicians = ['Tiger Woods', 'John Doe', 'Jane Smith'];
    const supervisors = ['Lorem ipsum', 'Dolor sit', 'Amet consectetur'];
    const descriptions = [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris',
    ];

    // Calculate how many new entries need to be generated
    const newEntriesCount = count - existingData.length;

    for (let i = 0; i < newEntriesCount; i++) {
      const randomSurveyName =
        surveyNames[Math.floor(Math.random() * surveyNames.length)];
      const randomSurveyType =
        surveyTypes[Math.floor(Math.random() * surveyTypes.length)];
      const randomPriority =
        priorities[Math.floor(Math.random() * priorities.length)];
      const randomStatus =
        statuses[Math.floor(Math.random() * statuses.length)];
      const randomDate = new Date(2023, 0, 1);
      const randomCollectionType =
        collectionTypes[Math.floor(Math.random() * collectionTypes.length)];
      const randomTechnician =
        technicians[Math.floor(Math.random() * technicians.length)];
      const randomSupervisor =
        supervisors[Math.floor(Math.random() * supervisors.length)];
      const randomDescription =
        descriptions[Math.floor(Math.random() * descriptions.length)];

      existingData.push({
        id: (existingData.length + 1).toString(),
        surveyName: randomSurveyName,
        surveyType: randomSurveyType,
        surveyPriority: randomPriority,
        surveyStatus: randomStatus,
        dueDate: randomDate,
        dataCollectionType: randomCollectionType,
        technician: randomTechnician,
        supervisor: randomSupervisor,
        dateOfCompletion: randomDate,
        surveyDescription: randomDescription,
      });
    }

    // Save the combined data back to local storage
    localStorage.setItem(this.storageKey, JSON.stringify(existingData));

    return existingData;
  }
  

  async addSurveyEntry(
    newEntry: CreateSurveyOverview,
    assets: Assets[],
  ): Promise<void> {
    const dueDate = new Date(newEntry.dueDate);
    dueDate.setHours(0, 0, 0, 0);
    const inputObject: SurveyCreateInput = {
      primaryCompanyId: newEntry.primaryCompanyId,
      surveyName: newEntry.surveyName,
      surveyType: newEntry.surveyType,
      surveyPriority: newEntry.surveyPriority,
      dueDate: dueDate.toISOString(),
      dataCollectionType: newEntry.dataCollectionType,
      surveyDescription: newEntry.surveyDescription,
      assignedTo: newEntry.assignedTo,
      troubleShooting: newEntry.troubleshooting,
      surveyAssociatedAssetInput: assets.map((asset) => ({
        assetId: asset.id ?? '',
        level1ValueId: asset.level1Id ?? '',
        level2ValueId: asset.level2Id ?? '',
        level3ValueId: asset.level3Id ?? '',
        order: asset.order ?? null,
      })),
    };
    const responce = await this.createSurvey(inputObject);
    if (responce?.createSurvey?.status?.toLocaleLowerCase() == SUCCESS) {
      this.toastr.showSuccess(CREATE_SURVEY_SUCCESS, TOASTER_SUCCESS);
      this.router.navigate([REDIRECT_SURVEY_OVERVIEW]);
    } else {
      this.toastr.showError(FAILED_CREATE_SURVEY, ERROR);
    }
  }

  async createSurveyUpdateInput(
    newEntry: UpdateSurveyOverview,
    assets: Assets[],
  ): Promise<void> {
    const dueDate = new Date(newEntry.dueDate);
    dueDate.setHours(0, 0, 0, 0);
    const inputObject: SurveyUpdateInput = {
      id: newEntry.surveyId,
      pk: newEntry.surveyPk,
      sk: newEntry.surveySk,
      surveyName: newEntry.surveyName,
      surveyType: newEntry.surveyType,
      surveyPriority: newEntry.surveyPriority,
      dueDate: dueDate.toISOString(),
      dataCollectionType: newEntry.dataCollectionType,
      surveyDescription: newEntry.surveyDescription,
      assignedTo: newEntry.assignedTo,
      troubleShooting: newEntry.troubleshooting,
      surveyAssociatedAssetInput: assets.map((asset) => ({
        assetId: asset.id ?? '',
        level1ValueId: asset.level1Id ?? '',
        level2ValueId: asset.level2Id ?? '',
        level3ValueId: asset.level3Id ?? '',
        order: asset.order ?? null,
      })),
    };
    const responce = await this.updateSurvey(inputObject);
    if (responce?.updateSurvey?.status?.toLocaleLowerCase() == SUCCESS) {
      this.toastr.showSuccess(UPDATE_SURVEY_SUCCESS, TOASTER_SUCCESS);
      this.router.navigate([REDIRECT_SURVEY_OVERVIEW]);
    } else {
      this.toastr.showError(FAILED_CREATE_SURVEY, ERROR);
    }
  }

  public async getAllSurvey(
    input: SurveyFilterInput,
  ): Promise<SurveyConnection | null> {
    const query = getAllSurvey; // Ensure this is your GraphQL query string or document
    const variables: GetAllSurveyQueryVariables = {
      input: input,
    };

    try {
      const result = await this.awsService.executeGetQuery<{
        getAllSurvey: SurveyConnection;
      }>(query, variables);
      return result.getAllSurvey || null;
    } catch (error) {
      console.error(ERROR_FETCHING_SURVEY_OVERVIEW, error);
      return null;
    }
  }

  public async createSurvey(
    input: SurveyCreateInput,
  ): Promise<CreateSurveyMutation | null> {
    try {
      const variables: CreateSurveyMutationVariables = { input };
      const response =
        await this.awsService.executeMutation<CreateSurveyMutation>(
          createSurvey,
          variables,
        );
      return response || null;
    } catch (error) {
      console.error(CREATE_SURVEY_ERROR, error);
      return null;
    }
  }

  public async updateSurvey(
    input: SurveyUpdateInput,
  ): Promise<UpdateSurveyMutation | null> {
    try {
      const variables: UpdateSurveyMutationVariables = { input };
      const response =
        await this.awsService.executeMutation<UpdateSurveyMutation>(
          updateSurvey,
          variables,
        );
      return response || null;
    } catch (error) {
      console.error(CREATE_SURVEY_ERROR, error);
      return null;
    }
  }

  public async getSurveyName(
    primaryCompanyId: string, surveyName: string,
  ): Promise<Survey | null>{
    try {
      const variables: GetSurveyByNameQueryVariables = { primaryCompanyId, surveyName};
      const query = getSurveyByName;
      const response =
        await this.awsService.executeGetQuery<GetSurveyByNameQuery>(query ,variables);
      return response.getSurveyByName || null;
    } catch (error) {
      console.error("Error Fetching Survey", error);
      return null;
    }
  }
}
