<div class="double-arrows-wrapper">
    <span class="double-arrows-icon"
        [ngClass]="isExpand ? 'double-arrows-expand-icon' : 'double-arrows-collapse-icon'"></span>
</div>

<div class="frame-group">
    <div class="double-arrows-wrapper">
        <div [ngClass]="isExpand ? 'action-tiles-expand' : 'action-tiles'">
            <div class="sidebar" [class.create-22-parent]="isExpand">
                <span class="left-bar-icon complianceIcon"></span>
                <div class="label">
                    <div class="compliance">Compliance</div>
                    <span class="expand-icon"></span>
                </div>
            </div>
        </div>
        <div [ngClass]="isExpand ? 'action-tiles-expand' : 'action-tiles'">
            <div [ngClass]="{'create-22-parent': isExpand}">
                <span class="left-bar-icon reportsIcon"></span>
                <div class="label">
                    <div class="compliance">Reports</div>
                </div>
            </div>
        </div>
        <div [ngClass]="isExpand ? 'action-tiles-expand' : 'action-tiles'">
            <div [ngClass]="{'create-22-parent': isExpand}">
                <span class="left-bar-icon Assets assetsIcon"></span>
                <div class="label">
                    <div class="compliance">Assets</div>
                    <span class="expand-icon"></span>
                </div>
            </div>
        </div>
        <div [ngClass]="isExpand ? 'action-tiles-expand' : 'action-tiles'">
            <div [ngClass]="{'create-22-parent': isExpand}">
                <span class="left-bar-icon surveyIcon"></span>
                <div class="label">
                    <div class="compliance">Surveys</div>
                    <span class="expand-icon"></span>
                </div>
            </div>
        </div>
    </div>
    <div [ngClass]="isExpand ? 'action-tiles-expand' : 'action-tiles'">
        <div [ngClass]="{'create-22-parent': isExpand}">
            <span class="left-bar-icon setupIcon"></span>
            <div class="label4">
                <div class="setup" style="color: #abccff;">Setup</div>
                <span class="expand-icon"></span>
            </div>
        </div>
    </div>
</div>