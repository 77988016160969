import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { ASSETTYPE_SETUP_STATE, ASSETTYPE_SETUP_STATE_TYPE_SET, ASSETTYPE_SETUP_STATE_TYPE_SET_REMOVE } from "../../shared/constants";

export interface AssetTypeModel {
    selectedAssetTypeIds: string [];
}
export class SetAssetTypeModel {
    static readonly type = ASSETTYPE_SETUP_STATE_TYPE_SET;
    constructor(
      public selectedAssetTypeIds: string [],
    ) {}
}
export class RemoveSelectedAssetTypeIds {
    static readonly type = ASSETTYPE_SETUP_STATE_TYPE_SET_REMOVE;
}
@State<AssetTypeModel>({
    name: ASSETTYPE_SETUP_STATE,
    defaults: {
        selectedAssetTypeIds: []
    }
})

@Injectable()
export class AssetTypeState {
    @Selector()
    static getSelectedAssetTypeIds(state: AssetTypeModel) {
        return state.selectedAssetTypeIds;
    }

    @Action(SetAssetTypeModel)
    SetAssetTypeModel(
        { patchState }: StateContext<AssetTypeModel>,
        {
            selectedAssetTypeIds,
        }: SetAssetTypeModel,
    ): void {
        patchState({
            selectedAssetTypeIds : selectedAssetTypeIds
        });
    }
    @Action(RemoveSelectedAssetTypeIds)
    RemoveSelectedAssetTypeIds({
        setState,
    }: StateContext<AssetTypeModel>): void {
        setState({
            selectedAssetTypeIds:[]
        });
    }
}