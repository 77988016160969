import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-survey-route-edit-modal',
  templateUrl: './survey-route-edit-modal.component.html',
  styleUrl: './survey-route-edit-modal.component.css'
})
export class SurveyRouteEditModalComponent {

  Proceed():void {
    alert('');
  }

  
  constructor(
    private dialogRef: MatDialogRef<SurveyRouteEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { routeName: string,isSurveyRouteFlag?:boolean }, private router: Router
  ) {
    // Update the label with the passed route name
    this.options[0].label = `Update Existing Survey Route - ${data.routeName}`;
    
  }

  selectedType = '';
  options = [
    { id: 1, label: 'Update existing Survey Route - <Route Name 1>', selected: false },
    { id: 2, label: 'Save as New Survey Route', selected: false },
    { id: 3, label: 'Proceed without Creating Survey Route', selected: false },
  ];

  selection(value: string) {
    this.selectedType = value;
    if(this.selectedType === '1') {
      this.dialogRef.close(1);
    }
    if(this.selectedType === '2') {
      this.dialogRef.close(2);
    }
    if(this.selectedType === '3') {
      this.dialogRef.close(3);
    }
  }

  close(): void {
    this.dialogRef.close(0);
  }

  proceed(): void {
    // Pass the selected option to the caller
    const selectedOption = this.options.find(option => option.selected);
    this.dialogRef.close(selectedOption);
  }

  isAnyCheckboxSelected = false;

  onCheckboxChange(selectedOption: any): void {
    // Uncheck all checkboxes except the selected one
    this.options.forEach(option => {
      option.selected = option === selectedOption;
    });

    // Update the isAnyCheckboxSelected flag
    this.isAnyCheckboxSelected = selectedOption.selected;
  }
}

