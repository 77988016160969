import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CANCEL_BTN_TEXT, CONFIRM, DELETE_ASSEST_METHOD } from '../constants';
import { AssetSetupService } from '../../core/services/asset-setup.service';
import { PopupDialogService } from './popup-dialog.service';

@Component({
  selector: 'app-popup-dialog',
  templateUrl: './popup-dialog.component.html',
  styleUrl: './popup-dialog.component.css',
})
export class PopupDialogComponent {
  constructor(
    @Inject(AssetSetupService) public assetSetupService: AssetSetupService,
    public dialogRef: MatDialogRef<PopupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private popupDialogService: PopupDialogService,
  ) {
    this.dialogRef.backdropClick().subscribe(() => {
      this.onCancel();
    });
  }

  onClose(): void {
    this.dialogRef.close(this.data.cancelButtonText);
  }
  onConfirm() {
    this.popupDialogService.getButtonClicked(CONFIRM);
    if (this.data.isConfirmEvent) {
      const methodName = this.data.confirmMethod;
      if (methodName === DELETE_ASSEST_METHOD) {
        //this.assetSetupService.deleteAssetOnConfirmClick();
      }
    }
    this.dialogRef.close(this.data.buttonText);
  }
  onCancel() {
    this.dialogRef.close(this.data.cancelButtonText);
    this.popupDialogService.getButtonClicked(CANCEL_BTN_TEXT);
  }
}
