import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

interface INotification {
  avatar: string;
  name: string;
  fileName: string;
  fileSize: string;
  timestamp: string;
  isNew: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notificationsSubject = new BehaviorSubject<INotification[]>([]);
  notifications$ = this.notificationsSubject.asObservable();

  private notificationSubject = new Subject<boolean>();
  notificationState = this.notificationSubject.asObservable();

  addNotification(notification: INotification) {
    const currentNotifications = this.notificationsSubject.value;
    this.notificationsSubject.next([...currentNotifications, notification]);
  }

  setNotificationState(state: boolean) {
    this.notificationSubject.next(state);
  }

  markAllAsRead(): void {
    const notifications = this.notificationsSubject.value.map(notification => ({
      ...notification,
      isNew: false
    }));
    this.notificationsSubject.next(notifications);
  }
  
}