import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { SEGMENT_SETUP_STATE, SEGMENT_SETUP_STATE_TYPE_SET, SEGMENT_SETUP_STATE_TYPE_SET_REMOVE } from "../../shared/constants";

export interface SegmentModel {
    selectedSegmentIds: string [];
}
export class SetSegmentModel {
    static readonly type = SEGMENT_SETUP_STATE_TYPE_SET;
    constructor(
      public selectedSegmentIds: string [],
    ) {}
}
export class RemoveSelectedSegmentIds {
    static readonly type = SEGMENT_SETUP_STATE_TYPE_SET_REMOVE;
}
@State<SegmentModel>({
    name: SEGMENT_SETUP_STATE,
    defaults: {
        selectedSegmentIds: []
    }
})

@Injectable()
export class SegmentState {
    @Selector()
    static getSelectedSegmentIds(state: SegmentModel) {
        return state.selectedSegmentIds;
    }

    @Action(SetSegmentModel)
    SetSegmentModel(
        { patchState }: StateContext<SegmentModel>,
        {
            selectedSegmentIds,
        }: SetSegmentModel,
    ): void {
        patchState({
            selectedSegmentIds : selectedSegmentIds
        });
    }
    @Action(RemoveSelectedSegmentIds)
    RemoveSelectedSegmentIds({
        setState,
    }: StateContext<SegmentModel>): void {
        setState({
            selectedSegmentIds:[]
        });
    }
}