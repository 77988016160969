import { Injectable } from '@angular/core';
import { ReportManagement } from '../models/report-management.module';
import { AUDIT, PERFORMANCE } from '../../shared/constants';
@Injectable({
  providedIn: 'root',
})
export class ReportManagementService {
  reports: ReportManagement[] = [];
  reportsWithFilter: ReportManagement[] = [];

  getSpecificReportsData(reportType: string) {
    if (reportType === '') {
      this.reportsWithFilter = [];
    } else if (reportType === AUDIT) {
      this.reportsWithFilter = this.reports.filter(
        (x) => x.reportType === AUDIT,
      );
    } else if (reportType === PERFORMANCE) {
      this.reportsWithFilter = this.reports.filter(
        (x) => x.reportType === PERFORMANCE,
      );
    } else {
      this.reportsWithFilter = this.reports;
    }
    return this.reportsWithFilter;
  }
  getReportManagementData() {
    this.reports = [];
    const startDate = new Date(2023, 0, 1); // January 1, 2023
    const endDate = new Date(2024, 11, 31); // December 31, 2024
    for (let i = 0; i < 23; i++) {
      const createdDate = this.getRandomDate(startDate, endDate);
      const exportedDate = this.getRandomDate(startDate, endDate);
      this.reports.push({
        id:i.toString(),
        reportName: 'Pipe to Soil Test Station',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...',
        reportType: i % 2 === 0 ? 'Audit' : 'Performance',
        createdDate: createdDate,
        exportedDate: exportedDate,
        createdBy: 'Lorem ipsum',
        schedule: i % 2 === 0 ? true : false,
      });
    }
    return this.reports;
  }

  getRandomDate(start: Date, end: Date): Date {
    const startTime = start.getTime();
    const endTime = end.getTime();
    const randomTime = startTime + Math.random() * (endTime - startTime);

    const randomDate = new Date(randomTime);

    // Set time to 00:00:00 to remove the time part
    randomDate.setHours(0, 0, 0, 0);

    return randomDate;
  }
}
