import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  EMPTY_STRING,
  SURVEY_OVERVIEW_DATA_STATE,
  SURVEY_OVERVIEW_DATA_STATE_TYPE_REMOVE,
  SURVEY_OVERVIEW_DATA_STATE_TYPE_SET,
} from '../../shared/constants';
import { Injectable } from '@angular/core';
import { SurveyAssociatedAsset } from '../../../awsAppSync/API';

export interface SurveyOverviewDataStateModel {
  surveyID: string | null;
  surveyPK: string | null;
  surveySK: string | null;
  surveyName: string | null;
  surveyType: string | null;
  surveyAssociatedAssets: (SurveyAssociatedAsset | null)[] | null | undefined,
  surveyPriority: string | null;
  dataCollectionType: string | null;
  dueDate: Date | null;
  assignedTo: string | null;
  assignedName: string | null;
  troubleshooting: string | null;
  surveyDescription: string | null;
  isEdit: boolean | false;
}

export class SetSurveyOverviewData {
  static readonly type = SURVEY_OVERVIEW_DATA_STATE_TYPE_SET;
  constructor(
    public surveyID: string,
    public surveyPK: string,
    public surveySK: string,
    public surveyName: string,
    public surveyType: string,
    public surveyAssociatedAssets: (SurveyAssociatedAsset | null)[] | null | undefined,
    public surveyPriority: string,
    public dataCollectionType: string,
    public dueDate: Date | null,
    public assignedTo: string,
    public assignedName: string,
    public troubleshooting: string,
    public surveyDescription: string,
    public isEdit: boolean,
  ) {}
}

export class RemoveSurveyOverviewData {
  static readonly type = SURVEY_OVERVIEW_DATA_STATE_TYPE_REMOVE;
}

@State<SurveyOverviewDataStateModel>({
  name: SURVEY_OVERVIEW_DATA_STATE,
  defaults: {
    surveyID: null,
    surveyPK: null,
    surveySK: null,
    surveyName: null,
    surveyType: null,
    surveyAssociatedAssets: null,
    surveyPriority: null,
    dataCollectionType: null,
    dueDate: null,
    assignedTo: null,
    assignedName: null,
    troubleshooting: null,
    surveyDescription: null,
    isEdit: false,
  },
})
@Injectable()
export class SurveyOverviewDataState {
  @Selector()
  static getSurveyID(state: SurveyOverviewDataStateModel) {
    return state.surveyID;
  }

  @Selector()
  static getSurveyPK(state: SurveyOverviewDataStateModel) {
    return state.surveyPK;
  }

  @Selector()
  static getSurveySK(state: SurveyOverviewDataStateModel) {
    return state.surveySK;
  }

  @Selector()
  static getSurveyName(state: SurveyOverviewDataStateModel) {
    return state.surveyName;
  }

  @Selector()
  static getSurveyType(state: SurveyOverviewDataStateModel) {
    return state.surveyType;
  }

  @Selector()
  static getSurveyAssociatedAsset(state: SurveyOverviewDataStateModel) {
    return state.surveyAssociatedAssets;
  }

  @Selector()
  static getSurveyPriority(state: SurveyOverviewDataStateModel) {
    return state.surveyPriority;
  }

  @Selector()
  static getDataCollectionType(state: SurveyOverviewDataStateModel) {
    return state.dataCollectionType;
  }
  @Selector()
  static getDueDate(state: SurveyOverviewDataStateModel) {
    return state.dueDate;
  }
  @Selector()
  static getAssignedTo(state: SurveyOverviewDataStateModel) {
    return state.assignedTo;
  }

  @Selector()
  static getAssignedName(state: SurveyOverviewDataStateModel) {
    return state.assignedName;
  }

  @Selector()
  static getTroubleshooting(state: SurveyOverviewDataStateModel) {
    return state.troubleshooting;
  }

  @Selector()
  static getSurveyDescription(state: SurveyOverviewDataStateModel) {
    return state.surveyDescription;
  }

  @Selector()
  static getIsEdit(state: SurveyOverviewDataStateModel) {
    return state.isEdit;
  }


  //actions to do operation
  @Action(SetSurveyOverviewData)
  SetSurveyOverviewData(
    { patchState }: StateContext<SurveyOverviewDataStateModel>,
    {
      surveyID,
      surveyPK,
      surveySK,
      surveyName,
      surveyType,
      surveyAssociatedAssets,
      surveyPriority,
      dataCollectionType,
      dueDate,
      assignedTo,
      assignedName,
      troubleshooting,
      surveyDescription,
      isEdit,
    }: SetSurveyOverviewData,
  ): void {
    patchState({
      surveyID: surveyID,
      surveyPK: surveyPK,
      surveySK: surveySK,
      surveyName: surveyName,
      surveyType: surveyType,
      surveyAssociatedAssets: surveyAssociatedAssets,
      surveyPriority: surveyPriority,
      dataCollectionType: dataCollectionType,
      dueDate: dueDate,
      assignedTo: assignedTo,
      assignedName: assignedName,
      troubleshooting: troubleshooting,
      surveyDescription: surveyDescription,
      isEdit: isEdit,
    });
  }
  @Action(RemoveSurveyOverviewData)
  RemoveSurveyOverviewData({
    setState,
  }: StateContext<SurveyOverviewDataStateModel>): void {
    setState({
      surveyID: EMPTY_STRING,
      surveyPK: EMPTY_STRING,
      surveySK: EMPTY_STRING,
      surveyName: EMPTY_STRING,
      surveyType: EMPTY_STRING,
      surveyAssociatedAssets: null,
      surveyPriority: EMPTY_STRING,
      dataCollectionType: EMPTY_STRING,
      dueDate: null,
      assignedTo: EMPTY_STRING,
      assignedName: EMPTY_STRING,
      troubleshooting: EMPTY_STRING,
      surveyDescription: EMPTY_STRING,
      isEdit: false,
    });
  }
}
