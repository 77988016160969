import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { EMPTY_STRING } from '../../shared/constants';

export interface PrimaryCompanyModel {
    isEditable: boolean | false,
    id: string,
    entityType: string | null,
    createdBy: string | null,
    createdDate: string | null,
    updatedBy: string | null,
    updatedDate: string | null,
    isDeleted: boolean | null,
    primaryCompany: string,
    logoUrl: string | null,
    phoneNumber: string | null,
    officeNumber: string | null,
    country: string | null,
    state: string | null,
    zipCode: string | null,
    city: string | null,
    address: string | null,
    timeZone: string | null,
    setupStatus: number | null,
    totalSetup: number | null,
    timeZoneId:string |null,
}

export class SetPrimaryCompany {
    static readonly type = '[PrimaryCompany] Set';
    constructor(
        public isEditable: boolean,
        public id: string,
        public entityType: string,
        public createdBy: string,
        public createdDate: string,
        public updatedBy: string,
        public updatedDate: string,
        public isDeleted: boolean,
        public primaryCompany: string,
        public logoUrl: string,
        public phoneNumber: string,
        public officeNumber: string,
        public country: string,
        public state: string,
        public zipCode: string,
        public city: string,
        public address: string,
        public timeZone: string,
        public setupStatus: number,
        public totalSetup: number,
        public timeZoneId:string
    ) {}
}

export class RemovePrimaryCompany {
    static readonly type = '[PrimaryCompany] Remove';
}

@State<PrimaryCompanyModel>({
    name: 'PrimaryCompany',
    defaults: {
        isEditable: false,
        id: EMPTY_STRING,
        entityType: null,
        createdBy: null,
        createdDate: null,
        updatedBy: null,
        updatedDate: null,
        isDeleted: null,
        primaryCompany: EMPTY_STRING,
        logoUrl: null,
        phoneNumber: null,
        officeNumber: null,
        country: null,
        state: null,
        zipCode: null,
        city: null,
        address: null,
        timeZone: null,
        setupStatus: null,
        totalSetup: null,
        timeZoneId:null
    },
})

@Injectable()
export class PrimaryCompanyState {

    @Selector()
    static getPrimaryCompanyIsEditable(state: PrimaryCompanyModel) {
        return state.isEditable;
    }

    @Selector()
    static getPrimaryCompanyId(state: PrimaryCompanyModel) {
        return state.id;
    }

    @Selector()
    static getPrimaryCompanyEntityType(state: PrimaryCompanyModel) {
        return state.entityType;
    }

    @Selector()
    static getPrimaryCompanyCreatedBy(state: PrimaryCompanyModel) {
        return state.createdBy;
    }

    @Selector()
    static getPrimaryCompanyCreatedDate(state: PrimaryCompanyModel) {
        return state.createdDate;
    }

    @Selector()
    static getPrimaryCompanyUpdatedBy(state: PrimaryCompanyModel) {
        return state.updatedBy;
    }

    @Selector()
    static getPrimaryCompanyUpdatedDate(state: PrimaryCompanyModel) {
        return state.updatedDate;
    }

    @Selector()
    static getPrimaryCompanyIsDeleted(state: PrimaryCompanyModel) {
        return state.isDeleted;
    }

    @Selector()
    static getPrimaryCompany(state: PrimaryCompanyModel) {
        return state.primaryCompany;
    }

    @Selector()
    static getPrimaryCompanyLogoUrl(state: PrimaryCompanyModel) {
        return state.logoUrl;
    }

    @Selector()
    static getPrimaryCompanyPhoneNumber(state: PrimaryCompanyModel) {
        return state.phoneNumber;
    }

    @Selector()
    static getPrimaryCompanyOfficeNumber(state: PrimaryCompanyModel) {
        return state.officeNumber;
    }

    @Selector()
    static getPrimaryCompanyCountry(state: PrimaryCompanyModel) {
        return state.country;
    }

    @Selector()
    static getPrimaryCompanyState(state: PrimaryCompanyModel) {
        return state.state;
    }

    @Selector()
    static getPrimaryCompanyZipCode(state: PrimaryCompanyModel) {
        return state.zipCode;
    }

    @Selector()
    static getPrimaryCompanyCity(state: PrimaryCompanyModel) {
        return state.city;
    }

    @Selector()
    static getPrimaryCompanyAddress(state: PrimaryCompanyModel) {
        return state.address;
    }

    @Selector()
    static getPrimaryCompanyTimeZone(state: PrimaryCompanyModel) {
        return state.timeZone;
    }

    @Selector()
    static getPrimaryCompanySetupStatus(state: PrimaryCompanyModel) {
        return state.setupStatus;
    }

    @Selector()
    static getPrimaryCompanyTotalSetup(state: PrimaryCompanyModel) {
        return state.totalSetup;
    }
    @Selector()
    static getPrimaryCompanyTimeZoneId(state:PrimaryCompanyModel){
        return state.timeZoneId
    }

    @Action(SetPrimaryCompany)
    setPrimaryCompany(
        { patchState }: StateContext<PrimaryCompanyModel>,
        {
            isEditable,
            id,
            entityType,
            createdBy,
            createdDate,
            updatedBy,
            updatedDate,
            isDeleted,
            primaryCompany,
            logoUrl,
            phoneNumber,
            officeNumber,
            country,
            state,
            zipCode,
            city,
            address,
            timeZone,
            setupStatus,
            totalSetup,
            timeZoneId,
        }: SetPrimaryCompany,
    ): void {
        patchState({
            isEditable: isEditable,
            id: id,
            entityType: entityType,
            createdBy: createdBy,
            createdDate: createdDate,
            updatedBy: updatedBy,
            updatedDate: updatedDate,
            isDeleted: isDeleted,
            primaryCompany: primaryCompany,
            logoUrl: logoUrl,
            phoneNumber: phoneNumber,
            officeNumber: officeNumber,
            country: country,
            state: state,
            zipCode: zipCode,
            city: city,
            address: address,
            timeZone: timeZone,
            setupStatus: setupStatus,
            totalSetup: totalSetup,
            timeZoneId:timeZoneId
        });
    }

    @Action(RemovePrimaryCompany)
    removePrimaryCompany({
        setState,
    }: StateContext<PrimaryCompanyModel>): void {
        setState({
            isEditable: false,
            id: EMPTY_STRING,
            entityType: EMPTY_STRING,
            createdBy: EMPTY_STRING,
            createdDate: EMPTY_STRING,
            updatedBy: EMPTY_STRING,
            updatedDate: EMPTY_STRING,
            isDeleted: false,
            primaryCompany: EMPTY_STRING,
            logoUrl: EMPTY_STRING,
            phoneNumber: EMPTY_STRING,
            officeNumber: EMPTY_STRING,
            country: EMPTY_STRING,
            state: EMPTY_STRING,
            zipCode: EMPTY_STRING,
            city: EMPTY_STRING,
            address: EMPTY_STRING,
            timeZone: EMPTY_STRING,
            setupStatus: 0,
            totalSetup: 0,
            timeZoneId:EMPTY_STRING
        });
    }
}
