import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DEFAULT, LONG, ND, PAGINATION_ARRAY, RD, REPORTDETAILS, ST, TH, ZERO } from '../../shared/constants';
import { MatDialog } from '@angular/material/dialog';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { ExportModalComponent } from '../../shared/export-modal/export-modal.component';
import { SurveyOverviewService } from '../../core/services/survey-overview.service';

@Component({
  selector: 'app-report-detail',
  templateUrl: './report-detail.component.html',
  styleUrl: './report-detail.component.css'
})
export class ReportDetailComponent implements OnInit{
  @ViewChild('pdfExport', { static: false }) pdfExport!: PDFExportComponent;
  public gridData: any[] = [];
  public isLoading=true;
  originalData: any[] = [];
  public pageSize = 50;
  public currentPage = 1;
  public skip = ZERO;
  public rowId = ZERO;
  pageChanged = false;
  gridDataToExport: any[] = [];
  filteredData: any[] = [];
  getFilterDropdownValue = false;
  public _selectedColumns: string[] = [
    'surveyName',
    'surveyType',
    'surveyPriority',
    'surveyStatus',
    'dueDate',
    'dataCollectionType',
    'technician',
    'supervisor',
    'dateOfCompletion',
    'surveyDescription',
  ];
  public PAGINATION_ARRAY = PAGINATION_ARRAY;

  constructor(private surveyOverviewService: SurveyOverviewService, private cdr: ChangeDetectorRef, public dialog: MatDialog,) {
  }

  ngOnInit() {
    this.bindGridData();
  }

  async bindGridData() {
    this.gridData = this.surveyOverviewService
    .generateRandomData(500)
    .map((item) => ({
      ...item,
      formattedDueDate: this.formatDateString(item.dueDate),
      formattedDateOfCompletion: this.formatDateString(item.dateOfCompletion),
    })); // Generate 15 random rows
    this.originalData = [...this.gridData];
    this.isLoading=false
    this.cdr.detectChanges();
    this.loadData(this.gridData);
  }

  loadData(data: any[]) {
    this.originalData = data.map((item) => ({
      ...item,
      formattedDueDate: this.formatDate(new Date(item.dueDate)),
      formattedDateOfCompletion: this.formatDate(
        new Date(item.dateOfCompletion),
      ),
    }));
    this.gridData = [...this.originalData];
  }

  formatDate(date: Date): string {
    if (!date) return '';

    const day = date.getDate();
    const month = date.toLocaleString(DEFAULT, { month: LONG });
    const year = date.getFullYear();

    const daySuffix = this.getDaySuffix(day);

    return `${day}${daySuffix} ${month}, ${year}`;
  }

  formatDateString(dateString: string | Date | null): string | null {
    if (dateString !== null) {
      const date = new Date(dateString);

      const day = date.getDate();
      const daySuffix = this.getDaySuffix(day);

      const options: Intl.DateTimeFormatOptions = {
        month: 'long',
        year: 'numeric',
      };
      const formattedDate = date.toLocaleDateString('en-US', options);

      return `${day}${daySuffix} ${formattedDate}`;
    }
    return null;
  }

  getDaySuffix(day: number): string {
    if (day > 3 && day < 21) return TH;
    switch (day % 10) {
      case 1:
        return ST;
      case 2:
        return ND;
      case 3:
        return RD;
      default:
        return TH;
    }
  }

  getPriorityClass(priority: string): string {
    switch (priority.toLowerCase()) {
      case 'high':
        return 'high-priority';
      case 'medium':
        return 'medium-priority';
      case 'low':
        return 'low-priority';
      default:
        return '';
    }
  }

  openDialog(): void {
    const filteredData = this.gridData.map(() => {
      const filteredItem: Record<string, any> = {};
      return filteredItem;
    });

    this.dialog.open(ExportModalComponent, {
      data: {
        selectedColumnData: filteredData,
        selectedColumn: this._selectedColumns,
        pdfExport: this.pdfExport,
        pageSize: this.pageSize,
        gridData: this.originalData,
        allData: this.originalData,
        flag: REPORTDETAILS,
      },
    });
    this.pageChanged = false;
  }

  // page change event handler
  public onPageChange(event: any): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.currentPage = this.skip / this.pageSize + 1;
    this.updatePagedData();
    this.pageChanged = true;
  }

  // Update the grid data for the current page
  private updatePagedData(): void {
    const startIndex = this.skip;
    const endIndex = startIndex + this.pageSize;
    this.gridDataToExport = this.originalData.slice(startIndex, endIndex);
    this.cdr.markForCheck(); // Notify Angular to check for changes
  }
}
