import {
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Assets } from '../../core/models/assets';
import {
  API_ERROR_MSG,
  CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
  CANCEL_TEMPLATE_HEADING,
  CANCEL_TEMPLATE_MSG,
  CLOSE,
  DEFAULTPAGESIZE,
  EMPTY_STRING,
  ERROR,
  FAILED,
  FAILED_ASSET_FETCH,
  FAILED_ASSET_LOAD,
  FAILED_ASSET_UPDATE,
  FETCHED_ASSEST_UNDEFINED,
  NO,
  REDIRECT_CREATE_SURVEY,
  REDIRECT_SURVEY_OVERVIEW,
  SUCCESS,
  ZERO,
} from '../../shared/constants';
import { AssetService } from '../../core/services/asset.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MapService } from '../../core/services/map.service';
import { PopupDialogService } from '../../shared/popup-dialog/popup-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { SurveyOverviewService } from '../../core/services/survey-overview.service';
import {
  CreateSurveyOverview,
  UpdateSurveyOverview,
} from '../../core/models/survey-overview.module';
import { Store } from '@ngxs/store';
import { SurveyRouteState } from '../../core/store/filter-serveyrotes';
import { Observable } from 'rxjs';
import { SurveyRouteService } from '../../core/services/survey-route.service';
import { SurveyRouteEditModalComponent } from '../../shared/survey-route-edit-modal/survey-route-edit-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { AddSurveyRouteModalComponent } from '../add-survey-route-modal/add-survey-route-modal.component';
import { Router } from '@angular/router';
import {
  FilterInput,
  HierarchyFilterInput,
  KendoGridFilters,
  SurveyAssociatedAsset,
  SurveyRouteCreateInput,
  SurveyRouteFilterInput,
  SurveyRouteUpdateInput,
} from '../../../awsAppSync/API';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import { AssetTypeState } from '../../core/store/filter-assettype';
import { Level3State } from '../../core/store/filter-level3.state';
import { SegmentState } from '../../core/store/filter-segments';
import { SegmentTypeState } from '../../core/store/filter-segmenttype.state';
import { State } from '@progress/kendo-data-query';
import {
  RemoveSurveyOverviewData,
  SurveyOverviewDataState,
} from '../../core/store/survey-overview.state';
import { HeirarchyFilterServiceService } from '../../shared/service/heirarchy-filter-service/heirarchy-filter-service.service';
import { CustomToastrService } from '../../shared/ngx-toastr/custom-toastr.service';
interface GoogleMaps {
  maps: any;
  Map: any;
  drawing: any;
  ControlPosition: any;
  OverlayType: any;
  event: any;
}

declare let google: GoogleMaps;
@Component({
  selector: 'app-asset-selection',
  templateUrl: './asset-selection.component.html',
  styleUrl: './asset-selection.component.css',
})
export class AssetSelectionComponent implements OnInit, OnDestroy {
  @ViewChild('map', { static: true }) mapElementRef!: ElementRef;
  @ViewChild('infoCard') infoCardElement!: ElementRef;
  currentStep = 2;
  center = { lat: 48.88478197, lng: -107.41672385 };
  map: any;
  private renderer = inject(Renderer2);
  assets: Assets[] = [];
  private markerMap = new Map<string, any>();
  drawingManager: any;
  private currentRectangle: any;
  selectedAsset: Assets | null = null;
  private selectedAssets = new Set<string>();
  displayOrderSelection = false;
  displayPreviewVisible = false;
  isOrderButtonEnabled = false;
  markerLabel = EMPTY_STRING;
  allSelected = false;
  originalAssetsafterOrder: Assets[] = [];
  storeSelectedSurveyRoute$: Observable<string[]>;
  selectedSurveyRoutes: string[] = [];
  surveyRouteData: any;
  assetLoadedFromSurveyRoute = false;
  surveyRouteAssets: Assets[] = [];
  assetsBeforeOrdering: Assets[] = [];
  isSubmitButtonDisabled = false;
  showHierarchicalFilter = true;
  title = 'SURVEY_TASK_PREVIEW.ASSET_SELECTION';
  // formData: any;
  filterModel: FilterInput = {};
  storePrimaryCompanyID$: Observable<string | null>;
  selectedprimaryComapnyId: string | null = '';
  storeSelectedLevel3$: Observable<string[]>;
  selectedLevel3: string[] = [];
  storeSegmentTypeSet$: Observable<string[]>;
  selectedSegmentType: string[] = [];
  storeSelectedSegments$: Observable<string[]>;
  selectedSegments: string[] = [];
  selectedSurveyRoute: string[] = [];
  storeSelectedAssetType$: Observable<string[]>;
  selectedAssetType: string[] = [];
  hierarchyFilterInput: HierarchyFilterInput[] = [];
  surveyRouteFilterInput: SurveyRouteFilterInput[] = [];
  public searchText = '';
  kendoGridFilters: KendoGridFilters = {};
  public pageSize = DEFAULTPAGESIZE;
  currentPage = ZERO;
  surveyNameVal: string | null = '';
  surveyTypeVal: string | null = '';
  surveyPriorityVal: string | null = '';
  surveyCollectionTypeVal: string | null = '';
  surveIsEditVal = false;
  surveyAssociatedAssetVal: (SurveyAssociatedAsset | null)[] | null | undefined;
  dueDateVal: Date = new Date();
  assignedToVal: string | null = '';
  assignedToName: string | null = '';
  troubleshootingVal: string | null = '';
  surveyDescriptionVal: string | null = '';
  current_Date = new Date();
  surveyIsEdit$: Observable<boolean>;
  surveyAssociatedAsset$: Observable<
    (SurveyAssociatedAsset | null)[] | null | undefined
  >;

  surveyName$: Observable<string | null>;
  surveyType$: Observable<string | null>;
  surveyPriority$: Observable<string | null>;
  dataCollectionType$: Observable<string | null>;
  dueDate$: Observable<Date | null>;
  assignedTo$: Observable<string | null>;
  assignedName$: Observable<string | null>;
  troubleshooting$: Observable<string | null>;
  surveyDescription$: Observable<string | null>;
  isLoading = false;
  isBackClick = false;
  surveyId$: Observable<string | null>;
  surveyPK$: Observable<string | null>;
  surveySK$: Observable<string | null>;
  storeSurveyId: string | null = EMPTY_STRING;
  storeSurveyPk: string | null = EMPTY_STRING;
  storeSurveySk: string | null = EMPTY_STRING;
  filterDataPresent = false;
  routeId = '';
  routeDescription = '';
  routeName = '';

  public state: State = {
    skip: 0,
    take: this.pageSize,
    group: [],
    filter: { filters: [], logic: 'and' },
    sort: [],
  };

  constructor(
    private assetService: AssetService,
    private mapService: MapService,
    private popupDialogService: PopupDialogService,
    private router: Router,
    private translate: TranslateService,
    private toastr: CustomToastrService,
    private surveyOverviewService: SurveyOverviewService,
    private store: Store,
    private surveyRouteService: SurveyRouteService,
    public dialog: MatDialog,
    private hirarchyFilter: HeirarchyFilterServiceService,
  ) {
    this.storeSelectedSurveyRoute$ = this.store.select(
      SurveyRouteState.getSelectedSurveyRouteIds,
    );

    this.storePrimaryCompanyID$ = this.store.select(
      AuthenticateUserState.getSyrcPrimaryCompanyId,
    );
    this.storeSelectedLevel3$ = this.store.select(
      Level3State.getSelectedLevel3Name,
    );
    this.storeSegmentTypeSet$ = this.store.select(
      SegmentTypeState.getSelectedSegmentType,
    );
    this.storeSelectedSegments$ = this.store.select(
      SegmentState.getSelectedSegmentIds,
    );
    this.storeSelectedSurveyRoute$ = this.store.select(
      SurveyRouteState.getSelectedSurveyRouteIds,
    );
    this.storeSelectedAssetType$ = this.store.select(
      AssetTypeState.getSelectedAssetTypeIds,
    );

    this.surveyId$ = this.store.select(SurveyOverviewDataState.getSurveyID);
    this.surveyPK$ = this.store.select(SurveyOverviewDataState.getSurveyPK);
    this.surveySK$ = this.store.select(SurveyOverviewDataState.getSurveySK);
    this.surveyName$ = this.store.select(SurveyOverviewDataState.getSurveyName);
    this.surveyType$ = this.store.select(SurveyOverviewDataState.getSurveyType);
    this.surveyIsEdit$ = this.store.select(SurveyOverviewDataState.getIsEdit);
    this.surveyAssociatedAsset$ = this.store.select(
      SurveyOverviewDataState.getSurveyAssociatedAsset,
    );
    this.surveyPriority$ = this.store.select(
      SurveyOverviewDataState.getSurveyPriority,
    );
    this.dataCollectionType$ = this.store.select(
      SurveyOverviewDataState.getDataCollectionType,
    );
    this.dueDate$ = this.store.select(SurveyOverviewDataState.getDueDate);
    this.assignedTo$ = this.store.select(SurveyOverviewDataState.getAssignedTo);
    this.assignedName$ = this.store.select(
      SurveyOverviewDataState.getAssignedName,
    );
    this.troubleshooting$ = this.store.select(
      SurveyOverviewDataState.getTroubleshooting,
    );
    this.surveyDescription$ = this.store.select(
      SurveyOverviewDataState.getSurveyDescription,
    );
  }
  ngOnInit(): void {
    this.isLoading = true;
    this.updatePageTitle(this.title);
    this.checkPreFilledData();

    this.storeSelectedSurveyRoute$.subscribe((Surveyroute) => {
      if (!this.isBackClick) {
        this.selectedSurveyRoutes = Surveyroute;
        this.storePrimaryCompanyID$.subscribe((id) => {
          this.selectedprimaryComapnyId = '';
          this.selectedprimaryComapnyId = id;
        });
        if (this.selectedSurveyRoutes.length > 0) {
          // this.surveyRouteService
          //   .getSurveyRouteById(this.selectedSurveyRoutes[0])
          //   .subscribe({
          //     next: (route) => {
          //       this.surveyRouteData = route;
          //       if (this.surveyRouteData) {
          //         this.assets = [];
          //         this.surveyRouteAssets = this.surveyRouteData.assets;
          //         this.assets = this.surveyRouteData.assets.map(
          //           (asset: any) => ({
          //             ...asset,
          //           }),
          //         );
          //         this.assets.forEach((asset) => {
          //           asset.selected = true;
          //         });
          //         this.assetLoadedFromSurveyRoute = true;
          //         this.clearMarkers();
          //         this.map = null;
          //         this.loadMap();
          //         this.isOrderButtonEnabled = true;
          //         this.isSelectAllCheck();
          //       }
          //     },
          //     error: (err) => {
          //       console.error('Error fetching survey route:', err);
          //     },
          //   });
        } else {
          this.clearMarkers();
          // this.assets = [];
          this.map = null;
          this.loadAssets();
          this.assetLoadedFromSurveyRoute = false;
          this.isSelectAllCheck();
        }
      }
    });

    this.storeSelectedSurveyRoute$.subscribe((Surveyroute) => {
      this.surveyRouteFilterInput = [];
      this.selectedSurveyRoute = Surveyroute;
      if (
        this.selectedSurveyRoute.length > 0 &&
        this.selectedprimaryComapnyId
      ) {
        this.fetchSurveyRouteValues(this.selectedprimaryComapnyId);
      }
    });

    this.storeSelectedLevel3$.subscribe((level3name) => {
      this.selectedLevel3 = level3name;
      this.hierarchyFilterInput = [];
      if (this.selectedprimaryComapnyId && this.selectedLevel3.length > 0) {
        this.fetchLevel3Values(this.selectedprimaryComapnyId);
      }
    });

    this.hirarchyFilter.isSurveyRouteClearFilterClick.subscribe((value) => {
      this.onClearFilterPressed(value);
    });

    this.hirarchyFilter.isClearFilterClick.subscribe((value) => {
      this.onClearFilterPressed(value);
    });
  }

  onClearFilterPressed(value: boolean) {
    this.updateFilterModel(value);
  }

  checkPreFilledData() {
    this.surveyName$.subscribe((surveyName: string | null) => {
      this.surveyNameVal = surveyName;
    });

    this.surveyType$.subscribe((surveyType: string | null) => {
      this.surveyTypeVal = surveyType;
    });

    this.surveyPriority$.subscribe((surveyPriority: string | null) => {
      this.surveyPriorityVal = surveyPriority;
    });

    this.dataCollectionType$.subscribe(
      (surveyCollectionType: string | null) => {
        this.surveyCollectionTypeVal = surveyCollectionType;
      },
    );

    this.dueDate$.subscribe((dueDateVal: Date | null) => {
      this.dueDateVal = new Date(dueDateVal ?? '');
    });

    this.assignedTo$.subscribe((assignedTo: string | null) => {
      this.assignedToVal = assignedTo;
    });
    this.assignedName$.subscribe((assignedName: string | null) => {
      this.assignedToName = assignedName;
    });

    this.troubleshooting$.subscribe((troubleshooting: string | null) => {
      this.troubleshootingVal = troubleshooting;
    });
    this.surveyDescription$.subscribe((surveyDescription: string | null) => {
      this.surveyDescriptionVal = surveyDescription;
    });
    this.surveyIsEdit$.subscribe((isEdit: boolean) => {
      this.surveIsEditVal = isEdit;
    });
    this.surveyAssociatedAsset$.subscribe(
      (
        surveyAssociatedAssets:
          | (SurveyAssociatedAsset | null)[]
          | null
          | undefined,
      ) => {
        this.surveyAssociatedAssetVal = surveyAssociatedAssets;
      },
    );
    this.surveyId$.subscribe((id) => {
      this.storeSurveyId = id;
    });
    this.surveyPK$.subscribe((pk) => {
      this.storeSurveyPk = pk;
    });
    this.surveySK$.subscribe((sk) => {
      this.storeSurveySk = sk;
    });
  }

  ngOnDestroy(): void {
    this.resetAssetData();
  }

  filterSearchButtonClicked(): void {
    this.loadAssets();
  }

  surveyRouteFilterApplied(): void {
    this.assetLoadedFromSurveyRoute = true;
  }

  async fetchLevel3Values(primaryCompanyId: string) {
    const allLevel3Values: HierarchyFilterInput[] = [];
    const level3Values =
      await this.hirarchyFilter.getAllLevel3ValueByPrimaryCompany(
        primaryCompanyId,
      );
    if (level3Values?.items) {
      level3Values.items.forEach((item: any) => {
        if (item) {
          if (this.selectedLevel3.includes(item.id))
            allLevel3Values.push({
              level1: item.level1ValueId || '',
              level2: item.level2ValueId || '',
              level3: item.id || '',
            });
        }
      });
    }
    this.hierarchyFilterInput = allLevel3Values;
  }

  async fetchSurveyRouteValues(primaryCompanyId: string) {
    const allSurveyRouteFilterValues: SurveyRouteFilterInput[] = [];
    const surveyRouteValues =
      await this.hirarchyFilter.getAllSurveyRouteBySubFilterInput({
        primaryCompanyId: primaryCompanyId,
      });
    if (surveyRouteValues?.items) {
      surveyRouteValues.items.forEach((item) => {
        if (item) {
          if (this.selectedSurveyRoute.includes(item.id)) {
            this.routeId = item.id;
            this.routeDescription = item.routeDescription ?? '';
            this.routeName = item.routeName ?? '';
            item.RouteAssociateAssetList?.forEach((level) => {
              level?.level3ValueIdList?.forEach((l3) => {
                allSurveyRouteFilterValues.push({
                  level1: level?.level1ValueId,
                  level2: level?.level2ValueId,
                  level3: l3,
                  assetId: level.assetId,
                });
              });
            });
          }
        }
      });
    }

    this.surveyRouteFilterInput = allSurveyRouteFilterValues;
  }

  private updateFilterModel(value: boolean) {
    if (value) {
      this.assets = [];
    } else {
      this.filterModel = {
        primaryCompany: this.selectedprimaryComapnyId,
        level4: this.selectedSegments,
        assetTypes: this.selectedAssetType,
        segmentTypes: this.selectedSegmentType,
        surveyRouteFilterInputs: this.surveyRouteFilterInput,
        hierarchyFilterInputs: this.hierarchyFilterInput,
        searchText: this.searchText,
        pageNumber: this.currentPage == 0 ? 1 : this.currentPage,
        pageSize: this.state.take ?? 50,
        gridFilters: this.kendoGridFilters,
      };
    }
  }

  async loadAssets(): Promise<void> {
    this.isLoading = true;
    await this.fetchLevel3Values(this.selectedprimaryComapnyId ?? '');
    await this.fetchSurveyRouteValues(this.selectedprimaryComapnyId ?? '');
    this.updateFilterModel(false);
    (await this.assetService.getAssets(this.filterModel)).subscribe({
      next: (data: Assets[]) => {
        this.mergeWithPrePopulatedAssets(this.assets, data);
        let order = 1;
        data.forEach((asset) => {
          asset.order = order.toString();
          order++;
        });
        if (data.length === 0 && this.selectedLevel3.length > 0) {
          this.filterDataPresent = true;
        }
        this.surveyRouteAssets = data;
        // this.assets = data;
        if (this.surveIsEditVal) {
          this.surveyAssociatedAssetVal?.forEach((associatedAsset) => {
            if (associatedAsset) {
              const matchingAsset = this.assets.find(
                (asset) => asset.id === associatedAsset.assetId,
              );
              if (matchingAsset) {
                matchingAsset.selected = true;
                matchingAsset.order = associatedAsset.order ?? '';
              }
            }
          });

          const unmatchedAssets: SurveyAssociatedAsset[] =
            this.surveyAssociatedAssetVal?.filter(
              (associatedAsset): associatedAsset is SurveyAssociatedAsset =>
                associatedAsset !== null &&
                !this.assets.some(
                  (asset) => asset.id === associatedAsset.assetId,
                ),
            ) || [];

          unmatchedAssets.forEach((unmatchedAsset) => {
            this.assets.push({
              id: unmatchedAsset.assetId,
              pk: null,
              sk: null,
              level1Id: unmatchedAsset.level1ValueId || null,
              level2Id: unmatchedAsset.level2ValueId || null,
              level3Id: unmatchedAsset.level3ValueId || null,
              name: unmatchedAsset.assetName || null,
              latitude: unmatchedAsset.latitude
                ? parseFloat(unmatchedAsset.latitude)
                : null,
              longitude: unmatchedAsset.longitude
                ? parseFloat(unmatchedAsset.longitude)
                : null,
              type: unmatchedAsset.assetType || null,
              selected: true,
              lastInspectionDate: unmatchedAsset.lastInspectionDate || null,
              assetDelinquencyDate: unmatchedAsset.assetDelinquencyDate || null,
              order: unmatchedAsset.order || '',
              status: unmatchedAsset.status,
            });
            this.assetService.assets.push({
              id: unmatchedAsset.assetId,
              pk: null,
              sk: null,
              level1Id: unmatchedAsset.level1ValueId || null,
              level2Id: unmatchedAsset.level2ValueId || null,
              level3Id: unmatchedAsset.level3ValueId || null,
              name: unmatchedAsset.assetName || null,
              latitude: unmatchedAsset.latitude
                ? parseFloat(unmatchedAsset.latitude)
                : null,
              longitude: unmatchedAsset.longitude
                ? parseFloat(unmatchedAsset.longitude)
                : null,
              type: unmatchedAsset.assetType || null,
              selected: true,
              lastInspectionDate: unmatchedAsset.lastInspectionDate || null,
              assetDelinquencyDate: unmatchedAsset.assetDelinquencyDate || null,
              order: unmatchedAsset.order || '',
              status: unmatchedAsset.status,
            });
          });
          this.assets.sort((a, b) => {
            if (a.order && b.order) {
              return parseInt(a.order, 10) - parseInt(b.order, 10);
            } else if (a.order) {
              return -1;
            } else if (b.order) {
              return 1;
            } else {
              return 0;
            }
          });

          this.isOrderButtonEnabled = true;
        }
        if (this.map) {
          this.updateExistingMarkers();
        } else {
          this.loadMap();
        }
      },
      error: (err: any) => console.error(FAILED_ASSET_LOAD, err),
      complete: () => (this.isLoading = false),
    });
  }

  mergeWithPrePopulatedAssets(
    oldAssetValues: Assets[],
    newAssetValues: Assets[],
  ) {
    const mergedMap = new Map<string, Assets>();

    // Step 1: Add selected items from oldAssetValues with valid ids first
    oldAssetValues.forEach((oldItem) => {
      if (oldItem.selected && oldItem.id) {
        mergedMap.set(oldItem.id, oldItem);
      }
    });

    // Step 2: Add remaining items from newAssetValues with valid ids, only if they are not already added
    newAssetValues.forEach((newItem) => {
      if (newItem.id && !mergedMap.has(newItem.id)) {
        mergedMap.set(newItem.id, newItem);
      }
    });

    // Convert the merged Map back to an array, preserving the order of selected items first
    this.assets = Array.from(mergedMap.values());
  }

  private async loadMap(): Promise<void> {
    const { Map } = await google.maps.importLibrary('maps');
    const mapEl = this.mapElementRef.nativeElement;
    if (this.assets.length > 0) {
      const lat = this.assets[0].latitude;
      const long = this.assets[0].longitude;
      const mapValues = { lat: lat, lng: long };

      this.map = new Map(mapEl, {
        center: mapValues,
        zoom: 12,
        mapId: '4504f8b37365c3d7',
        scaleControl: true,
        streetViewControl: false,
        zoomControl: true,
        overviewMapControl: true,
        mapTypeControl: true,
        fullscreenControl: true,
      });

      this.renderer.addClass(mapEl, 'visible');
      this.createMarkers();
      this.initDrawingManager();
    } else {
      const lat = 44.21839866380285;
      const long = -104.11857786015787;
      const mapValues = { lat: lat, lng: long };

      this.map = new Map(mapEl, {
        center: mapValues,
        zoom: 12,
        mapId: '4504f8b37365c3d7',
        scaleControl: true,
        streetViewControl: false,
        zoomControl: true,
        overviewMapControl: true,
        mapTypeControl: true,
        fullscreenControl: true,
      });

      this.renderer.addClass(mapEl, 'visible');
      this.createMarkers();
      this.initDrawingManager();
    }
  }

  private async createMarkers(): Promise<void> {
    const { Marker } = await google.maps.importLibrary('marker');
    this.infoCardElement.nativeElement.classList.add('visible');
    this.assets.forEach((asset) => {
      const markerIcon = {
        url: this.mapService.getMarkerIcon(
          this.assetLoadedFromSurveyRoute ? '✔' : asset.selected ? '✔' : '',
          this.mapService.pickMarkerColor(asset.status ?? ''),
          this.assetLoadedFromSurveyRoute || asset.selected,
          this.mapService.pickMarkerInnerText(asset.type ?? ''),
        ), // Create SVG badge URL  url: this.createBadgeIcon('✔', '#2D4B7F'), colors - #B42A78 , #2D4B7F ,#23D696 ,#003DF7
        scaledSize: new google.maps.Size(48, 48), // Size of the icon
      };

      const marker = new Marker({
        position: { lat: asset.latitude, lng: asset.longitude },
        map: this.map,
        title: asset.name ?? '',
        icon: markerIcon,
      });

      marker.addListener('click', () => this.onMarkerClick(asset));
      marker.addListener('dblclick', () => this.onMarkerDoubleClick(asset));

      this.markerMap.set(asset.id ?? '', marker); // Track marker by ID
    });
  }

  private async initDrawingManager(): Promise<void> {
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: null, // Disable drawing mode initially
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.RECTANGLE], // Available drawing modes
      },
      rectangleOptions: {
        editable: true,
        fillColor: '#FF0000',
        fillOpacity: 0.2,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
      },
    });

    this.drawingManager.setMap(this.map);
    google.maps.event.addListener(
      this.drawingManager,
      'rectanglecomplete',
      (rectangle: any) => {
        this.onRectangleDrawn(rectangle);
      },
    );
  }

  private onRectangleDrawn(rectangle: any): void {
    this.currentRectangle = rectangle; // Store the drawn rectangle
    const bounds = rectangle.getBounds();

    if (!bounds) return;
    // Clear previously selected markers
    this.clearSelectedMarkers();

    // Check and select markers within the drawn rectangle
    const newlySelectedAssets = new Set<string>();

    if (!(this.displayOrderSelection || this.displayPreviewVisible)) {
      this.assets.forEach((asset) => {
        const marker = this.markerMap.get(asset.id ?? '');
        if (marker) {
          const position = marker.getPosition();
          if (bounds.contains(position)) {
            newlySelectedAssets.add(asset.id ?? '');
            this.updateMarkerIcon(asset, true); // Update icon to selected
          }
        }
      });
    }

    // Update grid checkboxes based on newly selected assets
    this.updateGridCheckboxes(newlySelectedAssets);

    // Stop drawing mode and remove the rectangle
    this.drawingManager.setDrawingMode(null); // Stop the drawing mode
    this.currentRectangle.setMap(null); // Remove the rectangle from the map
    this.isSelectAllCheck();
  }

  private onMarkerClick(asset: Assets): void {
    this.selectedAsset = asset;
    this.infoCardElement.nativeElement.classList.add('visible');
  }
  private updateGridCheckboxes(selectedAssetIds: Set<string>): void {
    this.assets.forEach((asset) => {
      const checkbox = document.querySelector<HTMLInputElement>(
        `input[type="checkbox"][data-asset-id="${asset.id}"]`,
      );
      if (checkbox) {
        // Keep the checkbox checked if it was already checked, or if it's in the newly selected assets
        const isSelected =
          checkbox.checked || selectedAssetIds.has(asset.id ?? '');
        checkbox.checked = isSelected;

        // Set the asset.selected property to true if the asset is selected
        if (isSelected) {
          asset.selected = true;
        }
      }
    });
    this.isSelectAllCheck();
    this.updateButtonState();
  }
  private updateMarkerIcon(asset: Assets, selected: boolean): void {
    const marker = this.markerMap.get(asset.id ?? '');
    if (marker) {
      this.markerLabel =
        asset.order && this.displayOrderSelection ? asset.order.toString() : '';
      if (this.markerLabel) {
        const markerIcon = {
          url: this.mapService.getMarkerIcon(
            this.markerLabel,
            this.mapService.pickMarkerColor(asset.status ?? ''),
            selected,
            this.mapService.pickMarkerInnerText(asset.type ?? ''),
          ), // Create SVG badge URL  url: this.createBadgeIcon('✔', '#2D4B7F'), colors - #B42A78 , #2D4B7F ,#23D696 ,#003DF7
          scaledSize: new google.maps.Size(48, 48), // Size of the icon
        };
        marker.setIcon(markerIcon);
      } else {
        const markerIcon = {
          url: this.mapService.getMarkerIcon(
            '✔',
            this.mapService.pickMarkerColor(asset.status ?? ''),
            selected,
            this.mapService.pickMarkerInnerText(asset.type ?? ''),
          ), // Create SVG badge URL  url: this.createBadgeIcon('✔', '#2D4B7F'), colors - #B42A78 , #2D4B7F ,#23D696 ,#003DF7
          scaledSize: new google.maps.Size(48, 48), // Size of the icon
        };
        marker.setIcon(markerIcon);
      }
    }
  }
  private onMarkerDoubleClick(asset: Assets): void {
    this.assetService.getAssetById(asset.id ?? '').subscribe({
      next: (currentAsset: Assets | undefined) => {
        if (currentAsset) {
          const updatedAsset: Assets = {
            ...currentAsset,
            selected: !currentAsset.selected,
          };
          this.updateAsset(updatedAsset);
        } else {
          console.error(FETCHED_ASSEST_UNDEFINED);
        }
      },
      error: (err: any) => console.error(FAILED_ASSET_FETCH, err),
    });

    this.updateButtonState();
    this.isSelectAllCheck();
  }
  private updateAsset(updatedAsset: Assets): void {
    this.assetService.updateAsset(updatedAsset).subscribe({
      next: (asset: Assets) => {
        const index = this.assets.findIndex((a) => a.id === asset.id);
        if (index !== -1) {
          this.assets[index] = asset;
        }

        this.updateMarkerIcon(asset, asset.selected);
        this.updateGridCheckbox(asset);
      },
      error: (err: any) => console.error(FAILED_ASSET_UPDATE, err),
    });
  }
  updateMarkerLabel(asset: Assets): void {
    const marker = this.markerMap.get(asset.id ?? '');
    if (marker) {
      const label = asset.order ? asset.order.toString() : ''; // Check if order is not null or empty
      const markerIcon = {
        url: this.mapService.getMarkerIcon(
          label,
          this.mapService.pickMarkerColor(asset.status ?? ''),
          true,
          this.mapService.pickMarkerInnerText(asset.type ?? ''),
        ), // Create SVG badge URL  url: this.createBadgeIcon('✔', '#2D4B7F'), colors - #B42A78 , #2D4B7F ,#23D696 ,#003DF7
        scaledSize: new google.maps.Size(48, 48), // Size of the icon
      };
      marker.setIcon(markerIcon);
    }
  }
  private updateGridCheckbox(asset: Assets): void {
    const checkbox = document.querySelector<HTMLInputElement>(
      `input[type="checkbox"][data-asset-id="${asset.id}"]`,
    );
    if (checkbox) {
      checkbox.checked = asset.selected;
    }
  }
  private clearSelectedMarkers(): void {
    this.selectedAssets.forEach((assetId: string) => {
      const marker = this.markerMap.get(assetId);
      if (marker) {
        const asset = this.assets.find((a) => a.id === assetId);
        if (asset) {
          this.updateMarkerIcon(asset, false); // Update icon to unselected
        }
      }
    });
    this.selectedAssets.clear();
  }

  private updateExistingMarkers(): void {
    this.assets.forEach((asset) => {
      this.updateMarkerIcon(asset, asset.selected);
    });
  }

  onSelectAllChange(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.assets.forEach((asset) => {
      if (isChecked) {
        if (!asset.selected) {
          // Only update if not already selected
          const updatedAsset: Assets = { ...asset, selected: true };
          this.updateAsset(updatedAsset);
          this.updateButtonState();
        }
      } else {
        if (asset.selected) {
          // Only update if already selected
          const updatedAsset: Assets = { ...asset, selected: false };
          this.updateAsset(updatedAsset);
          this.updateButtonState();
        }
      }
    });
  }
  onCheckboxChange(event: Event, asset: Assets): void {
    const inputElement = event.target as HTMLInputElement;
    const selected = inputElement.checked;

    const updatedAsset: Assets = { ...asset, selected: selected };
    this.updateAsset(updatedAsset);
    this.updateButtonState();

    this.isSelectAllCheck();
  }
  drop(event: CdkDragDrop<any[]>): void {
    moveItemInArray(this.assets, event.previousIndex, event.currentIndex);
    this.updateAllMarkerLabels();
  }

  updateAllMarkerLabels(): void {
    let selectedIndex = 1;
    this.assets.forEach((asset) => {
      if (asset.selected) {
        asset.order = selectedIndex.toString();
        this.updateMarkerLabel(asset);
        selectedIndex++;
      }
    });
  }
  updateButtonState(): void {
    this.isOrderButtonEnabled = this.assets.some((asset) => asset.selected);
  }

  toggleOrderSelection(): void {
    this.showHierarchicalFilter = false;
    this.displayOrderSelection = !this.displayOrderSelection;
    this.updateAllMarkerLabels();
    this.assetsBeforeOrdering = this.assets;
    const selectedAssets = this.assets.filter((asset) => asset.selected);
    this.updateGridAndMap(selectedAssets);
    this.currentStep = 2;
  }

  updateGridAndMap(selectedAsset: Assets[]): void {
    // Update the grid with selected assets
    this.assets = selectedAsset;

    // Update the map markers
    this.updateMarkers();
  }

  private async updateMarkers(): Promise<void> {
    this.clearMarkers();
    const { Marker } = await google.maps.importLibrary('marker');
    this.assets.forEach((asset) => {
      const label = asset.order ? asset.order.toString() : '';
      const markerIcon = {
        url: this.mapService.getMarkerIcon(
          label,
          this.mapService.pickMarkerColor(asset.status ?? ''),
          true,
          this.mapService.pickMarkerInnerText(asset.type ?? ''),
        ), // Create SVG badge URL  url: this.createBadgeIcon('✔', '#2D4B7F'), colors - #B42A78 , #2D4B7F ,#23D696 ,#003DF7
        scaledSize: new google.maps.Size(48, 48), // Size of the icon
      };
      const marker = new Marker({
        position: { lat: asset.latitude, lng: asset.longitude },
        map: this.map,
        title: asset.name,
        icon: markerIcon,
      });

      this.markerMap.set(asset.id ?? '', marker);
    });
  }
  private clearMarkers(): void {
    this.markerMap.forEach((marker) => marker.setMap(null));
    this.markerMap.clear();
  }

  back() {
    this.currentStep = this.currentStep == 3 ? 2 : 1;
    this.displayOrderSelection = !this.displayOrderSelection;
    this.displayPreviewVisible = false;
    this.resetAssetData();
    this.loadAssets();
    this.createMarkers();
  }

  resetAssetData() {
    this.assets.forEach((asset) => {
      if (asset.selected) {
        const updatedAsset: Assets = {
          ...asset,
          selected: false,
          order: EMPTY_STRING,
        };
        this.updateAsset(updatedAsset);
        this.updateButtonState();
      }
    });
  }

  resetAssetSelectedData() {
    this.assets.forEach((asset) => {
      if (asset.selected) {
        this.markerMap.forEach((marker, id) => {
          const asset = this.assets.find((a) => a.id === id);
          if (asset) {
            this.updateMarkerLabelForSelectedState(marker, asset.type ?? '');
          }
        });
      }
    });
  }

  private updateMarkerLabelForSelectedState(
    marker: any,
    assettype: string,
  ): void {
    const label = '✔'; // Set the label to a checkmark
    const markerIcon = {
      url: this.mapService.getMarkerIcon(
        label,
        this.mapService.pickMarkerColor(assettype), // Assuming 'selected' is a valid type for coloring
        true,
        this.mapService.pickMarkerInnerText(assettype ?? ''),
      ),
      scaledSize: new google.maps.Size(48, 48), // Size of the icon
    };
    marker.setIcon(markerIcon);
  }

  cancel() {
    const dialogRef = this.popupDialogService.openDialog(
      CANCEL_TEMPLATE_HEADING,
      CANCEL_TEMPLATE_MSG,
      FAILED,
      CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
      () => {
        this.router.navigate([REDIRECT_SURVEY_OVERVIEW]);
        this.store.dispatch(new RemoveSurveyOverviewData());
      },
      true,
      NO,
      true,
    );

    dialogRef.afterClosed().subscribe(() => {
      this.dialog.closeAll();
    });
  }

  isSelectAllCheck() {
    if (this.assets.length > 0) {
      // Check if all checkboxes are selected
      this.allSelected = this.assets.every((item) => item.selected);
    }
  }

  //  Order selection ,preview code
  backtoOrderSelection() {
    this.showHierarchicalFilter = false;
    this.currentStep = 2;
    this.displayOrderSelection = true;
    this.displayPreviewVisible = false;
    this.restoreOriginalAssets();
    this.updatePageTitle('SURVEY_TASK_PREVIEW.ASSET_SELECTION');
  }

  backtoAssetSelection() {
    this.isBackClick = true;
    this.showHierarchicalFilter = true;
    this.currentStep = 2;
    this.displayOrderSelection = false;
    this.displayPreviewVisible = false;
    this.assets = this.assetsBeforeOrdering;
    this.clearMarkers();
    this.createMarkers();
    this.updatePageTitle('SURVEY_TASK_PREVIEW.ASSET_SELECTION');
  }

  backToCreateSurvey() {
    this.currentStep = 1;
    this.router.navigate([REDIRECT_CREATE_SURVEY], {
      queryParams: { isbackflag: true }, // or any value you want to pass
    });
  }

  displayPreview() {
    this.showHierarchicalFilter = false;
    this.originalAssetsafterOrder = [...this.assets];
    this.currentStep = 3;
    this.displayOrderSelection = false;
    this.displayPreviewVisible = true;
    this.updatePageTitle('SURVEY_TASK_PREVIEW.PREVIEW');
    this.updateSubmitButtonState();
  }

  restoreOriginalAssets(): void {
    const orderedAssets = [...this.originalAssetsafterOrder];
    this.updateGridAndMap(orderedAssets);
  }

  cancelFromPreview() {
    const dialogRef = this.popupDialogService.openDialog(
      CANCEL_TEMPLATE_HEADING,
      CANCEL_TEMPLATE_MSG,
      FAILED,
      CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
      () => {
        this.router.navigate([REDIRECT_SURVEY_OVERVIEW]);
        this.store.dispatch(new RemoveSurveyOverviewData());
      },
      true,
      NO,
      true,
    );

    dialogRef.afterClosed().subscribe(() => {
      this.dialog.closeAll();
    });
  }

  compareAssets(): boolean {
    if (this.surveyRouteAssets.length !== this.assets.length) {
      return false;
    }
    const assetsMap = new Map(
      this.assets.map((asset) => [asset.name, asset.order]),
    );

    for (const surveyRouteAsset of this.surveyRouteAssets) {
      const assetOrder = assetsMap.get(surveyRouteAsset.name);

      if (assetOrder === undefined) {
        return false;
      }

      if (surveyRouteAsset.order !== assetOrder) {
        return false;
      }
    }
    return true;
  }

  async submitSurveyTask() {
    const heading = this.translate.instant(
      'SURVEY_TASK_PREVIEW.SAVE_TASK_HEADING',
    );
    const message = this.translate.instant(
      'SURVEY_TASK_PREVIEW.SAVE_TASK_MESSAGE',
    );
    const failed = this.translate.instant('GENERIC_Buttons.FAILED');
    const Cancel = this.translate.instant('GENERIC_Buttons.CANCEL');
    const Continue = this.translate.instant('GENERIC_Buttons.CONTINUE');

    const surveyTask: CreateSurveyOverview = {
      primaryCompanyId: this.selectedprimaryComapnyId ?? '',
      surveyName: this.surveyNameVal ?? '',
      surveyType: this.surveyTypeVal ?? '',
      surveyPriority: this.surveyPriorityVal ?? '',
      dueDate: new Date(this.dueDateVal ?? ''),
      dataCollectionType: this.surveyCollectionTypeVal ?? '',
      surveyDescription: this.surveyDescriptionVal ?? '',
      assignedTo: this.assignedToVal ?? '',
      troubleshooting: this.troubleshootingVal ?? '',
    };

    const _updateSurveyTask: UpdateSurveyOverview = {
      surveyId: this.storeSurveyId ?? '',
      surveyPk: this.storeSurveyPk ?? '',
      surveySk: this.storeSurveySk ?? '',
      surveyName: this.surveyNameVal ?? '',
      surveyType: this.surveyTypeVal ?? '',
      surveyPriority: this.surveyPriorityVal ?? '',
      dueDate: new Date(this.dueDateVal ?? ''),
      dataCollectionType: this.surveyCollectionTypeVal ?? '',
      surveyDescription: this.surveyDescriptionVal ?? '',
      assignedTo: this.assignedToVal ?? '',
      troubleshooting: this.troubleshootingVal ?? '',
    };

    let assetOrderChanged = false;
    if (this.assetLoadedFromSurveyRoute) {
      assetOrderChanged = !this.compareAssets();
    }

    if (assetOrderChanged) {
      const dialogRef = this.dialog.open(SurveyRouteEditModalComponent, {
        data: { routeName: this.routeName,isSurveyRouteFlag: false }, // Pass your data here
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (result == 2) {
            const dialogRefRoute = this.dialog.open(
              AddSurveyRouteModalComponent,
              {
                data: { allowNavigation: false }, // Directly pass the data object
              },
            );

            dialogRefRoute.afterClosed().subscribe(async (route) => {
              if (route) {
                const input: SurveyRouteCreateInput = {
                  primaryCompanyId: this.selectedprimaryComapnyId ?? '',
                  routeDescription: route.routeDescription,
                  routeName: route.routeName,
                  surveyRouteAssociatedAssetInput: [
                    ...this.assets.map((asset) => ({
                      assetId: asset.id,
                      level1ValueId: asset.level1Id ?? null,
                      level2ValueId: asset.level2Id ?? null,
                      level3ValueId: asset.level3Id ?? null,
                      order: asset.order,
                    })),
                  ],
                };

                const response =
                  await this.surveyRouteService.createSurveyRoute(input);

                this.isLoading = true;
                if (response!.status === 'Success') {
                  this.popupDialogService.openDialog(
                    'Survey Route Created Successfully!',
                    "You'll be redirected to Survey Route Overview list page",
                    SUCCESS,
                    CLOSE,
                    () => this.router.navigate([REDIRECT_SURVEY_OVERVIEW]),
                  );
                } else {
                  this.popupDialogService.openDialog(
                    'Creating Survey Route Failed!',
                    'An unexpected error occurred. Please try adding your User again.',
                    FAILED,
                    CLOSE,
                  );
                  this.toastr.showError(
                    response?.error?.message ?? API_ERROR_MSG,
                    ERROR,
                  );
                }

                if (this.surveIsEditVal) {
                  this.surveyOverviewService.createSurveyUpdateInput(
                    _updateSurveyTask,
                    this.assets,
                  );
                } else {
                  this.surveyOverviewService.addSurveyEntry(
                    surveyTask,
                    this.assets,
                  );
                }

                this.store.dispatch(new RemoveSurveyOverviewData());
              }
              this.dialog.closeAll();
              this.isLoading = false;
            });
          } else if (result == 1) {
            const dialogRef = this.popupDialogService.openDialog(
              heading,
              message,
              failed,
              Continue,
              async () => {
                this.isLoading = true;
                if (this.surveIsEditVal) {
                  this.surveyOverviewService.createSurveyUpdateInput(
                    _updateSurveyTask,
                    this.assets,
                  );

                  this.store.dispatch(new RemoveSurveyOverviewData());
                } else {
                  this.surveyOverviewService.addSurveyEntry(
                    surveyTask,
                    this.assets,
                  );
                  this.store.dispatch(new RemoveSurveyOverviewData());
                }
                const input: SurveyRouteUpdateInput = {
                  id: this.routeId,
                  pk: `PC#${this.selectedprimaryComapnyId}`,
                  sk: `SurveyRoute#${this.routeId}`,
                  routeDescription: this.routeDescription,
                  routeName: this.routeName,
                  surveyRouteAssociatedAssetInput: [
                    ...this.assets.map((asset: Assets) => ({
                      assetId: asset.id,
                      level1ValueId: asset.level1Id ?? null,
                      level2ValueId: asset.level2Id ?? null,
                      level3ValueId: asset.level3Id ?? null,
                      order: asset.order,
                    })),
                  ],
                };
                const response =
                  await this.surveyRouteService.updateSurveyRoute(input);
                this.isLoading = false;
                if (response!.status === 'Success') {
                  this.popupDialogService.openDialog(
                    'Survey Route Updated Successfully!',
                    "You'll be redirected to Survey Route Overview list page",
                    SUCCESS,
                    CLOSE,
                    () => this.router.navigate([REDIRECT_SURVEY_OVERVIEW]),
                  );
                } else {
                  this.popupDialogService.openDialog(
                    'Updating Survey Route Failed!',
                    'An unexpected error occurred. Please try again.',
                    FAILED,
                    CLOSE,
                  );
                  this.toastr.showError(
                    response?.error?.message ?? API_ERROR_MSG,
                    ERROR,
                  );
                  this.isLoading = false;
                }
              },
              true,
              Cancel,
              true,
            );
            dialogRef.afterClosed().subscribe(() => {
              dialogRef.close();
            });
          } else if (result == 3) {
            if (this.surveIsEditVal) {
              this.surveyOverviewService.createSurveyUpdateInput(
                _updateSurveyTask,
                this.assets,
              );
            } else {
              this.surveyOverviewService.addSurveyEntry(
                surveyTask,
                this.assets,
              );
            }
            this.store.dispatch(new RemoveSurveyOverviewData());
          }
        } else {
          console.log(
            'No options selected or dialog was closed without selection',
          );
        }
      });
    } else {
      const dialogRef = this.popupDialogService.openDialog(
        heading,
        message,
        failed,
        Continue,
        () => {
          this.isLoading = true;
          if (this.surveIsEditVal) {
            this.surveyOverviewService.createSurveyUpdateInput(
              _updateSurveyTask,
              this.assets,
            );
            this.store.dispatch(new RemoveSurveyOverviewData());
          } else {
            this.surveyOverviewService.addSurveyEntry(surveyTask, this.assets);
          }
          //   this.router.navigate([REDIRECT_SURVEY_OVERVIEW]);
        },
        true,
        Cancel,
        true,
      );
      dialogRef.afterClosed().subscribe(() => {
        dialogRef.close();
      });
    }
    this.isLoading = false;
  }

  removeAsset(dataItem: any): void {
    const filteredAssets = this.assets.filter(
      (asset) => asset.id !== dataItem.id,
    );
    const reorderedAssets: Assets[] = filteredAssets.map((asset, index) => ({
      ...asset,
      order: (index + 1).toString(), // Convert order to string
    }));
    this.updateGridAndMap(reorderedAssets);
    this.updateSubmitButtonState();
  }

  updateSubmitButtonState(): void {
    this.isSubmitButtonDisabled = this.assets.length === 0;
  }

  updatePageTitle(title: string) {
    this.title = title;
  }
}
