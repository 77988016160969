<kendo-dialog *ngIf="isDialogOpen" (close)="onCancel()">
  <div class="calendar-icon"></div>
  <div class="button-close-x1">
    <span class="x-close-icon1" (click)="closePopup()"></span>
  </div>
  <div class="header-css mt-3">
    Set Custom Frequency
  </div>
  <p class="sub-header-css">Set custom Measurement frequency</p>

  <div class="container">
    <div class="row mb-3">
      <div class="col-12">
        <label for="startDate" class="form-label label-css">Start Date</label>
        <kendo-datepicker
          id="startDate"
          [(ngModel)]="startDate"
        ></kendo-datepicker>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-4">
        <label for="repeatEvery" class="form-label label-css">Repeat every</label>
        <input
          id="repeatEvery"
          type="number"
          [(ngModel)]="repeatInterval"
          min="1"
          class="form-control"
        />
      </div>
      <div class="col-8">
        <label for="repeatUnit" class="form-label label-css">&nbsp;</label>
        <kendo-dropdownlist
          id="repeatUnit"
          [data]="repeatOptions"
          [(ngModel)]="selectedRepeatOption"
        >
        </kendo-dropdownlist>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <p class="text-muted">
          <strong>
          Occurs every {{ repeatInterval }}
          {{ selectedRepeatOption.toLowerCase() }} starting from
          {{ startDate | date: 'dd-MM-yyyy' }}.
        </strong>
        </p>
      </div>
    </div>
  </div>
  <kendo-dialog-actions>
    <button
      kendoButton
      (click)="onSave()"
      class="btn mtx-btn-primary btn-padding"
      [primary]="true" 
    >
      Save
    </button>
    <button kendoButton (click)="closePopup()" class="btn mtx-btn-secondary btn-padding">
        Cancel
      </button>
  </kendo-dialog-actions>
</kendo-dialog>