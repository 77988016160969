<div *ngIf="isAuthenticated$ | async">
  <app-top-navigation></app-top-navigation>
  <div class="main-container" [class.shrink]="isShrunk">
      <app-sidebar (shrink)="toggleShrink($event)"></app-sidebar>
      <div class="main-content">
        <app-breadcrumb></app-breadcrumb>
        <router-outlet></router-outlet>
      </div>
  </div>
</div> 
<div *ngIf="defaultLoad">
  <router-outlet></router-outlet>
</div>
<app-auth-return></app-auth-return> 
