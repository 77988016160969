import { Component } from '@angular/core';

@Component({
  selector: 'app-left-navigation',
  templateUrl: './left-navigation.component.html',
  styleUrl: './left-navigation.component.css',
})
export class LeftNavigationComponent {
  isExpand = true;
}
