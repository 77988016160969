import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ReportTemplatesLibraryService } from '../../core/services/report-templates-library.service';
import {
  BUTTON_BACKGROUND_COLOR,
  CREATE_REPORT,
  SEARCHREPORTS,
  DEFAULTPAGESIZE,
  REPORT_TEMPLATE_SCREEN,
} from '../../shared/constants';
import { Router } from '@angular/router';
import { ReportTemplateLibrary } from '../../core/models/report-templates-library.module';
import { REDIRECT_CREATE_REPORT } from '../../shared/constants';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { SetTemplate } from '../../core/store/template.state';
import { GridComponent } from '@progress/kendo-angular-grid';
import { PermissionService } from '../../core/services/permission.service';

@Component({
  selector: 'app-report-templates-library',
  templateUrl: './report-templates-library.component.html',
  styleUrl: './report-templates-library.component.css',
})
export class ReportTemplatesLibraryComponent implements OnInit {
  @ViewChild('grid') grid!: GridComponent;
  gridData: ReportTemplateLibrary[] = [];
  selectedTemplate: any = null;
  public originalData: any[] = [];
  public pageSize = DEFAULTPAGESIZE;
  public expandedRows: Record<string, boolean> = {};
  disableCreateReport = true;
  public searchPlaceholder = SEARCHREPORTS;
  isFrom = 'fromTemplateLibrary';
  permission = false;

  constructor(
    private reportTemplatesService: ReportTemplatesLibraryService,
    public router: Router,
    public dialog: MatDialog,
    private store: Store,
    private renderer: Renderer2,
    public permissionService: PermissionService,
  ) {}

  navigateToCreateNewTemplate() {
    this.router.navigate(['reports/report-templates-library/create']);
  }
  ngOnInit() {
    this.permission = this.permissionService.hasPermission(REPORT_TEMPLATE_SCREEN, 'fullAccess');
    this.gridData =
      this.reportTemplatesService.generateRandomReportTemplates();
    this.originalData = [...this.gridData];
  }
  getAssetDetails(dataItem: ReportTemplateLibrary) {
    return dataItem.templateColumns.map((detail) => ({ detail }));
  }

  onTemplateSelect(template: any): void {
    this.store.dispatch(
      new SetTemplate(this.isFrom, template.templateName, template.type),
    );
    this.selectedTemplate = template;
    const element = document.getElementById(CREATE_REPORT);
    if (element) {
      this.renderer.addClass(element, BUTTON_BACKGROUND_COLOR);
    }
  }

  onCreateReportClick(): void {
    this.router.navigate([REDIRECT_CREATE_REPORT]);
  }
  handleSearchInput(changeWord: string) {
    if (changeWord.trim() === '') {
      this.gridData = [...this.originalData];
      return;
    }
    const lowerCaseChangeWord = changeWord.toLowerCase();

    const newData = this.originalData.filter((item: any) => {
      return (
        this.containsIgnoreCase(item.templateName, lowerCaseChangeWord) ||
        this.containsIgnoreCase(item.description, lowerCaseChangeWord) ||
        this.containsIgnoreCase(item.type, lowerCaseChangeWord) ||
        this.containsIgnoreCase(item.lastUsedOn, lowerCaseChangeWord) ||
        this.containsIgnoreCase(item.templateColumns, lowerCaseChangeWord)
      );
    });

    this.gridData = newData;
    this.gridData = this.gridData.slice(0, this.pageSize);
    this.grid.pageChange.emit({ skip: 0, take: this.pageSize });
  }

  containsIgnoreCase(field: any, searchTerm: string): boolean {
    if (typeof field === 'string') {
      return field.toLowerCase().includes(searchTerm);
    }
    return false;
  }

  public toggleDescription(rowId: string): void {
    this.expandedRows[rowId] = !this.expandedRows[rowId];
  }

  public isExpanded(rowId: string): boolean {
    return !!this.expandedRows[rowId];
  }
}
